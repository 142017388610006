import React, { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
import star_full from "../../../../images/category_star_full.png";
import star_blank from "../../../../images/category_star_blank.png";
import recipe_banner_img from "../../../../images/Group-40065_11zon.png";
import icon_veg from "../../../../images/Icon/veg.svg";
import icon_nonveg from "../../../../images/Icon/nonveg.svg";
// import message from "../../../../images/message 1.png";
import foodtray from "../../../../images/food-tray 1.png";
import cutlery from "../../../../images/cutlery.png";
import trend from "../../../../images/trend.png";
import deskbell from "../../../../images/desk-bell 1.png";
import stopwatch from "../../../../images/stopwatch.png";
import stopwatch1 from "../../../../images/stopwatch1.png";
import burning from "../../../../images/burning.png";
import clock from "../../../../images/clock.png";
// import notes from "../../../../images/notes.png";
// import user from "../../../../images/user(450).jpg";
// import Commentthumb from "../../../../images/Comment-thumb.png";
// import Commentthumb1 from "../../../../images/Comment-thumb1.png";
import { Tab, Backdrop, Box, CircularProgress } from "@mui/material";
import Rating from "react-rating";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Table } from "reactstrap";
import RatingPopUp from "../RatingPopUp/index";
import RecipeReview from "../RecipeReview/index"
// import { GetCallApi } from "../../../../Action/Action";
// import cardimg2 from "../../../../images/default_profile.jpg";

const renderLoader = () => <p></p>;
function RecipeDeatail() {
  // const [value, setValue] = React.useState(2);
  const { id } = useParams();
  // const [count, setCount] = useState(0);
  // const [upthumb, setupThumb] = useState(0);
  // const [downthumb, setdownThumb] = useState(0);
  // function increment() {
  //   //setCount(prevCount => prevCount+=1);
  //   setCount(function (prevCount) {
  //     return (prevCount += 1);
  //   });
  // }

  // function decrement() {
  //   setCount(function (prevCount) {
  //     if (prevCount > 0) {
  //       return (prevCount -= 1);
  //     } else {
  //       return (prevCount = 0);
  //     }
  //   });
  // }

  // const changeValUpThumb = () => {
  //   //setCount(prevCount => prevCount+=1);
  //   if (upthumb === 0) {
  //     setupThumb(upthumb + 1);
  //   } else {
  //     setupThumb(0);
  //   }
  // };
  // function downThumb() {
  //   //setCount(prevCount => prevCount+=1);
  //   setdownThumb(function (prevThumb) {
  //     return (prevThumb += 1);
  //   });
  // }
  // const Nutritionfacts = [
  //   {
  //     text: "0.72 Can All Purpose Pan Spray",
  //     per: "33%",
  //   },
  //   {
  //     text: "12.39 Pounds Ground Beef",
  //     per: "50%",
  //   },
  //   {
  //     text: "8.75 Ounces Liquid Egg",
  //     per: "10%",
  //   },
  //   {
  //     text: "1.82 Pounds Bread Crumbs",
  //     per: "05%",
  //   },
  //   {
  //     text: "8.75 Cups Ketchup",
  //     per: "12%",
  //   },
  //   {
  //     text: "0.36 Cup Worcestershire Sauce",
  //     per: "14%",
  //   },
  //   {
  //     text: "0.72 Can All Purpose Pan Spray",
  //     per: "33%",
  //   },
  //   {
  //     text: "0.72 Can All Purpose Pan Spray",
  //     per: "33%",
  //   },
  //   {
  //     text: "12.39 Pounds Ground Beef",
  //     per: "50%",
  //   },
  //   {
  //     text: "8.75 Ounces Liquid Egg",
  //     per: "10%",
  //   },
  //   {
  //     text: "1.82 Pounds Bread Crumbs",
  //     per: "05%",
  //   },
  //   {
  //     text: "8.75 Cups Ketchup",
  //     per: "12%",
  //   },
  //   {
  //     text: "0.36 Cup Worcestershire Sauce",
  //     per: "14%",
  //   },
  //   {
  //     text: "0.72 Can All Purpose Pan Spray",
  //     per: "33%",
  //   },
  // ];

  const [open, setOpen] = useState(false);
  const [reData, setReData] = useState([]);

  const [values, setValues] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (id) {
      getDataRecipeById(id);
    }
    // eslint-disable-next-line
  }, [id]);
  const getDataRecipeById = (id) => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Recipe/GetRecipeDataByRecipeID?recipeId=${id}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpen(false);
            let rData = res.data.payload;
            setReData(rData);
            // setCount(rData?.servings);
          }
        }
      })
      .catch((errors) => {
        setOpen(false);
        console.log(errors);
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="recipe_detail_page">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="recipe_d_title">Recipe Detail</div>
            <div className="recipe_detail_main_box">
              <div className="recipe_course_box">
                <div className="recipe_banner">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="img_re">
                        <img
                          src={
                            reData?.coverImage
                              ? `${Config.API_HOST_URL_live}${reData?.coverImage}`
                              : recipe_banner_img
                          }
                          className="img-fluid baner_re_img"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="recipe_banner_info">
                        <div className="title mb-3">
                          <h3>{reData?.name}</h3>
                          <img
                            src={reData.isVeg ? icon_veg : icon_nonveg}
                            alt=""
                          />
                        </div>
                        <div className="description mb-3">
                          <p>Recipe by {reData?.userName}</p>
                        </div>
                        <div className="rating mb-3">
                          <div className="rate">
                            <Rating
                              emptySymbol={
                                <img src={star_blank} alt="rating_blank" />
                              }
                              fullSymbol={
                                <img src={star_full} alt="rating-full" />
                              }
                              initialRating={reData?.rating}
                              readonly
                            />
                          </div>
                          <RatingPopUp btnName="Recipe Review" recipeId={reData?.recipeId} getDataRecipeById={getDataRecipeById} />
                          {/* <div className="comment">
                            <img
                              src={message}
                              className="img-fluid"
                              width={40}
                              alt=""
                            />
                            <span>{reData?.comment} comment</span>
                          </div> */}
                        </div>
                        <div className="main_desc">
                          <p>{reData?.shortDescription}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="recipe_course_box mt-4">
                    <div className="list">
                      <div className="icon">
                        <img src={foodtray} alt="" width={40} />
                      </div>
                      <div className="text">
                        <p>
                          Course: <span>{reData?.course}</span>
                        </p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="icon">
                        <img src={cutlery} alt="" width={40} />
                      </div>
                      <div className="text">
                        <p>
                          Cuisine: <span>{reData?.cuisine}</span>
                        </p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="icon">
                        <img src={trend} alt="" width={40} />
                      </div>
                      <div className="text">
                        <p>
                          Difficulty: <span>{reData?.difficulty}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="recipe_course_box1 mt-4">
                    <div className="icon">
                      <img src={deskbell} alt="" width={40} />
                    </div>
                    <div className="text">
                      <p>Serving Size</p>
                      {/* <p>Servings</p> */}
                      <div className="count">
                        {/* <button>+</button> */}
                        {/* <button onClick={increment}>+</button> */}
                        <span>{reData?.servingSize}</span>
                        {/* <button>-</button> */}
                        {/* <button onClick={decrement}>-</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <div className="recipe_timeing_details">
                    <div className="time_box">
                      <img
                        src={stopwatch}
                        className="img-fluid"
                        alt=""
                        width={40}
                      />
                      <p>{reData?.timeToPrepare}</p>
                      <span>Prep time</span>
                    </div>
                    <div className="time_box">
                      <img
                        src={stopwatch1}
                        className="img-fluid"
                        alt=""
                        width={40}
                      />
                      <p>{reData?.timeTocookieing}</p>
                      <span>Cooking time</span>
                    </div>
                    <div className="time_box">
                      <img
                        src={burning}
                        className="img-fluid"
                        alt=""
                        width={40}
                      />
                      <p>{reData?.calories}</p>
                      <span>Calories</span>
                    </div>
                    <div className="time_box">
                      <img
                        src={clock}
                        className="img-fluid"
                        alt=""
                        width={40}
                      />
                      <p>{reData?.timeToResting}</p>
                      <span>Resting Time</span>
                    </div>
                    {/* <div className="time_box">
                      <img
                        src={foodtray}
                        className="img-fluid"
                        alt=""
                        width={40}
                      />
                      <p>{reData?.servingSize}</p>
                      <span>Serving Size</span>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-md-12 mt-5">
                  <div className="notes">
                    <div className="title mb-4">
                      <h3>Notes</h3>
                    </div>
                    {reData?.noteComments?.map((note, index) => (
                      <div className="notes_box mt-2" key={index}>
                        <img
                          src={notes}
                          className="img-fluid"
                          alt=""
                          width={40}
                        />
                        <p>{note}</p>
                      </div>
                    ))}
                  </div>
                </div> */}
                {/* <div className="col-md-6 mt-5">
                  <div className="nutrition_facts">
                    <div className="title mb-4">
                      <h3>Nutrition Facts</h3>
                    </div>
                    <div className="nutrition_facts_box mt-2">
                      <div className="cmg_soon">Coming Soon</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <div className="directions">
                    <div className="title mb-4">
                      <h3>Directions</h3>
                    </div>
                    <div className="directions_box mt-2">
                      <span>{reData?.preparingDirections}</span>
                    </div>
                  </div>
                </div> */}
                <TabContext value={values}>
                  <Box
                    className="tab_group mt-5"
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                  >
                    <TabList
                      className="comment_tab_button"
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="Preparation Instructions"
                        value="1"
                        className="tab_1"
                      />
                      <Tab label="Other Details" value="2" className="tab_2" />
                      <Tab label="Ingredients" value="3" className="tab_3" />
                      <Tab label="Vitamin" value="4" className="tab_4" />
                      <Tab label="Allergen" value="5" className="tab_5" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="tab_panel">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text">
                          <p>
                            <b>Note:</b>{" "}
                            {reData?.notes?.map((n) => n?.note1)?.join(", ")}{" "}
                            <br />
                          </p>
                          <p style={{ whiteSpace: "pre-line" }}>
                            {reData?.preparingDirections}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2" className="tab_panel">
                    <div className="row">
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>{reData?.name} Serving Size :</b>{" "}
                            {reData?.servingSize}
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Serving Measure :</b> {reData?.servingMeasure}
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Yield :</b> {reData?.yield}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Nutrient Facts</b>
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Serving Size : </b> {reData?.servingSize}
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Serving Weight :</b> {reData?.servingWeight} gm
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Amounts Per Saving</b>
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Calories :</b> {reData?.calories} Kcal
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <p className="p_16_text">
                          <b>Total Fat :</b> {reData?.totalFat} gm
                        </p>
                        <div>
                          <p className="p_16_text">
                            <b>Saturated Fat :</b> {reData?.saturatedFat} gm
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Trans Fat :</b> {reData?.transFat} gm
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Cholesterol :</b> {reData?.cholesterol} mg
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Sodium :</b> {reData?.sodium} mg
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Potassium :</b> {reData?.potassium} mg
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Carbohydrates :</b> {reData?.carbohydrates} gm
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b> Fiber :</b> {reData?.fiber} gm
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b> Sugars :</b> {reData?.sugars} gm
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Iron :</b> {reData?.iron} mg
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">
                            <b>Calcium :</b> {reData?.calcium} mg
                          </p>
                        </div>
                        {/* <div>
                          <p className="p_16_text">
                            <b>Saturated Fat :</b> {reData?.saturatedFatPer} %
                          </p>
                        </div> */}
                        <div>
                          <p className="p_16_text">
                            <b>Saturated Fat % of Calories :</b>{" "}
                            {reData?.caloriesPer} %
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Protein :</b> {reData?.protein} gm
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Meal Components</b>
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">{reData?.mealComponents}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <p className="p_16_text">
                            <b>Attributes</b>
                          </p>
                        </div>
                        <div>
                          <p className="p_16_text">{reData?.attributes}</p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="3" className="tab_panel">
                    {/* <div className="row">
                      <div className="col-md-8">
                        <div className="text">
                          <p className="p_16_text">
                            <b>
                              Ingredients
                            </b>
                          </p>
                        </div>
                        <div className="text">
                          <ul>
                            {reData?.ingredientData?.map((i, index) => <li key={index}><p className="p_16_text">{i?.ingredients}</p></li>)}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="text">
                          <p className="p_16_text">
                            <b>
                              Quantity
                            </b>
                          </p>
                        </div>
                        <div className="text">
                          <ul>
                            {reData?.ingredientData?.map((i, index) => <li key={index}><p>{i?.quantity}</p></li>)}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <div className="table-responsive mb-5">
                      <Table className="">
                        <thead>
                          <tr className="table_pading">
                            <th>
                              <p>
                                <b>Ingredients</b>
                              </p>
                            </th>
                            <th>
                              <p>
                                <b>Quantity</b>
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reData?.ingredientData?.map((i, index) => (
                            <tr key={index}>
                              <td>
                                <p>{i?.ingredients}</p>
                              </td>
                              <td>
                                <p>{i?.quantity}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </TabPanel>
                  <TabPanel value="4" className="tab_panel">
                    <div className="table-responsive mb-5">
                      <Table className="">
                        <thead>
                          <tr className="table_pading">
                            <th>
                              <p>
                                <b>Vitamin Name</b>
                              </p>
                            </th>
                            <th>
                              <p>
                                <b>Vitamin Weight</b>
                              </p>
                            </th>
                            <th>
                              <p>
                                <b>Vitamin Measure Type</b>
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reData?.vitaminData?.map((v, index) => (
                            <tr key={index}>
                              <td>
                                <p>{v?.vitaminName}</p>
                              </td>
                              <td>
                                <p>{v?.vitaminWeight}</p>
                              </td>
                              <td>
                                <p>{v?.measureType}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </TabPanel>
                  <TabPanel value="5" className="tab_panel">
                    <div className="col-md-3">
                      <div className="text">
                        <p>
                          <b>Allergens</b>
                        </p>
                      </div>
                      <div className="text">
                        <p>
                          {reData?.allergenData
                            ? reData?.allergenData
                              ?.map((a) => a?.allergenName)
                              ?.join(", ")
                            : "No Allergens"}
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
                {/* <div className="col-md-12 mt-5">
                  <div className="comments">
                    <div className="title mb-4">
                      <h3>Directions</h3>
                    </div>
                    <div className="comments_box mt-2">
                      <div className="w-100 comment-sec d-flex">
                        <img
                          src={user}
                          className="img-fluid user_icon"
                          alt=""
                        />
                        <div className="comment_info">
                          <h3>Becki Swan</h3>
                          <span className="date_time">
                            May 26, 2022 at 6:15 pm
                          </span>
                          <p>
                            Thank you @jmurban for the great recipe. Thank you
                            @jmurban for the great recipe. Thank you @jmurban
                            for the great recipe.{" "}
                          </p>
                          <div className="comment_action mt-4">
                            <div className="comment_action_inner me-4">
                              <Button
                                className=""
                                onClick={changeValUpThumb}
                                default1
                                disabled={upthumb === 1 ? true : false}
                              >
                                <Badge badgeContent={upthumb} color="primary">
                                  <img
                                    src={Commentthumb}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </Badge>
                              </Button>
                            </div>
                            <div className="comment_action_inner">
                              <Button className="">
                                <Badge badgeContent={downthumb} color="primary">
                                  <img
                                    src={Commentthumb1}
                                    className="img-fluid"
                                    onClick={downThumb}
                                    alt=""
                                  />
                                </Badge>
                              </Button>
                            </div>
                            <Button className="theme_btn">Reply</Button>
                          </div>
                        </div>
                        <div className="rating_comment">
                          <Rating
                            emptySymbol={
                              <img src={star_blank} alt="rating_blank" />
                            }
                            fullSymbol={
                              <img src={star_full} alt="rating-full" />
                            }
                            initialRating={
                              reData?.rating ? reData?.rating : "0"
                            }
                            readonly
                          />
                          <Button className="theme_btn">Report Comment</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <RecipeReview />
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default React.memo(RecipeDeatail);
