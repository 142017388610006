import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import uploadimg from "../../../images/Icon/file-upload.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../Utils/Config";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../Action/Action";

const renderLoader = () => <p></p>;

function CreateTicket(props) {
  const { changeTab } = props;
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);
  const [schList, setSchList] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    let tdata = {
      ...data,
      IsSentByFigSupport: false,
      CreatedBy: LoginUserde?.userId,
      IsWithOutLogin: false,
      Status: "created",
    };
    delete tdata?.imgUpload;
    setOpenCircle(true);
    // axios
    //   .post(
    //     `${Config.API_HOST_URL_live}/api/SupportTicket/CreateSupportTicket`,
    //     tdata,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         "Content-Type": "multipart/form-data",
    //         Accept: "*/*",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       if (res.data.success) {
    //         setOpenCircle(false);

    let seData = {
      url: `/api/SupportTicket/CreateSupportTicket`,
      body: tdata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };

    let response = await PostCallApi(seData);
    setOpenCircle(false);
    if (response?.status === 200) {
      if (response.data.success) {
        changeTab("2");
        reset();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        setValue(
          "UserName",
          LoginUserde?.firstName + " " + LoginUserde?.lastName
        );
        setValue("Email", LoginUserde?.email);
        setValue("UserId", LoginUserde?.userId);
        setValue("Priority", "Medium");
        setValue("SupportCategoryId", 1);
        //     } else {
        //       setOpenCircle(false);
        //     }
        //   } else {
        //     setOpenCircle(false);
        //   }
        // })
        // .catch((e) => console.log(e));
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  useEffect(() => {
    setValue("UserName", LoginUserde?.firstName + " " + LoginUserde?.lastName);
    setValue("Email", LoginUserde?.email);
    setValue("UserId", LoginUserde?.userId);
    setValue("Phone", LoginUserde?.phone);
    setValue("Priority", "Medium");
    setValue("SupportCategoryId", 1);
    getSchoolList();
    // eslint-disable-next-line
  }, []);

  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LoginUserde?.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            const data = response?.data.payload?.map((item) => {
              return {
                schId: item?.schoolDistrictId,
                schName: item?.districtName,
              };
            });
            setSchList(data);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const handleImg = (e) => {
    setValue("FilePath", e.target.files[0]);
    setValue("imgUpload", e.target.files[0].name);
    e.target.files = null;
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="CreateTicket">
          <div className="row">
            {/* <div className="col-md-5 me-auto">
                            <div className="title">Frequently Asked Questions</div>
                            <div className="content">
                                <ul>
                                    <li>- Hosting quick start guide</li>
                                    <li>- What's my temporary domain name?</li>
                                    <li>- How to map a domain name?</li>
                                    <li>- What's my domain name IP address?</li>
                                </ul>
                            </div>
                        </div> */}
            <div className="col-md-12">
              <div className="title">
                Please enter all information below and someone will respond to
                your inquiry within 24 hours. For immediate support call (800)
                817-1408
              </div>
              <div className="content">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          {...register("UserName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.UserName && (
                          <span role="alert" className="error_text">
                            {errors.UserName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email (This field is not editable)
                        </label>
                        <input
                          type="text"
                          className="form-control disabled"
                          placeholder="Enter email"
                          disabled={true}
                          {...register("Email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.Email && (
                          <span role="alert" className="error_text">
                            {errors.Email.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Phone</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Enter Phone"
                          {...register("Phone", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.Phone && (
                          <span role="alert" className="error_text">
                            {errors.Phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Priority</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("Priority", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Priority</option>
                          <option value="High">High</option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                        </select>
                        {errors.Priority && (
                          <span role="alert" className="error_text">
                            {errors.Priority.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Category</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("SupportCategoryId", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        >
                          <option value="">Select Category</option>
                          <option value="3">Data Entry Service</option>
                          <option value="2">Sales or Other Help</option>
                          <option value="1">Technical Support</option>
                        </select>
                        {errors.SupportCategoryId && (
                          <span role="alert" className="error_text">
                            {errors.SupportCategoryId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter subject"
                          {...register("Subject", {
                            required: "This field is required",
                          })}
                        />

                        {errors.Subject && (
                          <span role="alert" className="error_text">
                            {errors.Subject.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Upload Attachment</label>
                      <div className="upload_img">
                        <input
                          type="text"
                          className="form-control upload_box"
                          placeholder="No file Chosen"
                          readOnly
                          {...register("imgUpload", {
                            // required: "This field is required",
                          })}
                        />
                        <input
                          type="file"
                          id="attch_tic"
                          // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          className="form-control"
                          style={{ display: "none" }}
                          placeholder="User You Want To Be In District"
                          onChange={handleImg}
                        />
                        <label
                          htmlFor="attch_tic"
                          className="upload_img_label mb-0 "
                        >
                          <img src={uploadimg} className="img-fluid" alt="" />
                        </label>
                      </div>
                      {errors?.imgUpload && (
                        <span role="alert" className="error_text">
                          {errors.imgUpload.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">School District</label>
                        <select
                          className="form-select"
                          {...register("SchoolDistrictId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School District</option>
                          {schList &&
                            schList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.schName}
                              </option>
                            ))}
                        </select>
                        {errors.SchoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.SchoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Description</label>
                        <textarea
                          rows={5}
                          type="text"
                          className="form-control"
                          placeholder="Enter description"
                          {...register("Description", {
                            required: "This field is required",
                          })}
                        />
                        {errors?.Description && (
                          <span role="alert" className="error_text">
                            {errors.Description.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" mt-2 d-flex justify-content-between">
                    <Button type="submit" className="theme_btn">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(CreateTicket);
