import React, { useState, useCallback } from "react";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import pro_img from "../../../../../images/default_profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../../Utils/Config";
import axios from "axios";
import { LoginUser } from "../../../../../reducers/login";
import Swal from "sweetalert2";
import CropModal from "./cropmodel";
import { Button } from "@mui/material";

export default function Profile_img(props) {
  const { userProfile } = props;
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);
  let dispatch = useDispatch();
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const handleProImg = (e) => {
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/User/UserProfile`,
        {
          UserId: LoginUserde?.userId,
          ProfileImage: e,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          let loginDeatil = {
            ...response.data.payload,
            authenticate: true,
          };
          cancleprofile();
          dispatch(LoginUser(loginDeatil));
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      });
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [profiledit, setProfiledit] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const [newFile, setNewFile] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        //handleProImg(url);
        setProfiledit(url);
        setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      setNewFile(e.target.files[0])
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  }; 
   const handleFileEdit = (e) => {

      const reader = new FileReader();
      setFileName(newFile.name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(newFile);
    
  };

  const cancleprofile = () => {
    setProfiledit(null);
    setCroppedImageUrl(null);
    setNewFile()
  };

  return (
    <>
      <div className="profile-overlap mb-3">
        <div className="edit_profile">
          {croppedImageUrl ? (
            <div className="p_img_h">
              <img src={croppedImageUrl} alt="cropImage" />
            </div>
          ) : (
            <div className="p_img_h">
              <img
                src={
                    LoginUserde?.thumbnailProfileUrl === "" ||
                      LoginUserde?.thumbnailProfileUrl === null
                    ? pro_img
                      : `${Config.API_HOST_URL_live}${LoginUserde?.thumbnailProfileUrl}`
                }
                alt=""
              />
            </div>
          )}
          <div className="cap_icon">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleFile}
              />
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="44" rx="22" fill="white" />
                <path
                  d="M32 28.5455C32 29.0277 31.8084 29.4901 31.4675 29.8311C31.1265 30.1721 30.664 30.3636 30.1818 30.3636H13.8182C13.336 30.3636 12.8735 30.1721 12.5325 29.8311C12.1916 29.4901 12 29.0277 12 28.5455V18.5455C12 18.0632 12.1916 17.6008 12.5325 17.2598C12.8735 16.9188 13.336 16.7273 13.8182 16.7273H17.4545L19.2727 14H24.7273L26.5455 16.7273H30.1818C30.664 16.7273 31.1265 16.9188 31.4675 17.2598C31.8084 17.6008 32 18.0632 32 18.5455V28.5455Z"
                  stroke="#2C2E35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 26.7273C24.0083 26.7273 25.6364 25.0993 25.6364 23.091C25.6364 21.0826 24.0083 19.4546 22 19.4546C19.9917 19.4546 18.3636 21.0826 18.3636 23.091C18.3636 25.0993 19.9917 26.7273 22 26.7273Z"
                  stroke="#2C2E35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="profile_info">
          <h2>{userProfile?.firstName + " " + userProfile?.lastName}</h2>
          <div className="d-flex profile_details_name">
            <p>{userProfile?.followers} followers</p>
            <p>{userProfile?.following} following</p>
          </div>
        </div>
      </div>
      {profiledit ? (
        <div className="profile-edit-buttons d-block">
          <Button className="profile-btn red-bg" onClick={() => cancleprofile()}>Cancel</Button>
          <Button className="profile-btn" onClick={()=> handleProImg(profiledit)}>Save</Button>
          <Button className="profile-btn" onClick={()=> handleFileEdit()}>Edit</Button>
        </div>
        ) : (
          ''
      )}
      <div className="crop_box">
        {src && (
          <CropModal
            src={src}
            filename={filename}
            setCroppedImageUrl={setCroppedImageUrlCallback}
            openCropModal={openCropModal}
            setCropModal={setCropModal}
          />
        )}
      </div>
    </>
  );
}
