import React, { useEffect, useState } from "react";
import userimg from "../../../../../../images/user.png";
import axios from "axios";
import Config from "../../../../../../Utils/Config";
import { useSelector } from "react-redux";
import "./index.scss"

function ViewConnectionList(props) {
  const [conList, setConList] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getListCon();
    // eslint-disable-next-line
  }, []);
  const getListCon = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyConnectionsList?userId=${props?.id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const Mylist = response.data.payload?.map((item) => {
            return {
              userId: item?.userId,
              sentRequestUserId: item?.sentRequestUserId,
              name: item?.firstName + " " + item?.lastName,
              img:
                item?.profileUrl === null || item?.profileUrl === ""
                  ? userimg
                  : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
              activeHours:
                item?.activeHours !== null || item?.activeHours !== ""
                  ? item?.activeHours
                  : "0",
              followers: item?.followers,
              join:
                item?.userJoinDate === "" || item.userJoinDate === null
                  ? ""
                  : convert(item?.userJoinDate?.split("T")[0]),
            };
          });
          setConList(Mylist);
        }
      });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day].join(" ");
  }
  const FilterList = conList?.filter((user) => {
    if (props?.searchField) {
      return user?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(props?.searchField.toLowerCase());
    } else return user;
  });
  return (
    <div className="row view_connection_List">
      {props.grid === true ? (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-md-3 mb-3" key={i}>
              <div className="grid_connection_card1 text-center">
                <div class="connection_card_text">
                  <img
                    src={item?.img}
                    className="img-fluid mb-3 user_img_p"
                    alt=""
                  />
                  <h6>{item?.name}</h6>
                  <p>Hire Date {item?.join}</p>
                  <p>
                    Active {item?.activeHours ? item?.activeHours : "-"} hours
                    ago
                  </p>
                  <p>{item?.followers} followers</p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_connection_card1">
                <div className="connection_card_text d-flex">
                  <img
                    src={item?.img}
                    className="img-fluid user_img_p"
                    alt=""
                  />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Hire Date {item?.join}</p>
                  </div>
                  <p>
                    Active {item?.activeHours ? item?.activeHours : "-"} hours
                    ago
                  </p>
                  <p>{item?.followers} followers</p>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {FilterList?.length > 0 ? (
        ""
      ) : (
        <div className="text-center my-5 p_20_text"> No Data Found</div>
      )}
    </div>
  );
}

export default React.memo(ViewConnectionList);
