import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import back_img from "../../../images/login_in_Background.svg";
import Login_img from "../../../images/login_logo.png";
import close_icon from "../../../images/Icon/eye.svg";
import open_icon from "../../../images/Icon/eye-off.svg";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import Config from "../../../Utils/Config";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../Action/Action";
const renderLoader = () => <p></p>;

function ResetPassword(props) {
  const [derror, setDerror] = useState();
  const [open, setOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [mailOtp, setMailOtp] = useState();
  const { email } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  let navigate = useNavigate();
  useEffect(() => {
    setValue("email", email?.split("email=")[1]);
    let key = email?.split("encryptedkey=")[1];
    setMailOtp(key?.split("&")[0]);
    // eslint-disable-next-line
  }, [email]);
  const onSubmit = async (data) => {
    setOpen(true);
    let mail = {
      ...data,
      otp: mailOtp,
    };
    let seData = {
      url: "/api/Login/ResetPasword",
      body: mail,
    };
    let response = await PostCallApi(seData);
    // axios
    //   .post(`${Config.API_HOST_URL_live}/api/Login/ResetPasword`, mail)
    //   .then((response) => {
    if (response?.status === 200) {
      if (response.data.success) {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        navigate("/", { replace: true });
      } else {
        setDerror({ Log: response.data?.message });
        setOpen(false);
      }
    } else {
      // })
      // .catch((error) => {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
      //   console.log(error);
      // });
    }
  };
  return (
    <Suspense fallback={renderLoader()}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="login_1">
        <div className="row m-0">
          <div className="col-md-6 p-0">
            <img src={back_img} alt="" className="img_back" />
          </div>
          <div className="col-md-6 p-0 m-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="left_logo">
                <img className="img-fluid" src={Login_img} alt="" />
              </div>
              <div className="logo_details">
                <h3>Reset Password</h3>
                <div className="form-group login_input_box">
                  <label className="form-label ">Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email Address"
                    disabled
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span role="alert" className="error_text">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="form-group login_input_box position-relative">
                  <label className="form-label ">New Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Your New Password"
                    {...register("newPassword", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                    })}
                  />

                  <span
                    className="eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  >
                    <img
                      src={passwordShown === true ? close_icon : open_icon}
                      alt=""
                    />
                  </span>
                  {errors.newPassword && (
                    <span role="alert" className="error_text">
                      {errors.newPassword.message}
                    </span>
                  )}
                </div>
                <div className="form-group login_input_box position-relative">
                  <label className="form-label ">Confirm Password</label>
                  <input
                    type={passwordShown1 ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                      validate: (val) => {
                        if (watch("newPassword") !== val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                  />
                  <span
                    className="eye"
                    onClick={() => setPasswordShown1(!passwordShown1)}
                  >
                    <img
                      src={passwordShown1 === true ? close_icon : open_icon}
                      alt=""
                    />
                  </span>
                  {errors.confirmPassword && (
                    <span role="alert" className="error_text">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
                <div>
                  <input type="submit" value="Save" className="login_button" />
                  {derror?.Log && (
                    <span role="alert" className="d-block error_text">
                      {derror.Log}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default React.memo(ResetPassword);
