import React, { useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import "./CeuCoursePopup.scss";
import SetCource from "../Popup/setCouce";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function CeuCoursePopup(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleOpen} className={"st_btn mt-auto w-100"} style={{color:"white"}}>
        {props?.btnName}
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_boxes"
        >
          <Box sx={style} className={"popup_wh width_600"}>
            <h4 className={`pupup_title`}>{props?.btnName}</h4>
            <div className="pupup_input_box">
              {props?.btnName === "Assign Course" && (
                <SetCource setOpen={setOpen} courseId={props?.courseId} />
              )}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default React.memo(CeuCoursePopup);
