import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import back_img from "../../../images/login_in_Background.svg";
import Login_img from "../../../images/login_logo.png";
import { CircularProgress } from "@mui/material";
import { TopHeaderMenuList, resetSetting } from "../../../reducers/setting";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../../reducers/login";
import Swal from "sweetalert2";
// import { Base64 } from "js-base64";
import { GetCallApi, PostCallApi } from "../../../Action/Action";

let oldMenu = [
  {
    menuName: "Community Groups",
    menuUrl: "/community-groups",
    old: true,
  },
  { menuName: "Help", menuUrl: "/help", old: true },
  { menuName: "Messages", menuUrl: "/messages", old: true },
  { menuName: "Profile", menuUrl: "/edit-profile", old: true },
  {
    menuName: "View Profile",
    menuUrl: "/view-profile",
    old: true,
  },
  {
    menuName: "Email Preference",
    menuUrl: "/email-preference",
    old: true,
  },
  {
    menuName: "Logged Reset Password",
    menuUrl: "/logged-reset-password",
    old: true,
  },
  {
    menuName: "View Quiz Submitted",
    menuUrl: "/view-quiz-submitted",
    old: true,
  },
  {
    menuName: "certificate",
    menuUrl: "/certificate",
    old: true,
  },
  {
    menuName: "Complete Payment",
    menuUrl: "/complete-payment",
    old: true,
  },
];

function SSOLogin() {
  let Location = useLocation();
  const [derror, setDerror] = useState({});
  let dispatch = useDispatch();
  let navigate = useNavigate();
  // const { code } = useParams();
  useEffect(() => {
    // getToken(Location?.search?.split("code=")[1]);
    if (Location?.search) {
      getToken(Location?.search);
      // localStorage.setItem("code", Location?.search?.split("code=")[1]);
      // window.location.replace(`/sso-login/code`);
    } else {
      // if (code) {
      //   getToken(code);
      // }
    }
    // eslint-disable-next-line
  }, []);
  // const callBack = localStorage.getItem("code");
  // useEffect(() => {
  //   if (callBack) {
  //     getToken(callBack);
  //     localStorage.removeItem("code");
  //   }
  // }, [callBack]);
  function titleCase(str) {
    var splitStr = str.toLowerCase()?.split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const getToken = async (t) => {
    setDerror({});
    let seData = {
      url: `/api/Login/oauth/callback${t}`,
    };
    let res1 = await PostCallApi(seData);
    // const data = decodeURIComponent(t);
    // const baseDecode = Base64.decode(data);
    // var tokendata = JSON.parse(baseDecode);
    if (res1?.status === 200) {
      if (res1?.data.success) {
        let loginDeatil = {
          ...res1?.data.payload,
          versionId: res1.data?.versionId,
          authenticate: true,
        };
        dispatch(resetSetting());
        let mse = {
          url: `/api/AccessLevel/GetHeaderMenu?userId=${loginDeatil?.userId}&menuType=HeaderMenu`,
          headers: {
            Authorization: `Bearer ${loginDeatil?.accessToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
        let res = await GetCallApi(mse);
        if (res?.status === 200) {
          if (res?.data.success) {
            let menuData = res.data.payload;
            menuData = [...menuData, ...oldMenu];
            if (res.data?.accessType === 3) {
              menuData = menuData?.filter((x) => x.menuUrl !== "/community");
            }
            dispatch(TopHeaderMenuList(menuData));
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(res?.data.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            let menuData = [
              {
                menuId: 1,
                menuName: "Dashboard",
                menuUrl: "/dashboard",
                sequenceNo: 2,
              },
            ];
            menuData = [...menuData, ...oldMenu];
            dispatch(TopHeaderMenuList(menuData));
            // Swal.fire({
            //   position: "center",
            //   width: "50%",
            //   // icon: "success",
            //   title: "You have no menu assign that's why not login in site. ",
            //   // text: des,

            //   confirmButtonText: "Ok",
            //   // confirmButtonColor: "#E64942",
            // });
            // localStorage.clear("expireDate");
            // dispatch(LoginUser({ authenticate: false }));
          }
        }
        dispatch(LoginUser(loginDeatil));
        navigate("/dashboard", { replace: true });
      } else {
        setDerror({ log_er: "Authentication false" });
      }
    }
  };

  return (
    <section className="login_1">
      <div className="row m-0">
        <div className="col-md-6 p-0">
          <img src={back_img} alt="" className="img_back" />
        </div>
        <div className="col-md-6 p-0 d-flex m-auto">
          <form className="form_box">
            <div className="left_logo">
              <img className="img-fluid" src={Login_img} alt="" />
            </div>
            <div className="logo_details">
              <h3 className="p_20_text">
                Please wait while we authenticate your details and logging you
                in....
              </h3>
            </div>

            {derror?.log_er ? (
              <>
                <div className="mb-4">
                  <span role="alert" className="d-block error_text">
                    {derror.log_er}
                  </span>
                </div>
                <div>
                  <Link to="/" className="back_btn">
                    Back to Login
                  </Link>
                </div>
              </>
            ) : (
              <CircularProgress sx={{ color: "#86c65a" }} />
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default SSOLogin;
