import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../Utils/Config";
import { DashboardRe } from "../../../../reducers/user";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";

function GroupCeu({ handleClose }) {
  const [derrors, setDerrors] = useState({});
  const [groupList, setGroupList] = useState();
  const [schoolList, setSchoolList] = useState();
  const [courseCode, setCourseCode] = useState([]);
  const [courseCodeList, setCourseCodeList] = useState([]);
  const [courseLear, setCourseLear] = useState([]);
  const [courseLearList, setCourseLearList] = useState([]);
  const [courseTrai, setCourseTrai] = useState([]);
  const [courseTraiList, setCourseTraiList] = useState([]);
  const [schList, setSchList] = useState([]);
  const [dateCompletion, setDateCompletion] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getCategory();
    getSchoolYear();
    if (LogUserId?.roleId === 1) {
      getSchoolList();
    } else {
      getGroupList(LogUserId?.schoolDistrictId);
    }
    // eslint-disable-next-line
  }, []);
  const getCategory = () => {
    axios
      .get(`${Config.API_HOST_URL_live}/api/CourseCategory/GetCategoryList?createdBy=${LogUserId.userId}`, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          const listdata = response.data;
          const main = listdata.mainCategory?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item?.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item?.courseCategoryCode + " - " + item?.courseCategoryName,
            };
          });
          const lear = listdata.learningObjective?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item.courseCategoryCode + " - " + item.courseCategoryName,
            };
          });
          const trai = listdata.trainingTopic?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item.courseCategoryCode + " - " + item.courseCategoryName,
            };
          });
          setCourseCodeList(main);
          setCourseLearList(lear);
          setCourseTraiList(trai);
        }
      });
  };
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId?.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId:
                item?.schoolDistrictId === "" || item?.schoolDistrictId === null
                  ? ""
                  : item?.schoolDistrictId,
              schName:
                item?.districtName === "" || item?.districtName === null
                  ? ""
                  : item?.districtName,
            };
          });
          setSchoolList(data);
        }
      })
      .catch((error) => console.log(error));
  };
  const getGroupList = async (schId) => {
    if (schId) {
      // let url = `${Config.API_HOST_URL_live}/api/Group/GetGroupsBySchoolDistrict?schoolDistrictId=${schId}`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let seData = {
        url: `/api/Group/GetGroupsBySchoolDistrict?schoolDistrictId=${schId}&userId=${LogUserId?.userId}`,
        headers: headers
      };
      const response = await GetCallApi(seData)
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              GroupId:
                item?.groupId === "" || item?.groupId === null
                  ? ""
                  : item.groupId,
              GroupName:
                item?.groupName === "" || item?.groupName === null
                  ? ""
                  : item.groupName,
            };
          });
          setGroupList(data);
        } else {
          setGroupList([]);
        }
      } else {
        setGroupList([]);
      }
      // })
      //     .catch ((error) => console.log(error));
    } else {
      setGroupList([]);
    }
  };
  const onSubmit = (data) => {
    let is_success = true;
    let url = "";
    if (LogUserId.roleId === 1) {
      url = `${Config.API_HOST_URL_live}/api/Group/GetUsersByGroupId?groupId=${data.groupId}&schoolDistrictId=${data.schoolDistrictId}&userId=${LogUserId?.userId}`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/Group/GetUsersByGroupId?groupId=${data.groupId}&schoolDistrictId=${LogUserId?.schoolDistrictId}&userId=${LogUserId?.userId}`;
    }

    axios.get(url, { headers }).then((response) => {
      if (response.data.success) {
        // let users = [];
        // const data2 = response?.data.payload?.map((item, i) => {
        //   if (item?.isAssigned === true) {
        //     return users.push(item?.userId);
        //   }
        //   return 0;
        // });
        // let users = [];
        let users = response?.data.payload?.filter(
          (item, i) => item?.isAssigned === true
        );
        if (users?.length > 0) {
          setDerrors({ userId: "" });
        } else {
          is_success = false;
          setDerrors({
            userId: "Select another group there is no user in this group",
          });
        }
        if (is_success) {
          users = users?.map((x) => x.userId);
          let ceuTransaction = [];
          courseCode?.map((item) => {
            let cDE = {
              courseCategoryId: item,
              categoryTypeId: 1,
            };
            return ceuTransaction.push(cDE);
          });
          courseLear?.map((item) => {
            let cDE = {
              courseCategoryId: item,
              categoryTypeId: 2,
            };
            return ceuTransaction.push(cDE);
          });
          courseTrai?.map((item) => {
            let cDE = {
              courseCategoryId: item,
              categoryTypeId: 3,
            };
            return ceuTransaction.push(cDE);
          });
          let ayId = schList?.map((ac) => {
            if (ac.academicYear === data.schoolYear) {
              return ac.academicYearId;
            }
            return "";
          });
          ayId = ayId?.filter((ay) => ay !== "")[0];
          let ceuData = {
            ...data,
            users: users?.map((x) => x),
            AcademicYearId: ayId,
            createdBy: LogUserId.userId,
            completionDate: dateCompletion ? convert(dateCompletion) : null,
            ceuTransaction: ceuTransaction,
          };
          setOpenCircle(true);

          axios
            .post(`${Config.API_HOST_URL_live}/api/Ceu/CreateCEU`, ceuData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
              },
            })
            .then((response) => {
              if (response.data.success) {
                setOpenCircle(false);

                handleClose();
                if (changeDash) {
                  dispatch(DashboardRe(false));
                } else {
                  dispatch(DashboardRe(true));
                }
                Swal.fire({
                  position: "top-end",
                  width: 400,
                  // icon: "success",
                  text: titleCase(response.data?.message),
                  showConfirmButton: false,
                  timer: 1500,
                  color: "#ffffff",
                  background:
                    "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                  },
                });
              } else {
                setOpenCircle(false);
              }
            });
        }
      }
    });
  };
  const getSchoolYear = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const user = response?.data.payload?.map((item, i) => {
            return {
              academicYearId: item?.academicYearId ? item?.academicYearId : "",
              academicYear: item?.academicYear ? item?.academicYear : "",
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
            };
          });
          setSchList(user);
        }
      });
  };
  const handleChangeCourceMulti = (e) => {
    setCourseCode(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeLearMulti = (e) => {
    setCourseLear(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeTraiMulti = (e) => {
    setCourseTrai(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const handleImage = async (e) => {
    var file = e.target?.files[0];
    if (file.size < 5253880) {
      setValue("MaterialsAttachment", file);
      setValue("imgUpload", file.name);
      setDerrors({ img_err: "" });
    } else {
      setDerrors({ img_err: "Please Upload Max 5MB File" });
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="tab_panel row">
        {LogUserId.roleId === 1 ? (
          <div className="tab_panel_input_mt mt-0 col-md-6">
            <select
              className={`form-select `}
              aria-label="Default select example"
              {...register("schoolDistrictId", {
                required: "This field is required",
                onChange: (e) => getGroupList(e.target.value),
              })}
            >
              <option value=""> Select School District </option>
              {schoolList &&
                schoolList?.map((data, i) => (
                  <option value={data.schId} key={i}>
                    {data.schName}
                  </option>
                ))}
            </select>
            {errors.schoolDistrictId && (
              <span role="alert" className="error_text">
                {errors.schoolDistrictId.message}
              </span>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="tab_panel_input_mt mt-0 col-md-6">
          <select
            className="form-select dropdown_group"
            aria-label="Default select example"
            {...register("groupId", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          >
            <option value="">Select Group</option>
            {groupList &&
              groupList?.map((item, i) => (
                <option key={i} value={item.GroupId}>
                  {item.GroupName}
                </option>
              ))}
          </select>
          {errors.groupId && (
            <span role="alert" className="error_text">
              {errors.groupId.message}
            </span>
          )}
          {derrors?.userId && (
            <span role="alert" className="error_text">
              {derrors?.userId}
            </span>
          )}
        </div>
        <div
          className={`tab_panel_input_mt ${LogUserId.roleId === 1 ? "" : "mt-0"
            } col-md-6`}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Title"
            {...register("ceuTitle", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          />
          {errors.ceuTitle && (
            <span role="alert" className="error_text">
              {errors.ceuTitle.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <Select
            isMulti
            name="colors"
            options={courseCodeList}
            className="course-caretory-multi-select p_14_text"
            classNamePrefix="select"
            placeholder="Key Area"
            value={courseCodeList.filter((obj) =>
              courseCode?.includes(obj.value)
            )}
            onChange={handleChangeCourceMulti}
          />
          {derrors?.course_code && (
            <span role="alert" className="error_text">
              {derrors?.course_code}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <Select
            isMulti
            name="colors"
            options={courseTraiList}
            className="course-caretory-multi-select p_14_text"
            placeholder="Training Topic"
            classNamePrefix="select"
            value={courseTraiList.filter((obj) =>
              courseTrai?.includes(obj.value)
            )}
            onChange={handleChangeTraiMulti}
          />
          {derrors?.course_t && (
            <span role="alert" className="error_text">
              {derrors?.course_t}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <Select
            isMulti
            name="colors"
            options={courseLearList}
            className="course-caretory-multi-select p_14_text"
            placeholder="Learning Objective "
            classNamePrefix="select"
            value={courseLearList.filter((obj) =>
              courseLear?.includes(obj.value)
            )}
            onChange={handleChangeLearMulti}
          />
          {derrors?.course_l && (
            <span role="alert" className="error_text">
              {derrors?.course_l}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="CEU"
            {...register("ceus", {
              required: {
                value: true,
                message: "This field is required",
              },
              pattern: {
                value: /^\d+(\.\d{1,2})?$/,
                message: "Enter valid Number",
              },
            })}
          />
          {errors.ceus && (
            <span role="alert" className="error_text">
              {errors.ceus.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Training Provider"
            {...register("trainingProviderName", {
              // required: {
              //   value: true,
              //   message: "This field is required",
              // },
            })}
          />
          {errors.trainingProviderName && (
            <span role="alert" className="error_text">
              {errors.trainingProviderName.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Training Format"
            {...register("trainingFormat", {
              // required: {
              //   value: true,
              //   message: "This field is required",
              // },
            })}
          />
          {errors.trainingFormat && (
            <span role="alert" className="error_text">
              {errors.trainingFormat.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <input
            type="text"
            className="form-control timing_icon"
            placeholder="Training Length (HH:MM:SS)"
            {...register("trainingLengthInMinute", {
              // required: {
              //   value: true,
              //   message: "This field is required",
              // },
              pattern: {
                // value: /^([01]\d|2[0-3]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                // value: /^([01]\d|[1-9][0-9]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                value: /^([01]\d|[1-9][0-9]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                // value: /^([01]\d|2[0-3]):([0-5]\d)$/, (hh:mm)
                message: "Please Enter Valid Time",
              },
            })}
          />
          {errors.trainingLengthInMinute && (
            <span role="alert" className="error_text">
              {errors.trainingLengthInMinute.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={dateCompletion}
            onChange={setDateCompletion}
            placeholderText="Completion Date"
            className="Date_picker"
          />
          {derrors?.d && (
            <span role="alert" className="error_text">
              {derrors.d}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt col-md-6">
          <select
            className="form-select dropdown_group"
            aria-label="Default select example"
            {...register("schoolYear", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          >
            <option value=""> Select Year </option>
            {schList &&
              schList?.map((item, i) => (
                <option key={i} value={item.academicYear}>
                  {item?.academicYear}{" "}
                  {item?.isCurrentYear === "True" ? "- Current Year" : ""}
                </option>
              ))}
          </select>
          {errors.schoolYear && (
            <span role="alert" className="error_text">
              {errors.schoolYear.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt">
          <div className="upload_img ceu">
            <input
              type="text"
              className="form-control upload_box"
              placeholder="No file Chosen"
              readOnly
              {...register("imgUpload", {})}
            />
            <input
              type="file"
              id="school_img_upload"
              className="form-control"
              style={{ display: "none" }}
              placeholder="User You Want To Be In District"
              onChange={handleImage}
            />
            <label
              htmlFor="school_img_upload"
              className="upload_img_label mb-0 "
            >
              <img src={uploadimg} className="img-fluid" alt="" />
            </label>
          </div>
          {errors.imgUpload && (
            <span role="alert" className="error_text">
              {errors.imgUpload.message}
            </span>
          )}
          {derrors.img_err && (
            <span role="alert" className="error_text">
              {derrors.img_err}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt">
          <textarea
            className="form-control"
            placeholder="Comments"
            {...register("remarks", {})}
          ></textarea>
          {errors.remarks && (
            <span role="alert" className="error_text">
              {errors.remarks.message}
            </span>
          )}
        </div>
        <div className="tab_panel_input_mt">
          <button
            type="button"
            className="popup_btn update"
            onClick={handleSubmit(onSubmit)}
          >
            Add CEU
          </button>
          <button
            type="button"
            className="popup_btn cancel"
            onClick={handleClose}
          >
            cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default GroupCeu;
