import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import TicketTable from "./ticketTable";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
const renderLoader = () => <p></p>;

let headCellPending = [
  { id: "refId", label: "Ticket Reference" },
  // { id: "ticketId", label: "Ticket Id" },
  { id: "schoolDistrictName", label: "District Name" },
  { id: "subject", label: "Subject" },
  { id: "priority", label: "Priority" },
  { id: "category", label: "Category" },
  { id: "created", label: "Created Time" },
  { id: "status", label: "Status" },
];
let headCellAdmin = [
  { id: "refId", label: "Ticket Reference" },
  { id: "schoolDistrictName", label: "District Name" },
  { id: "userName", label: "User Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  // { id: "ticketId", label: "Ticket Id" },
  { id: "subject", label: "Subject" },
  { id: "priority", label: "Priority" },
  { id: "category", label: "Category" },
  { id: "created", label: "Created Time" },
  { id: "updatedBy", label: "Updated By" },
  { id: "updatedOn", label: "Updated Time" },
  { id: "status", label: "Status" },
];
function ViewClosedTicket(props) {
  const { setReplyT, changeTab } = props;
  const [pendingTicket, setPendingTicket] = useState([]);
  const [closedTicket, setClosedTicket] = useState([]);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getPenList();
    getCloseList();
    // eslint-disable-next-line
  }, []);
  const getPenList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SupportTicket/GetSupportTicketStatus?userId=${logDetail?.userId}&status=`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let cd = res.data.payload?.map((td) => {
              return {
                ...td,
                refId: td?.ticketReferenceNumber,
                category: td?.supportCategoryName,
                created: td?.createdOn
                  ? convertDate(td?.createdOn?.split("T")[0]) +
                    " " +
                    new Date(td?.createdOn).toLocaleTimeString()
                  : "",
                updatedOn: td?.updatedOn?.createdOn
                  ? convertDate(td?.updatedOn?.createdOn?.split("T")[0]) +
                    " " +
                    new Date(td?.updatedOn?.createdOn).toLocaleTimeString()
                  : // : td?.updatedOnArray?.lastUpdatedOn
                    // ? convertDate(
                    //     td?.updatedOnArray?.lastUpdatedOn?.split("T")[0]
                    //   ) +
                    //   " " +
                    //   new Date(
                    //     td?.updatedOnArray?.lastUpdatedOn
                    //   ).toLocaleTimeString()
                    "",

                updatedBy:
                  td?.updatedBy === null
                    ? td?.updatedByOutSideUser
                    : td?.updatedBy,
              };
            });
            setPendingTicket(cd);
          } else {
            setPendingTicket([]);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getCloseList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SupportTicket/GetSupportTicketStatus?userId=${logDetail?.userId}&status=Close`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let cd = res.data.payload?.map((td) => {
              return {
                ...td,
                refId: td?.ticketReferenceNumber,
                category: td?.supportCategoryName,
                created: td?.createdOn
                  ? convertDate(td?.createdOn?.split("T")[0]) +
                    " " +
                    new Date(td?.createdOn).toLocaleTimeString()
                  : "",
                updatedOn: td?.updatedOn?.createdOn
                  ? convertDate(td?.updatedOn?.createdOn?.split("T")[0]) +
                    " " +
                    new Date(td?.updatedOn?.createdOn).toLocaleTimeString()
                  : // : td?.updatedOnArray?.lastUpdatedOn
                    // ? convertDate(
                    //     td?.updatedOnArray?.lastUpdatedOn?.split("T")[0]
                    //   ) +
                    //   " " +
                    //   new Date(
                    //     td?.updatedOnArray?.lastUpdatedOn
                    //   ).toLocaleTimeString()
                    "",

                updatedBy:
                  td?.updatedBy === null
                    ? td?.updatedByOutSideUser
                    : td?.updatedBy,
              };
            });
            setClosedTicket(cd);
          } else {
            setClosedTicket([]);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <TicketTable
          headCells={logDetail?.roleId === 1 ? headCellAdmin : headCellPending}
          ticketList={pendingTicket}
          title="Pending Tickets"
          setReplyT={setReplyT}
          changeTab={changeTab}
        />{" "}
        <div className="mt-5">
          <TicketTable
            headCells={
              logDetail?.roleId === 1 ? headCellAdmin : headCellPending
            }
            ticketList={closedTicket}
            title="Closed Tickets"
            setReplyT={setReplyT}
            changeTab={changeTab}
          />
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(ViewClosedTicket);
