import React, { Suspense, useState, useEffect } from "react";
import "./index.scss";
import contactbanner from "../../images/contact-banner.png";
import rightarrow from "../../images/right-arrow.svg";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../Action/Action";
import Config from "../../Utils/Config";
import { CircularProgress } from "@mui/material";
import WebinarLive from "./WebinarLive";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function ContactUs() {
  const [isLoading, setIsLoading] = useState(true);
  const [contactData, setConatctData] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);

  useEffect(() => {
    // GetAllEventsList();
    setOpenCircle(!openCircle);
    setIsLoading(true);
    setTimeout(() => {
      getContData();
    }, 200);
    // eslint-disable-next-line
  }, []);

  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading5").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading5").innerHTML = "";
  // }, []);
  const getContData = async () => {
    let seData = {
      url: `/api/ContactUs/GetAllContactUsListWithOutToken`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ctData = res.data.payload;
        setConatctData(ctData);
        // stopTimer();
        setOpenCircle(false);
        setIsLoading(false);
      } else {
        // stopTimer();
        setOpenCircle(false);
        setIsLoading(false);
      }
    } else {
      // stopTimer();
      setOpenCircle(false);
      setIsLoading(false);
    }
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#86c65a", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* <div
        id="loading5"
        className="h6_text p-5 text-center"
        style={{
          height: openCircle ? "100vh" : "",
          display: !openCircle ? "none" : "block",
        }}
      ></div> */}
      {/* {!openCircle ? ( */}
      <main>
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="banner-content">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title mb-5 w-100"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-5"></div>
                      <div className="skeleton-btn mb-5"></div>
                    </div>
                  ) : (
                    <>
                      <h1>
                        {contactData?.contactUsTitle}
                        {/* CREATE A BRIGHTER FUTURE FOR YOUR TEAM WHERE WE INSPIRE
                    CHANGE AND GROW TOGETHER */}
                      </h1>
                      <div className="contact-social">
                        <Link to={`mailto: ${contactData?.contactUsEmail}`}>
                          {contactData?.contactUsEmail}
                        </Link>
                        <Link
                          to={`tel: ${contactData?.contactUsPhone}`}
                          className="text-decoration-underline"
                        >
                          {contactData?.contactUsPhone}
                        </Link>
                      </div>
                      <img
                        src={contactbanner}
                        alt="Contact"
                        className="banner-mobile-img"
                      />
                      <button className="primary-btn">
                        DROP US A LINE !{" "}
                        <img src={rightarrow} alt="right-arrow" />{" "}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6 right-banner">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <>
                    <img
                      src={
                        contactData?.contactUsImageURL
                          ? `${Config.API_HOST_URL_live}${contactData?.contactUsImageURL}`
                          : contactbanner
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = contactbanner;
                      }}
                      alt="Contact"
                      className="img-fluid banner-desktop-img"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Start Contact US */}
        <section className="contactbox-section common-padding">
          <div className="container">
            <div className="row">
              {isLoading ? (
                <div className="d-flex gap-3">
                  <div className="skeleton-loader">{box}</div>
                  <div className="skeleton-loader">{box}</div>
                  <div className="skeleton-loader">{box}</div>
                </div>
              ) : (
                <>
                  <div className="col-md-4 margin-bottom">
                    <Link to="/contactus/requestcard">
                      <div className="contact-box">
                        <h3>REQUEST A DEMONSTRATION</h3>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 margin-bottom">
                    <Link to="/contactus/webinarcard">
                      <div className="contact-box">
                        <h3>JOIN AN UPCOMING WEBINAR</h3>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 margin-bottom">
                    <Link to="/contactus/requestschoolcard">
                      <div className="contact-box">
                        <h3>REQUEST A PROPOSAL FOR YOUR SCHOOL DISTRICT</h3>
                      </div>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        {/* End Contact US */}
        <WebinarLive />
      </main>
      {/* ) : (
        ""
      )} */}
    </Suspense>
  );
}

export default ContactUs;
