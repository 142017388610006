import React, { useState } from "react";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
// import send_icon from "../../../../images/Icon/send_mes.svg";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import pro_img from "../../../../images/default_profile.jpg";
import Swal from "sweetalert2";
import { VscSend } from "react-icons/vsc";
import { PostCallApi } from "../../../../Action/Action";

function Comments(props) {
  const { postId, setCommentReply, comList, likeId, setLikeId } = props;
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const onSubmit = async (data) => {
    setOpen(true);
    let cmtData = {};
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    let url = "";
    url = `${Config.API_HOST_URL_live}/api/CommunityComment/CreateCommunityComment`;
    cmtData = {
      communityPostId: postId,
      comment: data?.comment,
      commentByUserId: LogUserId,
    };
    let response = await PostCallApi({
      url: url,
      body: cmtData,
      headers: headers,
    });
    // axios
    //   .post(url, cmtData, {
    //     headers,
    //   })
    //   .then((response) => {
    setOpen(false);

    if (response?.status === 200) {
      if (response.data.success) {
        setCommentReply(false);
        setValue("comment", "");
        setOpen(false);
        if (likeId) {
          setLikeId(false);
        } else {
          setLikeId(true);
        }
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* {open && 
       <CircularProgress color="inherit" />} */}
      <div className="reply_comment_box_new" key={postId}>
        {/* {open && 
          <div className="comment_loader">
            <CircularProgress sx={{color:"black"}} />
          </div>
      }  */}
        <form
          className="d-flex align-items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            name=""
            className="form-control"
            placeholder="Type here ......"
            {...register("comment", {
              required: "This field is required for Comment",
            })}
          />

          {open ? (
            <IconButton type="butoon" disabled className="pst_btn">
              <CircularProgress sx={{ color: "#22beca" }} />
            </IconButton>
          ) : (
            <IconButton type="submit" className="pst_btn">
              <VscSend />
            </IconButton>
          )}
        </form>
        {errors.comment && (
          <span role="alert" className="error_text">
            {errors.comment.message}
          </span>
        )}
        {comList?.length > 0
          ? comList?.map((item) => (
            <>
              <div className="d-flex px-3 py-2 gap-3">
                <img
                  src={
                    item?.thumbnailProfileUrl === "" ||
                      item?.thumbnailProfileUrl === null ||
                      item?.thumbnailProfileUrl === undefined
                      ? pro_img
                      : `${Config.API_HOST_URL_live}${item?.thumbnailProfileUrl}`
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = pro_img;
                  }}
                  alt=""
                  className="img-fluid post_u_img m-auto"
                />
                <div className="last_comment w-100">
                  <div className="comment_title d-flex">
                    <p>
                      {item?.userName}
                      <span className="d-block">
                        {item?.comment}{" "}
                        {item?.commentByUserId === LogUserId
                          ? "(You Commented)"
                          : ""}
                      </span>
                    </p>
                  </div>
                  <span className="text-muted">{item?.date}</span>
                </div>
              </div>
            </>
          ))
          : ""}
      </div>
    </>
  );
}
export default React.memo(Comments);
