
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import userReducer from "../reducers/user"
import loginReducer from "../reducers/login"
import ceu_cource from '../reducers/ceu_cource';
import SettingReducer from '../reducers/setting';
import CommonReducer from '../reducers/Common';

const reducers = combineReducers({
    user: userReducer,
    login: loginReducer,
    ceuCource: ceu_cource,
    setting: SettingReducer,
    Common: CommonReducer,

});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;
