import React, { useState } from "react";
import { Modal, Box, Backdrop, CircularProgress } from "@mui/material";
import "../Popup.scss";
import DatePicker from "react-datepicker";
import axios from "axios";
import Config from "../../../../Utils/Config";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DashboardRe } from "../../../../reducers/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const EditCourseSechudle = (props) => {
  const { data } = props;
  const [dateCompletion2, setDateCompletion2] = useState();
  const [derrors, setDerrors] = useState({});
  const [openCircle, setOpenCircle] = useState(false);
  const [open, setOpen] = useState(false);

  let dispatch = useDispatch();

  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const handleOpen = (data) => {
    setOpen(true);

    let cvD = data?.dueDate ? convert(data?.dueDate) : "";

    function convert(str) {
      var date = new Date(str?.replace(/-/g, "/")),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("/");
    }
    if (cvD) {
      setDateCompletion2(new Date(cvD));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = () => {
    let is_success = true;

    if (dateCompletion2) {
      setDerrors({ D: "" });
      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }

      let userdata = {
        schoolDistrictId: data?.schoolDistrictId,
        userCourseList: [
          {
            userCourseAllocationId: data?.userCourseAllocationId,
            courseId: data?.courseId,
            userId: data?.id,
          },
        ],
        expirationDate: convert(dateCompletion2),
        createdBy: LogUserId.userId,
      };
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      if (is_success) {
        setOpenCircle(true);
        axios
          .post(
            `${Config.API_HOST_URL_live}/api/UserCourse/AssignCourseToUser`,
            userdata,
            { headers }
          )
          .then((response) => {
            if (response.data.success) {
              setOpenCircle(false);

              setDerrors({ ermsg: "" });
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              handleClose();
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            } else {
              setOpenCircle(false);
              setDerrors({ ermsg: response.data.message });
            }
          })
          .catch((error) => console.log(error));
      }
    } else {
      setDerrors({ D: "Select Date" });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 1500 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <button
        type="button"
        className="btn_edit ms-2"
        onClick={(e) => handleOpen(data)}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8899 3.00006C18.1525 2.73741 18.4643 2.52907 18.8075 2.38693C19.1507 2.24479 19.5185 2.17163 19.8899 2.17163C20.2613 2.17163 20.6291 2.24479 20.9723 2.38693C21.3154 2.52907 21.6272 2.73741 21.8899 3.00006C22.1525 3.2627 22.3609 3.57451 22.503 3.91767C22.6452 4.26083 22.7183 4.62862 22.7183 5.00006C22.7183 5.37149 22.6452 5.73929 22.503 6.08245C22.3609 6.42561 22.1525 6.73741 21.8899 7.00006L8.38989 20.5001L2.88989 22.0001L4.38989 16.5001L17.8899 3.00006Z"
            stroke="#1EBEC8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_boxes"
        >
          <Box sx={style} className={`popup_wh width_600`}>
            <h4 className={`pupup_title`}>Edit Course Schedule</h4>
            <div className="pupup_input_box">
              <div className="tab_panel ">
                {/* <div className="tab_panel_input_mt">
                  <select
                    className="form-select dropdown_group"
                    aria-label="Default select example"
                   value={data}
                  >
                  </select>
                </div> */}
                <div className="tab_panel_input_mt mt-0">
                  <label className="p_14_text mb-2 "> User Name </label>
                  <select
                    className="form-select dropdown_group disabled_color"
                    aria-label="Default select example"
                    value={data?.name}
                    disabled
                  >
                    <option value={data?.name}>{data?.name}</option>
                  </select>
                </div>
                <div className="tab_panel_input_mt">
                  <label className="p_14_text mb-2"> Course Name </label>
                  <select
                    className="form-select dropdown_group disabled_color"
                    aria-label="Default select example"
                    value={data?.courceName}
                    disabled
                  >
                    <option value={data?.courceName}>{data?.courceName}</option>
                  </select>
                </div>
                <div className="tab_panel_input_mt">
                  <label className="p_14_text mb-2"> Course Due Date </label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={dateCompletion2}
                    onChange={setDateCompletion2}
                    placeholderText="MM/DD/YYYY"
                    className="Date_picker"
                  />
                  {derrors?.D && (
                    <span role="alert" className="error_text">
                      {derrors.D}
                    </span>
                  )}
                  {derrors?.ermsg && (
                    <span role="alert" className="error_text">
                      {derrors.ermsg}
                    </span>
                  )}
                </div>
                <div className="tab_panel_input_mt">
                  <button
                    type="button"
                    className="popup_btn update"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditCourseSechudle;
