import React from "react";
import DistrictCourse from "./DistrictCourse";
function ProfileCources(props) {
  const { courseList } = props;
  return (
    <>
      <div className="row">
        <DistrictCourse data={courseList} />
      </div>
    </>
  );
}

export default React.memo(ProfileCources);
