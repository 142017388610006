import React, { useState } from "react";
import "../index.scss";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import Pagination from "../../../Reuseable_Components/Paginations/Pagination";
import search_icon from "../../../../images/Icon/table_search_icon.svg";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TicketTable(props) {
  const { headCells, ticketList, title, setReplyT, changeTab } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("refId");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const logDetail = useSelector((state) => state.login.LoginDetails);

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "refId") {
      if (parseInt(b[orderBy]) < parseInt(a[orderBy])) {
        return -1;
      }
      if (parseInt(b[orderBy]) > parseInt(a[orderBy])) {
        return 1;
      }
    } else {
      if (
        b[orderBy]?.toString()?.toLowerCase() <
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy]?.toString()?.toLowerCase() >
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
              style={{
                width: `${headCell?.id === "refId" ? "190px" : ""}`,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}

                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [searchField, setSearchField] = useState("");

  const formattedSearchResults = ticketList?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });
  const handleReply = (tic) => {
    changeTab("3");
    setReplyT(tic);
  };
  return (
    <section className="list_my_ticket">
      <div className="head_box">
        <div className="ticket_title">{title}</div>
        <div className="search_input">
          <input
            type="text"
            onChange={(e) => {
              setSearchField(e.target.value);
              setCurrentPage(1);
            }}
            className="form-control"
            placeholder="Search "
          />
          <img src={search_icon} alt="" />
        </div>
      </div>
      <Paper className="table-box">
        <TableContainer className="">
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(formattedSearchResults, getComparator(order, orderBy))
                ?.slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((row, index) => {
                  return (
                    <StyledTableRow className="table_body" key={index + 1}>
                      {headCells?.map((column) => {
                        const value = row[column?.id];
                        if (
                          logDetail?.roleId === 1 &&
                          column?.id === "priority"
                        ) {
                          return (
                            <StyledTableCell
                              className={`column_data ${
                                value === "High" ? "red" : ""
                              }`}
                              key={column?.id}
                            >
                              {logDetail?.roleId === 1 && row?.isReadByAdmin ? (
                                <>
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </>
                              ) : (
                                <b>
                                  {" "}
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </b>
                              )}
                            </StyledTableCell>
                          );
                        } else if (column?.id === "subject") {
                          return (
                            <StyledTableCell
                              className="column_data"
                              key={column?.id}
                              onClick={() => handleReply(row)}
                              style={{
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              {logDetail?.roleId === 1 && row?.isReadByAdmin ? (
                                <>
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </>
                              ) : (
                                <b>
                                  {" "}
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </b>
                              )}
                            </StyledTableCell>
                          );
                        }
                        return (
                          <StyledTableCell
                            className="column_data"
                            key={column?.id}
                          >
                            {logDetail?.roleId === 1 && row?.isReadByAdmin ? (
                              <>
                                {column?.format && typeof value === "number"
                                  ? column?.format(value)
                                  : value}
                              </>
                            ) : (
                              <b>
                                {" "}
                                {column?.format && typeof value === "number"
                                  ? column?.format(value)
                                  : value}
                              </b>
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {formattedSearchResults?.length > 0 ? (
          ""
        ) : (
          <div className="text-center no_found">No data Found</div>
        )}
        <div className="page_nation">
          <div className="rowsPerPageOptions">
            <span className="p_18_text">show</span>
            <select
              className="pagination_select p_16_text"
              value={pageSize}
              onChange={(e) => {
                setPageSize(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
            <span className="p_18_text">entries</span>
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={formattedSearchResults?.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Paper>
    </section>
  );
}

export default TicketTable;
