import React, { Suspense, useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import ReactDatePicker from "react-datepicker";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Swal from "sweetalert2";

function RequestCard() {
  const [stateList, setStateList] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let sendData = {
      ...data,
    };
    let seData = {
      url: `/api/RequestDemonstration/CreateRequestDemonstration`,
      body: sendData,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        reset();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  useEffect(() => {
    getState();
    // eslint-disable-next-line
  }, []);

  const getState = async () => {
    let seData = {
      url: `/api/Common/GetStatesList`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data?.payload);
      }
    }
  };

  return (
    <Suspense>
      <main>
        <section className="request-card-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title-text">REQUEST A DEMONSTRATION</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grey-box">
                    <div className="row">
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>District Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="District Name"
                            {...register("schoolDistrictName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.schoolDistrictName && (
                            <span role="alert" className="error_text">
                              {errors.schoolDistrictName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            className="form-select"
                            {...register("stateId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select State</option>
                            {stateList?.map((st, i) => (
                              <option value={st.stateId} key={i}>
                                {st?.stateCode} - {st?.stateName}
                              </option>
                            ))}
                          </select>
                          {errors.stateId && (
                            <span role="alert" className="error_text">
                              {errors.stateId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Number of Schools</label>
                          <input
                            min={0}
                            type="number"
                            className="form-control"
                            placeholder="Number of Schools"
                            {...register("noOfSchool", {
                              required: "This field is required",
                            })}
                          />
                          {errors.noOfSchool && (
                            <span role="alert" className="error_text">
                              {errors.noOfSchool.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Number of Employees</label>
                          <input
                            min={0}
                            type="number"
                            className="form-control"
                            placeholder="Number of Employees"
                            {...register("noOfEmployee", {
                              required: "This field is required",
                            })}
                          />
                          {errors.noOfEmployee && (
                            <span role="alert" className="error_text">
                              {errors.noOfEmployee.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register("firstName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span role="alert" className="error_text">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("lastName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.lastName && (
                            <span role="alert" className="error_text">
                              {errors.lastName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Preferred Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("prefferedDate", {
                              required: "This field is required",
                              valueAsDate: true,
                            })}
                          />
                          {errors.prefferedDate && (
                            <span role="alert" className="error_text">
                              {errors.prefferedDate.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Best Time of Day</label>
                          <select
                            className="form-select"
                            {...register("bestTimeOfDay", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Best Time of Day</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                            <option value="News">News</option>
                          </select>
                          {errors.bestTimeOfDay && (
                            <span role="alert" className="error_text">
                              {errors.bestTimeOfDay.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Phone/Mobile</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone/Mobile"
                            {...register("phoneMobile", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors.phoneMobile && (
                            <span role="alert" className="error_text">
                              {errors.phoneMobile.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  "Entered value does not match email format",
                              },
                            })}
                          />
                          {errors.email && (
                            <span role="alert" className="error_text">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 padding-bottom">
                        <div className="form-group">
                          <label>Questions or Comments</label>
                          <textarea
                            className="form-control"
                            placeholder="Questions or Comments"
                            rows="5"
                            {...register("questionOfComments", {
                              required: "This field is required",
                            })}
                          ></textarea>
                          {errors.questionOfComments && (
                            <span role="alert" className="error_text">
                              {errors.questionOfComments.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="button-group">
                          <button className="primary-btn" type="submit">
                            Submit
                          </button>
                          <Link
                            to={"/contactus"}
                            style={{ textDecoration: "none" }}
                            className="primary-btn"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Suspense>
  );
}

export default RequestCard;
