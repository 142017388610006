import React, { useState } from "react";
import ViewConnectionList from "./ViewConnectionList";
import active_list from "../../../../../images/active_list.png";
import inactive_list from "../../../../../images/inactive_list.png";
import active_grid from "../../../../../images/active_grid.png";
import inactive_grid from "../../../../../images/inactive_grid.png";
import "./index.scss";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

function ViewConnection() {
  const [grid, setGrid] = useState(true);
  const [searchField, setSearchField] = useState();
  const { id } = useParams();
  return (
    <>
      <div className="row view_connection m-0">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex ">
            <div className="searc_box">
              <input
                type="search"
                className="form-control"
                placeholder="Search By Name"
                onChange={(e) => setSearchField(e.target.value)}
              />
            </div>
            <div className="rightside_action">
              <Button className="grid_btn" onClick={() => setGrid(true)}>
                <img
                  src={grid === true ? active_grid : inactive_grid}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
              <Button className="list_btn" onClick={() => setGrid(false)}>
                <img
                  src={grid === false ? active_list : inactive_list}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
            </div>
          </div>
        </div>
        <ViewConnectionList grid={grid} searchField={searchField} id={id} />
      </div>
    </>
  );
}
export default React.memo(ViewConnection);
