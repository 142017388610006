import { createSlice } from "@reduxjs/toolkit";

const initialState = { MenuName: "", ReportMenuName: "", graphData : {} };

export const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    SettingName(state, action) {
      state.MenuName = action.payload;
    },
    SettingMenuList(state, action) {
      state.MenuList = action.payload;
    },
    ReportName(state, action) {
      state.ReportMenuName = action.payload;
    },
    ReportMenuList(state, action) {
      state.ReportList = action.payload;
    },
    TopHeaderMenuList(state, action) {
      state.TopMenuList = action.payload;
    },
    DashboardButtonAccess(state, action) {
      state.DashBtnList = action.payload;
    }, 
    StoreGraphData(state, action) {
      state.graphData = action.payload;
    },
    resetSetting: () => initialState,
  },
});

export const {
  SettingName,
  SettingMenuList,
  ReportName,
  ReportMenuList,
  DashboardButtonAccess,
  TopHeaderMenuList,
  resetSetting,
  StoreGraphData
} = SettingSlice.actions;

export default SettingSlice.reducer;
