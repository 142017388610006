import React from "react";
import "./index.scss";
import pro_img from "../../../../../images/default_profile.jpg";
import Config from "../../../../../Utils/Config";

export default function ViewProfileimg(props) {
  return (
    <>
      <div className="view_profile_p_img_h">
        <img
          src={
            props?.profileUrl === "" || props?.profileUrl === null
              ? pro_img
              : `${Config.API_HOST_URL_live}${props?.profileUrl}`
          }
          alt=""
        />
      </div>

      {/* <div className="profile_details_box">
        <div className="d-flex justify-content-between profile_details_name">
          <p>{props?.roleName}</p>
          <p>Hire Date {props?.joined}</p>
          <p className={`${props?.active === "Active now" ? "green" : ""}`}>
            {props?.active}
          </p>
        </div>

        <div className="ceu_goal_detail">
          <p>Users Progress Towards CEU Goals</p>
          <div className="fullwidth">
            <div style={{ width: `${props?.ceu_goal_percent}%` }}>
              <span>{props?.ceu_goal_percent}%</span>
            </div>
          </div>
        </div>
        <div className="edit_profile_button">
          <button type="button" onClick={() => props?.setEdiPro(true)}>
            Edit Profile
          </button>
        </div>
      </div> */}
      {/* <div className="col-lg-4 col-md-6 m-auto">
        <div className="profile_img_section">
          <div className="profile_img">
          </div>
        </div>
      </div> */}
    </>
  );
}
