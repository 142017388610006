import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import ceu from "../../images/ceu-banner.png";
import rightarrow from "../../images/right-arrow.svg";
// import figvideo from "../../images/fig-food-video.png";
// import schoolvideo from "../../images/school-business-video.png";
// import CardGrid from "../../Pages/Card_Grid/index";
// import { useSelector } from "react-redux";
// import userimg2 from "../../images/user.png";
// import cardimg1 from "../../images/ceu_couce_card_img.png";
// import Config from "../../Utils/Config";
import { CircularProgress } from "@mui/material";
// import { PostCallApi } from "../../Action/Action";
import CourseList from "./CourseList";
import ReactPlayer from "react-player";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function CEU() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // GetAllEventsList();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
    // eslint-disable-next-line
  }, []);
  // const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, []);

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <main>
        <section className="ceupage">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="banner-content">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title mb-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    <>
                      <h1>
                        It's Not About How Much
                        <span> You Know, It's How Much </span>You Can Learn!
                        {/* IT’S NOT JUST LESSON ITS
                        <span> LIVING HEALTHY & FIT LIFE</span> LESSON */}
                        <img
                          src={rightarrow}
                          alt="right-arrow"
                          className="right-arrow"
                        />{" "}
                      </h1>
                    </>
                  )}
                  {/* <img
                    src={ceu}
                    alt="Organic USDA"
                    className="banner-mobile-img"
                  /> */}
                </div>
              </div>
              <div className="col-lg-6 right-banner">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <>
                    <img
                      src={ceu}
                      alt="Organic USDA"
                      className="banner-desktop-img"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Start CEU COURSE CATALOG */}

        <CourseList title="CEU COURSE CATALOG" type="1" />

        {/* End CEU COURSE CATALOG */}

        {/* Start SAMPLE COURSE LESSONS */}
        <section className="samplecourse-section common-padding">
          <div className="container">
            <h2 className="title-text">SAMPLE COURSE LESSONS</h2>
            <div className="row">
              <div className="col-md-6">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/813612074?h=7c5492d061&autoplay=0&title=0&portrait=0&byline=0&badge=0`}
                  className="react-player"
                  volume={1}
                  width="100%"
                  height="350px"
                  controls
                  responsive
                />

                {/* <div className="fig-image">
                  <img src={figvideo} alt="Fig Food" />
                </div> */}
              </div>
              <div className="col-md-6">
                <h5>FIG FOOD HANDLER - INTRO TO SAFETY</h5>
                <p>
                  In this lesson we introduce the basics of food handling
                  safety. We lay the foundation to prepare you for food handler
                  certification as we discuss causes of foodborne illness
                  outbreaks and how to prevent them.
                </p>
              </div>
            </div>
            <div className="row food-hadler">
              <div className="col-md-6">
                <h5>MEAL COUNTING AND CLAIMING</h5>
                <p>
                  In this lesson we will discuss Free and Reduced eligibilities,
                  how to apply for benefits and process applications as well as
                  correct counting and claiming procedures to keep us in
                  compliance with FNS Guidelines
                </p>
              </div>
              <div className="col-md-6">
                {/* {isLoading ? (
                <div className="skeleton-loader">{box}</div>
              ) : (
                <> */}
                <ReactPlayer
                  url={`https://player.vimeo.com/video/716576261?h=bb040a0276&autoplay=0&title=0&portrait=0&byline=0&badge=0`}
                  className="react-player"
                  volume={1}
                  width="100%"
                  height="350px"
                  controls
                  // responsive
                />
                {/* <div className="food-image">
                  <img src={schoolvideo} alt="Fig Food" />
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Start SAMPLE COURSE LESSONS */}
      </main>
    </Suspense>
  );
}

export default CEU;
