import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const ceucourse = createSlice({
  name: "course",
  initialState,
  reducers: {
    showMessage(state, action) {
      state.redirect = action.payload;
    },
    CreteQuestionQuizData(state, action) {
      state.Question = action.payload;
    },
  },
});

export const { showMessage, CreteQuestionQuizData } = ceucourse.actions;

export default ceucourse.reducer;
