import Swal from "sweetalert2";

export const SwalSuccess = (mes) => {
    return Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: mes ? mes : "Something went wrong please try again later.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background:
            "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
        showClass: {
            popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
            popup: "animate__animated animate__fadeOutRight",
        },
    });
}
export const SwalError = (mes) => {
    return Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: mes ? mes : "Something went wrong please try again later.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
            popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
            popup: "animate__animated animate__fadeOutRight",
        },
    });;
}