import React, { useEffect, useState } from "react";
import Groups from "./Groups";
import Conections from "./Connections";
import back_img from "../../../../images/Cover_photo_user_profile.png";
import "./index.scss";
import Profileimg from "./Components/profile_img";
import incomplete_img from "../../../../images/incomplete_icon.png";
import complete_img from "../../../../images/complete_icon.png";
import Aboutsec from "./Components/About_sec/Index";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Backdrop, CircularProgress } from "@mui/material";
import ProfileCources from "./ProfileCourses";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
// import userimg2 from "../../../../images/user10.png";
import backimg12 from "../../../../images/group_card_back12.png";
import userimg13 from "../../../../images/user13.png";
import { useLocation, useParams } from "react-router-dom";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
import Swal from "sweetalert2";
import { GetCallApi } from "../../../../Action/Action";
import RegisterConference from "./Components/RegisterConference";
import FreeSourcesTab from "./Components/FreeSourcesTab";
import { useSignalR } from "../../../../Utils/SignalRContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FigFamilyProfile() {
  // const [ediPro, setEdiPro] = useState(false);
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [userProfile, setUserProfile] = useState({});
  const [grpList, setGrpList] = useState([]);
  const [usList, setUsList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [freeSource, setFreeSource] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let { pathname } = useLocation();

  const {connection} = useSignalR();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [abtChange, setAbtChange] = useState(false);

 useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setUsList((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);
  useEffect(() => {
    getProfileDetail();
    // eslint-disable-next-line
  }, [abtChange]);
  const getProfileDetail = async () => {
    let ffpp =
      pathname?.split("/")[1] === "fig-family"
        ? "FigFamily"
        : "PreferedPartner";
    setOpenCircle(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictProfileForFigFamily?userId=${LogUserId}&schoolDistrictId=${id}&familyOrPartner=${ffpp}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpenCircle(false);

    const sendData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictProfileForFigFamily?userId=${LogUserId}&schoolDistrictId=${id}&familyOrPartner=${ffpp}`,
      headers: headers,
    };
    let response = await GetCallApi(sendData);
    setOpenCircle(false);
    if (response?.status === 200) {
      if (response.data.success) {
        setUserProfile(response.data?.payload[0]);
        const cgList = response.data.payload[0]?.communityGroups?.map(
          (item) => {
            return {
              communityGroupId: item?.communityGroupId,
              name: item?.communityGroupName,
              img:
                item?.groupImageUrl === "" || item?.groupImageUrl === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
              backimg:
                item?.groupCoverImage === "" || item?.groupCoverImage === null
                  ? backimg12
                  : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
              organizerUserId: item?.organizerUserId,
              communityGroupTypeName:
                item.communityGroupTypeId === 1 ? "Private" : "Public",
            };
          }
        );
        setGrpList(cgList);
        const uList = response.data?.payload[0]?.users?.map((u) => {
          // u.profileUrl === null || u.profileUrl === ""
          //     ? userimg13
          //     : `${Config.API_HOST_URL_live}${u.profileUrl}`,
          return {
            userId: u.userId === null || u.userId === "" ? "" : u?.userId,
            img:
              u.profileUrl === null ||
                u.profileUrl === "" ||
                u.profileUrl === undefined
                ? userimg13
                : `${Config.API_HOST_URL_live}${u.profileUrl}`,
            name: u.firstName + " " + u.lastName,
            join: u.userJoinDate,
            status: u.status === null || u.status === "" ? "" : u.status,
            sId: u?.sentRequestId,
            jobTitle: u.jobTitle,
            siteLocation: u.siteLocation,
            userStatus: u.userStatus
          };
        });
        setUsList(uList);
        const freeSourceList = response.data?.payload[0]?.freeSources?.map(
          (f) => {
            return {
              ...f,
            };
          }
        );
        setFreeSource(freeSourceList);
        const couList = response.data.payload[0]?.courses?.map((c) => {
          return {
            courseId: c?.courseId,
            cardTitle: c?.courseName,
            courseTypes: c?.courseType,
            // cardimg: c?.imageOrVideoUrl
            //   ? `${Config.API_HOST_URL_live}${c?.imageOrVideoUrl}`
            //   : cardimg1,
            cardimg: c?.thumbnailCourseUrl
              ? `${Config.API_HOST_URL_live}${c?.thumbnailCourseUrl}`
              : cardimg1,
            des: c?.description,
            startCompleted: c?.status,
          };
        });
        setCourseList(couList);
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    //   } else {
    //     setOpenCircle(false);
    //   }
    // });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="FigFamily_Profile_page main_backcolor">
        <div className="profile_header_back_img">
          <img
            src={
              userProfile?.coverImage === "" || userProfile?.coverImage === null
                ? back_img
                : `${Config.API_HOST_URL_live}${userProfile?.coverImage}`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = back_img;
            }}
            className="back_img"
            alt=""
          />
        </div>
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="profile-overlap mb-3">
              <Profileimg
                name={userProfile?.firstName + " " + userProfile?.lastName}
                id_name="@lina_al"
                joined={userProfile?.userJoiningDate}
                active="Active now"
                followers={userProfile?.followers}
                following={userProfile?.following}
                getDistrictData={userProfile}
                ceu_goal_percent="0"
              // setEdiPro={setEdiPro}
              />
              <div className="profile_info">
                <h2>{userProfile?.districtName}</h2>
                <span>({userProfile?.state})</span>
              </div>
            </div>
            <div className="comment_tabpanel">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="comment_tab_button"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab label="Intro" {...a11yProps(0)} />
                  <Tab label="Connections" {...a11yProps(1)} />
                  <Tab label="Groups" {...a11yProps(2)} />
                  <Tab label="Courses" {...a11yProps(3)} />
                  {pathname?.split("/")[1] === "fig-family" ? (
                    id === LogUserde?.schoolDistrictId &&
                      LogUserde.roleId === 2 ? (
                      <Tab label="Register for FIG Events" style={{ textTransform: "math-auto" }} {...a11yProps(4)} />
                    ) : LogUserde.roleId === 1 ? (
                      <Tab label="Register for FIG Events" style={{ textTransform: "math-auto" }} {...a11yProps(4)} />
                    ) : (
                      ""
                    )
                  ) : (
                    <Tab label="Free Sources" {...a11yProps(4)} />
                  )}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="tabPanel_div">
                <Aboutsec
                  title_text={userProfile?.aboutUs}
                  sub_title={userProfile?.schoolDistrictName}
                  incomplete_icon={incomplete_img}
                  complete_icon={complete_img}
                  abtChange={abtChange}
                  setAbtChange={setAbtChange}
                  getDistrictData={userProfile}
                />
              </TabPanel>
              <TabPanel value={value} index={1} className="tabPanel_div">
                <Conections
                  usList={usList}
                  setAbtChange={setAbtChange}
                  abtChange={abtChange}
                />
              </TabPanel>
              <TabPanel value={value} index={2} className="tabPanel_div">
                <Groups grpList={grpList} />
              </TabPanel>
              <TabPanel value={value} index={3} className="tabPanel_div">
                <ProfileCources courseList={courseList} />
              </TabPanel>
              {pathname?.split("/")[1] === "fig-family" ? (
                <TabPanel value={value} index={4} className="tabPanel_div">
                  <RegisterConference
                    usList={usList}
                    setAbtChange={setAbtChange}
                    abtChange={abtChange}
                    districtName={userProfile?.districtName}
                    districtDetails={userProfile}
                  />
                </TabPanel>
              ) : (
                <TabPanel value={value} index={4} className="tabPanel_div">
                  <FreeSourcesTab freeSource={freeSource} />
                </TabPanel>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default React.memo(FigFamilyProfile);
