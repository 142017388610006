import React, { useEffect, useRef, useState, Suspense } from "react";
import "./Index.scss";
import Totalcount from "../../Reuseable_Components/Totalcount/Totalcount";
import total_course_icon from "../../../images/Icon/All_certificate.svg";
import user_icon from "../../../images/Icon/total_user_new.svg";
import cource_icon from "../../../images/Icon/total_course_new.svg";
import certificate_icon from "../../../images/Icon/total_ceu_new.svg";
import varified_user from "../../../images/Icon/total_ac_user_new.svg";
import { Table } from "reactstrap";
import Popup from "../../Reuseable_Components/Popup/Popup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import AllCertificateDash from "./AllCertificate";
import Swal from "sweetalert2";
import { Button, Autocomplete, TextField } from "@mui/material";
import { useDownloadExcel } from "react-export-table-to-excel";
import { DashboardButtonAccess, ReportName } from "../../../reducers/setting";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import CEUStatus from "./CEUStatus/index";
import CourseCompleteAndSchedule from "./CourseCompleteAndSchedule";
import PartnerAnalytics from "./PartnerAnalytics";
// import DatePicker from "react-datepicker";
import { DateRangePicker } from "rsuite";
import * as signalR from "@microsoft/signalr";
import "rsuite/dist/rsuite.min.css";
import active_img from "../../../images/Icon/active_green.svg";
import pro_img from "../../../images/default_profile.jpg";


import { useSignalR } from "../../../Utils/SignalRContext";

const renderLoader = () => <p></p>;

function Dashboard() {
  const today = new Date();
  let oneMonthback = new Date();
  oneMonthback?.setDate(oneMonthback?.getDate() - 30);

  const [value, setValue] = useState([oneMonthback, today]);
  const [dashTab, setDashTab] = useState(true);
  const [schList, setSchList] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const { connection } = useSignalR();

  const [schId, setSchId] = useState({
    value: "00000000-0000-0000-0000-000000000000",
    label: "All School District",
  });

  const [dateRange, setDateRange] = useState([oneMonthback, new Date()]);

  const [totalNumber, setTotalNumber] = useState();
  const [startDate, endDate] = dateRange;
  const [acdYS, setAcdYS] = useState("");

  useEffect(() => {
    if (acdYS && schoolId) {
      handleDateChange([startDate, endDate], true);
    }
    // eslint-disable-next-line
  }, [acdYS, schId, schoolId]);

  let changeDash = useSelector((state) => state.user.Drefresh);

  const [courceData, setCourseData] = useState([
    {
      id: "NA",
      name: "NA",
      email: "NA",
      courceName: "NA",
      dueDate: "NA",
      expired: "NA",
    },
  ]);
  const [courceData1, setCourceData1] = useState([
    {
      id: 1,
      name: "All About Motivation",
      no: "10",
    },
    {
      id: 2,
      name: "Safety First!",
      no: "30",
    },
    {
      id: 3,
      name: "All About Motivation",
      no: "10",
    },
    {
      id: 4,
      name: "Safety First!",
      no: "30",
    },
    {
      id: 5,
      name: "All About Motivation",
      no: "10",
    },
    {
      id: 6,
      name: "Safety First!",
      no: "30",
    },
  ]);

  const [certificationExpiration, setCertificationExpiration] = useState([
    {
      id: 1,
      name: "NA",
      email: "NA",
      certificateName: "NA",
      DateOfComPletion: "NA",
      DateOfExpiration: "NA",
      expired: "NA",
      ReminderofExpiration: "NA",
    },
  ]);
  const [usRepo, setUsRepo] = useState([
    {
      id: 1,
      col1: "NA",
      col2: "NA",
      col3: "NA",
      col4: "NA",
      col5: "NA",
      col6: "NA",
      col7: "NA",
      col8: "NA",
      col9: "NA",
      col10: "NA",
      userStatus: null,
      profileUrl: null
    },
  ]);
  const columns = [
    { field: "col1", headerName: "Name", width: 150 },
    { field: "col2", headerName: "Email", width: 150 },
    { field: "col3", headerName: "Phone", width: 150 },
    { field: "col4", headerName: "Site/Location", width: 150 },
    { field: "col5", headerName: "Hire Date", width: 150 },
    { field: "col6", headerName: "Job Title", width: 150 },
    { field: "col7", headerName: "CEU Required", width: 150 },
    { field: "col8", headerName: "CEU Earned", width: 150 },
    { field: "col9", headerName: "CEUs Needed", width: 150 },
    { field: "col10", headerName: "Last Login", width: 150 },
    { field: "col11", headerName: "View Report", width: 150 },
  ];

  const [ceuCourseList, setCeuCourseList] = useState([]);
  const [acdYList, setAcdYList] = useState("");

  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);

  let dispatch = useDispatch();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "User Report",
    sheet: "Users",
  });

  const [ceuSearchData, setCeuSearchData] = useState([
    {
      id: "NA",
      name: "NA",
      no: "NA",
    },
  ]);

  const [totalCount, setTotalCount] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    getDashbtnAcc();
    getSchoolList();
    getAcademicYearList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schList?.length > 0) {
      if (acdYList?.length > 0) {
        if (schList.length > 1) {
          getDashData("00000000-0000-0000-0000-000000000000", acdYS);
        } else {
          getDashData(schList[0]?.value, acdYS);
          setSchId(schList[0]);
        }
      }
    }
    // eslint-disable-next-line
  }, [changeDash, schList, acdYList]);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  // const [connection, setConnection] = useState(null);

  // useEffect(() => {
  //   console.log(sessionStorage.getItem("connection"));
  //   if (sessionStorage.getItem("connection")) return;
  //   if (connection) return;
  //   const connect = new signalR.HubConnectionBuilder()
  //     .withUrl(`${Config.API_HOST_URL_live}/chathub`, {
  //       accessTokenFactory: () => token,
  //       transport: signalR.HttpTransportType.WebSockets,
  //       // Use token for authorization
  //     })
  //     .withAutomaticReconnect()
  //     // Reconnect automatically if the connection drops
  //     .build();

  //   // Start the SignalR connection
  //   connect
  //     .start()
  //     .then(() => {
  //       setConnection(connect);
  //       console.log(connect, "connect");
  //       sessionStorage.setItem("connection", JSON.stringify(connect));
  //     })
  //     .catch((err) => console.error("SignalR connection failed: ", err));
  //   connect.onclose(() => {
  //     setConnection(null); // Clear the connection state
  //     sessionStorage.removeItem("connection");
  //   });
  //   // Cleanup the connection when component unmounts
  //   return () => {
  //     if (connect) connect.stop();
  //   };
  // }, [token]);
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setCourseData((old) => {
          return old?.map((x) => {
            if (x?.id === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });


        setCourceData1((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });

        setUsRepo((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });

        setCertificationExpiration((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);

  const getDashbtnAcc = () => {
    if (DashBtnList === undefined || DashBtnList?.length === 0) {
      axios
        .get(
          `${Config.API_HOST_URL_live}/api/AccessLevel/GetAccessLevelByRoleId?roleId=${LogUserde.roleId}`,
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              let btnData = res.data.payload;
              dispatch(DashboardButtonAccess(btnData));
            }
          }
        });
    }
  };

  const getDashData = async (id, Aeid) => {
    let seManage = {
      url: `${Config.API_HOST_URL_live}/api/Dashboard/GetDashboardList?userId=${LogUserId}&schoolDistrictId=${id}&academicYearId=${Aeid}&totalRecord=10`,
      headers: headers,
    };
    let response = await GetCallApi(seManage);
    if (response?.status === 200) {
      if (response.data.success) {
        const dashData = response?.data;
        setTotalCount(dashData.totalCount[0]);
        // return false;
        let today = new Date()
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
        function convertDateFor(str) {
          var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
          return [mnth, day, date.getFullYear()].join("/");
        }
        const courseSE = dashData.courseSchedule?.map((item) => {
          // function parseDate(input, format) {
          //   format = format || "yyyy-mm-dd"; // default format
          //   var parts = input.match(/(\d+)/g),
          //     i = 0,
          //     fmt = {};
          //   // extract date-part indexes from the format
          //   format.replace(/(yyyy|dd|mm)/g, function (part) {
          //     fmt[part] = i++;
          //   });

          //   return new Date(
          //     parts[fmt["yyyy"]],
          //     parts[fmt["mm"]] - 1,
          //     parts[fmt["dd"]]
          //   );
          // }

          let td = convertDateFor(today);
          let ex = convertDateFor(item.dueDate);

          td = new Date(td).toLocaleDateString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          ex = new Date(ex).toLocaleDateString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          let st = new Date(td) <= new Date(ex) ? "a" : "e";

          return {
            id: item?.userId,
            name: item?.firstName + " " + item?.lastName,
            email:
              item?.email === "" || item?.email === null ? "" : item?.email,
            courseId: item?.courseId,
            courseTypes: item?.courseTypes,
            courceName:
              item?.courseName === "" || item?.courseName === null
                ? ""
                : item?.courseName,
            dueDate:
              item?.dueDate === "" || item?.dueDate === null
                ? ""
                : item?.dueDate,
            expired:
              item?.courseExpiringStatus === null ||
                item?.courseExpiringStatus === ""
                ? ""
                : item?.courseExpiringStatus === "Completed"
                  ? "Completed"
                  : st === "e"
                    ? "Expired"
                    : item?.courseExpiringStatus === "Scheduled"
                      ? "Scheduled"
                      : item?.courseExpiringStatus === "In Progress"
                        ? "In Progress"
                        : item?.courseExpiringStatus,
            userCourseAllocationId: item.userCourseAllocationId,
            userStatus: item.userStatus,
            profileUrl: item?.profileUrl
          };
        });
        setCourseData(courseSE);
        const topCourse = dashData.topCourses?.map((item) => {
          return {
            id:
              item?.courseId === "" || item?.courseId === null
                ? ""
                : item?.courseId,
            name:
              item?.courseName === "" || item?.courseName === null
                ? ""
                : item?.courseName,
            no:
              item?.numberOfCompletions === "" ||
                item?.numberOfCompletions === null
                ? ""
                : item?.numberOfCompletions,
          };
        });

        const topStudent = dashData.topStudents?.map((item, i) => {
          return {
            userId: item?.userId,
            name:
              item?.studentName === "" || item?.studentName === null
                ? ""
                : item?.studentName,
            no:
              item?.completedCEUs === "" || item?.completedCEUs === null
                ? ""
                : item?.completedCEUs,
            userStatus: item?.userStatus,
            profileUrl: item?.profileUrl
          };
        });
        setCourceData1(topStudent);
        setCeuSearchData(topCourse);
        const userReport = dashData.userReport?.map((item) => {
          return {
            userId: item?.userId,
            col1: item?.name === "" || item?.name === null ? "" : item?.name,
            col2: item?.email === "" || item?.email === null ? "" : item?.email,
            col3: item?.phone === "" || item?.phone === null ? "" : item?.phone,
            col4:
              item.site_Location === "" || item.site_Location === null
                ? ""
                : item.site_Location,
            col5:
              item.hireDate === "" || item.hireDate === null
                ? ""
                : item.hireDate,
            col6:
              item.jobTitle === "" || item.jobTitle === null
                ? ""
                : item.jobTitle,
            col7:
              item.ceuRequired === "" || item.ceuRequired === null
                ? ""
                : item.ceuRequired,
            col8:
              item.ceuEarned === "" || item.ceuEarned === null
                ? ""
                : item.ceuEarned,
            col9:
              item.ceUsNeeded === "" || item.ceUsNeeded === null
                ? ""
                : item.ceUsNeeded,
            col10:
              item.lastLogin === "" || item.lastLogin === null
                ? ""
                : item.lastLogin,
            userStatus: item?.userStatus,
            profileUrl: item?.profileUrl
          };
        });
        setUsRepo(userReport);
        const cerExpiry = dashData?.certificationExpiration?.map((item, i) => {
          let td = convertDateFor(today);
          let ex = convertDateFor(item.dateOfExpiration?.split("12:")[0]);
          let st = new Date(td) < new Date(ex) ? "a" : "e";
          return {
            userId: item?.userId,
            id: i + 1,
            courseName:
              item?.courseName === "" || item?.courseName === null
                ? ""
                : item?.courseName,
            certificateId: item?.id === "" || item.id === null ? "" : item?.id,
            certificateAgencyName:
              item?.certificateAgencyName === "" ||
                item?.certificateAgencyName === null
                ? ""
                : item?.certificateAgencyName,
            name: item?.name === "" || item?.name === null ? "" : item?.name,
            email:
              item?.userEmail === "" || item?.userEmail === null
                ? ""
                : item?.userEmail,
            certificateName:
              item?.certificateName === "" || item?.certificateName === null
                ? ""
                : item?.certificateName,
            DateOfComPletion:
              item?.dateOfCompletion === "" || item?.dateOfCompletion === null
                ? ""
                : item?.dateOfCompletion,
            DateOfExpiration:
              item?.dateOfExpiration === "" || item?.dateOfExpiration === null
                ? ""
                : item?.dateOfExpiration,
            expired:
              item?.status === "" || item?.status === null ? "" : item?.status,
            ReminderofExpiration:
              item?.reminderOfExpiry === "" || item?.reminderOfExpiry === null
                ? ""
                : item?.reminderOfExpiry,
            certificateFromOtherSourcesId:
              item?.certificateFromOtherSourcesId === "" ||
                item?.certificateFromOtherSourcesId === null
                ? ""
                : item?.certificateFromOtherSourcesId,
            certificateMaterialUrl: item?.certificateMaterialUrl,
            schoolDistrictId: item?.schoolDistrictId,
            statusEx: st,
            siteLocation: item?.siteLocation,
            userStatus: item?.userStatus,
            profileUrl: item?.profileUrl
          };
        });
        setCertificationExpiration(cerExpiry);
        const drpdwnCourse = dashData?.drpdwnforCourseTitle?.map((item) => {
          return {
            courseId: item?.courseId,
            courseTitle: item?.courseTitle,
          };
        });
        setCeuCourseList(drpdwnCourse);
      } else {
        setCourseData();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // });
  };

  const handleCerti = (id) => {
    navigate(`/certificate/report/${id}`, { replace: true });
  };
  const handleReCerti = () => {
    navigate("all-certificate", { replace: true });
  };
  const handleReUser = () => {
    // navigate("/dashboard/user-report", { replace: true });
    navigate("/reports", { replace: true });
    dispatch(ReportName("CEU Tracking Report"));
  };

  const getSchoolList = () => {
    let url;

    if (LogUserde?.roleName === "Instructor") {
      url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetAllDistrcitsListForInstructor?userId=${LogUserId}`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId}`;
    }

    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value: item?.schoolDistrictId,
              label: item?.districtName,
            };
          });
          let schData = [
            {
              value: "00000000-0000-0000-0000-000000000000",
              label: "All School District",
            },
            ...data,
          ];
          setSchList(data?.length > 1 ? schData : data);
          let sch = data?.length > 1 ? schData[0].value : data[0]?.value;
          setSchoolId(sch);
          // }
        }
      })
      .catch((error) => console.log(error));
  };

  const getAcademicYearList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const user = response?.data.payload?.map((item, i) => {
            return {
              academicYearId: item?.academicYearId ? item?.academicYearId : "",
              academicYear: item?.academicYear ? item?.academicYear : "",
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
            };
          });
          setAcdYList(user);
          let curtY = user?.filter((x) => x.isCurrentYear === "True");
          setAcdYS(curtY[0]?.academicYearId);
        }
      });
  };

  const handleAcademicYear = (e) => {
    setAcdYS(e);
    if (e) {
      getDashData(schId?.value, e);
    } else {
      setUsRepo([]);
    }
  };

  const handleDateChange = async (date, firstTime) => {
    setValue(date);

    if (!date || date.length === 0 || (!date[0] && !date[1])) {
      let passD = {
        userId: LogUserId,
        schoolDistrictId: schoolId,
        startDate: null,
        endDate: null,
        academicYearId: acdYS,
      };
      let seDate = {
        url: `/api/Dashboard/GetDashboardTotalCountByDateRange`,
        body: passD,
      };

      let res = await PostCallApi(seDate);
      if (res?.status === 200 && res?.data?.success) {
        let allData = res?.data?.payload;
        setTotalNumber(allData);
      }
      return;
    }

    let startEndDate = date?.map((x) => x?.toISOString());
    let sDate = startEndDate[0] ? new Date(startEndDate[0]) : null;
    let eDate = startEndDate[1] ? new Date(startEndDate[1]) : null;

    if (!firstTime) {
      if (startEndDate[0]) {
        sDate?.setDate(sDate?.getDate() + 1);
      }
      if (startEndDate[1]) {
        eDate?.setDate(eDate?.getDate() + 1);
      }
    }

    let passD = {
      userId: LogUserId,
      schoolDistrictId: schoolId,
      startDate: sDate ?? null,
      endDate: eDate ?? null,
      academicYearId: acdYS,
    };

    let seDate = {
      url: `/api/Dashboard/GetDashboardTotalCountByDateRange`,
      body: passD,
    };

    let res = await PostCallApi(seDate);
    if (res?.status === 200 && res?.data?.success) {
      let allData = res?.data?.payload;
      setTotalNumber(allData);
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const formatDateRange = (range) => {
    return range && range.length === 2
      ? `${formatDate(range[0])} ~ ${formatDate(range[1])}`
      : "Select Date Range";
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section
          className="dashboard_page main_backcolor"
          id="dashboard_section"
        >
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="dash_t_box ">
                {DashBtnList?.filter(
                  (check, i) =>
                    check?.menuName ===
                    "Partner Analytics Button On Dashboard" &&
                    check?.isAssigned === true
                )?.length > 0 ? (
                  <div className="d-flex">
                    <Button
                      className={`dash_head_buttonTab first ${dashTab ? "active" : ""
                        }`}
                      onClick={() => setDashTab(true)}
                    >
                      Dashboard
                    </Button>
                    <Button
                      className={`dash_head_buttonTab last ${!dashTab ? "active" : ""
                        }`}
                      onClick={() => setDashTab(false)}
                    >
                      Partner Analytics
                    </Button>
                  </div>
                ) : (
                  <div className="dashboard_title">DASHBOARD</div>
                )}

                <div className="btn_box">
                  {DashBtnList?.filter(
                    (check, i) =>
                      check?.menuName ===
                      "Employee Review Button On Dashboard" &&
                      check?.isAssigned === true
                  )?.length > 0 ? (
                    <Link to={"employee-review"} className="review_btn">
                      My Review
                    </Link>
                  ) : (
                    ""
                  )}
                  <select
                    name=""
                    id=""
                    className="form-select download_drop"
                    value={acdYS}
                    onChange={(e) => handleAcademicYear(e.target.value)}
                  >
                    <option value="">Select Academic Year</option>
                    {acdYList &&
                      acdYList?.map((acy, i) => (
                        <option value={acy.academicYearId} key={i}>
                          {acy.academicYear}
                        </option>
                      ))}
                  </select>
                  <Autocomplete
                    disablePortal
                    className="form-select1"
                    options={schList}
                    value={schId?.label}
                    onChange={(e, newValue) => {
                      if (newValue === null) {
                        setSchId(schList[0]);
                        getDashData(schList[0].value, acdYS);

                        // if (schList?.length > 1) {
                        //   getUserListSuper();
                        // } else {
                        setSchoolId(schList[0].value);
                        // }
                      } else {
                        setSchId(newValue);
                        getDashData(newValue.value, acdYS);
                        // if (
                        //   newValue?.value ===
                        //   "00000000-0000-0000-0000-000000000000"
                        // ) {
                        // if (schList?.length > 1) {
                        //   getUserListSuper();
                        // } else {
                        setSchoolId(newValue.value);
                        // }
                        // } else {
                        //   getuserListsNormal(newValue?.value);
                        // }
                      }
                    }}
                    renderInput={(params, i) => (
                      <TextField
                        className="texfiled-demo"
                        key={i}
                        {...params}
                      />
                    )}
                  />
                  {/* ) : (
                    ""
                  )} */}
                  {/* {LogUserde?.roleId === 2 ? (
                    <a
                      href="https://swanlo.com/lfserver/FIG_DoneForYou_process"
                      className="time_line my-auto me-3 p_14_text"
                      target="_blanck"
                    >
                      Done For You
                    </a>
                  ) : (
                    ""
                  )} */}
                  <Link to="/community-groups" className="time_line ">
                    Community Groups
                  </Link>
                </div>
              </div>
              {dashTab ? (
                <>
                  <div className="total_container">
                    <div className="row">
                      <div className="col-xl-3 col-lg-12 col-md-6 col-12 mb-3 m-xl-0">
                        <div
                          className="date_filter after_responsive"
                          style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="row" style={{ marginTop: "auto" }}>
                            {LogUserde?.roleId === 1 ||
                              LogUserde?.roleId === 2 ? (
                              <>
                                <div className={`col-md-12 total_count`}>
                                  <div className={`totalitem `}>
                                    <div className="totalitemcontent">
                                      <div className="totalitem_title">
                                        {totalCount?.totalUsers}
                                      </div>
                                      <div className="totalitem_desc ">
                                        Total Users
                                      </div>
                                    </div>
                                    <div className="totalicon">
                                      <img src={user_icon} alt="..." />
                                    </div>
                                  </div>
                                </div>
                                {/* <Totalcount
                                img={user_icon}
                                number={totalCount?.totalUsers}
                                desc="Total Users"
                              /> */}
                              </>
                            ) : (
                              <>
                                <div className={`col-md-12 total_count`}>
                                  <div className={`totalitem `}>
                                    <div className="totalitemcontent">
                                      <div className="totalitem_title">
                                        {totalCount?.totalCourses}
                                      </div>
                                      <div className="totalitem_desc ">
                                        Total Courses
                                      </div>
                                    </div>
                                    <div className="totalicon">
                                      <img src={total_course_icon} alt="..." />
                                    </div>
                                  </div>
                                </div>
                                {/* <Totalcount
                                img={total_course_icon}
                                number={totalCount?.totalCourses}
                                desc="Total Courses"
                              /> */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-12 col-md-6 col-12">
                        <div className="date_filter">
                          <div className="row start_end_date">
                            <div className="col-xl-3 col-lg-4 col-12">
                              <div className="date_pick">
                                <DateRangePicker
                                  value={value}
                                  onChange={(update) => {
                                    handleDateChange(update);
                                  }}
                                  placeholder="Select Date Range"
                                  style={{ width: 280 }}
                                  renderValue={() => formatDateRange(value)}
                                />
                                {/* <DatePicker
                                  className="date-input"
                                  placeholderText="Select Start and End Date"
                                  selectsRange={true}
                                  startDate={startDate}
                                  monthsShown={2}
                                  endDate={endDate}
                                  onChange={(update) => {
                                    handleDateChange(update);
                                  }}
                                  isClearable={true}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <Totalcount
                              img={cource_icon}
                              // number={totalCount?.totalCoursesCompleted}
                              number={totalNumber?.totalCoursesCompleted}
                              // number="50"
                              desc="Courses Completed"
                            />
                            <Totalcount
                              img={certificate_icon}
                              // number={totalCount?.totalCEUEarnedByStudents}
                              number={totalNumber?.totalCEUEarnedByStudents}
                              // number="20"
                              desc="CEU's Earned By Students"
                            />{" "}
                            <Totalcount
                              img={varified_user}
                              // number={totalCount?.totalUserActiveFromPastWeek}
                              number={totalNumber?.lastLogin}
                              // number="10"
                              // desc="Last Logins"
                              desc="Active Users"
                              filter={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CourseCompleteAndSchedule
                    schId={schId?.value}
                    courceData={courceData}
                    acdYS={acdYS}
                    ceuCourseList={ceuCourseList}
                  />
                  <CEUStatus
                    acdYS={acdYS}
                    schoolId={schoolId}
                    ceuCourseList={ceuCourseList}
                    courceData1={courceData1}
                    ceuSearchData={ceuSearchData}
                  />
                  {/* {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? ( */}
                  {DashBtnList?.map((check, i) => {
                    if (
                      check?.menuName === "Add Manual CEU Dashboard" &&
                      check?.isAssigned === true
                    ) {
                      return (
                        <div className="chart_data_sec">
                          <div className="chart_box cource_box">
                            <div className="chart_title_box d-flex justify-content-between">
                              <label className="chart_title">User Report</label>
                              <div className="grid_top_button_group d-flex responsive_btn_set">
                                <Button
                                  className="delete_btn btn_space"
                                  onClick={onDownload}
                                >
                                  Download
                                </Button>

                                <Button
                                  className="cource_reschedule_button btn_space"
                                  onClick={handleReUser}
                                >
                                  View all
                                </Button>

                                <Popup key={i} btnName="Add Manual CEU" />
                              </div>
                            </div>

                            <div
                              className="show_back table-responsive user_report"
                              ref={tableRef}
                            >
                              <Table className="data_table mb-2">
                                <thead>
                                  <tr className="table_pading">
                                    <th>No</th>
                                    {columns?.map((item, i) => (
                                      <th key={i}>{item?.headerName}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {usRepo &&
                                    usRepo?.map((data, i) => (
                                      <tr key={i + 1} className="table_pading">
                                        <td>{i + 1}</td>
                                        <td>
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="img_boxs">
                                              <div className="user_img">
                                                <img
                                                  src={`${data?.profileUrl ? Config?.API_HOST_URL_live + data?.profileUrl : pro_img}`}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </div>
                                              <img
                                                src={
                                                  data?.userStatus === "Online"
                                                    ? active_img
                                                    : ""
                                                }
                                                className="img-fluid dot_ac_in"
                                              />
                                            </div>
                                            {data?.col1}
                                          </div>
                                        </td>
                                        <td>{data?.col2}</td>
                                        <td>{data?.col3}</td>
                                        <td>{data?.col4}</td>
                                        <td>{data?.col5}</td>
                                        <td>{data?.col6}</td>
                                        <td>{data?.col7}</td>
                                        <td>{data?.col8}</td>
                                        <td>{data?.col9}</td>
                                        <td>{data?.col10}</td>
                                        <td>
                                          <Button
                                            className="theme_btn px-2 py-1"
                                            style={{
                                              lineHeight: "20px !important",
                                            }}
                                            onClick={() =>
                                              handleCerti(data?.userId)
                                            }
                                          >
                                            View Report
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return "";
                  })}
                  {/* ) : (
                    ""
                  )} */}
                  <div className="chart_data_sec mb-0">
                    <div className="chart_box cource_box">
                      <div className="chart_title_box d-flex justify-content-between">
                        <label className="chart_title">
                          Certification Expiration
                        </label>
                        <div className="grid_top_button_group">
                          <Button
                            className="cource_reschedule_button btn_space"
                            onClick={handleReCerti}
                          >
                            View all
                          </Button>
                          {DashBtnList?.map((check, i) => {
                            if (
                              check?.menuName === "Add Certificate Dashboard" &&
                              check?.isAssigned === true
                            ) {
                              return (
                                <Popup key={i} btnName="Add Certificates" />
                              );
                            }
                            return "";
                          })}
                        </div>
                      </div>
                      <AllCertificateDash
                        TableNEW={Table}
                        certificationExpiration={certificationExpiration}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <PartnerAnalytics acdYS={acdYS} schoolId={schoolId} />
                </>
              )}
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(Dashboard);
