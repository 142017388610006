import React, { useState } from "react";
import { Button } from "@mui/material";
import active_list from "../../../../images/active_list.png";
import inactive_list from "../../../../images/inactive_list.png";
import active_grid from "../../../../images/active_grid.png";
import inactive_grid from "../../../../images/inactive_grid.png";
import CEUCard from "../../CEU_Cources/Card";
import userimg2 from "../../../../images/user10.png";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
import MyCertificate from "../Components/MyCertificate";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function ProfileCources() {
  const [grid, setGrid] = useState(true);
  // const [connectionActive, setconnectionActive] = useState(true);
  const connectionActive = true;
  const [ceuCardData, setCeuCardData] = useState([]);
  let LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const myCer = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]?.map((item, i) => {
    return {
      name:
        i % 2 === 0
          ? "Safety First!"
          : "Overcoming Critical Issues Facing Child......",
      date: i % 2 === 0 ? convert("2022-10-21") : convert("2022-5-5"),
    };
  });
  function convert(str) {
    var date = new Date(str),
      month = date.toLocaleString("default", { month: "long" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }
  useEffect(() => {
    getMYcouses();
    // eslint-disable-next-line
  }, []);
  const getMYcouses = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyCourseList?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          let clist = response.data.payload?.map((item) => {
            return {
              id: item?.courseId,
              cardTitle: item?.courseTitle,
              cardimg:
                item?.thumbnailCourseUrl === "" || item?.thumbnailCourseUrl === null
                  ? cardimg1
                  : `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`,
              courseCeu:
                item?.totalCEU === null || item?.totalCEU === undefined
                  ? ""
                  : item?.totalCEU,
              cate: item?.categories,
              des: item?.description,
              courseTypes: item?.courseTypes,
              startCompleted: item?.status === null ? "" : item?.status,
              lesson:
                item?.totalLession === "" || item?.totalLession === ""
                  ? ""
                  : item?.totalLession + " Lessons",
              perCompleted: item?.progress ? item?.progress : "0",
              rating: item?.ratings ? item?.ratings : "0",
              userimg:
                item?.instructor === null ||
                item?.instructor?.profileUrl === "" ||
                item?.instructor?.profileUrl === null
                  ? userimg2
                  : `${Config.API_HOST_URL_live}${item.instructor?.profileUrl}`,
              userName:
                item?.instructor === null ||
                item?.instructor?.fistName === "" ||
                item?.instructor?.fistName === null
                  ? ""
                  : item.instructor?.firstName +
                    " " +
                    item?.instructor?.lastName,
              stepActive:
                item?.instructor === null ||
                item?.instructor?.siteLocation === null ||
                item?.instructor?.siteLocation === ""
                  ? "-"
                  : item?.instructor?.siteLocation,
            };
          });
          setCeuCardData(clist);
        }
      });
  };
  let nav = useNavigate();

  const HandleShowCouceCard = (e) => {
    if (e?.courseTypes === "1" || e?.courseTypes === 1) {
      nav(`/ceu-courses/${e?.id}`, { replace: true });
    } else if (e?.courseTypes === "2" || e?.courseTypes === 2) {
      nav(`/fig-lifestyle/${e?.id}`, { replace: true });
    }
  };
  return (
    <>
      <div className="row m-0">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex">
            {/* <div className="leftside_action">
              <Button
                onClick={() => setconnectionActive(true)}
                className={
                  connectionActive === true ? "theme_button me-3" : "me-3"
                }
              >
                My Courses
              </Button>
            </div> */}
            {connectionActive && (
              <div className="rightside_action w-100">
                <Button className="grid_btn" onClick={() => setGrid(true)}>
                  <img
                    src={grid === true ? active_grid : inactive_grid}
                    className="img-fluid"
                    alt="grid"
                  />
                </Button>
                <Button className="list_btn" onClick={() => setGrid(false)}>
                  <img
                    src={grid === false ? active_list : inactive_list}
                    className="img-fluid"
                    alt="grid"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        {connectionActive === true ? (
          <>
            <CEUCard
              grid={grid}
              c
              data={ceuCardData}
              pageSize="8"
              onClick={HandleShowCouceCard}
            />
          </>
        ) : (
          <>
            <MyCertificate data={myCer} />
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(ProfileCources);
