import React, { useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import userimg13 from "../../../../../images/user13.png";
import backimg13 from "../../../../../images/group_card_back13.png";
import Edit_icon_new from "../../../../../images/Icon/Edit_icon_new.svg";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function AllGroups(props) {
  const [open1, setOpen] = React.useState(false);
  const [grpList, setGrpList] = useState([]);
  const [cgName, setCgName] = useState("");
  let nav = useNavigate();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleClickOpen = (name) => {
    setCgName(name);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  useEffect(() => {
    getAllGroup();
    // eslint-disable-next-line
  }, []);
  const getAllGroup = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/CommunityGroupList`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const allGrp = response.data.payload?.map((item) => {
            return {
              communityGroupId: item?.communityGroupId,
              Name: item?.communityGroupName,
              typeId: item?.groupTypeId,
              organizerUserId: item?.organizerUserId,
              img:
                item?.groupImageUrl === "" || item?.groupImageUrl === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
              backimg:
                item?.groupCoverImage === "" || item?.groupCoverImage === null
                  ? backimg13
                  : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
            };
          });
          setGrpList(allGrp);
        }
      });
  };
  const handleRede = (id) => {
    nav(`${id}`, { replace: true });
  };
  return (
    <>
      {props.grid === true ? (
        <>
          {grpList?.map((item, i) => (
            <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-xsm-12 mb-3" key={i}>
              <div className="grid_groups_card text-center mb-3">
                {item?.organizerUserId === LogUserId ? (
                  <div
                    className="edit_icon"
                    onClick={() => {
                      props?.setconnectionActive(4);
                      props?.setEditId(item.communityGroupId);
                    }}
                  >
                    <img src={Edit_icon_new} alt="" />
                  </div>
                ) : (
                  ""
                )}
                <img
                  src={item?.backimg ? item?.backimg : backimg13}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = backimg13;
                  }}
                  alt="backimg"
                  className="img-fluid banner_img"
                />
                <div className="groups_card_text">
                  <img
                    src={item?.img ? item?.img : userimg13}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                    alt="profileimg"
                    className="img-fluid group_img mb-3"
                  />
                  <h6>
                    {item?.Name === null || item?.Name === ""
                      ? "--"
                      : item?.Name}
                  </h6>
                  <p>Group Private</p>
                  {/* <p>Active 2 days ago</p> */}
                </div>

                <div className="mt-2 groups_card_btn pb-3 border-0">
                  <Button
                    onClick={() => handleRede(item?.communityGroupId)}
                    fullWidth
                  >
                    View
                  </Button>
                </div>

              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_groups_card">
                <div className="connection_card_text d-flex">
                  <img src={item?.img ? item?.img : userimg13}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                    alt="profileimg" className="img-fluid" />

                  <div className="connection_card_title">
                    <h6>
                      {item?.Name === null || item?.Name === ""
                        ? "--"
                        : item?.Name}
                    </h6>
                    <h6>{item.name}</h6>
                    <p>Group Private</p>
                  </div>
                  {/* <p>Active 16 hours ago</p> */}
                </div>
                <div className="connection_card_btn">
                  {item?.organizerUserId === LogUserId ? (
                    <div
                      className="edit_icon d-flex align-items-center me-2"
                      onClick={() => {
                        props.setconnectionActive(4);
                        props.setEditId(item?.communityGroupId);
                      }}
                    >
                      <img src={Edit_icon_new} alt="" className="img-fluid " />
                    </div>
                  ) : (
                    ""
                  )}
                  <Button onClick={() => handleClickOpen(item?.Name)} fullWidth>
                    View
                  </Button>
                </div>

              </div>
            </div>
          ))}
        </>
      )}
      {grpList?.length <= 0 ? (
        <div className="text-center my-5 p_20_text"> No Data Found</div>
      ) : (
        ""
      )}
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose1}
          dividers
        >
          Join Group
        </BootstrapDialogTitle>
        <DialogContent>
          <div className="row mb-3">
            <div className="col-md-12 leave_group">
              <p>
                Are you sure you want to Join{" "}
                <span className="theme_span">{cgName}</span>?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2">Confirm</Button>
          <Button autoFocus onClick={handleClose1} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default React.memo(AllGroups);
