import React, { useState } from "react";
import "./index.scss";
import star_full from "../../../../images/star_full.png";
import star_blank from "../../../../images/star_blank.png";
import Rating from "react-rating";
import Pagination from "../../../Reuseable_Components/Paginations/Pagination";
import { useSelector } from "react-redux";
import Config from "../../../../Utils/Config";
import axios from "axios";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import cardimg1 from "../../../../images/course_not_found_final_1.png";
import { Backdrop, CircularProgress } from "@mui/material";
import { SwalSuccess } from "../../../Reuseable_Components/SwalToast";
import CeuCoursePopup from "../../../Reuseable_Components/CeuCoursePopup/CeuCoursePopup";
// import cardimg1 from "../../../../images/fig_CROP.png";

export default function CEU_Card({ data, onClick, pageSize, grid, tabValue }) {
  //pagination
  const LogUserDetails = useSelector((state) => state.login.LoginDetails);
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  let nav = useNavigate();
  let location = useLocation();
  let Pagesize = pageSize ? pageSize : 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  // const [searchField, setSearchField] = useState("");
  const searchField = "";
  const formattedSearchResults = data?.filter((user) => {
    if (searchField) {
      return user;
    } else return user;
  });
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const startCourse = async (id) => {
    let stCou = {
      courseId: pageSize ? id?.id : id,
      userId: LogUserDetails?.userId,
      status: "In Progress",
    };
    setOpen(true);
    let response = await axios.post(
      `${Config.API_HOST_URL_live}/api/UserCourse/StartUserCourse`,
      stCou,
      { headers }
    );
    setOpen(false);
    if (response.data.success) {
      setOpen(false);
      let msg = await titleCase(response.data?.message);
      SwalSuccess(msg);

      if (pageSize) {
        if (id?.courseTypes === "1" || id?.courseTypes === 1) {
          nav(`/ceu-courses/start-course/${id?.id}`, { replace: true });
        } else if (id?.courseTypes === "2" || id?.courseTypes === 2) {
          nav(`/fig-lifestyle/start-course/${id?.id}`, {
            replace: true,
          });
        }
      } else {
        nav(`/${location.pathname?.split("/")[1]}/start-course/${id}`, {
          replace: true,
        });
      }
    } else {
      setOpen(false);
    }
  };
  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };
  const completeCourse = (id) => {
    if (pageSize) {
      if (id?.courseTypes === "1" || id?.courseTypes === 1) {
        nav(`/ceu-courses/complete-course/${id?.id}`, { replace: true });
      } else if (id?.courseTypes === "2" || id?.courseTypes === 2) {
        nav(`/fig-lifestyle/complete-course/${id?.id}`, { replace: true });
      }
    } else {
      nav(`/${location.pathname?.split("/")[1]}/complete-course/${id}`, {
        replace: true,
      });
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {formattedSearchResults &&
        formattedSearchResults
          ?.slice(
            (currentPage - 1) * Pagesize,
            (currentPage - 1) * Pagesize + Pagesize
          )
          .map((item, i) => (
            <div
              className={` ${
                grid === undefined || grid === true
                  ? `${
                      location.pathname === "/ceu-courses" ||
                      location.pathname === "/fig-lifestyle"
                        ? "col-xl-3 col-lg-4 col-md-6"
                        : "col-xl-4 col-lg-4 col-md-6"
                    }`
                  : "col-12"
              } mb-4`}
              key={i}
            >
              <div
                className={`card_detail_box ${
                  grid === undefined || grid === true ? "" : "dir_row"
                }`}
              >
                <div className="img_box_text">
                  <img
                    src={item?.cardimg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = cardimg1;
                    }}
                    className="img-fluid main_card_img"
                    alt=""
                    onClick={(e) => onClick(pageSize ? item : item?.id)}
                  />
                  <div
                    className={`img_text ${
                      item?.startCompleted === "Completed"
                        ? "orange"
                        : item?.startCompleted === "In Progress" ||
                          item?.startCompleted === "Scheduled"
                        ? "linear"
                        : ""
                    }`}
                  >
                    <span>{item?.startCompleted}</span>
                  </div>
                  <div className="author_name">
                    <span>Offered By: {item?.userName}</span>
                  </div>
                </div>
                <div className="card_details">
                  <div className={`card_title ${grid === false ? "" : "tr"}`}>
                    {item?.cardTitle}

                    {grid === false ? (
                      <>
                        <p className="description">{item?.des}</p>
                        <span className="card_lesson">{item?.lesson}</span>
                        <div className="rating_completed">
                          <p>{item?.perCompleted}% Complete</p>
                          <div className="rating_box">
                            <Rating
                              emptySymbol={
                                <img src={star_blank} alt="rating_blank" />
                              }
                              fullSymbol={
                                <img src={star_full} alt="rating-full" />
                              }
                              initialRating={item.rating ? item.rating : "0"}
                              readonly
                            />
                          </div>
                        </div>
                        <div className="complete_percent">
                          <div
                            className="complete_color"
                            style={{
                              width: `${
                                item?.perCompleted > 100
                                  ? 100
                                  : item?.perCompleted
                              }%`,
                            }}
                          ></div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {grid === undefined || grid === true ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <span className="card_lesson">{item?.lesson}</span>
                        <span className="card_lesson ceu">
                          CEU: {item?.courseCeu}
                        </span>
                      </div>
                      <div className="rating_completed">
                        <p>{item?.perCompleted}% Complete</p>
                        <div className="rating_box">
                          <Rating
                            emptySymbol={
                              <img src={star_blank} alt="rating_blank" />
                            }
                            fullSymbol={
                              <img src={star_full} alt="rating-full" />
                            }
                            initialRating={item.rating ? item.rating : "0"}
                            readonly
                          />
                        </div>
                      </div>
                      <div className="complete_percent">
                        <div
                          className="complete_color"
                          style={{
                            width: `${
                              item?.perCompleted > 100
                                ? 100
                                : item?.perCompleted
                            }%`,
                          }}
                        ></div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="mt-3 category_show">
                    Key Area: <span>{item?.cate}</span>
                  </div>
                  <div className="mt-3">
                    <span className="description ">
                      Description: {item?.des?.slice(0, 85)}
                    </span>
                    {item?.des?.length > 85 && (
                      <span
                        className="description cr"
                        onClick={() => showReadMore(item?.des)}
                      >
                        ...read more
                      </span>
                    )}
                  </div>
                  {grid === false ? (
                    item?.startCompleted === "In Progress" ||
                    item?.startCompleted === "Scheduled" ||
                    item?.startCompleted === "" ? (
                      <Button
                        className="st_btn mt-auto w-100"
                        onClick={() => startCourse(pageSize ? item : item.id)}
                      >
                        Start Course
                      </Button>
                    ) : item?.startCompleted === "Completed" ? (
                      <Button
                        className="st_btn mt-auto w-100 ora"
                        onClick={() =>
                          completeCourse(pageSize ? item : item.id)
                        }
                      >
                        Start Again
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                {grid === true ? (
                  item?.startCompleted === "In Progress" ||
                  item?.startCompleted === "Scheduled" ||
                  item?.startCompleted === "" ? (
                    <div className="d-flex mt-auto">
                      <Button
                        className="st_btn w-100"
                        onClick={() => startCourse(pageSize ? item : item.id)}
                      >
                        Start Course
                      </Button>
                      {LogUserDetails?.roleId === 2 && tabValue === "4"
                        ? ""
                        : DashBtnList?.map((check, i) => {
                            if (
                              check?.menuName ===
                                "Assign Course Button On Dashboard" &&
                              check?.isAssigned === true
                            ) {
                              return (
                                <div
                                  className="grid_top_button_group w-100"
                                  key={i}
                                >
                                  <CeuCoursePopup
                                    btnName="Assign Course"
                                    courseId={item.id}
                                  />
                                </div>
                              );
                            }
                            return "";
                          })}
                    </div>
                  ) : item?.startCompleted === "Completed" ? (
                    <div className="d-flex mt-auto">
                      <Button
                        className="st_btn mt-auto w-100 ora"
                        onClick={() =>
                          completeCourse(pageSize ? item : item.id)
                        }
                      >
                        Start Again
                      </Button>
                      {LogUserDetails?.roleId !== 2 && tabValue !== "4"
                        ? ""
                        : DashBtnList?.map((check, i) => {
                            if (
                              check?.menuName ===
                                "Assign Course Button On Dashboard" &&
                              check?.isAssigned === true
                            ) {
                              return (
                                <div
                                  className="grid_top_button_group w-100"
                                  key={i}
                                >
                                  <CeuCoursePopup
                                    btnName="Assign Course"
                                    courseId={item.id}
                                  />
                                </div>
                              );
                            }
                            return "";
                          })}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      {formattedSearchResults?.length > 0 ? (
        <div className="d-flex justify-content-end">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={formattedSearchResults?.length}
            pageSize={Pagesize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <div className="h6_text text-center">No Data Found</div>
      )}
    </>
  );
}
