import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import "./Table_Com.scss";
import active_img from "../../../images/Icon/active_green.svg";
import Config from "../../../Utils/Config";
import pro_img from "../../../images/default_profile.jpg";


function Table_Com(props) {
  let navigate = useNavigate();
  const togglePopup = (id) => {
    navigate(`/certificate/report/${id}`, { replace: true });
  };
  return (
    <>
      <Table className="">
        <thead>
          <tr>
            <th>{props?.head1}</th>
            <th>{props?.head2}</th>
          </tr>
        </thead>
        <tbody>
          {props?.cource &&
            props?.cource?.map((data, i) => (
              <tr key={i + 1}>
                <td style={{ width: "50%" }}>
                  <div className="d-flex align-items-center gap-2">
                    {props?.head1 !== "Course Name" ? <div className="img_boxs">
                      <div className="user_img">
                        <img
                          src={`${
                            data?.profileUrl
                              ? Config?.API_HOST_URL_live + data?.profileUrl
                              : pro_img
                          }`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <img
                        src={data?.userStatus === "Online" ? active_img : ""}
                        className="img-fluid dot_ac_in"
                      />
                    </div> : "" }
                    
                    {data?.name}
                  </div>
                </td>
                {data?.no === "View Report" ? (
                  <td
                    className="view_btn"
                    onClick={() => togglePopup(data?.userId)}
                  >
                    {data?.no}
                  </td>
                ) : (
                  <td className="text-center">{data?.no}</td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
export default React.memo(Table_Com);
