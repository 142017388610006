import React, { Suspense } from "react";
import "./index.scss";
import { Button } from "@mui/material";

const renderLoader = () => <p></p>;

function Privacy() {
    return (
        <>
            <Suspense fallback={renderLoader()}>
                <div className="privacy_preference main_backcolor">
                    <div className="container-fluid">
                        <div className="main_wrapper">
                            <div className="privacy_title">Privacy</div>
                            <div className="privacy_wrapper">
                                <div className="title">
                                    <h2 className="mt-3">Profile Visibility Settings</h2>
                                    <p>Select who may see your profile details.</p>
                                </div>
                                <hr />
                                <div className="privacy_form">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>User Name</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Site / Location</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>School District / Company</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Professional Development – Number of Annual required CEUs</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Job Title</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Birth Date</label>
                                                <select className="form-select">
                                                    <option value="0">Only Me</option>
                                                    <option value="1">Public</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 d-flex border-0 px-3 justify-content-end">
                                            <Button className="theme_button me-3">Save Changes</Button>
                                            <Button className="light_btn">Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </>
    );
}

export default React.memo(Privacy);
