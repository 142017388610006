import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton } from "@mui/material";
import userimg from "../../../../../images/user.png";
import message from "../../../../../images/message.png";
// import pro_img from "../../../../../images/default_profile.jpg";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const options = ["Block & Report"];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Connection_list(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [conList, setConList] = useState([]);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open1, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };

  useEffect(() => {
    getListCon();
    // eslint-disable-next-line
  }, []);
  const getListCon = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyConnectionsList?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const Mylist = response.data.payload?.map((item) => {
            return {
              userId: item?.userId,
              sentRequestUserId: item?.sentRequestUserId,
              name: item?.firstName + " " + item?.lastName,
              img:
                item?.profileUrl === null || item?.profileUrl === ""
                  ? userimg
                  : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
              activeHours:
                item?.activeHours !== null || item?.activeHours !== ""
                  ? item?.activeHours
                  : "0",
              followers: item?.followers,
              join:
                item?.userJoinDate === "" || item.userJoinDate === null
                  ? ""
                  : convert(item?.userJoinDate?.split("T")[0]),
            };
          });
          setConList(Mylist);
        }
      });
  };
  let nav = useNavigate();

  const handleMessage = (id) => {
    let stChat = {
      userIdOne: LogUserId,
      userIdSecond: id,
      createdBy: LogUserId,
      isDeleted: false,
    };
    axios
      .post(`${Config.API_HOST_URL_live}/api/Chat/CreateChat`, stChat, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          nav(`/messages/${response.data.payload?.chatId}`, { replace: true });
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      });
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day].join(" ");
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i]?.charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
  }
  const FilterList = conList?.filter((user) => {
    if (props?.searchField) {
      return user?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(props?.searchField.toLowerCase());
    } else return user;
  });
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      {props.grid === true ? (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-md-3 mb-3" key={i}>
              <div className="grid_connection_card text-center">
                <div className="menu_icon">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>

                <div
                  className="connection_card_text"
                  onClick={() => handleViewProfile(item.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item?.img ? item?.img : userimg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg;
                    }}
                    className="img-fluid mb-3 user_img_p"
                    alt="user"
                  />
                  <h6>{item?.name}</h6>
                  <p>Hire Date {item?.join}</p>
                  <p>
                    Active {item?.activeHours ? item?.activeHours : "-"} hours
                    ago
                  </p>
                  <p>{item?.followers} followers</p>
                </div>
                <div className="connection_card_btn pb-3">
                  {/* <Button fullWidth onClick={() => reqReject(item?.userId)}>
                    Remove
                  </Button> */}
                </div>
                <img
                  src={message}
                  className="img-fluid my-3 mx-auto msg"
                  onClick={(e) => handleMessage(item.userId)}
                  alt="message"
                />
              </div>
            </div>
          ))}
          <Menu
            className="blockreport_box"
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={handleClickOpen}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          <BootstrapDialog
            onClose={handleClose1}
            aria-labelledby="customized-dialog-title"
            open={open1}
            className="block_popup"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose1}
              dividers
            >
              Block Member?
            </BootstrapDialogTitle>
            <DialogContent>
              <Typography>
                Please confirm you want to block this member.
              </Typography>
              <Typography>You will no longer be able to:</Typography>
              <Typography>
                <ul>
                  <li>
                    {" "}
                    See blocked member's posts
                    <li> Mention this member in posts</li>
                  </li>
                  <li> Invite this member to groups</li>
                  <li> Message this member</li>
                  <li> Add this member as a connection</li>
                </ul>
              </Typography>
              <Typography>
                <b>Please note:</b> This action will also remove this member
                from your connections and send a report to the site admin.
                Please allow a few minutes for this process to complete.
              </Typography>
            </DialogContent>
            <DialogActions className="popup_btn">
              <Button className="theme_button">Confirm</Button>
              <Button autoFocus onClick={handleClose1}>
                Cancel
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </>
      ) : (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_connection_card">
                <div
                  className="connection_card_text d-flex"
                  onClick={() => handleViewProfile(item.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item?.img ? item?.img : userimg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg;
                    }}
                    className="img-fluid user_img_p"
                    alt="user"
                  />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Hire Date {item?.join}</p>
                  </div>
                  <p>
                    Active {item?.activeHours ? item?.activeHours : "-"} hours
                    ago
                  </p>
                  <p>{item?.followers} followers</p>
                </div>
                <div className="connection_card_btn">
                  <img
                    src={message}
                    className="img-fluid msg"
                    onClick={(e) => handleMessage(item?.userId)}
                    alt="message"
                  />
                </div>
                <div className="menu_icon">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
          <Menu
            className="blockreport_box"
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={handleClickOpen}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          <BootstrapDialog
            onClose={handleClose1}
            aria-labelledby="customized-dialog-title"
            open={open1}
            className="block_popup"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose1}
              dividers
            >
              Block Member?
            </BootstrapDialogTitle>
            <DialogContent>
              <Typography>
                Please confirm you want to block this member.
              </Typography>
              <Typography>You will no longer be able to:</Typography>
              <Typography>
                <ul>
                  <li>
                    {" "}
                    See blocked member's posts
                    <li> Mention this member in posts</li>
                  </li>
                  <li> Invite this member to groups</li>
                  <li> Message this member</li>
                  <li> Add this member as a connection</li>
                </ul>
              </Typography>
              <Typography>
                <b>Please note:</b> This action will also remove this member
                from your connections and send a report to the site admin.
                Please allow a few minutes for this process to complete.
              </Typography>
            </DialogContent>
            <DialogActions className="popup_btn">
              <Button className="theme_button">Confirm</Button>
              <Button autoFocus onClick={handleClose1}>
                Cancel
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </>
      )}
    </>
  );
}

export default React.memo(Connection_list);
