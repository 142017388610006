import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userimg13 from "../../../../images/user13.png";
import userimg from "../../../../images/user.png";
import "./MemberList.scss";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function InviteList({ comGroupId }) {
  const [usList, setUsList] = useState();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  useEffect(() => {
    if (comGroupId) {
      getUserList();
    }
    // eslint-disable-next-line
  }, [comGroupId]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getUserList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/GetCommunityGroupMembers?communityGroupId=${comGroupId}`,
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          const user = responce.data.payload?.map((item) => {
            if (item?.status === "Accepted") {
              return undefined;
            }
            return {
              userId: item?.userId,
              name: item?.name,
              siteLocation: item?.siteLocation,
              schoolDistrict: item?.schoolDistrict,
              jobTitle: item?.jobTitle,
              img:
                item?.profileUrl === "" || item?.profileUrl === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
              status: item?.status,
            };
          });
          let nuser = user.filter((u) => u !== undefined);
          setUsList(nuser);
        }
      });
  };
  const handleReqest = (id, i) => {
    let inData = {
      communityGroupId: comGroupId,
      userId: id,
      communityRoleId: 3,
      // status: "Accepted",
      status: "invited",
      createdBy: LogUserId,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/CommunityGroupUser/CreateCommunityGroupUser`,
        inData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
        }
      });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const [searchval, setSearchval] = useState("");
  const searchParam = ["name", "jobTitle", "siteLocation", "schoolDistrict"];

  const filteredData = usList?.filter((user) => {
    if (searchval) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchval.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      {usList?.length > 0 ? (
        <div className="Member_List_secrion mb-3">
          <div className="card_title d-flex">
            <h6>Send Invites</h6>
          </div>
          <div className="searc_box">
            <label>
              Search by Name, Job Title, Site/Location, SchoolDistrict
            </label>
            <input
              type="search"
              className="form-control"
              placeholder=""
              onChange={(e) => setSearchval(e.target.value)}
            />
          </div>
          <div className="card_content member_box">
            {/* <div className="tags d-flex mb-3">
          <label
            className={`${usCon === "1" ? "active" : ""}`}
            onClick={() => setUsCon("1")}
          >
            Newest
          </label>
          <label
            className={`${usCon === "2" ? "active" : ""}`}
            onClick={() => setUsCon("2")}
          >
            Active
          </label>
          <label
            className={`${usCon === "3" ? "active" : ""}`}
            onClick={() => setUsCon("3")}
          >
            Popular
          </label>
        </div> */}
            <div className="user_list">
              {filteredData &&
                filteredData?.map((item, i) => (
                  <div className="d-flex user_line mb-3" key={i}>
                    <div
                      className="d-flex me-auto u_box"
                      onClick={() => handleViewProfile(item?.userId)}
                    >
                      <img
                        src={item?.img === "" ? userimg : item?.img}
                        className="img-fluid user_p_img"
                        alt="user"
                      />
                      <div className="me-auto text-start  ms-3">
                        <label className=" name invite">{item?.name}</label>
                        <div className="detail_box ">
                          <div className="p_12_text">{item?.jobTitle}</div>
                        </div>
                      </div>
                    </div>
                    {item.status === "invited" || item.status === "Invited" ? (
                      <Button className="invited">Invited</Button>
                    ) : (
                      <Button onClick={() => handleReqest(item?.userId, i)}>
                        Invite
                      </Button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(InviteList);
