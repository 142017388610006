import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import star_blank from "../../../../images/category_star_blank.png";
import star_full from "../../../../images/category_star_full.png";
// import courceRateingPop from "../../../../images/Rate&Review1.png";
import { DashboardRe } from "../../../../reducers/user";
// import Config from "../../../../Utils/Config";
import { Button } from "@mui/material";
import { PostCallApi } from "../../../../Action/Action";

function CreateReview({ handleClose, ReId, getDataRecipeById }) {
  const { id } = useParams();
  const [ratingVAl, setRatingVAl] = useState(5);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  let dispatch = useDispatch();
  let changeDash = useSelector((state) => state.user.Drefresh);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    const rateData = {
      ...data,
      recipeId: ReId,
      rating: ratingVAl,
      createdBy: LogUserId,
      ratingCommnets: data?.ratingComments,
      // createdOn: new Date()
    };

    const allD = {
      url: `/api/ReceipeRating/CreaterReceipeRating`,
      body: rateData,
      headers: headers,
    };

    let resp = await PostCallApi(allD);
    if (resp?.status === 200) {
      if (resp.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(resp.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        getDataRecipeById(id);
        handleClose();
        if (changeDash) {
          dispatch(DashboardRe(false));
        } else {
          dispatch(DashboardRe(true));
        }
      }
    }
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <div className="write_pop text-center">
      {/* <div className="img_box_rate text-center">
                <img src={courceRateingPop} alt="" className="img-fluid" />
            </div>
            <h4>How would you rate this course?</h4> */}
      <h6>What's your experience? We'd love to know!</h6>
      <Rating
        emptySymbol={<img src={star_blank} alt="" />}
        fullSymbol={<img src={star_full} alt="" />}
        initialRating={ratingVAl}
        className="rate_star_mb"
        onClick={(e) => setRatingVAl(e)}
      />
      <textarea
        name="feed"
        id="feed_txt"
        rows="5"
        className="form-control"
        placeholder="Review Description *"
        {...register("ratingComments", {
          // required: "This field is required",
          required: false,
        })}
      ></textarea>
      {errors.ratingComments && (
        <span role="alert" className="error_text">
          {errors.ratingComments.message}
        </span>
      )}
      <div className="d-flex gap-3 align-items-center justify-content-center mt-3">
        <Button className="theme_btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="theme_btn" onClick={handleSubmit(onSubmit)}>
          Save & Continue
        </Button>
      </div>
    </div>
  );
}

export default React.memo(CreateReview);
