import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
import CourceReviews from "./CourceReviews/Index";
import { useParams } from "react-router-dom";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";

const CardCategoryBox = lazy(() => import("./CardCategoryBox"));
const CourseAuther = lazy(() => import("./CourseAuther"));
// const CourseRating = lazy(() => import("./CourseRating"));
const renderLoader = () => <p></p>;

function CourseCardDetail() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [authorProfile, setAuthorProfile] = useState({});
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const [courceCategory, setCourceCategory] = useState([]);
  const [courseDetails, setCourseDetails] = useState();
  const [courseContent, setCourseContent] = useState([]);

  useEffect(() => {
    CeuCourseDetails();
    // eslint-disable-next-line
  }, []);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const CeuCourseDetails = async () => {
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data?.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {

        let ceuCourseD = response.data?.payload;
        let contentList = ceuCourseD?.content?.map((item) => {
          return {
            contentId: item?.contentId,
            title: item?.lessionTitle,
            sequenceNo: item?.sequenceNo,
            videoLength: item?.videoLength,
            description: item?.lessionDescription,
            contentMaterials: item?.contentMaterials,
          };
        });
        let keyArea = ceuCourseD?.keyArea?.split(",")
          ? ceuCourseD?.keyArea?.split(",")
          : ceuCourseD?.keyArea?.split("");
        let ceuDes = {
          id: ceuCourseD?.courseId,
          title: ceuCourseD?.courseTitle,
          coach:
            "Coach" +
            " " +
            ceuCourseD?.instructor?.firstName +
            " " +
            ceuCourseD?.instructor?.lastName,
          keyArea: keyArea,
          // keyArea: ceuCourseD?.keyArea?.split(","),
          ceuReq: ceuCourseD?.totalCEU + " CEU",
          description: ceuCourseD?.description,
          imageOrVideoUrl:
            ceuCourseD?.imageOrVideoUrl === "" ||
              ceuCourseD?.imageOrVideoUrl === null
              ? cardimg1
              : `${Config.API_HOST_URL_live}${ceuCourseD.imageOrVideoUrl}`,
          // imageOrVideoUrl:
          //   ceuCourseD?.thumbnailCourseUrl === "" ||
          //     ceuCourseD?.thumbnailCourseUrl === null
          //     ? cardimg1
          //     : `${Config.API_HOST_URL_live}${ceuCourseD?.thumbnailCourseUrl}`,
          shoStartButton: ceuCourseD?.shoStartButton,

          totalLession: ceuCourseD?.totalLession,
          totalQuiz: ceuCourseD?.totalQuiz,
          totalUsers: ceuCourseD?.totalUsers,
          rating: ceuCourseD?.courseRating ? ceuCourseD.courseRating : "0",
          courseProgress: ceuCourseD?.courseProgress
            ? ceuCourseD?.courseProgress
            : "0",
          completed: ceuCourseD?.courseStatus
            ? ceuCourseD?.courseStatus
            : "InProgress",
          courseMaterials: ceuCourseD?.courseMaterials
            ? ceuCourseD?.courseMaterials
            : [],
        };

        let cCat = ceuCourseD?.categories?.split(", ");
        cCat.sort((a, b) => {
          const newa = parseInt(a?.split(" ")[0]);
          const newb = parseInt(b?.split(" ")[0]);
          if (newa < newb) {
            return -1;
          }
          if (newa > newb) {
            return 1;
          }
          return 0;
        });
        setCourceCategory(cCat);
        setCourseDetails(ceuDes);
        setCourseContent(contentList);
        setAuthorProfile(ceuCourseD.instructor);

      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }

    //   } else {
    //     setOpen(false);
    //   }
    // });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="ceu_couce_title">{courseDetails?.title}</div>
              <div className="Couce_Card_Page">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                    <CourseAuther
                      item={authorProfile}
                      // date="July 2, 2022"
                      shoStartButton={courseDetails?.shoStartButton}
                      courseId={id}
                      courseStart={courseDetails?.completed}
                      // courseStart="Complete"
                      courseProgress={courseDetails?.courseProgress}
                      enroll={courseDetails?.totalUsers}
                      description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
                      lesson={courseDetails?.totalLession}
                      quiz={courseDetails?.totalQuiz}
                      certi="Course Certificate"
                      value="0"
                      autherActivity="July 2, 2022"
                    />
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-8 col-xs-12">
                    <CardCategoryBox
                      courceCategory={courceCategory}
                      rating={courseDetails?.rating}
                      title="Categories"
                      courseStart={courseDetails?.completed}
                    />
                    <CardCategoryBox
                      courseDetails={courseDetails}
                      title="Course Details"
                      courseStart={courseDetails?.completed}
                      shoStartButton={courseDetails?.shoStartButton}
                      courseId={id}
                    />
                    <CardCategoryBox
                      courseContent={courseContent}
                      title="Course Content"
                    />
                    <CourceReviews title={courseDetails?.title} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(CourseCardDetail);
