import React, { useEffect } from "react";
import "./index.scss";
import Edit_icon_new from "../../../../images/Icon/Edit_icon_new.svg";
import delete_icon from "../../../../images/Delete.svg";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  TableBody,
  Paper,
  TableContainer,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { DashboardRe } from "../../../../reducers/user";
import Swal from "sweetalert2";
import Popup from "../../../Reuseable_Components/Popup/Popup";
import AddCertificatePopUp from "../../../Reuseable_Components/Popup/Addcertificate";
import search_icon from "../../../../images/Icon/table_search_icon.svg";
import Pagination from "../../../Reuseable_Components/Paginations/Pagination";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import jsPDF from "jspdf";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import pro_img from "../../../../images/default_profile.jpg";
import active_img from "../../../../images/Icon/active_green.svg";
import { useSignalR } from "../../../../Utils/SignalRContext";
import { SwalError, SwalSuccess } from "../../../Reuseable_Components/SwalToast";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",

    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function AllCertificateDash({ certificationExpiration, TableNEW, title }) {
  let loc = useLocation();
  const [open, setOpen] = useState(false);
  const [pathNames, setPathNames] = useState("");
  const [cerEx, setCerEx] = useState([]);
  const [cerEdit, setCerEdit] = useState("");
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [schList, setSchList] = useState([]);
  const { connection } = useSignalR();

  const [schId, setSchId] = useState({
    value: "00000000-0000-0000-0000-000000000000",
    label: "All School District",
  });
  let dispatch = useDispatch();
  let changeDash = useSelector((state) => state.user?.Drefresh);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const columns = [
    { field: "id", headerName: "No" },
    { field: "name", headerName: "Name" },
    { field: "employeeNumber", headerName: "Employee Number" },
    { field: "siteLocation", headerName: "Site/Location" },
    { field: "certificateName", headerName: "Certificate Name" },
    { field: "DateOfComPletion", headerName: "Date Of Completion" },
    { field: "DateOfExpiration", headerName: "Date Of Expiration" },
    // { field: "expired", headerName: "Status" },
    { field: "ReminderofExpiration", headerName: "Reminder of Expiration" },
  ];

  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setCerEx((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);

  useEffect(() => {
    getSchoolList();
    // eslint-disable-next-line
  }, []);
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserde?.userId}`;
    axios
      .get(url, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value: item?.schoolDistrictId,
              label: item?.districtName,
            };
          });
          let schData = [
            {
              value: "00000000-0000-0000-0000-000000000000",
              label: "All School District",
            },
            ...data,
          ];
          setSchList(data?.length > 1 ? schData : data);
          // setSchList(schData);
        }
      })
      .catch((error) => console.log(error));
  };
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  function descendingComparator(a, b, orderBy) {
    if (orderBy === "id") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else if (
      orderBy === "DateOfComPletion" ||
      orderBy === "DateOfExpiration" ||
      orderBy === "ReminderofExpiration"
    ) {
      let bd = convertDate(b[orderBy].split("12:")[0]);
      let ad = convertDate(a[orderBy].split("12:")[0]);
      if (new Date(bd) < new Date(ad)) {
        return -1;
      }
      if (new Date(bd) > new Date(ad)) {
        return 1;
      }
    } else {
      if (
        b[orderBy]?.toString()?.toLowerCase() <
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy]?.toString()?.toLowerCase() >
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Certification Expiration",
    sheet: "Users",
  });
  useEffect(() => {
    setPathNames(loc.pathname);
    if (loc?.pathname === "/dashboard/all-certificate" || title) {
      if (schList?.length > 0) {
        if (schList.length > 1) {
          getDashData("00000000-0000-0000-0000-000000000000");
        } else {
          getDashData(schList[0]?.value);
          setSchId(schList[0]);
        }
      }
    }
    // eslint-disable-next-line
  }, [loc, changeDash, schList]);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [downloadExcel, setDownloadExcell] = useState(false);

  useEffect(() => {
    if (downloadExcel) {
      onDownload();
      setDownloadExcell(false);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);
  useEffect(() => {
    if (downloadPDF) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPDF]);

  const [openCXDelete, setOpenCXDelete] = React.useState(false);
  const [userAllDe, setUserAllDe] = useState({});

  const handleCertiUserDelete = (data, name) => {
    setUserAllDe(data);
    setOpenCXDelete(true);
    setCerEdit(name);
  };
  const handleCloseCXDelete = () => {
    setOpenCXDelete(false);
  };
  const getDashData = async (id) => {
    if (id) {
      setOpen(true);
      // axios
      //   .get(
      //     `${Config.API_HOST_URL_live}/api/Dashboard/GetCertificateFromOtherSourcesListByUserId?userId=${LogUserId}&schoolDistrictId=${id}`,
      //     { headers }
      //   )
      //   .then((response) => {
      //     if (response.data.success) {
      //       setOpen(false);

      const sendData = {
        url: `/api/Dashboard/GetCertificateFromOtherSourcesListByUserId?userId=${LogUserId}&schoolDistrictId=${id}`,
        headers: headers
      }
      let response = await GetCallApi(sendData)
      setOpen(false);

      if (response?.status === 200) {
        if (response.data.success) {
          let today = new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
          const dashData = response?.data;
          let cerExpiry = dashData?.payload?.map((item, i) => {
            let td = convertDate(today);
            let ex = convertDate(item.dateOfExpiration?.split("12:")[0]);
            let st = new Date(td) < new Date(ex) ? "a" : "e";
            return {
              userId: item?.userId,
              id: i + 1,
              courseName:
                item?.courseName === "" || item?.courseName === null
                  ? ""
                  : item?.courseName,
              certificateName:
                item?.certificateName === "" || item?.certificateName === null
                  ? ""
                  : item?.certificateName,
              certificateAgencyName:
                item?.certificateAgencyName === "" ||
                  item?.certificateAgencyName === null
                  ? ""
                  : item?.certificateAgencyName,
              certificateFromOtherSourcesId:
                item?.certificateFromOtherSourcesId === "" ||
                  item?.certificateFromOtherSourcesId === null
                  ? ""
                  : item?.certificateFromOtherSourcesId,
              name:
                item?.name === "" || item?.name === null ? "" : item?.name,
              email:
                item?.userEmail === "" || item?.userEmail === null
                  ? ""
                  : item?.userEmail,
              DateOfComPletion:
                item?.dateOfCompletion === "" ||
                  item?.dateOfCompletion === null
                  ? ""
                  : item?.dateOfCompletion,
              DateOfExpiration:
                item?.dateOfExpiration === "" ||
                  item?.dateOfExpiration === null
                  ? ""
                  : item?.dateOfExpiration,
              expired:
                item?.status === "" || item?.status === null
                  ? ""
                  : item?.status,
              ReminderofExpiration:
                item?.reminderOfExpiry === "" ||
                  item?.reminderOfExpiry === null
                  ? ""
                  : item?.reminderOfExpiry,
              certificateMaterialUrl: item?.certificateMaterialUrl,
              schoolDistrictId: item?.schoolDistrictId,
              statusEx: st,
              siteLocation: item?.siteLocation,
              employeeNumber: item?.employeeNumber,
              userStatus: item?.userStatus,
              profileUrl: item?.profileUrl
            };
          });

          setCerEx(cerExpiry);
          //   } else {
          //     setOpen(false);
          //     setCerEx();
          //   }
          // });

        } else {
          setOpen(false);
          setCerEx();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response?.data?.message
              ? response?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setCerEx();
    }
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const DeleteCertificate = async () => {

    let url = `/api/CourseUserCertificatesAllocation/DeleteCourseUserCertificatesOtherSources?certificateFromOtherSourcesId=${userAllDe.certificateFromOtherSourcesId}&deletedBy=${LogUserId}`


    const responce = await PostCallApi({ url: url, body: {} })

    // setOpenCircle(false);
    setOpen(false);
    if (responce?.status === 200 && responce?.data.success) {
      setUserAllDe({});
      handleCloseCXDelete();
      if (changeDash) {
        dispatch(DashboardRe(false));
      } else {
        dispatch(DashboardRe(true));
      }
      SwalSuccess(responce.data?.message)
    } else {
      SwalError(responce?.data?.message)
    }

  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = cerEx?.filter((user) => {
    if (searchField) {
      let state = false;
      columns?.forEach((property) => {
        let value = String(user[property?.field]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns?.map((headCell) => (
            <StyledTableCell
              key={headCell?.field}
              align="left"
              className={`${downloadPDF && "pdf_download"}`}
              sortDirection={orderBy === headCell?.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.field}
                direction={orderBy === headCell?.field ? order : "asc"}
                onClick={createSortHandler(headCell?.field)}
              >
                {headCell?.headerName}
                {downloadExcel || downloadPDF ? (
                  ""
                ) : (
                  <>
                    {orderBy === headCell?.field ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {downloadExcel || downloadPDF ? (
            ""
          ) : (
            <>
              {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                <StyledTableCell> Edit, Delete & View</StyledTableCell>
              ) : LogUserde?.roleId === 4 ? (
                <StyledTableCell> View</StyledTableCell>
              ) : (
                ""
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  let Time = new Date();

  const handleDownloadPdf = async () => {
    setOpen(true);
    const element = tableRef.current;

    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();

    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;

        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`Certificate Expiration.pdf`);
        setDownloadPDF(false);
        setOpen(false);
      });
  };

  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  const back = {
    maxWidth: `${downloadPDF ? "200mm" : "100%"}`,
    width: "100% ",
    height: "100% ",
    position: "relative",
    margin: "0",
    maxHeight: "none ",
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {pathNames === "/dashboard" ? (
        <div className="show_back table-responsive user_report">
          <TableNEW className="data_table mb-2">
            <thead>
              <tr className="table_pading">
                <th>No</th>
                <th>Name</th>
                <th>Site/Location</th>
                <th>Certificate Name</th>
                <th>Date Of Completion</th>
                <th>Date Of Expiration</th>
                {/* <th>Status</th> */}
                <th>Reminder of Expiration</th>
                {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                  <th>Edit, Delete & View</th>
                ) : LogUserde?.roleId === 4 ? (
                  <th>View</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {certificationExpiration &&
                certificationExpiration?.map((data, i) => (
                  <tr key={i + 1} className="table_pading">
                    <td>{i + 1}</td>
                    <td>
                      {/* <div className="d-flex align-items-center gap-2">
                        <div className="img_boxs">
                          <div className="user_img">
                            <img
                              src={`${
                                data?.profileUrl
                                  ? Config?.API_HOST_URL_live + data?.profileUrl
                                  : pro_img
                              }`}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <img
                            src={
                              data?.userStatus === "Online" ? active_img : ""
                            }
                            className="img-fluid dot_ac_in"
                          />
                        </div>
                      </div> */}
                      {data?.name}
                    </td>
                    <td>{data?.siteLocation}</td>
                    <td>{data?.certificateName}</td>
                    <td>{data?.DateOfComPletion}</td>
                    <td className={`${data.statusEx}`}>
                      {data?.DateOfExpiration}
                    </td>
                    <td>{data?.ReminderofExpiration}</td>
                    {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                      <td className="delete_cource">
                        <div className="d-flex">
                          <div
                            style={{ marginRight: 16, cursor: "pointer" }}
                            onClick={(e) => handleCertiUserDelete(data, "edit")}
                          >
                            <img src={Edit_icon_new} alt="" />
                          </div>
                          <div
                            className="delete"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleCertiUserDelete(data, "delete")
                            }
                          >
                            <img src={delete_icon} alt="" />
                          </div>
                          {data?.certificateMaterialUrl === "" ||
                            data?.certificateMaterialUrl === null ? (
                            ""
                          ) : (
                            <div
                              style={{
                                marginLeft: 16,
                                cursor: "pointer",
                              }}
                            >
                              <a
                                href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                                className="view_button me-2 p_14_text"
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                    ) : LogUserde?.roleId === 4 ? (
                      <td className="delete_cource">
                        {data?.certificateMaterialUrl === "" ||
                          data?.certificateMaterialUrl === null ? (
                          ""
                        ) : (
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <a
                              href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                              className="view_button me-2 p_14_text"
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </div>
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
            </tbody>
          </TableNEW>
        </div>
      ) : title ? (
        <div className="cr seprate">
          <label className="me-2">Certification Expiration</label>
          <div className="mb-4 listdata_control_sec ">
            <div className="sep_report_head  d-flex justify-content-between">
              <div className=" search_sec w-25 me-2">
                <div className="search_input w-100">
                  <input
                    onChange={(e) => {
                      setSearchField(e.target.value);
                      setCurrentPage(1);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img src={search_icon} alt="" />
                </div>
              </div>
              <div className="d-flex">
                <Autocomplete
                  disablePortal
                  className="form-select1 me-2 my-auto "
                  options={schList}
                  sx={{ width: 300 }}
                  value={schId?.label}
                  onChange={(e, newValue) => {
                    if (newValue === null) {
                      setSchId(schList[0]);
                      getDashData(schList[0]?.value);
                    } else {
                      setSchId(newValue);
                      getDashData(newValue?.value);
                    }
                  }}
                  renderInput={(params, i) => (
                    <TextField className="texfiled-demo" key={i} {...params} />
                  )}
                />
                {title ? (
                  <>
                    <Button
                      className="delete_btn btn_space me-3"
                      onClick={() => {
                        setDownloadPDF(true);
                      }}
                    >
                      PDF
                    </Button>
                    <Button
                      className="delete_btn btn_space me-2"
                      onClick={() => setDownloadExcell(true)}
                    >
                      Download
                    </Button>
                    {DashBtnList?.map((check, i) => {
                      if (
                        check?.menuName === "Add Certificate Dashboard" &&
                        check?.isAssigned === true
                      ) {
                        return <Popup key={i} btnName="Add Certificates" />;
                      }
                      return "";
                    })}
                  </>
                ) : (
                  <div className="grid_top_button_group">
                    <Button
                      className="delete_btn btn_space"
                      onClick={() => {
                        setDownloadPDF(true);
                      }}
                    >
                      PDF
                    </Button>
                    <Button
                      className="delete_btn btn_space"
                      onClick={() => setDownloadExcell(true)}
                    >
                      Download
                    </Button>
                    {DashBtnList?.map((check, i) => {
                      if (
                        check?.menuName === "Add Certificate Dashboard" &&
                        check?.isAssigned === true
                      ) {
                        return <Popup key={i} btnName="Add Certificates" />;
                      }
                      return "";
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3 school_district">
            <Paper className="table-box">
              <TableContainer
                className=""
                ref={tableRef}
                id="divToPrint"
                sx={back}
              >
                {downloadPDF && (
                  <div
                    className="logo_img_pdf d-flex justify-content-between align-items-center mb-5"
                    style={{ padding: "0px 20px" }}
                  >
                    <img
                      src={require("../../../../images/Icon/20211.png")}
                      alt=""
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        Certification Expiration
                      </label>
                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        {schId?.label}
                      </div>
                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        {" "}
                        {convertDate(
                          Time.toLocaleString("en-us", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                        )}{" "}
                        {Time.toLocaleTimeString()}
                      </div>
                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                        }}
                      >
                        Report Generated By:{" "}
                        {LogUserde?.firstName + " " + LogUserde?.lastName}
                      </div>
                    </div>
                  </div>
                )}

                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {stableSort(
                      formattedSearchResults,
                      getComparator(order, orderBy)
                    )
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((data, index) => (
                        <StyledTableRow
                          key={index + 1}
                          className="table_pading"
                        >
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.id}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {/* <div className="d-flex align-items-center gap-2">
                              <div className="img_boxs">
                                <div className="user_img">
                                  <img
                                    src={`${
                                      data?.profileUrl
                                        ? Config?.API_HOST_URL_live +
                                          data?.profileUrl
                                        : pro_img
                                    }`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <img
                                  src={
                                    data?.userStatus === "Online"
                                      ? active_img
                                      : ""
                                  }
                                  className="img-fluid dot_ac_in"
                                />
                              </div>
                            </div> */}
                            {data?.name}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.employeeNumber}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.siteLocation}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.certificateName}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.DateOfComPletion}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"} ${data.statusEx
                              }`}
                          >
                            {data?.DateOfExpiration}
                          </StyledTableCell>

                          <StyledTableCell
                            className={`${downloadPDF && "pdf_download"}`}
                          >
                            {data?.ReminderofExpiration}
                          </StyledTableCell>
                          {downloadExcel || downloadPDF ? (
                            ""
                          ) : (
                            <>
                              {LogUserde?.roleId === 1 ||
                                LogUserde?.roleId === 2 ? (
                                <StyledTableCell
                                  className={`delete_cource ${downloadPDF && "pdf_download"
                                    }`}
                                >
                                  <div className="d-flex">
                                    <div
                                      style={{
                                        marginRight: 16,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleCertiUserDelete(data, "edit")
                                      }
                                    >
                                      <img src={Edit_icon_new} alt="" />
                                    </div>

                                    <div
                                      className="delete"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleCertiUserDelete(data, "delete")
                                      }
                                    >
                                      <img src={delete_icon} alt="" />
                                    </div>
                                    {data?.certificateMaterialUrl === "" ||
                                      data?.certificateMaterialUrl === null ? (
                                      ""
                                    ) : (
                                      <a
                                        href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                                        className="theme_btn"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                          textDecoration: "none",
                                        }}
                                      >
                                        View
                                      </a>
                                    )}
                                  </div>
                                </StyledTableCell>
                              ) : LogUserde?.roleId === 4 ? (
                                <StyledTableCell className="delete_cource">
                                  {data?.certificateMaterialUrl === "" ||
                                    data?.certificateMaterialUrl === null ? (
                                    ""
                                  ) : (
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <a
                                        href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                                        className="view_button me-2 p_14_text"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View
                                      </a>
                                    </div>
                                  )}
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {formattedSearchResults?.length === 0 ? (
                <div className="text-center my-3 p_20_text">Data Not Found</div>
              ) : (
                ""
              )}
              <div className="page_nation">
                <div className="rowsPerPageOptions">
                  <span className="p_18_text">show</span>
                  <select
                    className="pagination_select p_16_text"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                  <span className="p_18_text">entries</span>
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={formattedSearchResults?.length}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </Paper>
          </div>
        </div>
      ) : (
        <section
          className="dashboard_page main_backcolor cetificate_page"
          id="dashboard_section"
        >
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="chart_data_sec mb-0">
                <div className="chart_box cource_box">
                  <div className="chart_title_box d-flex justify-content-between">
                    <label className="chart_title">
                      Certification Expiration
                    </label>
                    <div className=" search_sec w-25 me-4">
                      <div className="search_input w-100">
                        <input
                          onChange={(e) => {
                            setSearchField(e.target.value);
                            setCurrentPage(1);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                        <img src={search_icon} alt="" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <Autocomplete
                        disablePortal
                        className="form-select1 me-2 my-auto "
                        options={schList}
                        sx={{ width: 300 }}
                        value={schId?.label}
                        onChange={(e, newValue) => {
                          if (newValue === null) {
                            setSchId(schList[0]);
                            getDashData(schList[0]?.value);
                          } else {
                            setSchId(newValue);
                            getDashData(newValue?.value);
                          }
                        }}
                        renderInput={(params, i) => (
                          <TextField
                            className="texfiled-demo"
                            key={i}
                            {...params}
                          />
                        )}
                      />
                      <div className="grid_top_button_group">
                        <Button
                          className="delete_btn btn_space"
                          onClick={() => {
                            setDownloadPDF(true);
                          }}
                        >
                          PDF
                        </Button>
                        <Button
                          className="delete_btn btn_space"
                          onClick={() => setDownloadExcell(true)}
                        >
                          Download
                        </Button>
                        {DashBtnList?.map((check, i) => {
                          if (
                            check?.menuName === "Add Certificate Dashboard" &&
                            check?.isAssigned === true
                          ) {
                            return <Popup key={i} btnName="Add Certificates" />;
                          }
                          return "";
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="show_back table-responsive user_report_page"
                    ref={tableRef}
                    style={back}
                  >
                    {downloadPDF && (
                      <div
                        className="logo_img_pdf d-flex justify-content-between align-items-center mb-5"
                        style={{ padding: "0px 20px" }}
                      >
                        <img
                          src={require("../../../../images/Icon/20211.png")}
                          alt=""
                        />
                        <div>
                          <label
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            Certification Expiration
                          </label>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {schId?.label}
                          </div>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {" "}
                            {convertDate(
                              Time.toLocaleString("en-us", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                            )}{" "}
                            {Time.toLocaleTimeString()}
                          </div>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                            }}
                          >
                            Report Generated By:{" "}
                            {LogUserde?.firstName + " " + LogUserde?.lastName}
                          </div>
                        </div>
                      </div>
                    )}
                    <Table>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />

                      <TableBody>
                        {stableSort(
                          formattedSearchResults,
                          getComparator(order, orderBy)
                        )
                          ?.slice(
                            (currentPage - 1) * pageSize,
                            (currentPage - 1) * pageSize + pageSize
                          )
                          ?.map((data, index) => (
                            <StyledTableRow
                              key={index + 1}
                              className="table_pading"
                            >
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.id}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {/* <div className="d-flex align-items-center gap-2">
                                  <div className="img_boxs">
                                    <div className="user_img">
                                      <img
                                        src={`${
                                          data?.profileUrl
                                            ? Config?.API_HOST_URL_live +
                                              data?.profileUrl
                                            : pro_img
                                        }`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <img
                                      src={
                                        data?.userStatus === "Online"
                                          ? active_img
                                          : ""
                                      }
                                      className="img-fluid dot_ac_in"
                                    />
                                  </div>
                                </div> */}
                                {data?.name}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.employeeNumber}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.siteLocation}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.certificateName}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.DateOfComPletion}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"} ${data.statusEx
                                  }`}
                              >
                                {data?.DateOfExpiration}
                              </StyledTableCell>

                              <StyledTableCell
                                className={`${downloadPDF && "pdf_download"}`}
                              >
                                {data?.ReminderofExpiration}
                              </StyledTableCell>
                              {downloadExcel || downloadPDF ? (
                                ""
                              ) : (
                                <>
                                  {LogUserde?.roleId === 1 ||
                                    LogUserde?.roleId === 2 ? (
                                    <StyledTableCell
                                      className={`delete_cource ${downloadPDF && "pdf_download"
                                        }`}
                                    >
                                      <div className="d-flex">
                                        <div
                                          style={{
                                            marginRight: 16,
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                            handleCertiUserDelete(data, "edit")
                                          }
                                        >
                                          <img src={Edit_icon_new} alt="" />
                                        </div>

                                        <div
                                          className="delete"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            handleCertiUserDelete(
                                              data,
                                              "delete"
                                            )
                                          }
                                        >
                                          <img src={delete_icon} alt="" />
                                        </div>
                                        {data?.certificateMaterialUrl === "" ||
                                          data?.certificateMaterialUrl ===
                                          null ? (
                                          ""
                                        ) : (
                                          <div
                                            style={{
                                              marginLeft: 16,
                                              cursor: "pointer",
                                            }}
                                          >
                                            <a
                                              href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                                              className="view_button me-2 p_14_text"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              View
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  ) : LogUserde?.roleId === 4 ? (
                                    <StyledTableCell className="delete_cource">
                                      {data?.certificateMaterialUrl === "" ||
                                        data?.certificateMaterialUrl === null ? (
                                        ""
                                      ) : (
                                        <div
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <a
                                            href={`${Config.API_HOST_URL_live}${data?.certificateMaterialUrl}`}
                                            className="view_button me-2 p_14_text"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            View
                                          </a>
                                        </div>
                                      )}
                                    </StyledTableCell>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                    {formattedSearchResults?.length === 0 ? (
                      <div className="text-center my-3 p_20_text">
                        Data Not Found
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="page_nation mt-2">
                    <div className="rowsPerPageOptions">
                      <span className="p_18_text">show</span>
                      <select
                        className="pagination_select p_16_text"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                      <span className="p_18_text">entries</span>
                    </div>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={formattedSearchResults?.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <BootstrapDialog
        onClose={handleCloseCXDelete}
        aria-labelledby="customized-dialog-title"
        open={openCXDelete}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseCXDelete}
          dividers
          className="mb-3"
        >
          {cerEdit === "delete" ? " Delete Certificate" : "Edit Certificate"}
        </BootstrapDialogTitle>
        {cerEdit === "delete" ? (
          <>
            <DialogContent>
              <Typography>
                Please confirm you want to Delete this Certificate.
              </Typography>
              <Typography>
                <b>Please note:</b>
                This action will also remove this Certificate from User.
              </Typography>
            </DialogContent>
            <DialogActions className="popup_btn">
              <Button
                className="w-25 theme_button me-2"
                onClick={DeleteCertificate}
              >
                Confirm
              </Button>
              <Button autoFocus onClick={handleCloseCXDelete} className="w-25">
                Cancel
              </Button>
            </DialogActions>
          </>
        ) : (
          <div className="p-3">
            <AddCertificatePopUp
              editData={userAllDe}
              handleClose={handleCloseCXDelete}
            />
          </div>
        )}
      </BootstrapDialog>
    </>
  );
}

export default React.memo(AllCertificateDash);
