import React, { useState } from "react";
import "./index.scss";
import GroupsList from "./Components/Groups_list";
import Invitations from "./Components/Groups_list/invitations";
import active_list from "../../../images/active_list.png";
import inactive_list from "../../../images/inactive_list.png";
import active_grid from "../../../images/active_grid.png";
import inactive_grid from "../../../images/inactive_grid.png";
import plus_icon from "../../../images/Icon/Plus_gr.svg";
import plus_dark_icon from "../../../images/Icon/Plus_gr_dark.svg";

import { Button } from "@mui/material";
import AllGroups from "./Components/Groups_list/AllGroups";
import CreateComGroup from "../CommunityGroups/CreateComGroup";
import { useSelector } from "react-redux";

function Groups() {
  const [grid, setGrid] = useState(true);
  const [connectionActive, setconnectionActive] = useState(1);
  // const [searchval, setSearchval] = useState("");
  const [editId, setEditId] = useState("");
  const LogId = useSelector((state) => state.login?.LoginDetails);
  return (
    <>
      <div className="row m-0">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex">
            <div className="leftside_action">
              {LogId.roleId === 1 ? (
                <Button
                  onClick={() => setconnectionActive(3)}
                  className={
                    connectionActive === 3 ? "theme_button me-3" : "me-3"
                  }
                >
                  All Groups
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => setconnectionActive(1)}
                className={
                  connectionActive === 1 ? "theme_button me-3" : "me-3"
                }
              >
                My Groups
              </Button>
              <Button
                onClick={() => setconnectionActive(2)}
                className={
                  connectionActive === 2 ? "theme_button me-3" : "me-3"
                }
              >
                Invitations
              </Button>
              <Button
                onClick={() => {
                  setconnectionActive(4);
                  setEditId();
                }}
                className={
                  connectionActive === 4 ? "theme_button me-3" : "me-3"
                }
              >
                <img
                  src={connectionActive === 4 ? plus_icon : plus_dark_icon}
                  alt=""
                  className="cre_c_gr_img"
                />
              </Button>
            </div>
            {connectionActive !== 4 && (
              <>               
                <div className="rightside_action w-25">                
                  <Button className="grid_btn" onClick={() => setGrid(true)}>
                    <img
                      src={grid === true ? active_grid : inactive_grid}
                      className="img-fluid"
                      alt="grid"
                    />
                  </Button>
                  <Button className="list_btn" onClick={() => setGrid(false)}>
                    <img
                      src={grid === false ? active_list : inactive_list}
                      className="img-fluid"
                      alt="grid"
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {connectionActive === 3 ? (
          <>
            <AllGroups
              grid={grid}
              setconnectionActive={setconnectionActive}
              setEditId={setEditId}
            />
          </>
        ) : connectionActive === 1 ? (
          <>
            <GroupsList
              grid={grid}
              setconnectionActive={setconnectionActive}
              setEditId={setEditId}
            />
          </>
        ) : connectionActive === 2 ? (
          <>
            <Invitations grid={grid} />
          </>
        ) : connectionActive === 4 ? (
          <CreateComGroup editId={editId} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export default React.memo(Groups);
