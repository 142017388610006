import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Backdrop,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    Paper,
    styled,
} from "@mui/material";
import "./index.scss";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import search_icon from "../../../../images/Icon/table_search_icon.svg";
import Pagination from "../../../Reuseable_Components/Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";
import active_img from "../../../../images/Icon/active_green.svg";
import pro_img from "../../../../images/default_profile.jpg";
import Config from "../../../../Utils/Config";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F6FC",
    },
    borderBottom: "0px !important",
    "&.pdf_download": {
        fontSize: "10px !important",
        padding: "5px !important",
        ".MuiTableSortLabel-root": {
            ".MuiSvgIcon-root": { display: "none " },
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#ffffff",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#F1F6FC",
    },
    "&.odd": {
        backgroundColor: "#ffffff",
    },
    "&.even": {
        backgroundColor: "#F1F6FC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&.pdf_download": {
        fontSize: "10px !important",
        fontWeight: "300 !important",
        padding: "5px !important",
    },
}));

function ReportCommonTable(props) {
    const { download, searchShow, headCell, data, setOpen, open } = props

    const showReadMore = (t, des) => {
        Swal.fire({
            position: "center",
            width: window.innerWidth > 768 ? "50%" : "90%",
            title: t,
            text: des,
            confirmButtonColor: "#fff",
            // confirmButtonTextColor: "#000000",
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
        });
    };

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("col0");
    const [searchField, setSearchField] = useState("");
    const [userDataAll, setUserDataAll] = useState(false);
    const tableRef = useRef(null);

    const LogUserd = useSelector((state) => state.login.LoginDetails);
    // const token = useSelector((state) => state.login.LoginDetails.accessToken);
    const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: ReportMenuName,
        sheet: "Users",
    });

    useEffect(() => {
        if (data?.length > 0) {
            setCurrentPage(1)
        }
        // eslint-disable-next-line
    }, [data])

    function descendingComparator(a, b, orderBy) {
        if (orderBy === "col0") {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        } else {
            if (
                b[orderBy]?.toString()?.toLowerCase() <
                a[orderBy]?.toString()?.toLowerCase()
            ) {
                return -1;
            }
            if (
                b[orderBy]?.toString()?.toLowerCase() >
                a[orderBy]?.toString()?.toLowerCase()
            ) {
                return 1;
            }
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCell?.map((head) => (
                        <StyledTableCell
                            key={head?.id}
                            align="left"
                            sortDirection={orderBy === head?.id ? order : false}
                            colSpan={1}
                            className={`${downloadPdf && "pdf_download"}`}
                        >
                            <TableSortLabel
                                active={orderBy === head?.id}
                                direction={orderBy === head?.id ? order : "asc"}
                                onClick={createSortHandler(head?.id)}
                            >
                                {head?.headerName}
                                {!userDataAll && (
                                    <>
                                        {orderBy === head?.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </>
                                )}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [downloadPdf, setDownloadpdf] = useState(false);

    const formattedSearchResults = data?.filter((user) => {
        if (searchField) {
            let state = false;
            headCell?.forEach((property) => {
                let value = String(user[property?.id]);
                if (typeof value === "string") {
                    if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
                        state = true;
                    }
                }
            });
            return state;
        } else return user;
    });
    useEffect(() => {
        if (userDataAll) {
            if (downloadPdf) {
                handleDownloadPdf();
            } else {
                onDownload();
                setUserDataAll(false);
            }
        }
        // eslint-disable-next-line
    }, [userDataAll]);

    function convertDate(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const handleDownloadPdf = async () => {
        setOpen(true);

        const element = tableRef.current;
        let newPdfDate =
            convertDate(
                Time.toLocaleString("en-us", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
            ) +
            " " +
            Time.toLocaleTimeString();

        const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        doc
            .html(element, {
                margin: [20, 10, 20, 10],
                autoPaging: "text",
                html2canvas: { scale: 0.57 },
            })
            .then(() => {
                let totalPages = doc.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setTextColor(150);
                    doc.text(
                        newPdfDate,
                        doc.internal.pageSize.width / 2,
                        doc.internal.pageSize.getHeight() - 10,
                        {
                            align: "center",
                        }
                    );
                }
                doc.save(`${ReportMenuName}.pdf`);
                setDownloadpdf(false);
                setUserDataAll(false);
                setOpen(false);
            });
    };

    const back = {
        maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
        width: "100%",
        height: "100%",
        position: "relative",
        margin: "0",
    };
    let Time = new Date();

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="reuseable_for_reports">
                <div className="mb-4 heads_reports">
                    <div className="sep_report_head d-flex justify-content-between">
                        {searchShow ?
                            <div className="search_input w-50 me-4">
                                <input
                                    onChange={(e) => {
                                        setSearchField(e.target.value);
                                        setCurrentPage(1);
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search By Name"
                                />
                                <img src={search_icon} alt="" />
                            </div> : ""}
                        {download ?
                            <div className="grid_top_button_group d-flex w-100 justify-content-end btn-100 ms-4">
                                <Button
                                    className="delete_btn btn_space me-3"
                                    onClick={() => {
                                        setDownloadpdf(true);
                                        setUserDataAll(true);
                                    }}
                                >
                                    PDF
                                </Button>
                                <Button
                                    className="delete_btn btn_space"
                                    onClick={() => {
                                        setUserDataAll(true);
                                    }}
                                >
                                    Download
                                </Button>
                            </div>
                            : ""}
                    </div>
                </div>
                <div className="mt-3 school_district">
                    <Paper className="table-box">
                        <TableContainer className="" ref={tableRef} id="divToPrint" sx={back}>
                            {downloadPdf && (
                                <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                                    <img src={require("../../../../images/Icon/20211.png")} alt="" />
                                    <div>
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                color: "#2c2e35",
                                                fontWeight: 600,
                                                marginBottom: "7px",
                                            }}
                                        >
                                            {" "}
                                            {ReportMenuName}
                                        </label>
                                        <div
                                            className="names"
                                            style={{
                                                fontSize: "16px",
                                                color: "#2c2e35",
                                                fontWeight: 600,
                                                marginBottom: "7px",
                                            }}
                                        >
                                            {" "}
                                            {convertDate(
                                                Time.toLocaleString("en-us", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                                            )}{" "}
                                            {Time.toLocaleTimeString()}
                                        </div>
                                        <div
                                            className="names"
                                            style={{
                                                fontSize: "16px",
                                                color: "#2c2e35",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Report Generated By:{" "}
                                            {LogUserd?.firstName + " " + LogUserd?.lastName}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Table>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(
                                        formattedSearchResults,
                                        getComparator(order, orderBy)
                                    )
                                        ?.slice(
                                            (currentPage - 1) * pageSize,
                                            (currentPage - 1) * pageSize + pageSize
                                        )
                                        ?.map((row, index) => {
                                            return (
                                                <StyledTableRow
                                                    className={`table_body ${downloadPdf && "pdf_download"
                                                        }  ${index % 2 === 0 ? "odd" : "even"}`}
                                                    key={index + 1}
                                                >
                                                    {headCell?.map((column) => {
                                                        const value = row[column?.id];
                                                        if (column?.widthFix) {
                                                            if (userDataAll || downloadPdf) {
                                                                return (
                                                                    <StyledTableCell
                                                                        className={`column_data 
                                                                        ${downloadPdf && "pdf_download"}`}
                                                                        key={column?.id}
                                                                    >
                                                                        {value ? value : ""}
                                                                    </StyledTableCell>
                                                                );
                                                            }
                                                            return (
                                                                <StyledTableCell
                                                                    className="column_data"
                                                                    key={column?.id}
                                                                >
                                                                    {value ? value?.slice(0, 50) : ""}
                                                                    {value
                                                                        ? value?.length > 50 && (
                                                                            <span
                                                                                className="description cr"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    showReadMore(column?.headerName, value)
                                                                                }
                                                                            >
                                                                                ...read more
                                                                            </span>
                                                                        )
                                                                        : ""}
                                                                </StyledTableCell>
                                                            );
                                                        }
                                                        return (
                                                          <StyledTableCell
                                                            className={`column_data ${
                                                              downloadPdf &&
                                                              "pdf_download"
                                                            }`}
                                                            key={column?.id}
                                                            colSpan={1}
                                                          >
                                                            {column?.id ===
                                                              "MainCat" ||
                                                            column?.id ===
                                                              "LearCat" ||
                                                            column?.id ===
                                                              "TraiCat" ? (
                                                              <div
                                                                style={{
                                                                  width: `${
                                                                    column?.id ===
                                                                      "MainCat" ||
                                                                    column?.id ===
                                                                      "LearCat" ||
                                                                    column?.id ===
                                                                      "TraiCat"
                                                                      ? "200px"
                                                                      : ""
                                                                  }`,
                                                                  maxWidth: `${
                                                                    downloadPdf
                                                                      ? "100px"
                                                                      : ""
                                                                  }`,
                                                                  wordBreak:
                                                                    "break-all",
                                                                }}
                                                              >
                                                                {column?.format &&
                                                                typeof value ===
                                                                  "number"
                                                                  ? column?.format(
                                                                      value
                                                                    )
                                                                  : value}
                                                              </div>
                                                            ) : (
                                                              <>
                                                                {/* {column?.headerName ===
                                                                "User Name" ? (
                                                                  <div className="d-flex  align-items-center gap-2">
                                                                    <div className="img_boxs">
                                                                      <div className="user_img">
                                                                        <img
                                                                          src={`${
                                                                            row?.profileUrl
                                                                              ? Config?.API_HOST_URL_live +
                                                                                row?.profileUrl
                                                                              : pro_img
                                                                          }`}
                                                                          alt=""
                                                                          className="img-fluid"
                                                                        />
                                                                      </div>
                                                                      <img
                                                                        src={
                                                                          row?.userStatus ===
                                                                          "Online"
                                                                            ? active_img
                                                                            : ""
                                                                        }
                                                                        className="img-fluid dot_ac_in"
                                                                      />
                                                                    </div>
                                                                    {column?.format &&
                                                                    typeof value ===
                                                                      "number"
                                                                      ? column?.format(
                                                                          value
                                                                        )
                                                                      : value}
                                                                  </div>
                                                                ) : column?.format &&
                                                                  typeof value ===
                                                                    "number" ? (
                                                                  column?.format(
                                                                    value
                                                                  )
                                                                ) : (
                                                                  value
                                                                )} */}
                                                                {column?.format &&
                                                                typeof value ===
                                                                  "number"
                                                                  ? column?.format(
                                                                      value
                                                                    )
                                                                  : value}
                                                              </>
                                                            )}
                                                          </StyledTableCell>
                                                        );
                                                    })}
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {formattedSearchResults?.length === 0 ? (
                            <label className="r_cs_tit">Data Not Found</label>
                        ) : (
                            <div className="page_nation">
                                <div className="rowsPerPageOptions">
                                    <span className="p_18_text">show</span>
                                    <select
                                        className="pagination_select p_16_text"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(parseInt(e.target.value));
                                            setCurrentPage(1);
                                        }}
                                    >
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select>
                                    <span className="p_18_text">entries</span>
                                </div>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={formattedSearchResults?.length}
                                    pageSize={pageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        )}
                    </Paper>
                </div>
            </div>
        </>
    );
}
export default React.memo(ReportCommonTable);
