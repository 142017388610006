import React, { Suspense, useState, useEffect } from "react";
import "./index.scss";
import figlifestyle from "../../images/figlifestyle-banner.png";
// import lifestylevideo from "../../images/lifestyle-video-img.png";
// import yogavideo from "../../images/yoga-video-img.png";
// import { useSelector } from "react-redux";
// import userimg2 from "../../images/user.png";
// import cardimg1 from "../../images/ceu_couce_card_img.png";
// import Config from "../../Utils/Config";
// import axios from "axios";
// import CardGrid from "../Card_Grid";
// import { Backdrop, CircularProgress } from "@mui/material";
// import { PostCallApi } from "../../Action/Action";
import CourseList from "../CEU/CourseList";
import ReactPlayer from "react-player";

const renderLoader = () => <></>;

function FiglifeSyle() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // GetAllEventsList();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
    // eslint-disable-next-line
  }, []);

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <main>
        <section className="figlife-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="banner-content">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title mb-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-4"></div>
                    </div>
                  ) : (
                    <>
                      <h1>FIG Lifestyle</h1>
                      {/* <img
                    src={figlifestyle}
                    alt="Organic USDA"
                    className="banner-mobile-img"
                  /> */}
                      <h5>
                        FIG Lifestyle is all about bringing balance to your
                        team. Mental and physical wellness are necessities when
                        we consider putting our best foot forward. At FIG we
                        understand that means something different for everyone.
                        FIG Lifestyle is here to support your team in taking
                        better care of themselves from the inside out.
                      </h5>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6 right-banner">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <>
                    <img
                      src={figlifestyle}
                      alt="Organic USDA"
                      className="banner-desktop-img"
                    />
                  </>
                )}
              </div>
              <div></div>
            </div>
          </div>
        </section>
        {/* Start CEU COURSE CATALOG */}
        <CourseList title="FIG LIFESTYLE COURSES" type="2" />

        {/* End CEU COURSE CATALOG */}
        {/* Start SAMPLE COURSE LESSONS */}
        <section className="samplecourse-section common-padding">
          <div className="container">
            <div className="row">
              <h2 className="title-text">SAMPLE FIG LIFESTYLE VIDEOS</h2>
              <div className="col-md-6">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/504570116?h=b4929617b8&autoplay=0&title=0&portrait=0&byline=0&badge=0`}
                  className="react-player"
                  volume={1}
                  width="100%"
                  // height="100%"
                  height="350px"
                  controls
                  responsive
                />
                {/*  */}
                {/* <div className="fig-image">
                  <img src={lifestylevideo} alt="Fig Food" />
                </div> */}
              </div>
              <div className="col-md-6">
                <h5>CALMING YOURSELF</h5>
                <p>
                  Meditation means learning to watch your mind, understand it a
                  bit better and in time you can begin directing it more.
                  Techniques are simple. Explore different calming practices
                  with this exclusive FIG health and wellness video.
                </p>
              </div>
            </div>
            <div className="row food-hadler">
              <div className="col-md-6 padding-top">
                <h5>YOGA TO TRANSITION FROM WORK TO HOME</h5>
                <p>
                  Quick and easy yoga for breathwork to help you transition from
                  work mode to home.
                </p>
              </div>
              <div className="col-md-6">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/509870738?h=b9117cadab&autoplay=0&title=0&portrait=0&byline=0&badge=0`}
                  className="react-player"
                  volume={1}
                  width="100%"
                  // height="100%"
                  height="350px"
                  controls
                  // responsive
                />
                {/* <div className="food-image">
                  <img src={yogavideo} alt="Fig Food" />
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Start SAMPLE COURSE LESSONS */}
      </main>
    </Suspense>
  );
}

export default FiglifeSyle;
