import React, { useEffect, useState } from "react";
import { Modal, Button, Box, Backdrop, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import "./index.scss";
// import axios from "axios";
// import Config from "../../../../Utils/Config";
// import information from "../../../../images/Icon/information.svg";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  // LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Swal from "sweetalert2";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import { PostCallApi } from "../../../../Action/Action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};
function HelpBox(props) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handlePop = () => {
    setOpenEdit(true);
    props.test();
  };
  const handleClose = () => {
    setOpenEdit(false);
    setDerror();
    reset();
    props.test();
  };
  useEffect(() => {
    if (openEdit) {
      setTimeout(function () {
        loadCaptchaEnginge(6, "black", "white");
        setValue("Priority", "Medium");
        setValue("SupportCategoryId", 1);
      }, 500);
    }
    // eslint-disable-next-line
  }, [openEdit]);
  const onSubmit = async (data) => {
    if (validateCaptcha(data?.captcha, false) === true) {
      loadCaptchaEnginge(6, "black", "white");
      let tdata = {
        ...data,
        IsSentByFigSupport: false,
        IsWithOutLogin: true,
        Status: "created",
      };
      setDerror({ h_er: "" });
      setOpenCircle(true);
      let seData = {
        url: "/api/SupportTicket/CreateSupportTicket",
        body: tdata,
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      };
      let res = await PostCallApi(seData);
      // axios
      //   .post(
      //     `${Config.API_HOST_URL_live}/api/SupportTicket/CreateSupportTicket`,
      //     tdata,
      //     {
      //       headers: {
      //         // Authorization: `Bearer ${token}`,
      //         "Content-Type": "multipart/form-data",
      //         Accept: "*/*",
      //       },
      //     }
      //   )
      //   .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          handleClose();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(res.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
      // })
      // .catch((e) => console.log(e));
    } else {
      setDerror({ h_er: "Captcha does not match." });
    }
  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const handleImg = (e) => {
    setValue("FilePath", e.target.files[0]);
    setValue("imgUpload", e.target.files[0].name);
    e.target.files = null;
  };
  return (
    <>
      {" "}
      <Button onClick={handlePop} className="help_btn">
        {/* <img src={information} alt="" /> */}
        Need Help ? Contact Support
      </Button>
      {openEdit && (
        <Modal
          open={openEdit}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_help_box"
        >
          <Box sx={style} className={`main_box `}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openCircle}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={handleSubmit(onSubmit)} className="detail_submit">
              <h3 className="text-center mb-4">Create a Support Ticket</h3>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      {...register("UserName", {
                        required: "This field is required",
                        pattern: {
                          value: /[A-Za-z]{3}/,
                          message: "Enter only Alphabets",
                        },
                      })}
                    />
                    {errors.UserName && (
                      <span role="alert" className="error_text">
                        {errors.UserName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter email"
                      {...register("Email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span role="alert" className="error_text">
                        {errors.Email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Phone"
                      {...register("Phone", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.Phone && (
                      <span role="alert" className="error_text">
                        {errors.Phone.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Priority</label>
                    <select
                      name=""
                      className="form-select"
                      {...register("Priority", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select Priority</option>
                      <option value="High">High</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                    </select>
                    {errors.Priority && (
                      <span role="alert" className="error_text">
                        {errors.Priority.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Category</label>
                    <select
                      name=""
                      className="form-select"
                      {...register("SupportCategoryId", {
                        required: "This field is required",
                        valueAsNumber: true,
                      })}
                    >
                      <option value="">Select Category</option>
                      <option value="3">Data Entry Service</option>
                      <option value="2">Sales or Other Help</option>
                      <option value="1">Technical Support</option>
                    </select>
                    {errors.SupportCategoryId && (
                      <span role="alert" className="error_text">
                        {errors.SupportCategoryId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter subject"
                      {...register("Subject", {
                        required: "This field is required",
                      })}
                    />
                    {errors.Subject && (
                      <span role="alert" className="error_text">
                        {errors.Subject.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className=" col-md-6">
                  <div className="form-group">
                    <label className="form-label">Upload Attachment</label>
                    <div className="upload_img">
                      <input
                        type="text"
                        className="form-control upload_box"
                        placeholder="No file Chosen"
                        readOnly
                        {...register("imgUpload", {
                          // required: "This field is required",
                        })}
                      />
                      <input
                        type="file"
                        id="attch_tic"
                        // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        className="form-control"
                        style={{ display: "none" }}
                        placeholder="User You Want To Be In District"
                        onChange={handleImg}
                      />
                      <label
                        htmlFor="attch_tic"
                        className="upload_img_label mb-0 "
                      >
                        <img src={uploadimg} className="img-fluid" alt="" />
                      </label>
                    </div>
                    {errors?.imgUpload && (
                      <span role="alert" className="error_text">
                        {errors.imgUpload.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">School District Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter school district name"
                      {...register("SchoolDistrictName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.SchoolDistrictName && (
                      <span role="alert" className="error_text">
                        {errors.SchoolDistrictName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      rows={5}
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                      {...register("Description", {
                        required: "This field is required",
                      })}
                    />
                    {errors?.Description && (
                      <span role="alert" className="error_text">
                        {errors.Description.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="d-flex">
                  <div className="w-50 mt-auto me-3 captcha_box ">
                    <LoadCanvasTemplate />
                  </div>
                  <div className="w-50">
                    <label className="form-label">Captcha</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Captcha"
                      {...register("captcha", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        maxLength: {
                          value: 6,
                          message: "Max 6 characters",
                        },
                      })}
                    />
                    {errors?.captcha && (
                      <span role="alert" className="d-block error_text">
                        {errors.captcha.message}
                      </span>
                    )}
                    {derror?.h_er && (
                      <span role="alert" className="d-block error_text">
                        {derror.h_er}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Button className="submit" type="submit">
                  Submit
                </Button>
                <Button className="cancel" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default HelpBox;
