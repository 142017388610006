import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { DashboardRe } from "../../../../reducers/user";
import Config from "../../../../Utils/Config";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import "./index.scss";
import Select from "react-select";
import { GetCallApi } from "../../../../Action/Action";

function AddCertificatePopUp({ handleClose, editData }) {
  const [dateCompletion, setDateCompletion] = useState();
  const [dateExpiration, setDateExpiration] = useState();
  const [dateReminder, setDateReminder] = useState();

  const [userList, setUserList] = useState([]);
  const [derrors, setDerrors] = useState({});
  const [schoolList, setSchoolList] = useState([]);
  const LogUserId = useSelector((state) => state.login?.LoginDetails);
  let changeDash = useSelector((state) => state.user?.Drefresh);
  let dispatch = useDispatch();
  const [users, setUsers] = useState([]);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (LogUserId?.roleId === 1) {
      getSchoolList();
    } else {
      getUerList(LogUserId?.schoolDistrictId);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (editData !== undefined) {
      if (LogUserId.roleId === 1) {
        if (schoolList?.length > 0) {
          setValue("schoolDistrictId", editData?.schoolDistrictId);
        }
      }
      setValue("certificateAgencyName", editData?.certificateAgencyName);
      // setValue("status", editData?.expired);
      let cDate = "";
      if (editData?.DateOfComPletion === undefined) {
        cDate = "";
      } else {
        cDate = new Date(editData?.DateOfComPletion?.split("12:")[0]);
      }
      let eDate = "";
      if (editData?.DateOfExpiration === undefined) {
        eDate = "";
      } else {
        eDate = new Date(editData?.DateOfExpiration?.split("12:")[0]);
      }
      let reDate = "";
      if (editData?.ReminderofExpiration === undefined) {
        reDate = "";
      } else {
        reDate = new Date(editData?.ReminderofExpiration?.split("12:")[0]);
      }
      setDateCompletion(cDate === "" ? null : cDate);
      setDateReminder(reDate === "" ? null : reDate);
      setDateExpiration(eDate === "" ? null : eDate);
      setValue(
        "certificateFromOtherSourcesId",
        editData?.certificateFromOtherSourcesId
      );
      setValue("courseName", editData?.courseName);
      getUerList(editData?.schoolDistrictId);
      setValue("imgUpload", editData?.certificateMaterialUrl);
      setValue("MaterialsAttachment", editData?.certificateMaterialUrl);
      setValue("certificateName", editData?.certificateName);
    }
    // eslint-disable-next-line
  }, [editData, schoolList]);
  useEffect(() => {
    if (editData) {
      if (userList?.length > 0) {
        setValue("userId", editData?.userId);
      }
    }
    // eslint-disable-next-line
  }, [editData, userList]);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const onSubmit = (data) => {
    let is_success = true;
    if (dateCompletion) {
      setDerrors({ D: "" });
      if (dateReminder) {
        setDerrors({ re: "" });
        if (dateExpiration) {
          setDerrors({ Dex: "" });
        } else {
          setDerrors({ Dex: "Select Date of Expiration" });
          is_success = false;
        }
      } else {
        setDerrors({ re: "Select Date of Reminder" });
        is_success = false;
      }
    } else {
      setDerrors({ D: "Select Date Completion" });
      is_success = false;
    }
    if (dateCompletion >= dateExpiration) {
      is_success = false;
      setDerrors({
        Dex: "Completion Date can not greaterthan from  Date of Expiration",
      });
    } else if (dateReminder >= dateExpiration) {
      is_success = false;
      setDerrors({
        Dex: "Reminder Date can not greaterthan from  Date of Expiration",
      });
    }
    if (is_success) {
      let re = convert(dateExpiration);
      var d = new Date(re);
      d.setDate(d.getDate() - 3);
      d.toLocaleString();
      if (editData === undefined) {
        // users?.map((item, i) => {
        let CerData = {
          ...data,
          userId: users?.map((item) => item.value),
          status: "Completed",
          createdBy: LogUserId.userId,
          dateOfCompletion: convert(dateCompletion),
          dateOfExpiration: convert(dateExpiration),
          reminderOfExpiry: convert(dateReminder),
        };

        axios
          .post(
            `${Config.API_HOST_URL_live}/api/CourseUserCertificatesAllocation/CreateUserCertificateFromOtherSources`,
            CerData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              // if (users?.length - 1 === i) {
              handleClose();
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data?.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background:
                  "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              // }
              setDerrors({ err: "" });
            } else {
              setDerrors({ err: response.data.message });
            }
          });
        return "";
        // });
      } else {
        let CerData = {
          ...data,
          status: "Completed",
          createdBy: LogUserId.userId,
          dateOfCompletion: convert(dateCompletion),
          dateOfExpiration: convert(dateExpiration),
          reminderOfExpiry: convert(dateReminder),
        };
        axios
          .post(
            `${Config.API_HOST_URL_live}/api/CourseUserCertificatesAllocation/CreateUserCertificateFromOtherSources`,
            CerData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              handleClose();
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data?.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            }
          });
      }
    }
  };
  const getUerList = async (id) => {
    if (id) {
      // let url = `${Config.API_HOST_URL_live}/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let seData = {
        // url: `/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        url: `/api/User/GetUserListSchoolDistrictIdByRoleWise?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        headers: headers
      }
      const response = await GetCallApi(seData)
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value: item.userId,
              label: item.firstName + " " + item.lastName,
            };
          });
          if (editData === undefined) {
            setUsers([]);
            let uslist = [{ value: "all", label: "Select All" }, ...data];
            setUserList(uslist);
          } else {
            setUserList(data);
          }
        } else {
          setUserList([]);
          setUsers([]);
        }
      } else {
        setUserList([]);
        setUsers([]);
      }
      // })
      // .catch((error) => console.log(error));
    } else {
      setUserList([]);
      setUsers([]);
    }
  };
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId?.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId: item.schoolDistrictId,
              schName: item.districtName,
            };
          });
          setSchoolList(data);
        }
      })
      .catch((error) => console.log(error));
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const handleImage = async (e) => {
    var file = e.target?.files[0];
    if (file.size < 5253880) {
      setValue("MaterialsAttachment", file);
      setValue("imgUpload", file.name);
      setDerrors({ img_err: "" });
    } else {
      setDerrors({ img_err: "Please Upload Max 5MB File" });
    }
  };

  const handleChangeUsersMulti = (e) => {
    setUsers(e);
  };
  return (
    <div className="tab_panel">
      <div className="tab_panel_input_mt mt-0">
        <label className="p_14_text mb-2">Certifying Agency Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Certifying Agency Name"
          {...register("certificateAgencyName", {
            required: {
              value: true,
              message: "This field is required",
            },
          })}
        />
        {errors.certificateAgencyName && (
          <span role="alert" className="error_text">
            {errors.certificateAgencyName.message}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <label className="p_14_text mb-2">Course Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Course Name"
          {...register("courseName", {
            required: {
              value: true,
              message: "This field is required",
            },
          })}
        />
        {errors.courseName && (
          <span role="alert" className="error_text">
            {errors.courseName.message}
          </span>
        )}
      </div>
      {LogUserId.roleId === 1 ? (
        <div className="tab_panel_input_mt ">
          <label className="p_14_text mb-2">School District</label>
          <select
            className={`form-select `}
            aria-label="Default select example"
            disabled={editData !== undefined ? true : false}
            {...register("schoolDistrictId", {
              required: "This field is required",
              onChange: (e) => getUerList(e.target.value),
            })}
          >
            <option value=""> Select School District </option>
            {schoolList &&
              schoolList?.map((data, i) => (
                <option value={data.schId} key={i}>
                  {data.schName}
                </option>
              ))}
          </select>
          {errors.schoolDistrictId && (
            <span role="alert" className="error_text">
              {errors.schoolDistrictId.message}
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      {editData === undefined ? (
        <div className="tab_panel_input_mt">
          <label className="p_14_text mb-2">Users</label>
          <Select
            isMulti
            name="colors"
            options={userList}
            className="course-caretory-multi-select p_14_text"
            classNamePrefix="select"
            placeholder="Select Users To Add Certificate"
            value={users ? users : null}
            // onChange={handleChangeUsersMulti}
            onChange={(selected) => {
              true &&
                selected.length &&
                selected.find((option) => option.value === "all")
                ? handleChangeUsersMulti(userList.slice(1))
                : !true
                  ? handleChangeUsersMulti((selected && selected.value) || null)
                  : handleChangeUsersMulti(selected);
            }}
          />
        </div>
      ) : (
        <div className="tab_panel_input_mt">
          <label className="p_14_text mb-2">User</label>
          <select
            className="form-select dropdown_group"
            aria-label="Default select example"
            disabled={editData !== undefined ? true : false}
            {...register("userId", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
          >
            <option value="">Select User To Add Certificate</option>
            {userList &&
              userList?.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
          </select>
          {errors.userId && (
            <span role="alert" className="error_text">
              {errors.userId.message}
            </span>
          )}
        </div>
      )}
      <div className="tab_panel_input_mt ">
        <label className="p_14_text mb-2">Certificate Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Certificate Name"
          {...register("certificateName", {
            required: {
              value: true,
              message: "This field is required",
            },
          })}
        />
        {errors.certificateName && (
          <span role="alert" className="error_text">
            {errors.certificateName.message}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <label className="p_14_text mb-2">Date Of Completion</label>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dateCompletion}
          onChange={setDateCompletion}
          placeholderText="Date Of Completion"
          className="Date_picker"
        />
        {derrors?.D && (
          <span role="alert" className="error_text">
            {derrors.D}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <label className="p_14_text mb-2">Date Of Reminder</label>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dateReminder}
          onChange={setDateReminder}
          placeholderText="Date Of Reminder"
          className="Date_picker"
        />
        {derrors?.re && (
          <span role="alert" className="error_text">
            {derrors.re}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <label className="p_14_text mb-2">Date Of Expiration</label>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dateExpiration}
          onChange={setDateExpiration}
          placeholderText="Date Of Expiration"
          className="Date_picker"
        />
        {derrors?.Dex && (
          <span role="alert" className="error_text">
            {derrors.Dex}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <label className="p_14_text mb-2">Upload Document</label>
        <div className="upload_img">
          <input
            type="text"
            className="form-control upload_box"
            placeholder="No file Chosen"
            readOnly
            {...register("imgUpload", {
              // required: {
              //   value: true,
              //   message: "Please Upload File",
              // },
            })}
          />
          <input
            type="file"
            id="school_img_upload"
            className="form-control"
            style={{ display: "none" }}
            onChange={handleImage}
          />
          <label htmlFor="school_img_upload" className="upload_img_label mb-0 ">
            <img src={uploadimg} className="img-fluid" alt="" />
          </label>
        </div>
        {errors.imgUpload && (
          <span role="alert" className="error_text">
            {errors.imgUpload.message}
          </span>
        )}
        {derrors.img_err && (
          <span role="alert" className="error_text">
            {derrors.img_err}
          </span>
        )}
        {derrors?.err && (
          <span role="alert" className="error_text">
            {derrors?.err}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <button
          type="Submit"
          className="popup_btn update"
          onClick={handleSubmit(onSubmit)}
        >
          {editData ? "Edit" : "Add"} Certificate
        </button>
        <button
          type="button"
          className="popup_btn cancel"
          onClick={handleClose}
        >
          cancel
        </button>
      </div>
    </div>
  );
}

export default React.memo(AddCertificatePopUp);
