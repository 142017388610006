import React, { useEffect } from "react";
import "./index.scss";
import send_icon from "../../../images/Icon/send_mes.svg";
import Earth from "../../../images/Earth.svg";
import pro_img from "../../../images/default_profile.jpg";
import delete_icon from "../../../images/Delete.svg";
import camera from "../../../images/Camera.png";
import video from "../../../images/Video.png";
import lockOpen_icon from "../../../images/Icon/lock-open.png";
// import attach from "../../../images/Attachment.png";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../Utils/Config";
import Swal from "sweetalert2";
import { CreatePost } from "../../../reducers/user";
import Select from "react-select";

function Add_post(props) {
  const [openCir, setOpenCir] = useState(false);
  const [derror, setDerror] = useState();
  const [imgArr, setImgArr] = useState([]);
  const [imgShowArr, setImgShowArr] = useState([]);
  const [vidArr, setVidArr] = useState([]);
  const [vidShowArr, setVidShowArr] = useState([]);
  const [privId, setPrivId] = useState("");
  const [comGrpList, setComGrpList] = useState([]);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const PostC = useSelector((state) => state.user.Post_data);
  let LoginUser = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  const handleImg = (e) => {
    let is_succes = true;
    let imar = [];
    let newImageUrls = [];
    const chooseFiles = Array.prototype.slice.call(e.target?.files);
    if (e.target?.files?.length > 5) {
      setDerror({ img: "Please Select Only 5 Images" });
      return false;
    } else {
      chooseFiles.filter((file) => {
        if (file.size > 5242880 && is_succes === true) {
          setDerror({ img: `Select less than 5mb ${file?.name} Image` });
          is_succes = false;
        } else if (is_succes === true) {
          imar.push(file);
          newImageUrls.push(URL.createObjectURL(file));
        }
        return 0;
      });
    }
    if (is_succes && imar.length > 0) {
      setVidArr([]);
      setVidShowArr([]);
      setImgArr(imar);
      setImgShowArr(newImageUrls);
      setDerror({ img: "" });
    }
  };
  const handleVideo = (e) => {
    let is_succes = true;
    let viar = [];
    let chooseFiles = Array.prototype.slice.call(e.target?.files);
    let newImageUrls = [];

    if (e.target?.files?.length > 5) {
      setDerror({ vid: "Please Select Only 5 Videos" });
      is_succes = false;
    } else {
      chooseFiles?.filter((file, i) => {
        if (file.size > 5242880 && is_succes === true) {
          setDerror({ vid: ` Select less than 5mb ${file?.name} video` });
          is_succes = false;
        } else if (is_succes === true) {
          viar.push(file);
          newImageUrls.push(URL.createObjectURL(file));
        }
        return 0;
      });
    }
    if (is_succes && viar.length > 0) {
      setImgArr([]);
      setImgShowArr([]);
      setVidArr(viar);
      setVidShowArr(newImageUrls);
      setDerror({ vid: "" });
    }
  };
  const onSubmit = (data) => {
    const productData = new FormData();
    productData.append("description", data?.description);
    productData.append("UserId", LogUserId);
    productData.append("createdBy", LogUserId);
    if (
      derror?.vid === "" ||
      (derror?.vid === undefined && derror?.img === "") ||
      derror?.vid === undefined
    ) {
      setOpenCir(true);
      if (imgArr.length > 0) {
        productData.append("PostTypeId", 1);
        imgArr?.map((img) => productData.append("ImagesAttachment", img));
      } else if (vidArr.length > 0) {
        productData.append("PostTypeId", 2);
        vidArr?.map((vid) => productData.append("VideosAttachment", vid));
      } else {
        productData.append("PostTypeId", 3);
      }
      let url = "";
      url = `${Config.API_HOST_URL_live}/api/CommunityChat/CreateCommunityPost`;
      if (
        props?.comGroupId === "00000000-0000-0000-0000-000000000000" ||
        props?.comGroupId === undefined
      ) {
        if (privId === "1") {
          productData.append("PrivacyTypeId", 1);
          productData.append(
            "CommunityGroupId",
            "00000000-0000-0000-0000-000000000000"
          );
        } else if (privId === "3") {
          productData.append("PrivacyTypeId", 3);
          productData.append(
            "CommunityGroupId",
            "00000000-0000-0000-0000-000000000000"
          );
        } else {
          productData.append("PrivacyTypeId", 2);
          productData.append("CommunityGroupId", privId);
        }
      } else {
        productData.append("PrivacyTypeId", 2);
        productData.append("CommunityGroupId", props?.comGroupId);
      }

      axios
        .post(url, productData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((responce) => {
          if (responce.data.success) {
            setOpenCir(false);
            setVidArr([]);
            setVidShowArr([]);
            setImgArr([]);
            setImgShowArr([]);
            if (privId === "1") {
              setPrivId("1");
            } else if (privId === "3") {
              setPrivId("3");
            } else {
              setPrivId("2");
            }
            setValue("description", "");
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(responce.data.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (PostC) {
              dispatch(CreatePost(false));
            } else {
              dispatch(CreatePost(true));
            }
          } else {
            setOpenCir(false);
          }
        });
    }
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const RemoveImage = (e) => {
    const s = imgArr?.filter((item, index) => index !== e);
    const s1 = imgShowArr?.filter((item, index) => index !== e);
    setImgShowArr(s1); // update the state
    setImgArr(s); // update the state
  };
  const RemoveVideo = (e) => {
    const s = vidArr?.filter((item, index) => index !== e);
    const s1 = vidShowArr?.filter((item, index) => index !== e);
    setVidShowArr(s1); // update the state
    setVidArr(s); // update the state
  };
  useEffect(() => {
    getGrpList();
    // eslint-disable-next-line
  }, []);
  const getGrpList = () => {
    let url = "";

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    if (LoginUser.roleId === 1) {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/CommunityGroupList`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${LogUserId}`;
    }
    axios.get(url, { headers }).then((response) => {
      if (response.data.success) {
        const cgList = response.data.payload?.map((item) => {
          return {
            value: item?.communityGroupId,
            label: item?.communityGroupName,
            img:
              item?.groupImageUrl === "" || item?.groupImageUrl === null
                ? pro_img
                : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
          };
        });
        let mainList = [
          {
            value: "1",
            label: "Public",
            img: Earth,
          },
          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
          ...cgList,
        ];
        setPrivId("1");
        setComGrpList(mainList);
      } else {
        setComGrpList([
          {
            value: "1",
            label: "Public",
            img: Earth,
          },
          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
        ]);
        setPrivId("1");

        // setOpen(false);
      }
    });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCir}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="add_comment_box">
        <div className="comment_input_box d-flex">
          <img
            src={
              LoginUser.profileUrl === null || LoginUser.profileUrl === ""
                ? pro_img
                : `${Config.API_HOST_URL_live}${LoginUser.profileUrl}`
            }
            alt=""
            className="img-fluid me-1 m-auto post_user_img"
            width={50}
            height={50}
          />
          <textarea
            className="form-control comment_field"
            placeholder="Share What's On Your Mind"
            {...register("description", {
              required: "Description is required",
            })}
          />
          <IconButton className="pst_btn" onClick={handleSubmit(onSubmit)}>
            <img src={send_icon} alt="" />
          </IconButton>
        </div>
        <hr />
        <div className="action_button ">
          <div>
            <label htmlFor="post_img" className="p-2">
              <img src={camera} className="img-fluid" alt="" />
            </label>
            <input
              multiple
              max={5}
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              id="post_img"
              style={{ display: "none" }}
              onChange={(e) => handleImg(e)}
            />

            <label htmlFor="post_video" className="p-2">
              <img src={video} className="img-fluid" alt="" />
            </label>
            <input
              multiple
              max={5}
              accept="video/*"
              type="file"
              id="post_video"
              style={{ display: "none" }}
              onChange={(e) => handleVideo(e)}
            />
            {/* <label htmlFor="post_attch" className="p-2">
              <img src={attach} className="img-fluid" alt="attachment" />
            </label>
            <input
              multiple
              max={5}
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              id="post_attch"
              style={{ display: "none" }}
            /> */}
          </div>
          {props?.comGroupId === "00000000-0000-0000-0000-000000000000" ||
          props?.comGroupId === undefined ? (
            <Select
              isMulti={false}
              name="type"
              options={comGrpList}
              className="course-caretory-multi-select p_14_text"
              placeholder="Type"
              classNamePrefix="select"
              value={comGrpList.filter((obj) => privId === obj.value)}
              onChange={(e) => setPrivId(e.value)}
              formatOptionLabel={(country) => (
                <div className="country-option">
                  <img
                    src={country.img}
                    alt=""
                    className="me-2 "
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>{country.label}</span>
                </div>
              )}
            />
          ) : (
            ""
          )}
          {errors?.description && (
            <span role="alert" className="error_text">
              {errors?.description.message}
            </span>
          )}
          {derror?.img && (
            <span role="alert" className="error_text">
              {derror?.img}
            </span>
          )}
          {derror?.vid && (
            <span role="alert" className="error_text">
              {derror?.vid}
            </span>
          )}
        </div>
      </div>
      {imgShowArr.length > 0 && (
        <div className=" add_comment_box">
          <div className="row m-0">
            {imgShowArr.map((item, i) => (
              <div className="col-md-2">
                <div className="img_box">
                  <img
                    src={delete_icon}
                    alt=""
                    className="delete_btn"
                    onClick={(e) => RemoveImage(i)}
                  />
                  <img
                    src={item}
                    alt=""
                    className="img-fluid"
                    style={{ height: "125px" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {vidShowArr.length > 0 && (
        <div className=" add_comment_box">
          <div className="comment_input_box d-flex m-0">
            {vidShowArr.map((item, i) => (
              <div className="ms-3 ">
                <div className="video_box">
                  <img
                    src={delete_icon}
                    alt=""
                    className="delete_btn"
                    onClick={(e) => RemoveVideo(i)}
                  />
                </div>
                <video width="180" height="125" controls>
                  <source src={item} type="video/mp4" />
                </video>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
export default React.memo(Add_post);
