import React, { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DeleteDataFor } from "../../../reducers/user";
import { Box, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
// import { PostCallApi } from "../../../Action/Action";
import Axios from "../../../Utils/AxiosConfig";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function PostDeletePop(props) {
  const [open, setOpen] = useState(false);
  const [openCircle, setOpenCircle] = React.useState(false);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const deletedSuccess = useSelector((state) => state.user?.deleteData);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const onSubmit = async (data) => {
    let seData = {
      url: `/api/BlockReportPost/DeleteBlockReportPost`,
      body: {
        ...data,
        postBlockReportId: props?.postBlockReportId,
        createdBy: LogDetail?.userId,
      },
      headers: headers,
    };
    Axios.post(seData.url, { headers: seData.headers, data: seData.body })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            if (deletedSuccess) {
              dispatch(DeleteDataFor(false));
            } else {
              dispatch(DeleteDataFor(true));
            }
            setOpenCircle(false);
            handleClose();
          } else {
            dispatch(DeleteDataFor(false));
            setOpenCircle(false);
            handleClose();
          }
        }
      })
      .catch((errors) => {
        setOpen(false);
        console.log(errors);
      });
  };
  return (
    <>
      <button className="dlt_btn" onClick={handleOpen}>
        Delete Post
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data1"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="popup_delete_detail">
                    <h5>Alert</h5>
                    <p className="delete_text">
                      Are you sure you want to delete
                      <span className=""> {props?.text}'s</span> post?
                    </p>
                    <div className="mx-3 mb-3">
                      <textarea
                        rows="5"
                        className="form-control"
                        placeholder="Enter delete reason"
                        {...register("reason", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      ></textarea>
                      {errors.reason && (
                        <span role="alert" className="error_text">
                          {errors.reason.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <button type="submit" className="popup_btn delete">
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(PostDeletePop);
