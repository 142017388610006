import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useParams } from "react-router-dom";
import pro_img from "../../../../../../../images/default_profile.jpg";
import { GetCallApi, PostCallApi } from "../../../../../../../Action/Action";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import PaymentProcess from "./Payment";
import { AttendeeRegistrationConference } from "../../../../../../../reducers/Common";
import Swal from "sweetalert2";
import Config from "../../../../../../../Utils/Config";
import Select from "react-select";
import { useForm } from "react-hook-form";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

function RegisterList(props) {
  const { districtName, districtDetails } = props;
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const { id } = useParams();
  const [allSerror, setAllSerror] = useState();
  const [userList, setUserList] = useState([]);
  const [payDetail, setPayDetail] = useState();
  const [payDetail1, setPayDetail1] = useState();
  const [conferenceList, setConferenceList] = useState([]);
  const [conferenceSelect, setConferenceSelect] = useState();
  const [evList, setEvList] = useState([]);
  const [seList, setSeList] = useState([]);
  const [seAddList, setSeAddList] = useState([]);
  const [subtotal, setSubTotal] = useState("");
  const [conQt, setConQt] = useState(1);
  const [conPrice, setConPrice] = useState();

  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const [withSubmit, setWithSubmit] = useState(false);

  let dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   if (usList) {
  //     setUserList(usList);
  //   }
  // }, [usList]);
  useEffect(() => {
    getConferenceList();
    // eslint-disable-next-line
  }, []);

  const getUserList = async (cId) => {
    if (cId) {
      let seData = {
        url: `/api/MemberManagement/GetUsersListByDistrictAndConference?schoolDistrictId=${id}&conferenceId=${cId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res?.data.success) {
          let cl = res.data.payload?.map((u) => {
            return {
              ...u,
              img:
                u.profileUrl === null ||
                u.profileUrl === "" ||
                u.profileUrl === undefined
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${u.profileUrl}`,
              name: u.firstName + " " + u.lastName,
            };
          });
          setUserList(cl);
        }
      }
    } else {
      setUserList([]);
    }
  };
  const getConferenceList = async () => {
    let seData = {
      url: "/api/Conforence/ConforenceDropDown",
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        let cl = res.data.payload;
        setConferenceList(cl);
      }
    }
  };
  const getConferenceDetail = async (e) => {
    getUserList(e);
    setEvList([]);
    setSeList([]);
    setSeAddList([]);
    if (e) {
      let seData = {
        url: `/api/Conforence/GetConforenceDetail?ConforenceId=${e}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res?.data.success) {
          let cl = res.data.payload[0];
          let con = conferenceList?.filter((x) => x?.conferenceId === e)[0];
          setConferenceSelect({ ...res.data.payload[0], ...con });

          setEvList(cl.lstEvent);
          setSeList(
            cl.lstClasses?.map((x) => {
              return { ...x, add: false };
            })
          );
        }
      }
    } else {
      setConferenceSelect();
    }
  };

  const searchParam = ["name", "jobTitle", "siteLocation"];

  const FilterList = userList?.filter((user) => {
    if (props?.searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(props?.searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  const handleAll = (e) => {
    const newArr = FilterList.map((obj) => {
      if (obj.isRegistered) {
        return obj;
      }
      return { ...obj, add: e, events: e ? obj?.events : [] };
    });
    setUserList(newArr);
    setCheckBoxVal(e);
    if (conferenceSelect && e) {
      handleCQ(newArr?.filter((y) => y?.isRegistered === false)?.length);
      setValue(
        "ConferenceQuantity",
        newArr?.filter((y) => y?.isRegistered === false)?.length
      );
    }
  };

  const handleCQ = (e) => {
    setConQt(e);
  };

  const handleMainCheck = (e, index) => {
    let allMainCh = true;
    const newArr = FilterList.map((obj) => {
      if (obj.userId === index.userId) {
        return { ...obj, add: e, events: [] };
      }
      return obj;
    });

    setUserList(newArr);
    if (conferenceSelect) {
      handleCQ(newArr?.filter((x) => x?.add === true)?.length);
      setValue(
        "ConferenceQuantity",
        newArr?.filter((x) => x?.add === true)?.length
      );
    }
    if (e) {
      newArr
        ?.filter((y) => y?.isRegistered === false)
        ?.map((x) => {
          if (x?.add === false || x?.add === undefined) {
            return (allMainCh = false);
          }

          return 0;
        });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  const sendMultiReq = () => {
    if (conferenceSelect) {
      if (getValues("orType")) {
        let uList = userList?.filter((x) => x.add === true);
        if (uList?.length > 0) {
          setPayDetail(true);
          setAllSerror({ al: "" });
        } else {
          setAllSerror({ al: "You have to select at least one user." });
          setOpenCircle(false);
        }
      } else {
        Swal.fire({
          position: "center",
          width: "50%",
          title: "Please select register type",
          confirmButtonText: "Close",
          confirmButtonColor: "#E64942",
        });
      }
    } else {
      setAllSerror({ al: "Select Conference For Register User" });
    }
  };
  const FinalPay = async (data) => {
    let uList = userList
      ?.filter((x) => x.isRegistered !== true)
      ?.filter((x) => x.add === true);

    let sendData = {
      ...data,
      conferenceId: conferenceSelect?.conferenceId,
      subtotal: subtotal,
      evtList: evList?.filter((x) => x.Ql),
      organizationOrAttendeeType:
        conferenceSelect?.conferenceAttendeePriceTypeData?.filter(
          (x) => x.conferenceAttendeePriceTypeId === data?.orType
        )[0]?.name,
      conferenceMembersAttendee: parseInt(data?.ConferenceQuantity),
      conferenceMembersPrice: parseInt(data?.ConferenceQuantity) * conPrice,
      eventAttendeePriceList: evList
        ?.filter((x) => x.Ql)
        ?.map((x) => {
          return {
            eventId: x?.eventId,
            eventAttendee: parseInt(x?.Ql),
            eventPrice: x?.attendeeFees * parseInt(x?.Ql),
          };
        }),
      totalPrice: subtotal,
      sessionList: seAddList?.map((x) => x?.classesId),
      createdBy: LogDetails?.userId,
      // pyCC: pyCC,
      lstUserId: uList?.map((x) => {
        return {
          userId: x.userId,
          isNewUser: true,
          UserEventList: x?.events,
        };
      }),
      lstUser: uList,
      PaymentStatus: "Success",
      associationMemberDetails: {
        associationMemberName: districtName,
        state: districtDetails?.state,
        email: districtDetails?.email,
        phone: districtDetails?.phoneNumber,
        type: "LEA",
      },
    };
    if (subtotal === 0) {
      let paymentData = {
        ...sendData,
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: subtotal,
        discountAmount: 0,
        promoCodeId: null,
        conferenceAttendeePriceTypeId: sendData?.orType,
        paymentMode: "Online",
        createdBy: LogDetails?.userId,
      };
      setWithSubmit(true);
      let sveCC = {
        url: `/api/MemberManagement/ZeroPaymentMemberManagement`,
        body: paymentData,
        headers: headers,
      };
      let res1 = await PostCallApi(sveCC);
      if (res1?.status === 200 && res1?.data?.success) {
        let seData = {
          url: `/api/MemberManagement/CreateMemberManagement`,
          body: {
            ...sendData,
            memberManagementPaymentId:
              res1?.data.payload?.memberManagementPaymentId,
            PaymentStatus: "Success",
          },
          headers: headers,
        };
        let res = await PostCallApi(seData);
        setWithSubmit(false);

        if (res.status === 200) {
          if (res.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: "Registration Successful",
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            handleReset();
          } else {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res?.data?.message
                ? res?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      }
    } else {
      setPayDetail1(true);
      setPayDetail({ ...sendData, eventFees: subtotal });

      dispatch(
        AttendeeRegistrationConference({ ...sendData, registerAs: "Attendee" })
      );
    }
  };
  const handleReset = () => {
    setPayDetail();
    setConferenceSelect();
    setUserList([]);

    setEvList([]);
    setSeList([]);
    setSeAddList([]);
    setCheckBoxVal(false);
    setPayDetail1(false);
    reset();
  };

  const AddSessionList = (e) => {
    setSeAddList([...seAddList, e]);
    let ead = seList?.map((x) => {
      if (x?.classesId === e?.classesId) {
        return { ...x, add: true };
      }
      return { ...x };
    });
    setSeList(ead);
  };

  const RemoveSessionList = (e) => {
    setSeAddList((oldArray) =>
      oldArray?.filter((data) => data?.classesId !== e?.classesId)
    );
    let ead = seList?.map((x) => {
      if (x?.classesId === e?.classesId) {
        return { ...x, add: false };
      }
      return { ...x };
    });
    setSeList(ead);
  };

  const handleAddEventsToUser = (e, us) => {
    let newArr = FilterList.map((obj) => {
      if (obj.userId === us.userId) {
        return {
          ...obj,
          events: Array.isArray(e) ? e?.map((x) => x?.value) : [],
        };
      }
      return obj;
    });
    setUserList(newArr);
    let nuvList = newArr
      ?.filter((x) => x?.add === true)
      ?.filter((x) => x?.events?.length > 0);
    let evs = [];
    nuvList?.map((x) => {
      if (x?.events) {
        evs.push(...x.events); // Use push for safer mutation
      }
      return 0;
      //     return (evs = [...evs, ...x?.events]);
    });
    let evsave = evList?.map((x) => {
      if (evs?.length > 0) {
        let cn = evs?.filter((y) => y === x?.eventId)?.length;
        return {
          ...x,
          Ql: cn ? cn : 0,
        };
      }
      return { ...x, Ql: 0 };
    });
    setEvList(evsave);
  };

  useEffect(() => {
    handleTotal();
    // pyCC
    // eslint-disable-next-line
  }, [conQt, evList, conPrice]);

  const handleTotal = () => {
    let cp = conQt ? conPrice * parseInt(conQt) : conPrice * 1;
    let ep = evList
      ?.filter((x) => x.Ql)
      ?.map((ev) => ev?.attendeeFees * parseInt(ev?.Ql));
    const sum =
      ep?.length > 0
        ? ep?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    let sm = cp + sum;
    // if (pyCC) {
    //   setPyCCAm(sm * 0.03);
    //   sm = sm + sm * 0.03;
    // }
    setSubTotal(sm);
  };

  const handleChnagePrice = (n) => {
    // console.log(n, d);
    let pr = conferenceSelect?.conferenceAttendeePriceTypeData?.filter(
      (x) => x.conferenceAttendeePriceTypeId === n
    )[0]?.price;
    setConPrice(pr);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {payDetail ? (
        <div className="col-md-12 mt-3">
          {payDetail1 ? (
            <PaymentProcess
              companyData={payDetail}
              conferenceId={conferenceSelect?.conferenceId}
              handleReset={handleReset}
              districtName={districtName}
              districtDetails={districtDetails}
            />
          ) : (
            <>
              {conferenceSelect ? (
                <div className="card" style={{ overflow: "hidden" }}>
                  <div className="card-body p-0">
                    <div
                      className="conference_details"
                      style={{ padding: "1.25rem" }}
                    >
                      <div className="title mb-4 attend-title">
                        Conference Details
                      </div>
                      {conferenceSelect &&
                      (conPrice !== null || conPrice !== undefined) ? (
                        <div className="table-wrap ">
                          <Table className="table-bordered Conference-table">
                            <TableHead className="thead-primary">
                              <StyledTableRow>
                                <StyledTableCell className="txt_st">
                                  Item
                                </StyledTableCell>
                                <StyledTableCell className="txt_st">
                                  Qty
                                </StyledTableCell>
                                <StyledTableCell className="txt_st">
                                  Line Total
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell>
                                  {conferenceSelect?.conferenceName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {conQt ? conQt : 1}
                                </StyledTableCell>
                                <StyledTableCell>
                                  $
                                  {conQt
                                    ? conPrice * parseInt(conQt)
                                    : conPrice * 1}
                                </StyledTableCell>
                              </StyledTableRow>
                              {evList?.filter((x) => x.Ql)?.length > 0
                                ? evList
                                    ?.filter((x) => x.Ql)
                                    ?.map((ev, i) => (
                                      <StyledTableRow key={i}>
                                        <StyledTableCell>
                                          {ev?.eventName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {ev?.Ql}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          ${ev?.attendeeFees * parseInt(ev?.Ql)}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                : ""}

                              <StyledTableRow>
                                <StyledTableCell
                                  style={{
                                    backgroundColor: "white",
                                    textAlign: "right",
                                  }}
                                  colSpan={2}
                                >
                                  <b>Total</b>
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ backgroundColor: "white" }}
                                >
                                  <b>${subtotal}</b>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <ul>
                        <li>
                          {conferenceSelect?.conferenceName}{" "}
                          {userSL ? ` (${userSL} Users Fee)` : ""}
                          <span>
                            {conferenceSelect?.attendeeFees
                              ? userSL
                                ? userSL * conferenceSelect?.attendeeFees
                                : conferenceSelect?.attendeeFees
                              : "0.00"}
                          </span>
                        </li>
                        {evAddList?.map((evitem, i) => (
                          <li key={i}>
                            {evitem?.eventName}{" "}
                            {userSL ? ` (${userSL} Users Fee)` : ""}
                            <span>
                              {evitem?.attendeeFees
                                ? userSL
                                  ? userSL * evitem?.attendeeFees
                                  : evitem?.attendeeFees
                                : "0.00"}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <div className="subtotal">
                        <p>
                          Total <span>{subtotal ? subtotal : "0.00"}</span>
                        </p>
                      </div> */}
                      <div className="mt-3">
                        <Button
                          className="theme_btn"
                          onClick={handleSubmit(FinalPay)}
                        >
                          {withSubmit ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Pay"
                          )}
                        </Button>
                        <Button
                          className="Can_cel_btn ms-3"
                          onClick={() => handleReset()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className="col-md-6 forms_main">
            <div className="form-group">
              <label className="form-label">Conference</label>
              <select
                className="form-select"
                onChange={(e) => getConferenceDetail(e.target.value)}
              >
                <option value="">Select conference</option>
                {conferenceList &&
                  conferenceList?.map((con, i) => (
                    <option value={con.conferenceId} key={con.conferenceId}>
                      {con.conferenceName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6"></div>
          {conferenceSelect ? (
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  Register Type
                  <span className="red-color"> *</span>
                </label>
                {conferenceSelect?.conferenceAttendeePriceTypeData?.map(
                  (pr) => (
                    <div key={pr?.conferenceAttendeePriceTypeId}>
                      <input
                        type="radio"
                        value={pr?.conferenceAttendeePriceTypeId}
                        {...register("orType", {
                          required: "This field is required",
                          onChange: (e) =>
                            handleChnagePrice(e.target.value, pr.price),
                        })}
                      />{" "}
                      {pr?.name}
                    </div>
                  )
                )}
                {errors.orType && (
                  <span role="alert" className="error_text">
                    {errors.orType.message}
                  </span>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {seList?.length === 0 ? (
            ""
          ) : (
            <div className="col-md-6">
              <label className="form-label">Add Session</label>

              <div className="pr_box_gtup">
                {seList &&
                  seList?.map((item, index) => (
                    <div key={index} className="form-group1">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>{item?.classesName}</div>
                        {item?.add === true ? (
                          <>
                            <Button
                              className="grey_button"
                              onClick={() => RemoveSessionList(item)}
                            >
                              <AiOutlineMinus />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="green_button"
                              onClick={() => AddSessionList(item)}
                            >
                              <AiOutlinePlus className="icon" />
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {FilterList?.length > 0 ? (
            <div className="table-wrap mt-5">
              <Table className="table-bordered Conference-table">
                <TableHead className="thead-primary">
                  <StyledTableRow>
                    <StyledTableCell>
                      <label className="">
                        <input
                          type="checkbox"
                          checked={checkBoxVal}
                          onChange={(e) => handleAll(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </StyledTableCell>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Job Title</StyledTableCell>
                    <StyledTableCell>Site/Location</StyledTableCell>
                    <StyledTableCell>Event</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {FilterList?.map((us, id) => (
                    <StyledTableRow className="table_body" key={id}>
                      <StyledTableCell>
                        {us?.isRegistered ? (
                          "Registered"
                        ) : (
                          <label className=" ">
                            <input
                              type="checkbox"
                              checked={us?.add}
                              onChange={(e) =>
                                handleMainCheck(e?.target?.checked, us)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{id + 1}</StyledTableCell>
                      <StyledTableCell>{us?.name}</StyledTableCell>
                      <StyledTableCell>{us?.email}</StyledTableCell>
                      <StyledTableCell>{us?.jobTitle}</StyledTableCell>
                      <StyledTableCell>{us?.siteLocation}</StyledTableCell>
                      <StyledTableCell>
                        {us?.add ? (
                          <Select
                            isMulti
                            name="colors"
                            options={evList?.map((x) => {
                              return {
                                ...x,
                                label: x?.eventName,
                                value: x?.eventId,
                              };
                            })}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Events"
                            value={evList
                              ?.map((x) => {
                                return {
                                  ...x,
                                  label: x?.eventName,
                                  value: x?.eventId,
                                };
                              })
                              .filter((obj) => us?.events?.includes(obj.value))}
                            onChange={(e) => handleAddEventsToUser(e, us)}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            ""
          )}
          {conferenceSelect && (conPrice !== null || conPrice !== undefined) ? (
            <div className="table-wrap mt-5">
              <Table className="table-bordered Conference-table">
                <TableHead className="thead-primary">
                  <StyledTableRow>
                    <StyledTableCell className="txt_st">Item</StyledTableCell>
                    <StyledTableCell className="txt_st">Qty</StyledTableCell>
                    <StyledTableCell className="txt_st">
                      Line Total
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      {conferenceSelect?.conferenceName}
                    </StyledTableCell>
                    <StyledTableCell>{conQt ? conQt : 1}</StyledTableCell>
                    <StyledTableCell>
                      ${conQt ? conPrice * parseInt(conQt) : conPrice * 1}
                    </StyledTableCell>
                  </StyledTableRow>
                  {evList?.filter((x) => x.Ql)?.length > 0
                    ? evList
                        ?.filter((x) => x.Ql)
                        ?.map((ev, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell>{ev?.eventName}</StyledTableCell>
                            <StyledTableCell>{ev?.Ql}</StyledTableCell>
                            <StyledTableCell>
                              ${ev?.attendeeFees * parseInt(ev?.Ql)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    : ""}

                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                        textAlign: "right",
                      }}
                      colSpan={2}
                    >
                      <b>Total</b>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "white" }}>
                      <b>${subtotal}</b>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </div>
          ) : (
            ""
          )}
          <div className="check_box mt-3 ms-0">
            <Button className="connect_all" onClick={sendMultiReq}>
              Register
            </Button>
            {allSerror?.al && (
              <span role="alert" className={`d-block error_text`}>
                {allSerror.al}
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(RegisterList);
