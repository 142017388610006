import React from 'react'
import { Link } from 'react-router-dom';
import Config from '../../../../../../Utils/Config';
import doc from "../../../../../../images/docs.png";
import "./index.scss";

function FreeSourcesTab({ freeSource }) {

    return (
        <>
            <div className="free_sources_tab">
                <div className="row">
                    {freeSource[0]?.resourceData?.map((c, i) => (
                        <div
                            className="col-lg-4 col-md-6 col-12 mb-3"
                            key={i}
                        >
                            <Link
                                key={i}
                                className="view_btn"
                                to={`/free-sources/${c?.resourceId}`}
                            >
                                <div className="district_box">
                                    <div className="d-flex">
                                        <div className="img_box w-25">
                                            <img
                                                src={`${Config.API_HOST_URL_live}${freeSource[0]?.schoolDistrictLogo}`}
                                                alt=""
                                                className="img-fluid"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = doc;
                                                }}
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        <div className="details w-75 ms-3">
                                            <div className="d_boc">
                                                <div className="d_name">
                                                    {c?.title}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="d_boc">
                                            <div className="txt">
                                                {c?.author}
                                            </div>
                                            <div className="txt email">
                                                {freeSource[0]?.schoolDistrictName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                    )}
                </div>
                {freeSource[0]?.resourceData?.length === 0 ? (
                    <div class="h6_text text-center">No Data Found</div>
                ) : (
                    ""
                )}
            </div>
        </>
    )
}

export default FreeSourcesTab