import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import userimg13 from "../../../../../images/user13.png";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const styles = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  border: "1px solid #dddddd",
  borderRadius: "0.5rem",
  padding: "10px",
};

function Invitations(props) {
  const [grpList, setGrpList] = useState([]);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getGrpInList();
    // eslint-disable-next-line
  }, []);
  const getGrpInList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/GetCommunityGroupUsersByStatus?userId=${LogUserId}&status=invited`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const cgList = response.data.payload?.map((item) => {
            return {
              communityGroupId: item?.communityGroupId,
              name: item?.communityGroupName,
              img:
                item?.groupImageUrl === "" || item?.groupImageUrl === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
            };
          });
          setGrpList(cgList);
        }
      });
  };
  const handleReqest = (status, id) => {
    let inData = {
      communityGroupId: id,
      userId: LogUserId,
      status: status,
      createdBy: LogUserId,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/CommunityGroupUser/UpdateCommunityGroupsUser`,
        inData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getGrpInList();
        }
      });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <>
      {props.grid === true ? (
        <>
          {grpList?.map((item, i) => (
            <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-xsm-12 mb-3" key={i}>
              <div className="grid_connection_card text-center" style={styles}>
                <div className="connection_card_text">
                  <img
                    src={item?.img}
                    className="img-fluid mb-3 invi_img"
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                  />
                  <h6>{item?.name}</h6>
                  <p>Group Private</p>
                  {/* <p>Active 16 hours ago</p> */}
                </div>
                <div className="request_connection_card_btn" style={{ marginTop: "auto" }}>
                  <Button
                    fullWidth
                    className="theme_btn"
                    onClick={() =>
                      handleReqest("Accepted", item?.communityGroupId)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    className="Can_cel_btn my-2"
                    fullWidth
                    onClick={() =>
                      handleReqest("Rejected", item?.communityGroupId)
                    }
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_connection_card">
                <div className="connection_card_text d-flex">
                  <img
                    src={item?.img}
                    className="img-fluid invi_img"
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                  />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Group Private</p>
                  </div>
                  {/* <p>Active 16 hours ago</p> */}
                </div>
                <div className="request_connection_card_btn">
                  <Button
                    className="theme_btn"
                    onClick={() =>
                      handleReqest("Accepted", item?.communityGroupId)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    className="Can_cel_btn my-2"
                    onClick={() =>
                      handleReqest("Rejected", item?.communityGroupId)
                    }
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {grpList?.length <= 0 ? (
        <div className="text-center my-5 p_20_text"> No Data Found</div>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(Invitations);
