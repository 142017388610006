import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";
import { useForm } from "react-hook-form";
import ReportCommonTable from "./ReportCommonTable";
import { useSignalR } from "../../../Utils/SignalRContext";

const columns = [
  { id: "col1", headerName: "No" },
  { id: "col2", headerName: "User Name" },
  // { id: "col3", headerName: "Type" },
  { id: "col4", headerName: "Activity Title" },
  { id: "col5", headerName: "Page Name" },
  { id: "col6", headerName: "Description", widthFix: 600 },
  { id: "col7", headerName: "Created On" },
  // { id: "col8", headerName: "Created Time" }
];

function UserActivityReport() {
  let initialDate = new Date().toISOString().split("T")[0];
  const [open, setOpen] = useState(false);
  const [repo, setRepo] = useState([]);
  const [actDrop, setActDrop] = useState([]);
  const [today, setToday] = useState(initialDate);


  const { register, getValues } = useForm();

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setToday(currentDate);
    // eslint-disable-next-line
  }, []);
  
const {connection} = useSignalR();
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setRepo((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LoginUserId = useSelector((state) => state.login.LoginDetails.userId);
  const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getDashData("", today, today, "");
    getUserActivityDrop();
    // eslint-disable-next-line
  }, [today]);

  const getUserActivityDrop = async () => {
    let apiUrl = {
      url: `/api/Common/UserPageActivityTypeDropdown`,
    };

    let resp = await GetCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        setActDrop(resp?.data?.payload ?? []);
      }
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getDashData = async (pt, sd, ed, cn) => {
    setOpen(true);
    const sendData = {
      url: `/api/Report/GetUserActivityReport?userPageActivityTitle=${pt}&startDate=${sd}&endDate=${ed}&courseName=${cn}&userId=${LoginUserId}`,
      headers: headers,
    };
    let response = await GetCallApi(sendData);
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        const dashData = response.data?.payload;
        let userReport = dashData?.map((item, i) => {
          return {
            userId: item?.userId,
            col1: i + 1,
            col2:
              item?.userName === "" || item?.userName === null
                ? ""
                : item?.userName,
            col3: item?.type === "" || item?.type === null ? "" : item?.type,
            col4:
              item?.userPageActivityTitle === "" ||
              item?.userPageActivityTitle === null
                ? ""
                : item?.userPageActivityTitle,
            col5:
              item?.pageName === "" || item?.pageName === null
                ? ""
                : item?.pageName,
            col6:
              item?.description === null || item?.description === ""
                ? ""
                : item?.description,
            col7:
              item?.createdOn === null || item?.createdOn === ""
                ? ""
                : convertDate(item?.createdOn?.split("T")[0]),
            userStatus: item?.userStatus,
            profileUrl: item?.profileUrl,
            // col8: item?.createdOn ?
            //     new Date(item?.createdOn).toLocaleTimeString()
            //     : ""
          };
        });

        setRepo(userReport);
      } else {
        setRepo([]);
        setOpen(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mb-4 listdata_control_sec">
        <label>{ReportMenuName}</label>
        <div className="sep_report_head">
          <div className="grid_top_button_group w-100">
            <div className="row">
              <div className="col-md-3">
                <label className="form-label">Select Activity Type</label>
                <select
                  className="form-select select_drop me-3"
                  {...register("userPageActivityTitle", {
                    onChange: (e) =>
                      getDashData(
                        e?.target?.value,
                        getValues("startDate") ? getValues("startDate") : "",
                        getValues("endDate") ? getValues("endDate") : "",
                        getValues("courseName") ? getValues("courseName") : ""
                      ),
                  })}
                >
                  <option value="">Select Activity Type</option>
                  {actDrop &&
                    actDrop?.map((x, i) => {
                      return (
                        <option value={x?.value} key={i}>
                          {x?.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <label className="form-label">Select Start Date</label>
                <div className="date_pick">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start Date"
                    defaultValue={today}
                    {...register("startDate", {
                      onChange: (e) =>
                        getDashData(
                          getValues("userPageActivityTitle")
                            ? getValues("userPageActivityTitle")
                            : "",
                          e?.target?.value ? e?.target?.value : today,
                          getValues("endDate") ? getValues("endDate") : "",
                          getValues("courseName") ? getValues("courseName") : ""
                        ),
                    })}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label className="form-label">Select End Date</label>
                <div className="date_pick">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    defaultValue={today}
                    {...register("endDate", {
                      onChange: (e) =>
                        getDashData(
                          getValues("userPageActivityTitle")
                            ? getValues("userPageActivityTitle")
                            : "",
                          getValues("startDate") ? getValues("startDate") : "",
                          e?.target?.value ? e?.target?.value : today,
                          getValues("courseName") ? getValues("courseName") : ""
                        ),
                    })}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label className="form-label">Enter Course Name</label>
                <div className="date_pick">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Course Name"
                    {...register("courseName", {
                      onChange: (e) =>
                        getDashData(
                          getValues("userPageActivityTitle")
                            ? getValues("userPageActivityTitle")
                            : "",
                          getValues("startDate") ? getValues("startDate") : "",
                          getValues("endDate") ? getValues("endDate") : "",
                          e?.target?.value
                        ),
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportCommonTable
        headCell={columns}
        data={repo}
        setOpen={setOpen}
        open={open}
        searchShow={true}
        download={true}
      />
    </>
  );
}
export default React.memo(UserActivityReport);
