import React from "react";
import "./Index.scss";

export default function ViewAboutsec(props) {
  return (
    <div className="profile_about_section">
      <h3>About</h3>
      <div className="scroll_box">
        <div className="about_text">{props?.title_text}</div>
        {props?.sub_title_text?.length >0 ?
        <div className="about_detail_box">
          <h3> {props?.sub_title}</h3>
          <p className="about_text_subtitle">{props?.sub_title_text}</p>
        </div>
        :""}
        <div className="about_detail_completed">
          {props?.profileUrl === "" || props?.profileUrl === null ? (
            <div className="incomplete-details_line d-flex justify-content-between mb-3">
              <div className="incomplete_title">
                <img
                  src={props?.incomplete_icon ? props?.incomplete_icon : ""}
                  className="me-3"
                  alt=""
                />
                <p className="incomplete_text">Profile Photo</p>
              </div>
              <div className="incomplete_value">0/1</div>
            </div>
          ) : (
            <div className="complete-details_line d-flex justify-content-between mb-3">
              <div className="complete_title">
                <img
                  src={props?.complete_icon ? props?.complete_icon : ""}
                  className="me-3"
                  alt=""
                />
                <p className="complete_text">Profile Photo</p>
              </div>
              <div className="complete_value">1/1</div>
            </div>
          )}

          {props?.coverPhoto === "" || props?.coverPhoto === null ? (
            <div className="incomplete-details_line d-flex justify-content-between mb-3">
              <div className="incomplete_title">
                <img
                  src={props?.incomplete_icon ? props?.incomplete_icon : ""}
                  className="me-3"
                  alt=""
                />
                <p className="incomplete_text">Cover Photo</p>
              </div>
              <div className="incomplete_value">0/1</div>
            </div>
          ) : (
            <div className="complete-details_line d-flex justify-content-between mb-3">
              <div className="complete_title1">
                <img
                  src={props?.complete_icon ? props?.complete_icon : ""}
                  className="me-3"
                  alt=""
                />
                <p className="complete_text">Cover Photo</p>
              </div>
              <div className="complete_value">1/1</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
