// SignalRContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import Config from "./Config";
import * as signalR from "@microsoft/signalr";

const SignalRContext = createContext();

export const SignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const connectionRef = useRef(null);

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(Config.API_CHAT_URL_live, {
        accessTokenFactory: () => token,
        transport: signalR.HttpTransportType.WebSockets,
        // Use token for authorization
      })
      .withAutomaticReconnect()
      // Reconnect automatically if the connection drops
      .build();

    setConnection(newConnection);

    newConnection
      .start()
      .then(() => {
        console.log("Connected to SignalR");

      })
      .catch((error) => console.error("Connection failed: ", error));

    connectionRef.current = newConnection;

    return () => {
      newConnection.stop();
    };
  }, []);

  return (
    <SignalRContext.Provider value={{ connection }}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = () => {
  return useContext(SignalRContext);
};
