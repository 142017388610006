import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useForm } from "react-hook-form";
import ReportCommonTable from "../ReportCommonTable";
import { StoreGraphData } from "../../../../reducers/setting";
import { useSignalR } from "../../../../Utils/SignalRContext";

const columns = [
  { id: "col1", headerName: "No" },
  { id: "col2", headerName: "Course Title" },
  { id: "col3", headerName: "User Name" },
  { id: "col4", headerName: "Status" },
  { id: "col5", headerName: "Created On" },
];

function DashboardSummaryReport() {
  const [openCallAPi, setOpenCallApi] = useState(false);
  const [open, setOpen] = useState(false);
  const [repo, setRepo] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [schList, setSchList] = useState([]);
  const [acdYList, setAcdYList] = useState([]);
  const [acdYS, setAcdYS] = useState("");
  const [drr, setDrr] = useState("");
  const [schId, setSchId] = useState({
    value: "00000000-0000-0000-0000-000000000000",
    label: "All School District",
  });
  const { register, setValue, getValues } = useForm();

  let dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.setting?.graphData);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const LogUserDe = useSelector((state) => state.login.LoginDetails);
  const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);

  useEffect(() => {
    getCourseList();
    getSchoolList();
    getAcademicYearList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (courseList?.length > 0) {
      if (schList?.length > 0) {
        if (acdYList?.length > 0) {
          if (getStoreData) {
            setOpenCallApi(true);
            let sc = schList?.filter((x) => x.value === getStoreData?.schId);
            setSchId(sc[0]);
            setValue("courseId", getStoreData?.courseId);
            setValue("status", getStoreData?.graphName);
            setAcdYS(getStoreData?.acdYear);
            getGraphData(
              getStoreData?.schId,
              getStoreData?.graphName,
              getStoreData?.acdYear,
              getStoreData?.courseId
            );
            setTimeout(() => {
              dispatch(StoreGraphData());
            }, 200);
          } else {
            if (!openCallAPi) {
              setSchId(schList[0]);
              getGraphData(schList[0]?.value, null, acdYS, null);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [courseList, schList, acdYList, getStoreData]);
  const { connection } = useSignalR();
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setRepo((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);
  const getCourseList = async () => {
    let seData = {
      url: `/api/Common/${
        LogUserDe?.roleName === "Instructor"
          ? `GetCoursesListDropDownByInstructor?userId=${LogUserId}`
          : "GetAllCoursesListDropDown"
      }`,
    };

    let response = await GetCallApi(seData);
    if (response.data.success) {
      const data = response?.data?.payload?.map((item) => {
        return {
          value: item?.courseId,
          label: item?.courseTitle,
        };
      });
      setCourseList(data);
    }
  };

  const getSchoolList = async () => {
    let apiUrl;

    if (LogUserDe?.roleName === "Instructor") {
      apiUrl = `/api/SchoolDistrict/GetAllDistrcitsListForInstructor?userId=${LogUserId}`;
    } else {
      apiUrl = `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId}`;
    }

    let schoolUrl = {
      url: apiUrl,
    };
    const response = await GetCallApi(schoolUrl);
    if (response?.status === 200) {
      if (response.data.success) {
        const data = response?.data.payload?.map((item) => {
          return {
            value: item?.schoolDistrictId,
            label: item?.districtName,
          };
        });
        let schData = [
          {
            value: "00000000-0000-0000-0000-000000000000",
            label: "All School District",
          },
          ...data,
        ];
        setSchList(data?.length > 1 ? schData : data);
      }
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getGraphData = async (schId, status, acdYear, couId) => {
    setOpen(true);
    setRepo([]);
    let seData = {
      status: status ? status : null,
      courseId: couId ? couId : null,
      schoolDistrictId: schId,
      userId: LogUserId,
      academicYearId: parseInt(acdYear),
    };

    let apiUrl = {
      url: `/api/Dashboard/GetParnerAnalyticsCourseListReport`,
      body: seData,
    };

    const resp = await PostCallApi(apiUrl);
    setOpen(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allData = resp?.data?.payload?.map((item, i) => {
          return {
            userId: item?.userId,
            col1: i + 1,
            col2:
              item?.courseTitle === "" || item?.courseTitle === null
                ? ""
                : item?.courseTitle,
            col3:
              item?.userName === "" || item?.userName === null
                ? ""
                : item?.userName,
            col4:
              item?.status === "" || item?.status === null ? "" : item?.status,
            col5:
              item?.createdOn === "" || item?.createdOn === null
                ? ""
                : convertDate(item?.createdOn?.split("T")[0]),
            userStatus: item?.userStatus,
            profileUrl: item?.profileUrl,
          };
        });
        setRepo(allData);
      }
    }
  };

  const getAcademicYearList = async () => {
    let acdUrl = {
      url: `/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
    };
    const response = await GetCallApi(acdUrl);

    if (response?.status === 200) {
      if (response.data.success) {
        const user = response?.data.payload?.map((item, i) => {
          return {
            academicYearId: item?.academicYearId ? item?.academicYearId : "",
            academicYear: item?.academicYear ? item?.academicYear : "",
            isCurrentYear: item?.isCurrentYear ? "True" : "False",
          };
        });
        setAcdYList(user);
        let curtY = user?.filter((x) => x.isCurrentYear === "True");
        setAcdYS(curtY[0]?.academicYearId);
        // if (getStoreData === undefined) {
        //     getGraphData("00000000-0000-0000-0000-000000000000", null, curtY[0]?.academicYearId, null)
        // }
      }
    }
  };

  const handleAcademicYear = (e) => {
    setAcdYS(e);
    if (e) {
      getGraphData(
        schId?.value,
        getValues("status") ? getValues("status") : null,
        e,
        getValues("courseId") ? getValues("courseId") : null
      );
      setDrr("");
    } else {
      setDrr("This field is required");
      setRepo([]);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mb-4 listdata_control_sec">
        <label>{ReportMenuName}</label>
        <div className="sep_report_head">
          <div className="grid_top_button_group w-100">
            <div className="row">
              <div className="col-md-3">
                <div className="date_pick">
                  <div className="date_pick">
                    <label className="form-label">Select Course Status</label>
                    <select
                      className="form-select select_drop"
                      {...register("status", {
                        onChange: (e) =>
                          getGraphData(
                            schId?.value,
                            getValues("status")
                              ? getValues("status")
                              : e?.target?.value,
                            acdYS,
                            getValues("courseId") ? getValues("courseId") : null
                          ),
                      })}
                    >
                      <option value="">Select Course Status</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Reviews">Reviews</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="date_pick">
                  <label className="form-label">Select Course Name</label>
                  <select
                    className="form-select select_drop"
                    {...register("courseId", {
                      onChange: (e) =>
                        getGraphData(
                          schId?.value,
                          getValues("status") ? getValues("status") : null,
                          acdYS,
                          getValues("courseId")
                            ? getValues("courseId")
                            : e?.target?.value
                        ),
                    })}
                  >
                    <option value="">Select Course Name</option>
                    {courseList &&
                      courseList?.map((x, i) => {
                        return (
                          <option value={x?.value} key={i}>
                            {x?.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="date_pick">
                  <label className="form-label">Select Academic Year</label>
                  <select
                    name=""
                    id=""
                    className="form-select select_drop"
                    value={acdYS}
                    onChange={(e) => handleAcademicYear(e.target.value)}
                  >
                    <option value="">Select Academic Year</option>
                    {acdYList &&
                      acdYList?.map((acy, i) => (
                        <option value={acy.academicYearId} key={i}>
                          {acy.academicYear}
                        </option>
                      ))}
                  </select>
                  {drr && (
                    <span role="alert" className="error_text">
                      {drr}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <label className="form-label">Select School</label>
                <Autocomplete
                  disablePortal
                  className="select_school w-100"
                  options={schList}
                  value={schId?.label}
                  onChange={(e, newValue) => {
                    if (newValue === null) {
                      setSchId(schList[0]);
                      setRepo([]);
                    } else {
                      setSchId(newValue);
                      getGraphData(
                        newValue?.value,
                        getValues("status") ? getValues("status") : null,
                        acdYS,
                        getValues("courseId") ? getValues("courseId") : null
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} className="texfiled-demo" />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportCommonTable
        headCell={columns}
        data={repo}
        setOpen={setOpen}
        open={open}
        searchShow={true}
        download={true}
      />
    </>
  );
}
export default React.memo(DashboardSummaryReport);
