import React from "react";
import { Box, Modal } from "@mui/material";
import "./index.scss";
import axios from "axios";
import { DashboardRe, DeleteDataFor } from "../../../../reducers/user";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../../Action/Action";
import { SwalError, SwalSuccess } from "../../../Reuseable_Components/SwalToast";
// import Swal from "sweetalert2";

export default function Delete_popup(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    "justify-content": "center",
    "flex-direction": "column",
  };
  const dispatch = useDispatch();
  let changeDash = useSelector((state) => state.user.Drefresh);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handledelete = async () => {
    dispatch(DeleteDataFor(false));
    setOpenCircle(true);
    let md = props.method;
    if (!md) {
      axios
        .post(`${props.url}`, { headers })
        .then((res) => {
          if (res.data.success) {
            setOpenCircle(false);
            dispatch(DeleteDataFor(true));
            if (changeDash) {
              dispatch(DashboardRe(false));
            } else {
              dispatch(DashboardRe(true));
            }
            handleClose();
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res?.data?.message
                ? res?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      const responce = await PostCallApi({ url: props.url, body: {} })


      if (responce?.status === 200 && responce?.data.success) {
        dispatch(DeleteDataFor(true));
        if (changeDash) {
          dispatch(DashboardRe(false));
        } else {
          dispatch(DashboardRe(true));
        }
        setOpenCircle(false);
        handleClose();
        SwalSuccess(responce.data?.message)
      } else {
        SwalError(responce?.data?.message)
      }

    }
  };

  return (
    <>
      <button className="btn_edit" onClick={handleOpen}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 6H5H21"
            stroke="#FA4747"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
            stroke="#FA4747"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 11V17"
            stroke="#FA4747"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 11V17"
            stroke="#FA4747"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>{props?.title}</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {props?.text}?</span>
                  </p>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handledelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
