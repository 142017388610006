import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.scss";
import { useSelector } from "react-redux";
import Config from "../../../../Utils/Config";
import MemberList from "./MemberList";
import pro_img from "../../../../images/default_profile.jpg";
import { useLocation } from "react-router-dom";
import InviteList from "./InviteList";
// import { CiViewTimeline } from "react-icons/ci";
import { MdOutlineConnectWithoutContact, MdGroups } from "react-icons/md";
import Addpost from "../../../Reuseable_Components/New_Community/Add_post";
import Postshow from "../../../Reuseable_Components/New_Community/Post_show";
import Uploadpost from "../../../Reuseable_Components/New_Community/Upload_post";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { LuListChecks } from "react-icons/lu";
import backimg12 from "../../../../images/group_card_back12.png";
import lockOpen_icon from "../../../../images/Icon/lock-open.png";
import all_icon from "../../../../images/Icon/all_icon.png";
import { CircularProgress } from "@mui/material";
import { HiOutlineLockOpen } from "react-icons/hi2";
import { GoSearch } from "react-icons/go";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";
import { useSignalR } from "../../../../Utils/SignalRContext";
import inactive_img from "../../../../images/Icon/inactive_red.svg";
import active_img from "../../../../images/Icon/active_green.svg";

function CommunityFeedNew({ comGroupId, orgId }) {
  const [open, setOpen] = useState(false);
  const [postList, setPostList] = useState([]);
  const PostC = useSelector((state) => state.user.Post_data);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [likeId, setLikeId] = useState(false);
  const [grpList, setGrpList] = useState([]);
  const [likeByUserId, setLikeByUserId] = useState();
  const [pvtId, setPvtId] = useState(1);
  const [conList, setConList] = useState([]);
  const [searchField, setSearchField] = useState();

  const [grpId, setGrpId] = useState();

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let LoginUserd = useSelector((state) => state.login?.LoginDetails);
  let location = useLocation();
  const { connection } = useSignalR();
// useEffect(() => {
//   const fetchPostList = async (userId, gpid, totalre, pvtId) => {
//     if (connection && connection.state === "Connected") {
//       try {
//         await connection.invoke(
//           "GetCommunityPostDataByUserId",
//           LogUserId,
//           gpid,
//           totalre,
//           pvtId
//         );
//       } catch (err) {
//         console.error("Failed to fetch Post list: ", err);
//       }
//     } else {
//       console.warn(
//         "Connection not established. Current state:",
//         connection?.state
//       );
//     }
//   };
//   if (connection) {
//     // Listen for the "GetAllMessages" event from the backend
//     connection.on("GetCommunityPostDataByUserId", (data) => {
//       console.log(data?.data?.payload);
//     });

//     // Invoke the GetAllMessages method from the backend when necessary



//     // Replace `LogUserId` with the actual user ID from your context
//     if (LogUserId) {
//       let doc = location.pathname?.split("/")[1];
//       let totalre = doc === "community-groups" ? 0 : 20;
//       fetchPostList(
//         likeByUserId ? likeByUserId : LogUserId,
//         grpId?.value === "1" || grpId?.value === "3"
//           ? "00000000-0000-0000-0000-000000000000"
//           : grpId?.value,
//         totalre,
//         pvtId
//       ); // Fetch chat list for the logged-in user
//     }

//     // Cleanup the listener on unmount
//     return () => {
//       connection.off("GetCommunityPostDataByUserId");
//     };
//   }
// }, [connection?.state, comGroupId, PostC, likeId, grpId, likeByUserId]);
useEffect(() => {
  if (connection) {
    connection?.on("UserStatusChanged", (userid, status) => {
      setPostList((old) => {
        return old?.map((x) => {
          if (x?.userId === userid) {
            return {
              ...x,
              userStatus: status ? "Online" : "Offline",
            };
          }
          return x;
        });
      });
    });
  }
}, [connection?.state]);
  useEffect(() => {
    if (grpId) {
      getPostList(
        grpId?.value === "1" || grpId?.value === "3"
          ? "00000000-0000-0000-0000-000000000000"
          : grpId?.value
      );
    }
    // eslint-disable-next-line
  }, [comGroupId, PostC, likeId, grpId, likeByUserId]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getPostList = async (id) => {
    setPostList([]);
    let doc = location.pathname?.split("/")[1];
    let totalre = doc === "community-groups" ? 0 : 20;
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live
    //     }/api/CommunityChat/GetCommunityPostDataByUserId?LikedByUserId=${likeByUserId ? likeByUserId : LogUserId
    //     }&communityGroupId=${id}&totalRecord=${totalre}&privacyTypeId=${pvtId}`,
    //     { headers }
    //   )
    //   .then((responce) => {
    //     if (responce.data.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/CommunityChat/GetCommunityPostDataByUserId?LikedByUserId=${
        likeByUserId ? likeByUserId : LogUserId
      }&communityGroupId=${id}&totalRecord=${totalre}&privacyTypeId=${pvtId}`,
      headers: headers,
    };

    let responce = await GetCallApi(sendData);
    setOpen(false);
    if (responce?.status === 200) {
      if (responce.data.success) {
        const ptList = responce.data.payload?.map((item, i) => {
          return {
            ...item,
            key: i,
            postId: item?.communityPostId,
            name: item?.firstName + " " + item?.lastName,
            userPImg:
              item?.thumbnailProfileUrl === "" ||
              item?.thumbnailProfileUrl === null
                ? pro_img
                : `${Config.API_HOST_URL_live}${item?.thumbnailProfileUrl}`,
            postTypes: item?.postTypes,
            attachments: item.attachments?.map((att, i) => {
              return {
                postId: att?.communityPostId,
                postTransactionId: att?.communityPostTransactionId,
                attachmentUrl: att?.attachmentUrl,
              };
            }),
            description: item?.description,
            date:
              item?.date === "" || item?.date === null
                ? ""
                : convert(item.date?.split("T")[0]),
            userId: item?.createdBy,
            like: item?.isLiked,
            totalLike: item?.totalLike,
            comments: item?.comments,
            totalComment: item?.totalComment,
            isCommunityPost: pvtId === 2 ? false : item?.isCommunityPost,
            communityGroupName: item?.communityGroupName,
            userStatus: item?.userStatus,
          };
        });
        setPostList(ptList);
      } else {
        setPostList([]);
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: responce?.data?.message
            ? responce?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: responce?.data?.message
          ? responce?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }

    //   } else {
    //     setPostList([]);
    //     setOpen(false);
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   setOpen(false);
    // });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }
  const [comId, setComId] = useState("");
  const [CommentReply, setCommentReply] = useState(false);
  const handleComment = (id) => {
    setCommentReply(true);
    setComId(id);
    setPsEdit(false);
    if (id === comId) {
      setCommentReply(!CommentReply);
    }
  };
  const [psEd, setPsEd] = useState("");
  const [psEdit, setPsEdit] = useState(false);
  const handleEdit = (id) => {
    setCommentReply(false);
    setPsEdit(true);
    setPsEd(id);
    if (id === psEd) {
      setPsEdit(!psEdit);
    }
  };
  const filterData = postList?.filter((post) => {
    if (searchField) {
      return post?.description
        ?.toLowerCase()
        ?.includes(searchField.toLowerCase());
    } else return post;
  });
  const [readMore, setReadMore] = useState(false);
  const [readId, setReadId] = useState("");
  const handleReadMore = (id) => {
    setReadMore(true);
    setReadId(id);
    if (id === readId) {
      setReadMore(!readMore);
    }
  };
  const handleReadLess = (id) => {
    if (id === readId) {
      setReadMore(!readMore);
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    getGrpList();
    getListCon();
    // eslint-disable-next-line
  }, []);
  const getGrpList = async () => {
    let url = "";
    if (LogUserde.roleId === 1) {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/CommunityGroupList`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${LogUserId}`;
    }
    let seData = {
      url: `/api/Common/GetAssessTypeByUserId?userId=${LogUserId}`,
      headers: headers,
    };
    let access;
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        access = res?.data.payload?.catypeId;
      }
    }
    axios.get(url, { headers }).then((response) => {
      if (response.data.success) {
        const cgList = response.data.payload?.map((item) => {
          return {
            value: item?.communityGroupId,
            label: item?.communityGroupName,
            img:
              item?.groupImageUrl === "" || item?.groupImageUrl === null
                ? pro_img
                : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
            backimg:
              item?.groupCoverImage === "" || item?.groupCoverImage === null
                ? backimg12
                : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
            organizerUserId: item?.organizerUserId,
          };
        });
        let main = [
          { value: "1", label: "All", img: all_icon },
          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
          ...cgList,
        ];
        if (LoginUserd?.roleId !== 1) {
          if (access === 2) {
            main = main?.filter((x) => x.value !== "1");
            setPvtId(3);
          }
        }
        setGrpId(main[0]);
        setGrpList(main);
      } else {
        let main = [
          { value: "1", label: "All", img: all_icon },

          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
        ];
        if (LoginUserd?.roleId !== 1) {
          if (access === 2) {
            main = main?.filter((x) => x.value !== "1");
            setPvtId(3);
          }
        }
        setGrpList(main);
        setGrpId(main[0]);
        // setOpen(false);
      }
    });
  };
  const getListCon = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyConnectionsList?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const Mylist = response.data.payload?.map((item) => {
            return {
              userId:
                item.userId === null || item.userId === "" ? "" : item.userId,
              sentRequestUserId:
                item.sentRequestUserId === null || item.sentRequestUserId === ""
                  ? ""
                  : item.sentRequestUserId,
              name:
                item?.firstName === "" ||
                item?.firstName === null ||
                item?.lastName === "" ||
                item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
              img:
                item.thumbnailProfileUrl === null ||
                item.thumbnailProfileUrl === ""
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${item.thumbnailProfileUrl}`,
              activeHours:
                item.activeHours !== null || item.activeHours !== ""
                  ? item.activeHours
                  : "0",
              followers:
                item.followers === null || item.followers === ""
                  ? ""
                  : item.followers,
              join:
                item?.userJoinDate === "" || item?.userJoinDate === null
                  ? ""
                  : convert(item?.userJoinDate?.split("T")[0]),
              userStatus: item?.userStatus,
            };
          });
          setConList(Mylist);
        } else {
          setConList([]);
        }
      });
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <div className="community_feed_new">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div className="profile">
              <div className="img_box">
                <div className="user_img">
                  <img
                    src={
                      LoginUserd?.thumbnailProfileUrl === "" ||
                      LoginUserd?.thumbnailProfileUrl === null
                        ? pro_img
                        : `${Config.API_HOST_URL_live}${LoginUserd?.thumbnailProfileUrl}`
                    }
                    alt=""
                    className="user_profile"
                  />
                </div>
                <img
                  src={active_img
                  }
                  alt=""
                  className="img-fluid dot_ac_in"
                />
              </div>
              <div className="info">
                <p>{LoginUserd?.firstName + " " + LoginUserd?.lastName}</p>
                <span>{LoginUserd?.roleName}</span>
              </div>
            </div>
            <div className="search_box">
              <GoSearch className="icon" />
              <input
                type="search"
                className="form-control search"
                onChange={(e) => setSearchField(e.target.value)}
                placeholder="Search Feed"
              />
            </div>
            <div className="left_side_nav">
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                className="accordion"
              >
                <AccordionSummary
                  expandIcon={<BsChevronDown />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <div className="icon">
                    <MdOutlineConnectWithoutContact />
                  </div>
                  <span>Connections</span>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    {conList?.map((option, i) => (
                      <li
                        className={`${
                          option?.value === grpId?.value ? "active" : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setLikeByUserId(option?.userId);
                          setPvtId(3);
                        }}
                      >
                        <div className="img_box">
                          <div className="user_img">
                            <img
                              loading="lazy"
                              width="20"
                              src={option?.img ? option?.img : pro_img}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = pro_img;
                              }}
                              className="img-fluid post_u_img"
                              alt=""
                            />
                          </div>
                          <img
                            src={
                              option?.userStatus === "Online"
                                ? active_img
                                : ""
                            }
                            alt=""
                            className="img-fluid dot_ac_in"
                          />
                        </div>
                        {option.name}
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                className="accordion"
              >
                <AccordionSummary
                  expandIcon={<BsChevronDown />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <div className="icon">
                    <MdGroups />
                  </div>
                  <span>Groups</span>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    {grpList?.map((option, i) => (
                      <li
                        className={`${
                          option?.value === grpId?.value ? "active" : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setGrpId(option);
                          setPvtId(
                            option?.value === "1"
                              ? 1
                              : option?.value === "3"
                              ? 3
                              : 2
                          );
                        }}
                      >
                        {option?.value === "1" ? (
                          <LuListChecks className="icon" />
                        ) : option?.value === "3" ? (
                          <HiOutlineLockOpen className="icon" />
                        ) : (
                          <img
                            loading="lazy"
                            width="30"
                            src={option?.img ? option?.img : pro_img}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = pro_img;
                            }}
                            alt=""
                          />
                        )}
                        {option.label}
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 col-md-12">
            <div className="post_div">
              <Addpost
                pvtId={pvtId}
                grpList={grpList}
                comGroupId={
                  grpId?.value === "1" || grpId?.value === "3"
                    ? "00000000-0000-0000-0000-000000000000"
                    : grpId?.value
                }
              />
              <div className="commented_post">
                {open && (
                  <div className="post_loader">
                    <CircularProgress sx={{ color: "black" }} />
                  </div>
                )}

                {filterData?.map((item, i) => (
                  <Postshow
                    comGroupId={
                      grpId?.value === "1" || grpId?.value === "3"
                        ? "00000000-0000-0000-0000-000000000000"
                        : grpId?.value
                    }
                    key={i}
                    data={item}
                    likeId={likeId}
                    setLikeId={setLikeId}
                    comId={comId}
                    CommentReply={CommentReply}
                    handleComment={handleComment}
                    setCommentReply={setCommentReply}
                    psEd={psEd}
                    psEdit={psEdit}
                    handleEdit={handleEdit}
                    setPsEdit={setPsEdit}
                    handleReadMore={handleReadMore}
                    readMore={readMore}
                    readId={readId}
                    handleReadLess={handleReadLess}
                  />
                ))}
                {postList?.length === 0 ? (
                  <div className="text-center p_20_text mb-3">
                    No Post Found
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12">
            {location?.pathname === "/community" ? (
              <Uploadpost conList={conList} />
            ) : (
              <>
                {orgId === LogUserId || LogUserde?.roleId === 1 ? (
                  <InviteList comGroupId={comGroupId} />
                ) : (
                  ""
                )}
                <MemberList comGroupId={comGroupId} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(CommunityFeedNew);
