import React, { useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import "./index.scss";
import CreateReview from "./CreateReview";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
function RatingPopup(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button
                onClick={handleOpen}
                className={`theme_btn mr-5`}
            >
                Write A Review
            </Button>
            {open && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="popup_boxes"
                >
                    <Box
                        sx={style}
                        className={`popup_wh ${props?.btnName === "Add Manual CEU" ? "width_100" : "width_600"
                            } ${props?.btnName === "Delete CEU" ? "delete_ceu_pop" : ""}`}
                    >
                        <h4
                            className={`pupup_title ${props.btnName === "Recipe Review"
                                ? "d-flex justify-content-between"
                                : ""
                                }`}
                        >
                            Write A Review
                            {props.btnName === "Recipe Review" ? (
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="my-auto"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleClose}
                                >
                                    <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#paint0_linear_975_13562)"
                                    />
                                    <path
                                        d="M16.0579 14.9992L19.2829 11.7817C19.4242 11.6404 19.5035 11.4489 19.5035 11.2492C19.5035 11.0494 19.4242 10.8579 19.2829 10.7167C19.1417 10.5754 18.9502 10.4961 18.7504 10.4961C18.5507 10.4961 18.3592 10.5754 18.2179 10.7167L15.0004 13.9417L11.7829 10.7167C11.6417 10.5754 11.4502 10.4961 11.2504 10.4961C11.0507 10.4961 10.8592 10.5754 10.7179 10.7167C10.5767 10.8579 10.4974 11.0494 10.4974 11.2492C10.4974 11.4489 10.5767 11.6404 10.7179 11.7817L13.9429 14.9992L10.7179 18.2167C10.6476 18.2864 10.5919 18.3693 10.5538 18.4607C10.5157 18.5521 10.4961 18.6502 10.4961 18.7492C10.4961 18.8482 10.5157 18.9462 10.5538 19.0376C10.5919 19.129 10.6476 19.2119 10.7179 19.2817C10.7877 19.352 10.8706 19.4078 10.962 19.4458C11.0534 19.4839 11.1514 19.5035 11.2504 19.5035C11.3495 19.5035 11.4475 19.4839 11.5389 19.4458C11.6303 19.4078 11.7132 19.352 11.7829 19.2817L15.0004 16.0567L18.2179 19.2817C18.2877 19.352 18.3706 19.4078 18.462 19.4458C18.5534 19.4839 18.6514 19.5035 18.7504 19.5035C18.8495 19.5035 18.9475 19.4839 19.0389 19.4458C19.1303 19.4078 19.2132 19.352 19.2829 19.2817C19.3532 19.2119 19.409 19.129 19.4471 19.0376C19.4852 18.9462 19.5048 18.8482 19.5048 18.7492C19.5048 18.6502 19.4852 18.5521 19.4471 18.4607C19.409 18.3693 19.3532 18.2864 19.2829 18.2167L16.0579 14.9992Z"
                                        fill="white"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_975_13562"
                                            x1="6.62596e-07"
                                            y1="15"
                                            x2="19.3714"
                                            y2="29.3489"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#1EBEC8" />
                                            <stop offset="1" stopColor="#86C65A" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            ) : (
                                ""
                            )}
                        </h4>
                        <div className="pupup_input_box">
                            {props.btnName === "Recipe Review" && (
                                <CreateReview handleClose={handleClose} ReId={props?.recipeId} getDataRecipeById={props?.getDataRecipeById} />
                            )}
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    );
}

export default React.memo(RatingPopup);
