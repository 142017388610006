import React, { useEffect, useState } from "react";
import "./index.scss";
import pro_img from "../../../images/default_profile.jpg";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import cross_mes_up from "../../../images/Icon/cross_mes_up_gre.svg";
import like from "../../../images/Like.png";
import like_ic from "../../../images/Liked_ic.png";
import comment from "../../../images/Comment.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import delete_icon from "../../../images/Delete.svg";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import send_icon from "../../../images/Icon/send_mes.svg";
import Comment from "../Comment";
import Swal from "sweetalert2";
import BlockReport from "./BlockReport";
import PostListPop from "./postListPop";
import { SwalError, SwalSuccess } from "../SwalToast";
import { PostCallApi } from "../../../Action/Action";

const options = ["Block & Report"];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Post_show(props) {
  const {
    data,
    likeId,
    setLikeId,
    comId,
    CommentReply,
    handleComment,
    setCommentReply,
    handleEdit,
    psEd,
    setPsEdit,
    psEdit,
    comGroupId,
    handleReadMore,
    readMore,
    readId,
    handleReadLess,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElE, setAnchorElE] = React.useState(null);
  const open = Boolean(anchorEl);
  const openE = Boolean(anchorElE);
  const [open1, setOpen] = React.useState(false);
  const [suDEPT, setSuDEPT] = React.useState(false);
  const [openIV, setOpenIV] = React.useState(false);
  const [openCir, setOpenCir] = useState(false);
  const [psDe, setPsDe] = useState("");
  const [pstDesc, setPstDesc] = useState("");
  const [blockPost, setBlockPost] = useState(false);
  const [imgVidDe, setImgVidDe] = useState({});

  const [derror, setDerror] = useState();

  const handleClick = (event, id) => {
    if (id === LogUserId) {
      setAnchorElE(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClick1 = (event) => {
    setPsDe(event);
    setOpen(true);
    handleClose();
  };
  const handleCloseSuAdPt = (event) => {
    setSuDEPT(false);
  };
  const handleClickSuAdPt = (event) => {
    setPsDe(event);
    setSuDEPT(true);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElE(null);
  };
  const handleClose1 = (id) => {
    setOpen(false);
    if (id === 1) {
      setBlockPost(true);
    }
  };
  const handleCloseBlock = () => {
    setBlockPost(false);
  };
  const handleOpenDeI = (e, pId, ptId) => {
    setImgVidDe({ e: e, pId: pId, ptId: ptId });
    setOpenIV(true);
  };
  const handleCloseDeI = () => {
    setOpenIV(false);
  };
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);

  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const HandleLike = (id, lid) => {
    let likeData = {};
    let url = "";
    // if (comGroupId) {
    url = `${Config.API_HOST_URL_live}/api/CommunityLike/CreateCommunityLike`;
    likeData = {
      communityPostId: id,
      isLiked: lid ? false : true,
      likedByUserId: LogUserId,
    };
    // } else {
    //   url = `${Config.API_HOST_URL_live}/api/Like/CreateLike`;
    //   likeData = {
    //     postId: id,
    //     isLiked: lid ? false : true,
    //     likedByUserId: LogUserId,
    //   };
    // }
    axios
      .post(url, likeData, {
        headers,
      })
      .then((responce) => {
        if (responce.data.success) {
          if (likeId) {
            setLikeId(false);
          } else {
            setLikeId(true);
          }
        }
      });
  };
  const handleDelete = async () => {
    setOpenCir(true);
    let url = `${Config.API_HOST_URL_live}/api/CommunityChat/DeleteCommunityPost?communityPostId=${psDe}&userId=${LogUserId}`;

    const responce = await PostCallApi({ url: url, body: {} })
    if (responce?.status === 200 && responce?.data.success) {
      setOpenCir(false);
      setOpen(false);
      setPsDe("");
      handleCloseSuAdPt();
      if (likeId) {
        setLikeId(false);
      } else {
        setLikeId(true);
      }
      SwalSuccess(responce.data?.message)
    } else {
      SwalError(responce?.data?.message)
      setOpenCir(false);
    }


  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const RemoveImage = async (e, pId, ptId) => {
    setOpenCir(true);
    // let md = "post";
    let url = `/api/CommunityChat/DeleteCommunityPostFile?communityPostId=${pId}&communityPostTransactionId=${ptId}`;

    const responce = await PostCallApi({ url: url, body: {} })
    setOpenCir(false);
      if (responce?.status === 200 && responce?.data.success) {
        setOpen(false);
            setPsDe("");
            setPsEdit(false);
            handleCloseDeI();
            if (likeId) {
              setLikeId(false);
            } else {
              setLikeId(true);
            }
        SwalSuccess(responce.data?.message)
        setDerror({ delete: "" });
      } else {
        SwalError(responce?.data?.message)
        setDerror({ delete: responce.data?.message });
      }
    // }
  };

  useEffect(() => {
    if (psEd === data?.postId && psEdit) {
      setPstDesc(data?.description);
    }
  }, [data, psEd, psEdit]);
  const handlePostUpdate = (id, pvtId, postType) => {
    if (pstDesc === "") {
      setDerror({ Des: "This field is required" });
    } else {
      setDerror({ Des: "" });
      setOpenCir(true);
      const productData = new FormData();
      productData.append(
        "PrivacyTypeId",
        pvtId === "Public" ? 1 : pvtId === "Only Me" ? 2 : 3
      );
      productData.append(
        "PostTypeId",
        postType === "Image" ? 1 : postType === "Description" ? 3 : 2
      );

      productData.append("description", pstDesc);
      productData.append("UserId", LogUserId);
      productData.append("createdBy", LogUserId);
      let url = "";
      url = `${Config.API_HOST_URL_live}/api/CommunityChat/CreateCommunityPost`;
      productData.append("CommunityGroupId", comGroupId);
      productData.append("communityPostId", id);

      axios
        .post(url, productData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setOpenCir(false);
            handleEdit(data?.postId);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(response.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (likeId) {
              setLikeId(false);
            } else {
              setLikeId(true);
            }
          } else {
            setOpenCir(false);
          }
        });
    }
  };
  const [likePop, setLikePop] = useState();
  const handleLikePop = (d) => {
    setLikePop(d);
  };
  return (
    <React.Fragment key={data?.key ? data?.key : data?.postId}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => 99999 }} open={openCir}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="share_comment_box"
        key={data?.key ? data?.key : data?.postId}
      >
        <div className="d-flex px-3 mb-3">
          <img
            src={data.userPImg !== "" ? data.userPImg : pro_img}
            className="img-fluid post_u_img me-3 m-auto"
            alt=""
          />
          <div className="last_comment w-100">
            <div className="comment_title d-flex">
              <p>
                <b>
                  {data?.name}{" "}
                  {data?.isCommunityPost ? (
                    <>posted an update in {data?.communityGroupName} </>
                  ) : (
                    ""
                  )}
                </b>{" "}
                {data?.text}
              </p>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, data?.userId)}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
            <span className="text-muted">{data?.date}</span>
          </div>
        </div>
        {/* {data.goal && (
          <div className="d-flex goal-label mb-3">
            <label>Meditate for 30 Minutes</label>
            <Link>View Goal</Link>
          </div>
        )} */}
        {data.attachments?.length > 0 && (
          <>
            <div className="row mx-0 px-3">
              {data.attachments?.map((item, i) => {
                if (i <= 4 && item.postId === data?.postId) {
                  return (
                    <div
                      className={`${data.attachments?.length === 1
                        ? "col-md-12"
                        : data.attachments?.length === 2
                          ? "col-md-6"
                          : "col-md-4"
                        }  mb-3 `}
                    >
                      <div className="img_box">
                        {psEd === data?.postId && psEdit ? (
                          <img
                            src={delete_icon}
                            alt=""
                            className="delete_btn"
                            onClick={(e) =>
                              handleOpenDeI(
                                i,
                                data?.postId,
                                item?.postTransactionId
                              )
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {data?.postTypes === "Image" ||
                        data?.postTypes === "Goal" ? (
                        <div
                          className={`${data.attachments?.length === 1
                            ? "img_h_full"
                            : data.attachments?.length === 2
                              ? "img_h_half"
                              : "img_h_200"
                            }`}
                        >
                          <img
                            src={
                              item?.attachmentUrl === "" ||
                                item?.attachmentUrl === null
                                ? pro_img
                                : `${Config.API_HOST_URL_live}${item.attachmentUrl}`
                            }
                            alt=""
                            className={`img-fluid pst_img 
                            ${data.attachments?.length === 1
                                ? "h_full"
                                : data.attachments?.length === 2
                                  ? "h_half"
                                  : "h_200"
                              }`}
                          />
                        </div>
                      ) : (
                        <video width="100%" height="280" controls>
                          <source
                            src={
                              item?.attachmentUrl === "" ||
                                item?.attachmentUrl === null
                                ? ""
                                : `${Config.API_HOST_URL_live}${item.attachmentUrl}`
                            }
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>
                  );
                }
                return "";
              })}
            </div>
          </>
        )}
        {data?.postTypes === "Goal" ? (
          <div className="post_des ms-3 mb-2 px-3">
            <b>{data.goalName}</b>
          </div>
        ) : (
          ""
        )}
        {psEd === data?.postId && psEdit ? (
          <div className="ms-3 pst_update_box">
            <input
              type="text"
              value={pstDesc}
              className="input_box"
              onChange={(e) => setPstDesc(e.target?.value)}
            />
            <IconButton
              onClick={() =>
                handlePostUpdate(
                  data?.postId,
                  data?.privacyTypeName,
                  data?.postTypes
                )
              }
            >
              <img src={send_icon} alt="" className="img-fluid btn_update_c" />
            </IconButton>
            <IconButton onClick={() => handleEdit(data?.postId)}>
              <img
                src={cross_mes_up}
                alt=""
                className="img-fluid btn_update_c"
              />
            </IconButton>
          </div>
        ) : (
          <div className="post_des ms-3 mb-3 px-3">
            {readId === data?.postId && readMore ? (
              <>
                {data?.description}{" "}
                <span
                  className="description "
                  onClick={() => handleReadLess(data?.postId)}
                >
                  ...read Less
                </span>
              </>
            ) : (
              <>
                {data?.description?.slice(0, 220)}{" "}
                {data?.description?.length > 220 && (
                  <span
                    className="description "
                    onClick={() => handleReadMore(data?.postId)}
                  >
                    ...read more
                  </span>
                )}
              </>
            )}
          </div>
        )}
        {derror?.Des && (
          <span role="alert" className="ms-3 error_text">
            {derror?.Des}
          </span>
        )}
        <div className="react_action_button px-3">
          <span>
            <IconButton onClick={() => HandleLike(data?.postId, data?.like)}>
              <img
                src={data?.like ? like_ic : like}
                className="img-fluid"
                alt=""
              />
            </IconButton>
            <span
              onClick={() => handleLikePop(data)}
              style={{ cursor: `${data?.totalLike === 0 ? "" : "pointer"}` }}
            >
              {data?.totalLike === 0 ? "" : data?.totalLike}{" "}
              {data?.like ? "UnLike" : "Like"}
            </span>
          </span>
          <span
            onClick={() => handleComment(data?.postId)}
            style={{ cursor: "pointer" }}
          >
            <IconButton>
              <img src={comment} className="img-fluid" alt="" />
            </IconButton>
            {data?.totalComment === 0 ? "" : data?.totalComment} Comment
          </span>
        </div>
        {comId === data?.postId && CommentReply ? (
          <Comment
            comGroupId={comGroupId}
            postId={data?.postId}
            setCommentReply={setCommentReply}
            comList={data?.comments}
            likeId={likeId}
            setLikeId={setLikeId}
          />
        ) : (
          <></>
        )}
      </div>
      {data.userId === LogUserId ? (
        <Menu
          className="blockreport_box"
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorElE}
          open={openE}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleEdit(data?.postId);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => handleClick1(data?.postId)}>Delete</MenuItem>
        </Menu>
      ) : LoginUserde.roleId === 1 ? (
        <Menu
          className="blockreport_box"
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options?.map((option) => (
            <MenuItem key={option} onClick={() => handleClick1(data?.postId)}>
              {option}
            </MenuItem>
          ))}
          <MenuItem onClick={() => handleClickSuAdPt(data?.postId)}>
            Delete
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          className="blockreport_box"
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options?.map((option) => (
            <MenuItem key={option} onClick={() => handleClick1(data?.postId)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}

      <BootstrapDialog
        onClose={handleCloseDeI}
        aria-labelledby="customized-dialog-title"
        open={openIV}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDeI}
          dividers
        >
          Delete {data?.postTypes === "Image" ? "Image" : "Video"}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            Please confirm you want to delete this{" "}
            {data?.postTypes === "Image" ? "Image" : "Video"}.
          </Typography>
          <Typography>
            <b>Please note:</b>
            This action will remove{" "}
            {data?.postTypes === "Image" ? "Image" : "Video"} from your post.
          </Typography>
          {derror?.delete && (
            <span role="alert" className="ms-3 error_text">
              {derror?.delete}
            </span>
          )}
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button
            className="w-25 theme_button me-2"
            onClick={() =>
              RemoveImage(imgVidDe?.e, imgVidDe?.pId, imgVidDe?.ptId)
            }
          >
            Confirm
          </Button>

          <Button autoFocus onClick={handleCloseDeI} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose1}
          dividers
        >
          {data?.userId === LogUserId ? "Delete Post" : "Block & Report Post?"}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            Please confirm you want to{" "}
            {data?.userId !== LogUserId ? "Block" : "Delete"} this Post.
          </Typography>
          <Typography>
            <b>Please note:</b>
            {data?.userId !== LogUserId
              ? " This action will also remove this member from your connections and send a report to the site admin. Please allow a few minutes for this process to complete."
              : " This action will also remove this post from your timeline. "}
          </Typography>
        </DialogContent>
        <DialogActions className="popup_btn">
          {data?.userId !== LogUserId ? (
            <Button
              className="w-25 theme_button me-2"
              onClick={() => handleClose1(1)}
            >
              Confirm
            </Button>
          ) : (
            <Button className="w-25 theme_button me-2" onClick={handleDelete}>
              Confirm
            </Button>
          )}
          <Button autoFocus onClick={handleClose1} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleCloseSuAdPt}
        aria-labelledby="customized-dialog-title"
        open={suDEPT}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseSuAdPt}
          dividers
        >
          Delete Post
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>Please confirm you want to Delete this Post.</Typography>
          <Typography>
            <b>Please note:</b>
            This action will also remove this post from your timeline.
          </Typography>
          {derror?.delete && (
            <span role="alert" className="ms-3 error_text">
              {derror?.delete}
            </span>
          )}
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2" onClick={handleDelete}>
            Confirm
          </Button>
          <Button autoFocus onClick={handleCloseSuAdPt} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BlockReport
        blockPost={blockPost}
        postId={data?.postId}
        openCir={openCir}
        setOpenCir={setOpenCir}
        handleCloseBlock={handleCloseBlock}
        comGroupId={comGroupId}
      />
      {likePop?.postId !== undefined ? (
        <PostListPop pdata={likePop} setLikePop={setLikePop} />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default React.memo(Post_show);
