import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Login_img from "../../../images/login_logo.png";
import close_icon from "../../../images/Icon/eye.svg";
import open_icon from "../../../images/Icon/eye-off.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../Utils/Config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import "./index.scss";
const renderLoader = () => <p></p>;

function ResetPasswordLogged() {
  const [derror, setDerror] = useState();
  const [open, setOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  let navigate = useNavigate();
  useEffect(() => {
    setValue("email", LoginUserde?.email);
    // eslint-disable-next-line
  }, []);
  const onSubmit = (data) => {
    setOpen(true);
    axios
      .post(`${Config.API_HOST_URL_live}/api/Login/ResetMyPassword`, data, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          setOpen(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          navigate("/dashboard", { replace: true });
        } else {
          setDerror({ Log: response.data?.message });
          setOpen(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="login_reset">
        <div className="row m-auto">
          <div className="col-md-12 p-0 d-flex">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="left_logo">
                <img className="img-fluid" src={Login_img} alt="" />
              </div>
              <div className="logo_details">
                <h3>Reset Password</h3>
                <div className="form-group login_input_box position-relative">
                  <label className="form-label ">Old Password</label>
                  <input
                    type={passwordShown2 ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Your New Password"
                    {...register("currentPassword", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                    })}
                  />

                  <span
                    className="eye"
                    onClick={() => setPasswordShown2(!passwordShown2)}
                  >
                    <img
                      src={passwordShown2 === true ? close_icon : open_icon}
                      alt="eye"
                    />
                  </span>
                  {errors.currentPassword && (
                    <span role="alert" className="error_text">
                      {errors.currentPassword.message}
                    </span>
                  )}
                </div>
                <div className="form-group login_input_box position-relative">
                  <label className="form-label ">New Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Your New Password"
                    {...register("newPassword", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                      validate: (val) => {
                        if (watch("currentPassword") === val) {
                          return "Your old password and new password match";
                        }
                      },
                    })}
                  />

                  <span
                    className="eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  >
                    <img
                      src={passwordShown === true ? close_icon : open_icon}
                      alt="eye"
                    />
                  </span>
                  {errors.newPassword && (
                    <span role="alert" className="error_text">
                      {errors.newPassword.message}
                    </span>
                  )}
                </div>
                <div className="form-group login_input_box position-relative">
                  <label className="form-label ">Confirm Password</label>
                  <input
                    type={passwordShown1 ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Max 15 characters",
                      },
                      validate: (val) => {
                        if (watch("newPassword") !== val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                  />
                  <span
                    className="eye"
                    onClick={() => setPasswordShown1(!passwordShown1)}
                  >
                    <img
                      src={passwordShown1 === true ? close_icon : open_icon}
                      alt="eye"
                    />
                  </span>
                  {errors.confirmPassword && (
                    <span role="alert" className="error_text">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
                <div>
                  <input type="submit" value="Save" className="login_button" />
                  {derror?.Log && (
                    <span role="alert" className="d-block error_text">
                      {derror.Log}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default React.memo(ResetPasswordLogged);
