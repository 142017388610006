import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import Quizstart from "./CourseRightStart/Quiz_start";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";

const CourceDetails = lazy(() => import("./CourceDetails"));
const CourseRightStart = lazy(() => import("./CourseRightStart"));
const renderLoader = () => <p></p>;

function CompleteCource() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [quizData, setQuizData] = useState(false);
  const [authorProfile, setAuthorProfile] = useState({});
  const [quizList, setQuizList] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [conId, setConId] = useState("");
  const [quizId, setQuizId] = useState("");
  let location = useLocation();
  let nav = useNavigate();
  useEffect(() => {
    CeuCourseDetails();
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const CeuCourseDetails = async () => {
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);


    const sendData = {
      url: `/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        let ceuCourseD = response.data?.payload;
        if (ceuCourseD?.courseStatus === "Completed") {
          let contentList = ceuCourseD?.content?.map((item) => {
            return {
              contentId: item?.contentId,
              title: item?.lessionTitle,
              video: item?.lessionVideoUrl,
              videoLength: item?.videoLength,
              description: item?.lessionDescription,
              status: item?.status,
              sequenceNo: item?.sequenceNo,
              contentMaterials: item?.contentMaterials,
              contentType: item?.contentType,
              imageUrl: item?.imageUrl,
              lLabel: item?.linkLable,
              lUrl: item?.linkLableUrl,
            };
          });
          let ceuDes = {
            id: ceuCourseD?.courseId,
            title: ceuCourseD?.courseTitle,
            coach:
              "Coach" +
              " " +
              ceuCourseD?.instructor?.firstName +
              " " +
              ceuCourseD?.instructor?.lastName,
            ceuReq: ceuCourseD?.totalCEU + " CEU",
            description: ceuCourseD?.description,
            // imageOrVideoUrl:
            //   ceuCourseD?.imageOrVideoUrl === "" ||
            //     ceuCourseD?.imageOrVideoUrl === null
            //     ? cardimg1
            //     : `${Config.API_HOST_URL_live}${ceuCourseD?.imageOrVideoUrl}`,
            imageOrVideoUrl:
              ceuCourseD?.thumbnailCourseUrl === "" ||
                ceuCourseD?.thumbnailCourseUrl === null
                ? cardimg1
                : `${Config.API_HOST_URL_live}${ceuCourseD?.thumbnailCourseUrl}`,
            rating: ceuCourseD?.courseRating ? ceuCourseD?.courseRating : "0",
            courseProgress: ceuCourseD?.courseProgress
              ? ceuCourseD?.courseProgress
              : "0",
          };
          let quiList = ceuCourseD?.quiz?.map((item) => {
            return {
              quizId: item?.quizId,
              quizName: item?.quizName,
              quizDescription: item?.quizDescription,
              quizQuestion: item?.quizQuestion,
              status: item?.status,
            };
          });
          setQuizList(quiList);
          setCourseDetails(ceuDes);
          setCourseContent(contentList);
          setAuthorProfile(ceuCourseD?.instructor);
          setConId(contentList[0]?.contentId)
        } else {
          nav(
            `/${location.pathname?.split("/")[1]}/${ceuCourseD?.courseId}`,
            {
              replace: true,
            }
          );
        }
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpen(false)
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // } else {
    //   setOpen(false);
    // }
    // });
  };

  return (
    <Suspense fallback={renderLoader()}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="ceu_cource_page main_backcolor">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="ceu_couce_title">{courseDetails?.title}</div>
            <div className="course_start_page">
              <div className="row m-0 ">
                <div className="col-md-4 col-xxl-4  p-0">
                  <CourceDetails
                    authorProfile={authorProfile}
                    courseList={courseContent}
                    quizList={quizList}
                    setQuizData={setQuizData}
                    setConId={setConId}
                    setQuizId={setQuizId}
                    rating={courseDetails?.rating}
                    courseProgress={courseDetails?.courseProgress}
                  />
                </div>
                <div className="col-md-8 col-xxl-8 p-0">
                  {quizData === false ? (
                    <CourseRightStart
                      conId={conId}
                      courseList={courseContent}
                      mainTitle={courseDetails?.title}
                      materialList={quizList}
                      quizData={quizData}
                      setConId={setConId}
                      CourseID={id}
                    />
                  ) : (
                    <Quizstart
                      quizId={quizId}
                      mainTitle={courseDetails?.title}
                      quizList={quizList}
                      CourseID={id}
                      setQuizId={setQuizId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default React.memo(CompleteCource);
