import React, { useState } from "react";
import "./index.scss";
import ConnectionList from "./Components/Connection_list";
import Requests from "./Components/Connection_list/requests";
import active_list from "../../../images/active_list.png";
import inactive_list from "../../../images/inactive_list.png";
import active_grid from "../../../images/active_grid.png";
import inactive_grid from "../../../images/inactive_grid.png";

import { Button } from "@mui/material";

function Connections() {
  const [grid, setGrid] = useState(true);
  const [connectionActive, setconnectionActive] = useState(true);
  const [searchField, setSearchField] = useState();

  return (
    <>
      <div className="row m-0">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex">
            <div className="leftside_action">
              <Button
                onClick={() => setconnectionActive(true)}
                className={
                  connectionActive === true ? "theme_button me-3" : "me-3"
                }
              >
                My Connections
              </Button>
              <Button
                onClick={() => setconnectionActive(false)}
                className={
                  connectionActive === false ? "theme_button me-3" : "me-3"
                }
              >
                Requests
              </Button>
            </div>
            {connectionActive && (
              <div className="searc_box">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search By Name"
                  onChange={(e) => setSearchField(e.target.value)}
                />
              </div>
            )}
            <div className="rightside_action">
              {/* <select class="form-select me-3">
                                <option selected value="1">Recently Active</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select> */}
              <Button className="grid_btn" onClick={() => setGrid(true)}>
                <img
                  src={grid === true ? active_grid : inactive_grid}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
              <Button className="list_btn" onClick={() => setGrid(false)}>
                <img
                  src={grid === false ? active_list : inactive_list}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
            </div>
          </div>
        </div>
        {connectionActive === true ? (
          <>
            <ConnectionList grid={grid} searchField={searchField} />
          </>
        ) : (
          <>
            <Requests grid={grid} />
          </>
        )}
      </div>
    </>
  );
}
export default React.memo(Connections);
