import React from "react";
import "./totalcount.scss";
function Totalcount(props) {
  return (
    <div className={`col-xl-4 col-lg-4 col-12 total_count`} key={props?.number}>
      <div className={`totalitem `}>
        <div className="totalitemcontent">
          <div className="totalitem_title">{props?.number}</div>
          <div className="totalitem_desc ">{props?.desc}</div>
        </div>
        <div className="totalicon">
          <img src={props?.img} alt="..." />
        </div>
      </div>
    </div>
  );
}

export default React.memo(Totalcount);
