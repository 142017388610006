import React, { useEffect, useState } from "react";
// import Circulerprogress from "../../../../Reuseable_Components/Circuler_progress";
import "./index.scss";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function CourceProgress(props) {
  // const { ceuCardData } = props;
  const [ayId, setAyId] = useState();
  const [courseCertificate, setCourseCertificate] = useState([]);
  const [courseDoc, setCourseDoc] = useState([]);
  const [dashcerti, setDashcerti] = useState([]);
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getAcademicYear();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (ayId) {
      getCertificateList();
    }
    // eslint-disable-next-line
  }, [ayId]);
  const getAcademicYear = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetCurrentAcademicYear`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let ayId = res.data.payload[0]?.academicYearId;
            setAyId(ayId);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getCertificateList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/UserAllCertificateAndDocument?userId=${LoginUserde?.userId}&academicYearId=${ayId}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let userCer = res.data.payload;
            let certi = userCer?.certificates?.map((x) => {
              return {
                courseId: x.courseId,
                name: x.courseName,
              };
            });
            setCourseCertificate(certi);
            let couDoc = userCer?.courseList?.map((x) => {
              return {
                courseId: x.courseId,
                name: x.courseName,
                ceumaterialUrl: x.ceumaterialUrl,
              };
            });
            let manualDList = couDoc?.filter(
              (x) => x.ceumaterialUrl !== "" && x.ceumaterialUrl !== null
            );
            setCourseDoc(manualDList);
            let Dashcer = userCer?.userCertificateFromOtherSources?.map((x) => {
              return {
                name: x.courseName,
                certificateMaterialUrl: x.certificateMaterialUrl,
              };
            });
            setDashcerti(Dashcer);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="cource_section mt-3">
      <div className="scroll_box">
        <h3 className="mb-3">Course Certificates</h3>
        {courseCertificate?.length > 0 ? (
          courseCertificate?.map((item, i) => (
            <div className="cource_detail_box new_change_certi" key={i}>
              <div className="cource_Name">{item?.name}</div>
              <Link
                to={`/certificate/${item?.courseId}/${LoginUserde?.userId}/${ayId}`}
              >
                View
              </Link>
            </div>
          ))
        ) : (
          <div>Data not Found</div>
        )}
        <h3 className="mb-3">Course Documents</h3>
        {courseDoc?.length > 0 ? (
          courseDoc?.map((item, i) => (
            <div className="cource_detail_box new_change_certi" key={i}>
              <div className="cource_Name">{item?.name}</div>
              <a
                href={`${Config.API_HOST_URL_live}${item?.ceumaterialUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            </div>
          ))
        ) : (
          <div>Data not Found</div>
        )}
        <h3 className="mb-3">Dashboard Certificate Documents</h3>
        {dashcerti?.length > 0 ? (
          dashcerti?.map((item, i) => (
            <div className="cource_detail_box new_change_certi" key={i}>
              <div className="cource_Name">{item?.name}</div>
              <a
                href={`${Config.API_HOST_URL_live}${item?.certificateMaterialUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            </div>
          ))
        ) : (
          <div>Data not Found</div>
        )}
      </div>
    </div>
  );
}
