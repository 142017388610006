import React, { Profiler, useEffect, useState } from "react";
import "./index.scss";
import Charts from "../../../Reuseable_Components/Chart/Charts";
import Highcharts from "highcharts";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../../../Utils/Config";
import Select from "react-select";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import TableCom from "../../../Reuseable_Components/Table_com/Table_Com";

function CEUStatus(props) {

    const { acdYS, ceuCourseList, schoolId, courceData1, ceuSearchData } = props

    const [chart2, setChart2] = useState([]);
    const [userList, setUserList] = useState([]);
    const [courceData2, setCourseData2] = useState([]);
    const [courseRosterID, setCourseRosterID] = useState("");
    const [schId, setSchId] = useState({
        value: "00000000-0000-0000-0000-000000000000",
        label: "All School District",
    });
    const [userID, setUserID] = useState({
        value: "00000000-0000-0000-0000-000000000000",
        label: "NA",
    });

    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const chartOptions1 = {
        plotOptions: {
            pie: {
                colors: ["#1EBEC8", "#F79153", "#86C65A"],
                allowPointSelect: true,
                cursor: "pointer",
                position: "right",
                dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                    distance: -50,
                },
                showInLegend: true,
            },
        },
        title: {
            text: "",
        },
        accessibility: {
            enabled: false,
        },

        series: [
            {
                data: chart2,
                keys: ["y", "name"],
                type: "pie",
            },
        ],
    };

    useEffect(() => {
        if (acdYS) {
            if (schId) {
                if (courseRosterID) {
                    getCourseRosterData(courseRosterID, schId, acdYS)
                }
            }
        }
        // eslint-disable-next-line
    }, [acdYS, schId, courseRosterID])

    useEffect(() => {

        if (ceuCourseList?.length > 0) {
            setCourseRosterID(ceuCourseList[0]?.courseId);
        }

        // eslint-disable-next-line
    }, [ceuCourseList])

    useEffect(() => {
        if (schoolId) {
            setSchId(schoolId);
            getuserListsNormal(schoolId)
        }
        // eslint-disable-next-line
    }, [schoolId])

    useEffect(() => {
        if (acdYS) {
            if (userID) {
                getSecondChartData(userID.value, acdYS);
            }
        }
        // eslint-disable-next-line
    }, [userID, acdYS])


    const getuserListsNormal = (id) => {
        if (id) {
            axios
                .get(
                    `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetCurrentAcademicYear`,
                    { headers }
                )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.success) {
                            let ayId = res.data.payload[0]?.academicYearId;
                            let url = `${Config.API_HOST_URL_live}/api/Dashboard/GetUserReportListByUserId?userId=${LogUserId}&schoolDistrictId=${id}&academicYearId=${ayId}`;
                            axios
                                .get(url, { headers })
                                .then((response) => {
                                    if (response.data.success) {
                                        const data = response?.data.payload?.map((item) => {
                                            return {
                                                value: item.userId,
                                                label: item.name,
                                            };
                                        });
                                        data.sort((a, b) => {
                                            const nameA = a.label?.toString()?.toLowerCase();
                                            const nameB = b.label?.toString()?.toLowerCase();
                                            if (nameA < nameB) {
                                                return -1;
                                            }
                                            if (nameA > nameB) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                        setUserID({
                                            value: data[0].value,
                                            label: data[0].label,
                                        });
                                        setUserList(data);
                                        // getSecondChartData(data[0].value, ayId);
                                    } else {
                                        setUserList([]);
                                        setUserID({
                                            value: "00000000-0000-0000-0000-000000000000",
                                            label: "NA",
                                        });
                                    }
                                    setChart2([]);
                                })
                                .catch((error) => console.log(error));
                        }
                    }
                });
        } else {
            setUserList([]);
        }
    };

    const getSecondChartData = async (id, ayId) => {
        let seData = {
            url: `/api/Report/GetSubscriberReport?userId=${id}&academicYearId=${ayId}`,
        };
        let response = await GetCallApi(seData);
        if (response?.status === 200) {
            if (response.data.success) {
                const dashData = response.data?.payload;
                const userReport = [
                    [dashData?.ceuEarned, `Earned CEUs ${dashData?.ceuEarned}`],
                    [dashData?.ceuDifference, `Required CEUs ${dashData?.ceuRequired}`],
                ];

                setChart2(userReport);
            }
        }
    };

    const getCourseRosterData = async (id, schId, Aeid) => {
        if (id) {
            let rosterUrl = {
                url: `/api/Dashboard/GetCompletedCourseRosterByCourseId?courseId=${id}&userId=${LogUserId}&schoolDistrictId=${schId}&totalRecord=10&academicYearId=${Aeid}`
            }
            const response = await GetCallApi(rosterUrl)
            if (response?.status === 200) {
                if (response.data.success) {
                    const corsRoster = response.data.payload?.map((item, i) => {
                        return {
                            id: i + 1,
                            name: item?.studentName,
                            no: item?.action,
                            userId: item?.userId,
                            status: item?.status,
                            userCourseAllocationId: item?.userCourseAllocationId,
                            userStatus: item?.userStatus,
                            profileUrl: item?.profileUrl
                        };
                    });
                    const userstatus = corsRoster?.filter((user) => {
                        if (user?.status !== "") {
                            let state = false;
                            if (user?.status === "Completed") {
                                state = true;
                            }
                            return state;
                        } else return user;
                    });
                    setCourseData2(userstatus);
                } else {
                    setCourseData2([
                        {
                            id: 1,
                            name: "NA",
                            no: "NA",
                            userStatus: null,
                            profileUrl: null,
                        },
                    ]);
                }
            } else {
                setCourseData2([
                  {
                    id: 1,
                    name: "NA",
                    no: "NA",
                    userStatus: null,
                    profileUrl: null,
                  },
                ]);
            }
        } else {
            setCourseData2([
              {
                id: 1,
                name: "NA",
                no: "NA",
                userStatus: null,
                profileUrl: null,
              },
            ]);
        }
    }

    return (
        <div className="chart_data_sec">
            <div className="row">
                <div className="col-xl-3 col-md-6">
                    <div className="chart_box">
                        <div className="chart_title_box">
                            <label className="chart_title">CEU Status</label>
                        </div>
                        <div className="chart_box_select">
                            <Select
                                isMulti={false}
                                name="colors"
                                options={userList}
                                className="form_select_d "
                                classNamePrefix="select"
                                value={userList.filter(
                                    (obj) => userID?.value === obj.value
                                )}
                                onChange={(e) => {
                                    setUserID(e);
                                }}
                            />

                            <Charts
                                series={chartOptions1}
                                highcharts={Highcharts}
                                position="bottom"
                            />
                            {chart2?.length > 0 ? (
                                ""
                            ) : (
                                <b className="d-flex justify-content-center">
                                    There is no Assign User
                                </b>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="chart_box cource_box">
                        <div className="chart_title_box d-flex justify-content-between">
                            <Link to={"course-name"}>
                                <label
                                    className="chart_title cur_point"
                                // onClick={handleTopCourse}
                                >
                                    Top 10 Courses
                                </label>
                            </Link>
                        </div>
                        <div className="data_table show_back table-responsive">
                            <TableCom
                                cource={ceuSearchData}
                                head1="Course Name"
                                head2="Number of completions"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="chart_box cource_box">
                        <div className="chart_title_box d-flex justify-content-between">
                            <Link to={"completed-course-roster"}>
                                <label
                                    className="chart_title cur_point"
                                // onClick={handleComCourseRoster}
                                >
                                    Completed Course Roster
                                </label>
                            </Link>
                        </div>
                        <div className="dashboard_sec_2_table_box table-responsive">
                            <div className="data_select_box">
                                <select
                                    className="form-select p_16_text"
                                    aria-label="Default select example"
                                    value={courseRosterID}
                                    onChange={(e) => {
                                        setCourseRosterID(e.target.value);
                                    }}
                                >
                                    <option value=""> Select Course Title</option>
                                    {ceuCourseList &&
                                        ceuCourseList?.map((item, i) => (
                                            <option key={i} value={item?.courseId}>
                                                {item?.courseTitle}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="data_table ">
                                <TableCom
                                    cource={courceData2}
                                    head1="Students Name"
                                    head2="Action"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="chart_box cource_box">
                        <div className="chart_title_box d-flex justify-content-between">
                            <Link to={"top-student"}>
                                <label
                                    className="chart_title cur_point"
                                // onClick={handleTopStudent}
                                >
                                    Top 10 Students
                                </label>
                            </Link>
                        </div>
                        <div className="data_table show_back table-responsive">
                            <TableCom
                                cource={courceData1}
                                head1="Students Name"
                                head2="Completed CEUs"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CEUStatus