import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import "./assignUserG.scss";
import Config from "../../../../Utils/Config";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import user_img from "../../../../images/default_profile.jpg";
import { useNavigate } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";

function AssignUserGroup({ comGroupId }) {
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState();
  const [searchval, setSearchval] = useState("");
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, []);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getUserList = async () => {
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/CommunityGroups/GetCommunityGroupMembers?communityGroupId=${comGroupId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/CommunityGroups/GetCommunityGroupMembers?communityGroupId=${comGroupId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        let mem = response.data.payload?.map((item) => {
          if (item?.status === "Accepted") {
            return undefined;
          }
          return {
            userId: item?.userId,
            name: item?.name,
            siteLocation: item?.siteLocation,
            schoolDistrict: item?.schoolDistrict,
            jobTitle: item?.jobTitle,
            img:
              item?.profileUrl === "" || item?.profileUrl === null
                ? user_img
                : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
            val: 3,
            status: item?.status,
          };
        });
        let nuser = mem.filter((u) => u !== undefined);

        setUserList(nuser);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }

    //   } else {
    //     setOpen(false);
    //   }
    // })
    //   .catch ((error) => console.log(error));
  };
  const handleReqest = (id, i) => {
    let inData = {
      communityGroupId: comGroupId,
      userId: id,
      communityRoleId: 3,
      // status: "Accepted",
      status: "invited",
      createdBy: LogUserId,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/CommunityGroupUser/CreateCommunityGroupUser`,
        inData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
        }
      });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const searchParam = ["name", "jobTitle", "siteLocation", "schoolDistrict"];

  const filteredData = userList?.filter((user) => {
    if (searchval) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchval.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="community_group_create_form">
        <div className="search_box_group text-center d-flex justify-content-end">
          <div>
            <label>
              Search by Name, Job Title, Site/Location, School District
            </label>
            <input
              type="text"
              className="form-control "
              placeholder="Search "
              onChange={(e) => setSearchval(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          {filteredData?.map((item, i) => (
            <div className="col-md-3 mb-3" key={i}>
              <div className="send_user_request_box">
                <div className="main_box">
                  <div
                    className="img_box"
                    onClick={() => handleViewProfile(item.userId)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={item?.img}
                      alt=""
                      className="img-fluid user_profile"
                    />
                  </div>
                  <div
                    className=" "
                    onClick={() => handleViewProfile(item.userId)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className=" name text-start ">{item?.name}</div>
                  </div>
                  {item.status === "invited" || item.status === "Invited" ? (
                    <Button className="ms-auto invited">Invited</Button>
                  ) : (
                    <Button
                      className="ms-auto"
                      onClick={() => handleReqest(item?.userId, i)}
                    >
                      Invite
                    </Button>
                  )}
                </div>
                <div className="detail_box">
                  <div className="p_14_text">{item?.jobTitle}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </form>
    </>
  );
}

export default React.memo(AssignUserGroup);
