import React, { useEffect } from "react";
import "./index.scss";
import userimg from "../../../../images/user.png";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import pro_img from "../../../../images/default_profile.jpg";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import inactive_img from "../../../../images/Icon/inactive_red.svg";
import active_img from "../../../../images/Icon/active_green.svg";
import { useSignalR } from "../../../../Utils/SignalRContext";

function Upload_post(props) {
  // const [open, setOpen] = useState(false);
  const { conList } = props;
  const [usList, setUsList] = useState();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [usId, setUsId] = useState("");
  const [ucId, setUcId] = useState("");
  // const [errShow1, setErrShow1] = useState(false);
  const errShow1 = false;
  const [errShow2, setErrShow2] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const { connection } = useSignalR();

  
  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setUsList((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                  userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection]);
  
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getUserList = () => {
    setErrShow2(true);

    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Requests/MyRecommandedConnectionList?userId=${LogUserId}`,
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          const user = responce.data.payload?.map((item) => {
            return {
              userId:
                item.userId === null || item.userId === "" ? "" : item?.userId,
              img:
                item.profileUrl === null || item.profileUrl === ""
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${item.profileUrl}`,
              name:
                item?.firstName === "" ||
                item?.firstName === null ||
                item?.lastName === "" ||
                item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
              status:
                item.status === null || item.status === "" ? "" : item.status,
              sId: item?.sentRequestId,
              schoolDistrictName: item?.schoolDistrictName,
              siteLocation: item?.siteLocation,
              state: item?.stateName,
              userStatus: item?.userStatus,
            };
          });
          setUsList(user);
          setUsId("");
          setUcId("");
          setErrShow2(false);
        } else {
          setErrShow2(false);
          setUsList([]);
        }
      });
  };

  const SendReq = (req) => {
    setUsId(req);
    let userData = {
      sentRequestUserId: LogUserId,
      receiveRequestUserId: req,
      Status: "Pending",
      message: "I would like to add you as my connection.",
      createdBy: LogUserId,
    };
    // setOpen(true);
    axios
      .post(`${Config.API_HOST_URL_live}/api/Requests/SendRequest`, userData, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
          // setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // setOpen(false);
      });
  };
  const CancelReq = (req, newId) => {
    setUcId(req);
    let userData = {
      sentRequestUserId: LogUserId,
      receivedRequestUserId: req,
      Status: "Cancel",
      sentRequestId: newId,
      receivedRequestId: "00000000-0000-0000-0000-000000000000",
    };
    // setOpen(true);
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Requests/CancelSentRequest`,
        userData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
          // setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // setOpen(false);
      });
  };
  // function convert(str) {
  //   var date = new Date(str),
  //     month = date.toLocaleString("default", { month: "short" }),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [month, day].join(" ");
  // }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const [searchField, setSearchField] = useState();
  const searchParam = ["name", "schoolDistrictName", "state"];
  const filterUser = usList?.filter((user) => {
    if (searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <div className="connectionlist_card_new">
        <div className="card_title d-flex">
          <h5>Suggested Friends</h5>
          {/* <Link>View All</Link> */}
        </div>
        <div className="searc_box">
          <input
            type="search"
            className="form-control"
            placeholder="Search by Name, School District, State"
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
        <div className="card_content border-0 suggested_side_box">
          <div className="user_list">
            {filterUser?.length > 0 ? (
              <>
                {filterUser?.map((item, i) => (
                  <div
                    className="d-flex u_box"
                    // onClick={() => handleViewProfile(item?.userId)}
                    key={i}
                  >
                    <div className="img_box">
                      <div className="user_img">
                        <img
                          src={item?.img ? item?.img : userimg}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = userimg;
                          }}
                          className="img-fluid user_p_img"
                          alt=""
                        />
                      </div>
                      <img
                        src={
                          item?.userStatus === "Online"
                            ? active_img
                            : ""
                        }
                        alt=""
                        className="img-fluid dot_ac_in"
                      />
                    </div>
                    <label
                      className="u_name"
                      onClick={() => handleViewProfile(item?.userId)}
                    >
                      {item?.name}
                    </label>
                    {item.status === "Sent" ? (
                      <Button
                        className="theme_button cancel my-auto"
                        onClick={(e) => CancelReq(item?.userId, item?.sId)}
                        disabled={item?.userId === ucId ? true : false}
                      >
                        {item?.userId === ucId ? (
                          <CircularProgress className="loder" />
                        ) : (
                          <AiOutlineMinus />
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="theme_button my-auto"
                        onClick={(e) => SendReq(item?.userId)}
                        disabled={item?.userId === usId ? true : false}
                      >
                        {item?.userId === usId ? (
                          <CircularProgress className="loder" />
                        ) : (
                          <AiOutlinePlus />
                        )}
                      </Button>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>{!errShow2 && <div>No Data Found</div>}</>
            )}
            {errShow2 && <div>Loading...</div>}
          </div>
        </div>
      </div>
      <div className="connectionlist_card_new">
        <div className="card_title d-flex">
          <h5>Connections</h5>
          {/* {location.pathname === "/edit-profile" ? (
            <Link onClick={GoToCon}>View All</Link>
          ) : (
            <Link to="/edit-profile"><MoreVertIcon /></Link>
          )} */}
        </div>
        <div className="card_content">
          <div className="user_list">
            {conList?.length > 0 ? (
              <>
                {conList?.map((item, i) => (
                  <div className="d-flex u_box" key={i}>
                    <div className="img_box">
                      <div className="user_img">
                        <img
                          src={item?.img ? item?.img : pro_img}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = pro_img;
                          }}
                          className="img-fluid user_p_img"
                          alt=""
                        />
                      </div>
                      <img
                        src={
                          item?.userStatus === "Online"
                            ? active_img
                            : ""
                        }
                        alt=""
                        className="img-fluid dot_ac_in"
                      />
                    </div>

                    <div>
                      <label
                        className="u_name"
                        onClick={() => handleViewProfile(item?.userId)}
                      >
                        {item?.name}
                      </label>
                      <span className="p_14_text">
                        Followers : {item?.followers}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>{!errShow1 && <div>No Data Found</div>}</>
            )}
            {/* {errShow1 && <div>Loading...</div>} */}
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(Upload_post);
