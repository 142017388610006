import React, { useState } from "react";
import "./index.scss";
import ConnectionList from "./Components/Connection_list";
import active_list from "../../../../images/active_list.png";
import inactive_list from "../../../../images/inactive_list.png";
import active_grid from "../../../../images/active_grid.png";
import inactive_grid from "../../../../images/inactive_grid.png";

import { Button } from "@mui/material";

function Connections(props) {
  const { usList, setAbtChange, abtChange } = props;
  const [grid, setGrid] = useState(true);
  const [searchField, setSearchField] = useState();

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex">
            <div className="d-flex">
              <div className="searc_box">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search "
                  onChange={(e) => setSearchField(e.target.value)}
                />
              </div>
              {/* <div className="check_box">
                <label
                  className="personal_detail_label"
                  style={{ paddingLeft: 30 }}
                >
                  <input type="checkbox" className="login_check" />
                  <span className="checkmark"></span>
                </label>

                <Button className="connect_all">Connect to all</Button>
              </div> */}
            </div>
            <div className="rightside_action">
              <Button className="grid_btn" onClick={() => setGrid(true)}>
                <img
                  src={grid === true ? active_grid : inactive_grid}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
              <Button className="list_btn" onClick={() => setGrid(false)}>
                <img
                  src={grid === false ? active_list : inactive_list}
                  className="img-fluid"
                  alt="grid"
                />
              </Button>
            </div>
          </div>
        </div>
        <ConnectionList
          grid={grid}
          searchField={searchField}
          usList={usList}
          setAbtChange={setAbtChange}
          abtChange={abtChange}
        />
      </div>
    </>
  );
}
export default React.memo(Connections);
