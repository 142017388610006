import { Box, Modal } from "@mui/material";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import pro_img from "../../../../images/default_profile.jpg";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  "justify-content": "center",
  "flex-direction": "column",
};
function PostListPop(props) {
  const { pdata, setLikePop } = props;
  const [openPop, setOpenPop] = useState(false);
  const [likeList, setLikeList] = useState([]);
  const LogDetail = useSelector((state) => state.login?.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (pdata.postId) {
      getLikeList(pdata.postId);
    }
    // eslint-disable-next-line
  }, [pdata]);
  const getLikeList = async (postId) => {
    let res = await GetCallApi({
      url: `/api/CommunityLike/GetCommunityPostLikeByID?userId=${LogDetail?.userId}&postId=${postId}`,
      headers: headers,
    });
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/CommunityLike/GetCommunityPostLikeByID?userId=${LogDetail?.userId}&postId=${postId}`,
    //     { headers }
    //   )
    //   .then((res) => {
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.payload?.length > 0) {
          setOpenPop(true);
          setLikeList(res.data.payload);
        } else {
          handleClose();
        }
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // })
    // .catch((e) => console.log(e));
  };
  const handleClose = () => {
    setOpenPop(false);
    setLikePop();
  };
  return (
    <>
      {openPop && (
        <>
          <Modal
            open={openPop}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <div>
                  <h6>Like List</h6>
                </div>
                <div className="popup_delete_detail">
                  {likeList?.map((user, i) => (
                    <Link
                      key={i}
                      className="user_box"
                      to={`/view-profile/${user?.userId}`}
                    >
                      <img
                        src={
                          user.profileUrl
                            ? `${Config.API_HOST_URL_live}${user.profileUrl}`
                            : pro_img
                        }
                        alt=""
                      />
                      <div className="name">{user?.userName}</div>
                    </Link>
                  ))}
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default PostListPop;
