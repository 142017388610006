import React, { useState } from "react";
import "./Index.scss";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import Edit_icon_new from "../../../../../../images/Icon/Edit_icon_new.svg";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";

export default function About_sec(props) {
  const { abtChange, setAbtChange } = props;
  const [editAbout, setEditAbout] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const handleEdit = () => {
    setEditAbout(true);
    if (props?.title_text !== null || props?.title_text !== "") {
      setValue("aboutUs", props?.title_text);
    }
  };
  const handleClose = () => {
    setEditAbout(false);
  };
  const onSubmit = async (data) => {
    let sendData = {
      ...data,
      schoolDistrictId: props.getDistrictData?.schoolDistrictId,
      userId: LogDetail?.userId,
    };
    let seData = {
      url: "/api/SchoolDistrict/UpdateSchoolDistrictAboutUs",
      body: sendData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        handleClose();
        setAbtChange(!abtChange);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  return (
    <>
      <div className="figfamily_profile_about_section_user">
        <div className="scroll_box">
          <div className="col-md-12 mb-3">
            <h3>Contact Details</h3>
          </div>
          <div className="about_detail_completed">
            {/* <div className="about_detail_box">
                <h5>Contact Details</h5>
              </div> */}

            <div className="complete-details_line">
              <div className="complete_title">Phone Number :</div>
              <div className="complete_value">
                {props?.getDistrictData?.phoneNumber}
              </div>
            </div>

            <div className="complete-details_line">
              <div className="complete_title">Email :</div>
              <div className="complete_value">
                {props?.getDistrictData?.email}
              </div>
            </div>

            <div className="complete-details_line">
              <div className="complete_title">State :</div>
              <div className="complete_value">
                {props?.getDistrictData?.state}
              </div>
            </div>

            <div className="complete-details_line">
              <div className="complete_title">Number of Users :</div>
              <div className="complete_value">
                {props?.getDistrictData?.users?.length}
              </div>
            </div>
          </div>
          <div className="header_edit col-md-12">
            <h3>About Us</h3>
            {props?.getDistrictData?.isAllowEditAboutUs ? (
              <button
                type="button"
                className="ms-3 back_tra"
                onClick={handleEdit}
              >
                <img src={Edit_icon_new} alt="" />
              </button>
            ) : (
              ""
            )}
          </div>

          {editAbout ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form_about col-md-12 mb-3"
            >
              <div className="form-group">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  className="form-control"
                  {...register("aboutUs", {
                    required: "This field is required",
                  })}
                ></textarea>
                {errors.aboutUs && (
                  <span role="alert" className="error_text">
                    {errors.aboutUs.message}
                  </span>
                )}
              </div>
              <div className="form-group mt-3">
                <Button className="submit_btn me-3" type="submit">
                  Update
                </Button>
                <Button className="cancel_btn  " onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div className="about_text">{props?.title_text}</div>
          )}
        </div>
      </div>
      {/* <div className="figfamily_profile_about_section_user">
        <div className="scroll_box">
        </div>
      </div> */}
    </>
  );
}
