import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../Action/Action";
import { Box, Tab } from "@mui/material";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import "./index.scss";
import Config from "../../../Utils/Config";
import { TabContext, TabList } from "@mui/lab";
import doc from "../../../images/docs.png";
import pdf from "../../../images/pdf.png";
import ppt from "../../../images/ppt.png";
import sheet from "../../../images/sheet.png";
import download_icon from "../../../images/Icon/download_material.png";
import grid_lin_a from "../../../images/Icon/GridView_lin_a.svg";
import grid_lin from "../../../images/Icon/GridView_lin.svg";
import list_lin_a from "../../../images/Icon/ListView_lin_a.svg";
import list_lin from "../../../images/Icon/ListView_lin.svg";
// import Sch_logo from "../../../images/Icon/ep_school.svg";

const renderLoader = () => <p></p>;

function MainFreeSourceDetail() {
  const { id } = useParams();
  const [openCir, setOpenCir] = useState(false);
  const [sourceDetail, setSourceDetail] = useState();
  const [scDoc, setScDoc] = useState([]);
  const [value, setValue] = React.useState("1");
  const [dateArr, setDateArr] = useState();
  const [listView, setListView] = useState(true);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (id) {
      setOpenCir(true);
      setTimeout(() => {
        getSource();
      }, 200);
    }
    // eslint-disable-next-line
  }, [id]);
  const getSource = async () => {
    setOpenCir(true);
    let seData = {
      url: `/api/Resources/GetResourcesDataByIDWithoutToken?resourcesId=${id}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCir(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let detail = res.data?.payload;
        setSourceDetail(detail);

        let date1 = [];
        let document = res.data?.payload?.documentData?.map((x) => {
          if (
            date1?.filter(
              (y) =>
                new Date(y.date).toLocaleDateString() ===
                new Date(x?.documentDate).toLocaleDateString()
            )?.length > 0
          ) {
            return null;
          }
          return date1.push({
            date: x?.documentDate,
          });
        });
        date1 = date1?.map((x) => {
          return {
            date: new Date(x.date)?.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            cndate: new Date(x.date),
          };
        });
        const sortedAsc1 = date1.sort(
          (objA, objB) => Number(objA.cndate) - Number(objB.cndate)
        );
        setDateArr(sortedAsc1);
        date1 = document;
        let doc = res.data?.payload?.documentData?.map((x) => {
          return {
            ...x,
            date: new Date(x.documentDate)?.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            cndate: new Date(x.documentDate),
          };
        });
        const sortedAsc = doc.sort(
          (objA, objB) => Number(objA.cndate) - Number(objB.cndate)
        );
        setScDoc(sortedAsc);
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const formattedSearchResults = scDoc?.filter((user) => {
    if (value) {
      return user.date?.includes(dateArr[value - 1]?.date);
    } else return user;
  });
  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box" style={{ height: "150px" }}></div>
  ));
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="mainFreeSources_detail_page main_baclcolor">
          <div className="container">
            <div className="FreeSources_detail_page_box">
              <div className="listdata_control_sec mb-2 d-flex justify-content-between">
                {openCir ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-line skeleton-title mb-4"></div>
                  </div>
                ) : (
                  <>
                    <div className="title">
                      <span className="main_tit">
                        Title: {sourceDetail?.title}
                      </span>
                      <span className="txt">
                        Contributed By:{sourceDetail?.authorName}
                      </span>
                      <span className="txt dist">
                        <img
                          // src={`${Config.API_HOST_URL_live}${sourceDetail?.schoolDistrictLogo}`}
                          src={`${Config.API_HOST_URL_live}${sourceDetail?.thumbnailLogoUrl}`}
                          alt=""
                          className="me-2"
                          width={40}
                          height={40}
                        />
                        {/* <img src={Sch_logo} alt="" className="me-2" />
                        {sourceDetail?.schoolDistrictName} */}
                      </span>
                    </div>
                    {dateArr?.length > 0 ? (
                      <div className="grid_list">
                        <div
                          className="list_img"
                          onClick={() => setListView(true)}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={listView ? list_lin_a : list_lin} alt="" />
                        </div>
                        <div
                          className="grid_img"
                          style={{ cursor: "pointer" }}
                          onClick={() => setListView(false)}
                        >
                          <img src={listView ? grid_lin : grid_lin_a} alt="" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <hr />

              <div className="free_sources">
                {/* <div className="des_ mb-4">
                  <b>Description: {sourceDetail?.description}</b>
                </div> */}
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
                    <Box sx={{ marginBottom: "1rem" }}>
                      {openCir ? (
                        <div className="skeleton-loader">
                          <div className="skeleton-line skeleton-title mb-4"></div>
                        </div>
                      ) : (
                        <TabList
                          onChange={handleChange2}
                          variant="scrollable"
                          scrollButtons={false}
                          aria-label="lab API tabs example"
                          className={`session-tab `}
                        >
                          {dateArr?.map((se, i) => (
                            <Tab
                              key={i}
                              label={`${se.date}`}
                              value={`${i + 1}`}
                              className="head-tab"
                            />
                          ))}
                        </TabList>
                      )}
                    </Box>

                    <div className="row">
                      {openCir ? (
                        <>
                          {[...Array(4)?.keys()]?.map((x, i) => (
                            <div
                              className={`${listView ? "col-xl-3" : "col-xl-2"
                                }`}
                              key={i}
                            >
                              <div className="skeleton-loader">{box}</div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {formattedSearchResults &&
                            formattedSearchResults?.map((reso, i) => {
                              let url = reso?.documentUrl?.split(".");
                              url = url ? url[url?.length - 1] : "";

                              let checkType =
                                url === "doc" || url === "docx"
                                  ? doc
                                  : url === "pdf"
                                    ? pdf
                                    : url === "pptx"
                                      ? ppt
                                      : url === "xls" || url === "xlsx"
                                        ? sheet
                                        : `${Config.API_HOST_URL_live}${reso?.documentUrl}`;
                              if (reso?.documentUrl) {
                                return (
                                  <div
                                    className={`${listView ? "col-xl-3" : "col-xl-2"
                                      } col-lg-4 col-md-6  mb-3 `}
                                    key={i}
                                  >
                                    <div
                                      className={`add_box h-100 ${listView ? "" : "_grid"
                                        }`}
                                    >
                                      <div className="in-box">
                                        <img
                                          src={checkType}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      {listView ? (
                                        <>
                                          <div className="p_16_text name">
                                            {reso?.documentName}
                                            {/* <p className="p_12_text mb-0">
                                              {reso?.createdByName}
                                            </p> */}
                                          </div>
                                          <a
                                            href={`${Config.API_HOST_URL_live}${reso?.documentUrl}`}
                                            className=" num_show ms-auto me-3"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={download_icon}
                                              alt=""
                                              height={25}
                                              width={25}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <div className="grid_name_down">
                                          <div className="p_16_text name">
                                            {reso?.documentName}

                                            <p className="p_12_text mb-0">
                                              {reso?.createdByName}
                                            </p>
                                          </div>
                                          <a
                                            href={`${Config.API_HOST_URL_live}${reso?.documentUrl}`}
                                            className=" num_show ms-auto me-3"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={download_icon}
                                              alt=""
                                              height={25}
                                              width={25}
                                            />
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  // <div className="col-md-6  mb-3" key={i}>
                                  //   <div style={{ height: "400px" }}>
                                  //     {checkType ? (
                                  //       <iframe
                                  //         title={"PDF-Viewer"}
                                  //         src={`https://view.officeapps.live.com/op/embed.aspx?src=${Config.API_HOST_URL_live}${reso?.documentUrl}`}
                                  //         style={{
                                  //           height: "100%",
                                  //           width: "100%",
                                  //         }}
                                  //       ></iframe>
                                  //     ) : (
                                  //       <iframe
                                  //         title={"PDF-Viewer"}
                                  //         src={`${Config.API_HOST_URL_live}${reso?.documentUrl}`}
                                  //         style={{
                                  //           height: "100%",
                                  //           width: "100%",
                                  //         }}
                                  //       ></iframe>
                                  //     )}
                                  //   </div>
                                  // </div>
                                );
                              }
                              return null;
                            })}
                        </>
                      )}
                    </div>
                    {openCir ? (
                      ""
                    ) : (
                      <>
                        {formattedSearchResults?.length === 0 ? (
                          <div className="text-center my-3 p_20_text">
                            Keep watching, we are getting some exciting things
                            for you.
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MainFreeSourceDetail;
