import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import backimg12 from "../../../../../../images/group_card_back12.png";
import userimg13 from "../../../../../../images/user13.png";
import axios from "axios";
import Config from "../../../../../../Utils/Config";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function ViewGropsList(props) {
  const [open1, setOpen] = React.useState(false);
  const [grpList, setGrpList] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleClose1 = () => {
    setOpen(false);
  };
  useEffect(() => {
    getGrpList();
    // eslint-disable-next-line
  }, []);
  const getGrpList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${props?.id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const cgList = response.data.payload?.map((item) => {
            return {
              communityGroupId: item?.communityGroupId,
              name: item?.communityGroupName,
              img:
                item?.groupImageUrl === "" || item?.groupImageUrl === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
              backimg:
                item?.groupCoverImage === "" || item?.groupCoverImage === null
                  ? backimg12
                  : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
              organizerUserId: item?.organizerUserId,
            };
          });
          setGrpList(cgList);
        }
      });
  };

  return (
    <>
      {props.grid === true ? (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-3 mb-3" key={i}>
              <div className="grid_groups_card text-center pb-3 ">
                <img
                  src={item?.backimg ? item?.backimg : backimg12}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = backimg12;
                  }}
                  alt="backimg"
                  className="img-fluid banner_img"
                />
                <div className="groups_card_text">
                  <img
                    src={item?.img ? item?.img : userimg13}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                    alt="profileimg"
                    className="img-fluid group_img mb-3"
                  />
                  <h6>{item?.name}</h6>
                  <p>Group Private</p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_groups_card">
                <div className="connection_card_text d-flex">
                  <img
                    src={item?.img ? item?.img : userimg13}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userimg13;
                    }}
                    alt="profileimg"
                    className="img-fluid"
                  />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Group Private</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {grpList?.length <= 0 ? (
        <div className="text-center my-5 p_20_text"> No Data Found</div>
      ) : (
        ""
      )}
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose1}
          dividers
        >
          Leave Group
        </BootstrapDialogTitle>
        <DialogContent>
          <div className="row mb-3">
            <div className="col-md-12 leave_group">
              <p>
                Are you sure you want to leave{" "}
                <span className="theme_span">
                  Capistrano Unified School District (CA)
                </span>
                ?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2">Confirm</Button>
          <Button autoFocus onClick={handleClose1} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default React.memo(ViewGropsList);
