import React, { useEffect, useState, useCallback } from "react";
import Groups from "./Groups";
import Conections from "./Connections";
// import Photos from "./Photos";
// import Documents from "./Documents";
import back_img from "../../../images/Cover_photo_user_profile.png";
import "./index.scss";
import Profileimg from "./Components/profile_img";
import incomplete_img from "../../../images/incomplete_icon.png";
import complete_img from "../../../images/complete_icon.png";
import Aboutsec from "./Components/About_sec/Index";
import CourceProgress from "./Components/Cource_progress";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Button } from "@mui/material";
// import Timeline from "./Timeline";
import ProfileCources from "./ProfileCourses";
// import ProfileEmail from "./Profile_Email";
// import Videos from "./Videos";
// import EditProfile from "./Edit_Profile";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { LoginUser } from "../../../reducers/login";
import Swal from "sweetalert2";
import userimg2 from "../../../images/user10.png";
import CropmodelImage from "../../Reuseable_Components/CropImg/cropmodel";
import CardGrid from "../CEU_Cources/AuthorProfile/cardGrid";
import userimg1 from "../../../images/user18.png";
import cardimg1 from "../../../images/ceu_couce_card_img.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  // const [ediPro, setEdiPro] = useState(false);
  const [value, setValue] = React.useState(0);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);
  let dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState({});
  const [ceuCardData, setCeuCardData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const HandleCoverPhoto = (e) => {
    let user = new FormData();
    user.append("UserId", LogUserId);
    user.append("CoverPhoto", e);
    axios
      .post(`${Config.API_HOST_URL_live}/api/User/AddCoverPhoto`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          let loginDeatil = {
            ...response.data.payload,
            authenticate: true,
          };
          covercancleprofile();
          dispatch(LoginUser(loginDeatil));
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      });
  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const [abtChange, setAbtChange] = useState(false);
  useEffect(() => {
    getMYcouses();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getProfileDetail();

    // eslint-disable-next-line
  }, [abtChange]);
  const getProfileDetail = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/MyProfile?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setUserProfile(response.data?.payload);
        }
      });
  };

  const getMYcouses = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyCourseList?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          let clist = response.data.payload?.map((item) => {
            return {
              id: item?.courseId,
              cardTitle: item?.courseTitle,
              cardimg: `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`,
              startCompleted: item?.status === null ? "" : item?.status,
              lesson:
                item?.totalLession === "" || item?.totalLession === ""
                  ? ""
                  : item?.totalLession + " Lessons",
              perCompleted: item?.progress ? item?.progress : "0",
              rating: item?.ratings ? item.ratings : "0",
              userimg:
                item?.instructor === null ||
                item?.instructor?.profileUrl === "" ||
                item?.instructor?.profileUrl === null
                  ? userimg2
                  : `${Config.API_HOST_URL_live}${item.instructor?.profileUrl}`,
              userName:
                item?.instructor === null ||
                item?.instructor?.fistName === "" ||
                item?.instructor?.fistName === null
                  ? ""
                  : item.instructor?.firstName +
                    " " +
                    item?.instructor?.lastName,
              stepActive: `0/${item?.totalLession} steps`,
            };
          });
          setCeuCardData(clist);
        }
      });
  };

  // Crop Code Image

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const [ceuData, setCeuData] = useState([]);
  const [coverprofiledit, setCoverProfiledit] = useState(null);
  const [newFile, setNewFile] = useState(false);
  const LogRoleName = useSelector(
    (state) => state.login?.LoginDetails.roleName
  );
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        //HandleCoverPhoto(url);
        setCoverProfiledit(url);
        setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      setNewFile(e.target.files[0]);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleFilecoverEdit = (e) => {

    const reader = new FileReader();
    setFileName(newFile.name);
    reader.addEventListener(
      "load",
      () => {
        setSrc(reader.result);
        setCropModal(true);
      },
      false
    );
    reader.readAsDataURL(newFile);
  
};

  useEffect(() => {
    if (value === 4) {
      getInstructDeLists();
    }
    // eslint-disable-next-line
  }, [value]);

  const getInstructDeLists = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Instructor/Instructorprofile?InstructorId=${LogUserId}&followerUserId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const insData = response.data?.payload;
          const courstof = response.data?.payload?.myOfferedCourses?.map(
            (item, i) => {
              return {
                id:
                  item.courseId === "" || item.courseId === null
                    ? ""
                    : item?.courseId,
                cardimg:
                  item.imageOrVideoUrl === "" || item.imageOrVideoUrl === null
                    ? cardimg1
                    : `${Config.API_HOST_URL_live}${item.imageOrVideoUrl}`,
                cardTitle:
                  item.courseTitle === "" || item.courseTitle === null
                    ? ""
                    : item.courseTitle,
                startCompleted: `${item?.lessions} Video Series`,
                lesson: `${item?.lessions} Lessons`,
                // des: "Deep down, what motivates you? What aspects of your life are most meaningful to you?",
                rating: item?.ratings ? item?.ratings : 0,
                userimg:
                  response.data.payload?.profileUrl === "" ||
                  response.data.payload?.profileUrl === null
                    ? userimg1
                    : `${Config.API_HOST_URL_live}${response.data.payload?.profileUrl}`,
                userName:
                  insData?.firstName === "" ||
                  insData?.firstName === null ||
                  insData?.lastName === "" ||
                  insData?.lastName === null
                    ? ""
                    : insData?.firstName + " " + insData?.lastName,
              };
            }
          );
          setCeuData(courstof);
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  const covercancleprofile = () => {
    setCoverProfiledit(null);
    setCroppedImageUrl(null);
    setNewFile()
  };

  // Code End Code

  return (
    <>
      <section className="Profile_page main_backcolor">
        <div className="profile_header_back_img">
        {croppedImageUrl ? (
            <div className="back_img">
              <img src={croppedImageUrl} alt="cropImage" />
            </div>
          ) : (
          <div className="back_img">
            <img
              src={
                LoginUserde?.coverPhoto === "" ||
                LoginUserde?.coverPhoto === null
                  ? back_img
                  : `${Config.API_HOST_URL_live}${LoginUserde?.coverPhoto}`
              }
              alt=""
            />
          </div>
          )}
          <div className="profile_update_icon">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="p-0"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                // onChange={(e) => HandleCoverPhoto(e)}
                onChange={handleFile}
              />
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="44" rx="22" fill="white" />
                <path
                  d="M32 28.5455C32 29.0277 31.8084 29.4901 31.4675 29.8311C31.1265 30.1721 30.664 30.3636 30.1818 30.3636H13.8182C13.336 30.3636 12.8735 30.1721 12.5325 29.8311C12.1916 29.4901 12 29.0277 12 28.5455V18.5455C12 18.0632 12.1916 17.6008 12.5325 17.2598C12.8735 16.9188 13.336 16.7273 13.8182 16.7273H17.4545L19.2727 14H24.7273L26.5455 16.7273H30.1818C30.664 16.7273 31.1265 16.9188 31.4675 17.2598C31.8084 17.6008 32 18.0632 32 18.5455V28.5455Z"
                  stroke="#2C2E35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 26.7273C24.0083 26.7273 25.6364 25.0993 25.6364 23.091C25.6364 21.0826 24.0083 19.4546 22 19.4546C19.9917 19.4546 18.3636 21.0826 18.3636 23.091C18.3636 25.0993 19.9917 26.7273 22 26.7273Z"
                  stroke="#2C2E35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          {src && (
            <CropmodelImage
              src={src}
              filename={filename}
              setCroppedImageUrl={setCroppedImageUrlCallback}
              openCropModal={openCropModal}
              setCropModal={setCropModal}
            />
          )}
        </div>
        <div className="container-fluid">
          <div className="main_wrapper">
              <Profileimg
                name={userProfile?.firstName + " " + userProfile?.lastName}
                id_name="@lina_al"
                joined={userProfile?.userJoiningDate}
                active="Active now"
                followers={userProfile?.followers}
                following={userProfile?.following}
                ceu_goal_percent="0"
                userProfile={userProfile}
                // setEdiPro={setEdiPro}
              />
             
            {coverprofiledit ? (
              <div className="profile-edit-buttons d-block text-end">
                <Button className="profile-btn red-bg" onClick={() => covercancleprofile()}>cancel</Button>
                <Button className="profile-btn" onClick={()=> HandleCoverPhoto(coverprofiledit)}>Save</Button>
                <Button className="profile-btn" onClick={()=> handleFilecoverEdit()}>Edit</Button>
              </div>
              ) : (
                ''
            )}
            
            <div className="comment_tabpanel">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="comment_tab_button"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab label="Intro" {...a11yProps(0)} />
                  <Tab label="Connections" {...a11yProps(1)} />
                  <Tab label="Groups" {...a11yProps(2)} />
                  {/* <Tab label="Photos" {...a11yProps(3)} />
                      <Tab label="Documents" {...a11yProps(4)} /> */}
                  <Tab label="Courses" {...a11yProps(3)} />
                  {LogRoleName === "Instructor" && (
                    <Tab label="My Courses" {...a11yProps(4)} />
                  )}
                  {/* <Tab label="Videos" {...a11yProps(6)} />
                      <Tab label="Email Invites" {...a11yProps(7)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="tabPanel_div">
                <Aboutsec
                  title_text={userProfile?.aboutUs}
                  sub_title={userProfile?.schoolDistrictName}
                  incomplete_icon={incomplete_img}
                  complete_icon={complete_img}
                  abtChange={abtChange}
                  setAbtChange={setAbtChange}
                />
                <CourceProgress
                  ceuCardData={ceuCardData}
                  value="50"
                  value2="100"
                  value3="75"
                  value4="25"
                  first_text="All About Motivation"
                  second_text="Civil Rights"
                  third_text="Time Management"
                  forth_text="School Nutrition"
                />
              </TabPanel>
              <TabPanel value={value} index={1} className="tabPanel_div">
                <Conections />
              </TabPanel>
              <TabPanel value={value} index={2} className="tabPanel_div">
                <Groups />
              </TabPanel>
              <TabPanel value={value} index={3} className="tabPanel_div">
                <ProfileCources />
              </TabPanel>
              <TabPanel value={value} index={4} className="tabPanel_div">
                <div className="row">
                  <CardGrid data={ceuData} addclass="m_add" />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default React.memo(Profile);
