import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pro_img from "../../../../images/fig_CROP.png";
import "./index.scss";

function ImgPopUp(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <button
        className="btn_Img_show"
        onClick={handleOpen}
        style={{ backgroundColor: "transparent", border: 0 }}
      >
        <img
          src={props.img}
          alt="EventPromotion"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = pro_img;
          }}
          height={30}
          width={30}
        />
      </button>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_img_Show"
          >
            <Box sx={style} className="delete_data_box">  
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  bgcolor: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={props.popupImage ? props.popupImage : props.img}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = pro_img;
                }}
                alt=""
              />
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(ImgPopUp);
