import React, { useState } from "react";
import "./index.scss";
import star_full from "../../images/star_full.png";
import star_blank from "../../images/star_blank.png";
import Rating from "react-rating";
import Pagination from "../../Modules/Reuseable_Components/Paginations/Pagination";
import Swal from "sweetalert2";
// import cardimg1 from "../../images/fig_CROP.png";
import cardimg1 from "../../images/course_not_found_final_1.png";

import { Link } from "react-router-dom";

export default function CardGrid({ data, onClick, pageSize, grid }) {
  //pagination
  let Pagesize = pageSize ? pageSize : 50;
  const [currentPage, setCurrentPage] = useState(1);
  const searchField = "";
  const formattedSearchResults = data?.filter((user) => {
    if (searchField) {
      return user;
    } else return user;
  });

  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };
  return (
    <>
      {formattedSearchResults?.length > 0 ? (
        <>
          {formattedSearchResults &&
            formattedSearchResults
              ?.slice(
                (currentPage - 1) * Pagesize,
                (currentPage - 1) * Pagesize + Pagesize
              )
              .map((item, i) => (
                <div
                  className={` ${
                    grid === undefined || grid === true
                      ? "col-xl-3 col-lg-4 col-md-6"
                      : "col-12"
                  } mb-5`}
                  key={i}
                >
                  <div
                    className={`carddetail_box ${
                      grid === undefined || grid === true ? "" : "dir_row"
                    }`}
                  >
                    <Link to={`${item.id}`} className="img_box_text">
                      <img
                        src={item?.cardimg}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg1;
                        }}
                        className="img-fluid main_card_img"
                        alt=""
                      />
                      <div className="author_name">
                        <span>{item?.userName}</span>
                        {/* <span>Offered By: {item?.userName}</span> */}
                      </div>
                    </Link>
                    <div className="card_details">
                      <div className={`card_title tr`}>{item?.cardTitle}</div>
                      <div className="d-flex justify-content-between">
                        <span className="card_lesson">{item?.lesson}</span>
                        <span className="card_lesson ceu">
                          CEU: {item?.courseCeu}
                        </span>
                      </div>
                      <div className="rating_completed">
                        <div className="rating_box">
                          <Rating
                            emptySymbol={
                              <img src={star_blank} alt="rating_blank" />
                            }
                            fullSymbol={
                              <img src={star_full} alt="rating-full" />
                            }
                            initialRating={item.rating ? item.rating : "0"}
                            readonly
                          />
                        </div>
                      </div>
                      <div className="mt-3 category_show p_14_text">
                        Key Area: <span>{item?.cate}</span>
                      </div>
                      <div className="mt-3">
                        <span className="description ">
                          Description: {item?.des?.slice(0, 85)}
                        </span>
                        {item?.des?.length > 85 && (
                          <span
                            className="description cr"
                            onClick={() => showReadMore(item?.des)}
                          >
                            ...read more
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          <div className="d-flex justify-content-end">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={formattedSearchResults?.length}
              pageSize={Pagesize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      ) : (
        <div className="h6_text text-center">
          Keep watching, we are getting some exciting things for you.
        </div>
      )}
    </>
  );
}
