import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userimg13 from "../../../../images/user13.png";
import userimg from "../../../../images/user.png";
import "./MemberList.scss";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useNavigate } from "react-router-dom";

function MemberList({ comGroupId }) {
  const [usList, setUsList] = useState();
  useEffect(() => {
    if (comGroupId) {
      getUserList();
    }
    // eslint-disable-next-line
  }, [comGroupId]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getUserList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/GetCommunityGroupUserAcceptRequestData?CommunityGroupId=${comGroupId}`,
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          const user = responce.data.payload?.map((item) => {
            return {
              userId: item?.userId,
              name: item?.userName,
              img:
                item?.userImage === "" || item?.userImage === null
                  ? userimg13
                  : `${Config.API_HOST_URL_live}${item?.userImage}`,
            };
          });
          setUsList(user);
        }
      });
  };
  let nav = useNavigate();

  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <div className="Member_List_secrion">
      <div className="card_title d-flex">
        <h6>Members</h6>
      </div>
      <div className="card_content member_box">
        {/* <div className="tags d-flex mb-3">
          <label
            className={`${usCon === "1" ? "active" : ""}`}
            onClick={() => setUsCon("1")}
          >
            Newest
          </label>
          <label
            className={`${usCon === "2" ? "active" : ""}`}
            onClick={() => setUsCon("2")}
          >
            Active
          </label>
          <label
            className={`${usCon === "3" ? "active" : ""}`}
            onClick={() => setUsCon("3")}
          >
            Popular
          </label>
        </div> */}
        <div className="user_list">
          {usList &&
            usList?.map((item, i) => (
              <div
                className="d-flex user_line view_pro mb-3"
                key={i}
                onClick={() => handleViewProfile(item?.userId)}
              >
                <img
                  src={item?.img === "" ? userimg : item?.img}
                  className="img-fluid user_p_img"
                  alt="user"
                />
                <label className="m-auto name ms-3">{item?.name}</label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(MemberList);
