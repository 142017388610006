import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
// import paymentBanner from "../../assets/icons/payment-banner.jpg";
import "./index.scss";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { GetCallApi } from "../../Action/Action";
// import { AttendeeRegistrationConference } from "../../reducers/Common";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function PaymentSuccess(props) {
  //   const [openCircle, setOpenCircle] = useState(false);
  const { id, amt } = useParams();
  const location = useLocation();
  const [derror, setDerror] = useState();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(false);
  // const ed = JSON?.parse(localStorage?.getItem("usREG"));
  let ATRC = useSelector((state) => state.Common?.ATRC);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const intervalIDRef = React.useRef(null);
  const startTimer = React.useCallback(() => {
    var count = 0;
    intervalIDRef.current = setInterval(() => {
      document.getElementById("loading").innerHTML =
        "Loading" + new Array((++count % 4) + 1).join(".");
    }, 500);
  }, []);
  const stopTimer = React.useCallback(() => {
    clearInterval(intervalIDRef.current);
    document.getElementById("loading").innerHTML = "";
  }, []);
  // useEffect(() => {
  //   setTimeout(() => {
  //     const reloadCount = localStorage.getItem("reloadCount");
  //     if (reloadCount < 2) {
  //       localStorage.setItem("reloadCount", String(reloadCount + 1));
  //       window.location.reload();
  //     } else {
  //       localStorage.removeItem("reloadCount");
  //     }
  //   }, 3000);
  // }, []);
  useEffect(() => {
    if (location?.search) {
      startTimer();
      setLoading(true);
      getPayment(location?.search);
    }
    // eslint-disable-next-line
  }, [location]);
  // let dispatch = useDispatch();
  const getPayment = async (name) => {
    let pid = name
      ?.split("&payment_intent_client_secret=")[0]
      ?.split("?payment_intent=")[1];
    let scid = name
      ?.split("&payment_intent_client_secret=")[1]
      ?.split("&redirect_status=")[0];
    let rest = name
      ?.split("&payment_intent_client_secret=")[1]
      ?.split("&redirect_status=")[1];
    setDerror();
    if (pid && scid && rest) {
      let seData = {
        url: `/api/Conforence/CompletePaymentTransaction${name}&userId=${LogDetails?.userId}&conferenceId=${id}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      stopTimer();
      setLoading(false);

      if (res?.status === 200) {
        if (res.data.isPaid) {
          // let resSave = res.data;
          setPayment(scid);

          if (ATRC?.registerAs === "Attendee") {
            // let seData1 = {
            //   url: "/api/Attendee/SaveAttendeeUser",
            //   body: {
            //     ...ATRC,
            //     paymentStatus: "Success",
            //     paymentReference: scid,
            //   },
            //   headers: headers,
            // };
            // let res1 = await PostCallApi(seData1);
            // if (res1.status === 200) {
            //   if (res1.data.success) {
            //     dispatch(AttendeeRegistrationConference());
            //   }
            // }
          }
        } else {
          setDerror({ err: res?.data?.message });
        }
      } else {
      }
    }
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="payment_success py-5">
          {/* <img src={paymentBanner} className="img-fluid" /> */}
          <div className="main_wrapper">
            <div className="payment_inner">
              <div className="row">
                <div className="col-md-12">
                  <div id="loading" className="h6_text text-center"></div>
                  {derror?.err && (
                    <div>
                      <div>
                        <span role="alert" className="error_text">
                          If amount cut then refresh page one time.
                        </span>
                      </div>
                      <span role="alert" className="error_text">
                        {derror?.err}
                      </span>
                    </div>
                  )}
                  {derror?.err ? (
                    ""
                  ) : (
                    <>
                      {!loading && payment && (
                        <div className="success_message_box">
                          <BsFillPatchCheckFill className="icon" />
                          <div>
                            <h1 className="m-0">Payment Successful</h1>
                            <p>
                              We will share payment receipt on registered email.
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row paymentAndUserInfo mt-4">
                {derror?.err ? (
                  <div className="col-md-12 text-center mt-4">
                    <Link
                      to={`/dashboard`}
                      className="theme_btn"
                      style={{ textDecoration: "none" }}
                    >
                      DashBoard
                    </Link>
                  </div>
                ) : (
                  <>
                    {!loading && payment && (
                      <>
                        <div className="col-md-6">
                          <ul>
                            <li>
                              Payment Reference Id :{" "}
                              <span
                                style={{
                                  maxWidth: "300px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {payment}
                              </span>
                            </li>
                            <li>
                              Paid Amount : <span>{amt}</span>
                            </li>
                            {/* <li>Payment Date : <span>17 Aug 2023</span></li> */}
                            <li>
                              Payment Status : <span>Success</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul>
                            <li>
                              Name :{" "}
                              <span>
                                {LogDetails?.userId ? (
                                  <>
                                    {LogDetails?.firstName +
                                      " " +
                                      LogDetails?.lastName}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </li>
                            <li>
                              Email :{" "}
                              <span>
                                {LogDetails?.userId ? (
                                  <>{LogDetails?.email}</>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </li>
                            <li>
                              Phone :{" "}
                              <span>
                                {LogDetails?.userId ? (
                                  <>{LogDetails?.phone}</>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-12 text-end mt-4">
                          {LogDetails?.authenticate ? (
                            <>
                              <Link
                                className="theme_btn"
                                style={{ textDecoration: "none" }}
                                to={"/dashboard"}
                              >
                                Go To Dashboard
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default PaymentSuccess;
