import React, { useEffect, useState } from "react";
import "./index.scss";
// import send_icon from "../../../../images/Icon/send_mes.svg";
// import Earth from "../../../../images/Earth.svg";
import pro_img from "../../../../images/default_profile.jpg";
// import delete_icon from "../../../../images/Delete.svg";
// import camera from "../../../../images/Camera.png";
// import video from "../../../../images/Video.png";
// import lockOpen_icon from "../../../../images/Icon/lock-open.png";
// import attach from "../../../images/Attachment.png";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../../Utils/Config";
import Swal from "sweetalert2";
import { CreatePost } from "../../../../reducers/user";
// import Select from "react-select";
import { VscSend } from "react-icons/vsc";
import { HiOutlineCamera } from "react-icons/hi2";
import { IoVideocamOutline } from "react-icons/io5";
import { AiFillDelete } from "react-icons/ai";
import all_icon from "../../../../images/Icon/all_icon.png";
import active_img from "../../../../images/Icon/active_green.svg";
import { useSignalR } from "../../../../Utils/SignalRContext";

function Add_post(props) {
  const [open, setOpen] = useState(false);
  const [openCir, setOpenCir] = useState(false);
  const [derror, setDerror] = useState();
  const [imgArr, setImgArr] = useState([]);
  const [imgShowArr, setImgShowArr] = useState([]);
  const [vidArr, setVidArr] = useState([]);
  const [vidShowArr, setVidShowArr] = useState([]);
  const [privId, setPrivId] = useState("");
  const [userStatus, setUserStatus] = useState(false);
  const [grpId, setGrpId] = useState({
    value: "1",
    label: "All",
    img: all_icon,
  });
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const PostC = useSelector((state) => state.user.Post_data);
  let LoginUser = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const { connection } = useSignalR();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  const handleImg = (e) => {
    let is_succes = true;
    let imar = [];
    let newImageUrls = [];
    const chooseFiles = Array.prototype.slice.call(e.target?.files);
    if (e.target?.files?.length > 5) {
      setDerror({ img: "Please Select Only 5 Images" });
      return false;
    } else {
      chooseFiles.filter((file) => {
        if (file.size > 5242880 && is_succes === true) {
          setDerror({ img: `Select less than 5mb ${file?.name} Image` });
          is_succes = false;
        } else if (is_succes === true) {
          imar.push(file);
          newImageUrls.push(URL.createObjectURL(file));
        }
        return 0;
      });
    }
    if (is_succes && imar.length > 0) {
      setVidArr([]);
      setVidShowArr([]);
      setImgArr(imar);
      setImgShowArr(newImageUrls);
      setDerror({ img: "" });
    }
  };
  const handleVideo = (e) => {
    let is_succes = true;
    let viar = [];
    let chooseFiles = Array.prototype.slice.call(e.target?.files);
    let newImageUrls = [];

    if (e.target?.files?.length > 5) {
      setDerror({ vid: "Please Select Only 5 Videos" });
      is_succes = false;
    } else {
      chooseFiles?.filter((file, i) => {
        if (file.size > 10242880 && is_succes === true) {
          setDerror({ vid: ` Select less than 10mb ${file?.name} video` });
          is_succes = false;
        } else if (is_succes === true) {
          viar.push(file);
          newImageUrls.push(URL.createObjectURL(file));
        }
        return 0;
      });
    }
    if (is_succes && viar.length > 0) {
      setImgArr([]);
      setImgShowArr([]);
      setVidArr(viar);
      setVidShowArr(newImageUrls);
      setDerror({ vid: "" });
    }
  };

  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setUserStatus(status ? "Online" : "Offline");
      });
    }
  }, [connection?.state]);
  const onSubmit = (data) => {
    const productData = new FormData();
    productData.append("description", data?.description);
    productData.append("UserId", LogUserId);
    productData.append("createdBy", LogUserId);

    if (
      derror?.vid === "" ||
      (derror?.vid === undefined && derror?.img === "") ||
      derror?.vid === undefined
    ) {
      setOpenCir(!openCir);
      setOpen(true);
      if (imgArr.length > 0) {
        productData.append("PostTypeId", 1);
        imgArr?.map((img) => productData.append("ImagesAttachment", img));
      } else if (vidArr.length > 0) {
        productData.append("PostTypeId", 2);
        vidArr?.map((vid) => productData.append("VideosAttachment", vid));
      } else {
        productData.append("PostTypeId", 3);
      }
      let url = "";
      url = `${Config.API_HOST_URL_live}/api/CommunityChat/CreateCommunityPost`;

      productData.append(
        "PrivacyTypeId",
        grpId?.value === "1" ? 1 : grpId?.value === "3" ? 3 : 2
      );
      productData.append(
        "CommunityGroupId",
        grpId?.value === "1" || grpId?.value === "3"
          ? "00000000-0000-0000-0000-000000000000"
          : grpId.value
      );

      axios
        .post(url, productData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((responce) => {
          if (responce.data.success) {
            setOpenCir(false);
            setOpen(false);
            setVidArr([]);
            setVidShowArr([]);
            setImgArr([]);
            setImgShowArr([]);
            if (privId === "1") {
              setPrivId("1");
            } else if (privId === "3") {
              setPrivId("3");
            } else {
              setPrivId("2");
            }
            setValue("description", "");
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(responce.data.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (PostC) {
              dispatch(CreatePost(false));
            } else {
              dispatch(CreatePost(true));
            }
          } else {
            setOpenCir(false);
            setOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setOpenCir(false);
          setOpen(false);
        });
    }
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const RemoveImage = (e) => {
    const s = imgArr?.filter((item, index) => index !== e);
    const s1 = imgShowArr?.filter((item, index) => index !== e);
    setImgShowArr(s1); // update the state
    setImgArr(s); // update the state
  };
  const RemoveVideo = (e) => {
    const s = vidArr?.filter((item, index) => index !== e);
    // const+ s1 = vidShowArr?.filter((item, index) => index !== e);
    setVidShowArr(s?.map((file) => URL.createObjectURL(file))); // update the state
    setVidArr(s); // update the state
  };
  useEffect(() => {
    setGrpId(props?.grpList[0]);
    // eslint-disable-next-line
  }, [props?.grpList]);
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCir}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <div className="add_comment_box_new">
        <div className="comment_input_box d-flex">
          <div className="img_boxs">
            <div className="user_img">
              <img
                src={
                  LoginUser.thumbnailProfileUrl === null ||
                  LoginUser.thumbnailProfileUrl === ""
                    ? pro_img
                    : `${Config.API_HOST_URL_live}${LoginUser.thumbnailProfileUrl}`
                }
                alt=""
                className="img-fluid m-auto post_user_img"
                width={50}
                height={50}
              />
            </div>
            <img
              src={userStatus === "Online" ? active_img : ""}
              className="img-fluid dot_ac_in"
            />
          </div>
          <textarea
            className="form-control comment_field"
            placeholder="Share What's On Your Mind"
            {...register("description", {
              required: "Description is required",
            })}
          />
        </div>
        {imgShowArr.length > 0 && (
          <>
            <hr />
            <div className="row py-2 m-0">
              {imgShowArr.map((item, i) => (
                <div className="col-md-2">
                  <div className="img_box">
                    <AiFillDelete
                      className="delete_btn"
                      onClick={(e) => RemoveImage(i)}
                    />
                    {/* <img
                      src={delete_icon}
                      alt=""
                      className="delete_btn"
                      onClick={(e) => RemoveImage(i)}
                    /> */}
                    <img
                      src={item}
                      alt=""
                      className="img-fluid"
                      style={{ height: "125px" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {vidShowArr.length > 0 && (
          <>
            <hr />
            <div className="comment_input_box d-flex m-0">
              {vidShowArr.map((item, i) => (
                <div className="video_box">
                  <AiFillDelete
                    className="delete_btn"
                    onClick={(e) => RemoveVideo(i)}
                  />
                  {/* <img
                    src={delete_icon}
                    alt=""
                    className="delete_btn"
                    onClick={(e) => RemoveVideo(i)}
                  /> */}
                  <video width="180" height="125" controls>
                    <source src={item} type="video/mp4" />
                  </video>
                </div>
              ))}
            </div>
          </>
        )}
        <hr />
        <div className="action_button">
          <div className="d-flex gap-4">
            <label htmlFor="post_img">
              {/* <img src={camera} className="img-fluid" alt="camera" /> */}
              <HiOutlineCamera />
              Photo
            </label>
            <input
              multiple
              max={5}
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              id="post_img"
              style={{ display: "none" }}
              onChange={(e) => handleImg(e)}
            />

            <label htmlFor="post_video">
              {/* <img src={video} className="img-fluid" alt="video" /> */}
              <IoVideocamOutline />
              Video
            </label>
            <input
              multiple
              max={10}
              accept="video/*"
              type="file"
              id="post_video"
              style={{ display: "none" }}
              onChange={(e) => handleVideo(e)}
            />
          </div>
          <Autocomplete
            disablePortal
            className="form-select1"
            options={props?.grpList}
            value={grpId?.label ? grpId?.label : ""}
            onChange={(e, newValue) => {
              if (newValue === null) {
                setGrpId(props?.grpList[0]);
              } else {
                setGrpId(newValue);
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={option?.img ? option?.img : pro_img}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = pro_img;
                  }}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params, i) => (
              <TextField className="texfiled-demo" key={i} {...params} />
            )}
          />
          <label
            className="ms-auto pst_btn me-0"
            onClick={handleSubmit(onSubmit)}
          >
            {open ? (
              <IconButton type="butoon" disabled className="pst_btn">
                <CircularProgress sx={{ color: "#22beca" }} />
              </IconButton>
            ) : (
              <IconButton type="submit" className="pst_btn">
                <VscSend />
              </IconButton>
            )}
            {/* <VscSend />
            Send */}
          </label>

          {errors?.description && (
            <span role="alert" className="error_text">
              {errors?.description.message}
            </span>
          )}
          {derror?.img && (
            <span role="alert" className="error_text">
              {derror?.img}
            </span>
          )}
          {derror?.vid && (
            <span role="alert" className="error_text">
              {derror?.vid}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
export default React.memo(Add_post);
