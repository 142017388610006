import React, { useEffect, useState, useMemo } from "react";
import "./index.scss";
import Charts from "../../../Reuseable_Components/Chart/Charts";
import { Table } from "reactstrap";
import Popup from "../../../Reuseable_Components/Popup/Popup";
import Highcharts from "highcharts";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditCourseSechudle from "../../../Reuseable_Components/Popup/EditCourseSechudle";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import active_img from "../../../../images/Icon/active_green.svg";
import pro_img from "../../../../images/default_profile.jpg";

import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DashboardRe } from "../../../../reducers/user";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../Action/Action";
import Config from "../../../../Utils/Config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CourseCompleteAndSchedule(props) {
  const { acdYS, schId, ceuCourseList, courceData } = props;

  const [chart1, setChart1] = useState([]);
  const [userAllDe, setUserAllDe] = useState("");
  const [opencsDelete, setOpencsDelete] = useState(false);
  const [derrors, setDerrors] = useState({});
  const [courseId, setCourseId] = useState("");

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  let changeDash = useSelector((state) => state.user.Drefresh);
  // const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  // const headers = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //     Accept: "*/*",
  // };

  let chartOptions = {
    plotOptions: {
      pie: {
        colors: [
          "#1EBEC8",
          "#86C65A",
          "#F79153",
          // "#24CBE5",
          // "#64E572",
          // "#FF9655",
          // "#FFF263",
          // "#6AF9C4",
        ],
        allowPointSelect: true,
        cursor: "pointer",
        position: "right",
        dataLabels: {
          enabled: true,
          format: "{point.y}",
          distance: -50,
        },
        showInLegend: true,
      },
    },
    title: {
      text: "",
    },
    accessibility: {
      enabled: false,
    },
    series: [
      {
        // innerSize: "50%",
        data: chart1,
        keys: ["y", "name"],
        type: "pie",
      },
    ],
  };

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  useEffect(() => {
    if (acdYS) {
      if (schId) {
        if (courseId) {
          getChart1Data(courseId, schId, acdYS);
        }
      }
    }
    // eslint-disable-next-line
  }, [acdYS, schId, courseId]);

  useEffect(() => {
    if (ceuCourseList?.length > 0) {
      setCourseId(ceuCourseList[0]?.courseId);
    }

    // eslint-disable-next-line
  }, [ceuCourseList]);

  const getChart1Data = async (id, scId, Aeid) => {
    if (id) {
      const chart1Url = {
        url: `/api/Dashboard/GetCourseCompleteChartDataById?courseId=${id}&userId=${LogUserId}&schoolDistrictId=${scId}&academicYearId=${Aeid}`,
      };
      const response = await GetCallApi(chart1Url);
      if (response?.status === 200) {
        if (response.data.success) {
          let cha1 = response.data.payload?.map((item) => {
            return [item?.dataCounts, `${item?.status} ${item?.dataCounts}`];
          });
          setChart1(cha1);
        }
      } else {
        setChart1([]);
      }
    }
  };

  const handleCourseUserDelete = (id) => {
    setUserAllDe(id);
    setOpencsDelete(true);
    setDerrors({ comD: "" });
  };
  const handleClosecsDelete = () => {
    setOpencsDelete(false);
  };

  const Deleteschedule = async () => {
    
    let delUrl = {
      url: `/api/UserCourse/DeleteAssignCourse?userCourseAllocationId=${userAllDe}&deletedBy=${LogUserId}`,
      method:'post'
    };
    const response = await DeleteCallApi(delUrl);

    if (response?.status === 200) {
      if (response.data.success) {
        handleClosecsDelete();
        setUserAllDe("");
        if (changeDash) {
          dispatch(DashboardRe(false));
        } else {
          dispatch(DashboardRe(true));
        }
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        setDerrors({ comD: "" });
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        setDerrors({ comD: response.data?.message });
      }
    }
  };

  const handleCourseStart = async (cr) => {
    // courseTypes;
    let stCou = {
      courseId: cr.courseId,
      userId: LogUserId,
      status: "In Progress",
    };

    let coStartUrl = {
      url: `/api/UserCourse/StartUserCourse`,
      body: stCou,
    };
    const response = await PostCallApi(coStartUrl);
    if (response?.status === 200) {
      if (response.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });

        if (cr?.courseTypes === "1" || cr?.courseTypes === 1) {
          navigate(`/ceu-courses/start-course/${cr?.courseId}`, {
            replace: true,
          });
        } else if (cr?.courseTypes === "2" || cr?.courseTypes === 2) {
          navigate(`/fig-lifestyle/start-course/${cr?.courseId}`, {
            replace: true,
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };

  // Add state for sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Sorted data function
  const sortedData = useMemo(() => {
    if (!courceData) return [];
    const sortableData = [...courceData]; // Copy data to avoid mutation
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [courceData, sortConfig]);

  // Function to handle sort requests
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      <div className="chart_data_sec">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="chart_box">
              <div className="chart_title_box d-flex justify-content-between">
                <label className="chart_title">Course Completion</label>
              </div>
              <div className="chart_box_select">
                <select
                  className="form-select "
                  aria-label="Default select example"
                  value={courseId}
                  onChange={(e) => {
                    setCourseId(e.target.value);
                  }}
                >
                  <option value=""> Select Course Title</option>
                  {ceuCourseList &&
                    ceuCourseList?.map((item, i) => (
                      <option key={i} value={item?.courseId}>
                        {item?.courseTitle}
                      </option>
                    ))}
                </select>
                <Charts
                  series={chartOptions}
                  highcharts={Highcharts}
                  position="bottom"
                />
                {chart1?.length > 0 ? (
                  ""
                ) : (
                  <b className="d-flex justify-content-center ">
                    There is no Assign Course
                  </b>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-6">
            <div className="chart_box cource_box">
              <div className="chart_title_box d-flex justify-content-between">
                <Link to={"course-schedule"}>
                  <label
                    className="chart_title cur_point"
                    // onClick={handleCourseSchedule}
                  >
                    Course Schedule
                  </label>
                </Link>
                {DashBtnList?.map((check, i) => {
                  if (
                    check?.menuName === "Assign Course Button On Dashboard" &&
                    check?.isAssigned === true
                  ) {
                    return (
                      <div className="grid_top_button_group" key={i}>
                        <Popup btnName="Assign Course" />
                      </div>
                    );
                  }
                  return "";
                })}
              </div>
              <div className="table-responsive show_back">
                <Table className="data_table">
                  <thead>
                    <tr className="table_pading">
                      <th>No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Course Name</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courceData &&
                      courceData?.map((data, i) => (
                        <tr key={i + 1} className="table_pading">
                          <td>{i + 1}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <div className="img_boxs">
                                <div className="user_img">
                                  <img
                                    src={`${
                                      data?.profileUrl
                                        ? Config?.API_HOST_URL_live +
                                          data?.profileUrl
                                        : pro_img
                                    }`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <img
                                  src={
                                    data?.userStatus === "Online"
                                      ? active_img
                                      : ""
                                  }
                                  className="img-fluid dot_ac_in"
                                />
                              </div>
                              {data?.name}
                            </div>
                          </td>
                          <td>{data?.email}</td>
                          <td>{data?.courceName}</td>
                          <td>{data?.dueDate}</td>
                          <td
                            className={`${
                              data?.expired === "Expired" ||
                              data?.expired === "Scheduled"
                                ? "red"
                                : data?.expired === "In Progress"
                                ? "blue"
                                : data?.expired === "Completed"
                                ? "black"
                                : "green"
                            }`}
                          >
                            {data?.expired}
                          </td>
                          <td className="delete_cource ">
                            <div className="d-flex">
                              {LogUserId === data?.id ? (
                                <>
                                  {data?.expired === "In Progress" ||
                                  data?.expired === "Scheduled" ? (
                                    <button
                                      type="button"
                                      className="start"
                                      onClick={() => handleCourseStart(data)}
                                    >
                                      Start
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {data?.id === "NA" ? (
                                ""
                              ) : (
                                <>
                                  {LogUserde?.roleId === 1 ||
                                  LogUserde?.roleId === 2 ? (
                                    <>
                                      {data?.expired !== "Completed" && (
                                        <EditCourseSechudle data={data} />
                                      )}
                                      <button
                                        className="delete ms-2"
                                        type="button"
                                        onClick={() =>
                                          handleCourseUserDelete(
                                            data?.userCourseAllocationId
                                          )
                                        }
                                      >
                                        Delete
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClosecsDelete}
        aria-labelledby="customized-dialog-title"
        open={opencsDelete}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosecsDelete}
          dividers
        >
          Delete Course Schedule
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            Please confirm you want to Delete this Course Schedule.
          </Typography>
          {derrors?.comD && (
            <span role="alert" className="error_text">
              {derrors?.comD}
            </span>
          )}
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2" onClick={Deleteschedule}>
            Confirm
          </Button>
          <Button autoFocus onClick={handleClosecsDelete} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default CourseCompleteAndSchedule;
