import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Autocomplete,
  Button,
  TextField,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import search_icon from "../../../../images/Icon/table_search_icon.svg";
import Pagination from "../../../Reuseable_Components/Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import Popup from "../../../Reuseable_Components/Popup/Popup";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import EditCourseSechudle from "../../../Reuseable_Components/Popup/EditCourseSechudle";
import {
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardRe } from "../../../../reducers/user";
import active_img from "../../../../images/Icon/active_green.svg";
import pro_img from "../../../../images/default_profile.jpg";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",

    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function All_TableShow(prop) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("No");
  const tableRef = useRef(null);
  const [schList, setSchList] = useState([]);
  const [userStatusDrop, setUserStatusDrop] = useState("");
  let navigate = useNavigate();
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [downloadExcel, setDownloadExcell] = useState(false);
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [courseSchedule, setCourseSchedule] = useState([]);
  const [opencsDelete, setOpencsDelete] = React.useState(false);
  const [derrors, setDerrors] = useState({});
  const [openCircle, setOpenCircle] = useState(false);
  // const [repo, setRepo] = useState([]);
  const [acdYList, setAcdYList] = useState([]);
  let loc = useLocation();

  const handleClosecsDelete = () => {
    setOpencsDelete(false);
    setDerrors();
  };
  // const [pathNames, setPathNames] = useState("");

  const {
    getDashData,
    schId,
    setSchId,
    setCourseRosterID,
    ceuCourseList,
    courseRosterID,
    handleCourseUserDelete,
    setOpen,
    acdYS,
    setAcdYS,
  } = prop;
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCourseSchedule(prop?.dataValues);
    setCurrentPage(1);
  }, [prop?.dataValues]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: prop?.title ? prop?.title : "Table",
    sheet: "Users",
  });
  function descendingComparator(a, b, orderBy) {
    if (orderBy === "No" || orderBy === "NumOfCom" || orderBy === "ComCEu") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else {
      if (
        b[orderBy]?.toString()?.toLowerCase() <
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy]?.toString()?.toLowerCase() >
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
      handlePageChanege(currentPage);
    };

    return (
      <TableHead>
        <TableRow>
          {downloadPDF || downloadExcel ? (
            ""
          ) : (
            <>
              {prop?.title === "Course Schedule" && (
                <>
                  {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                    <StyledTableCell>
                      <div className="box_delete_course">
                        <label className="personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            disabled={
                              formattedSearchResults?.length === 0
                                ? true
                                : false
                            }
                            checked={checkBoxVal}
                            onChange={(e) => {
                              handleAll(e.target.checked);
                              setCheckBoxVal(e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>{" "}
                        </label>
                        <button
                          className="delete_btn_allschedulecourse "
                          onClick={() => {
                            setOpencsDelete(true);
                            setDerrors();
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 6H5H21"
                              stroke="#FA4747"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                              stroke="#FA4747"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 11V17"
                              stroke="#FA4747"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14 11V17"
                              stroke="#FA4747"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
          {prop?.headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              className={`${downloadPDF && "pdf_download"}`}
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {downloadPDF || downloadExcel ? (
                  ""
                ) : (
                  <>
                    {orderBy === headCell?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {downloadPDF || downloadExcel ? (
            ""
          ) : (
            <>
              {prop?.title === "Course Schedule" && (
                <StyledTableCell>Actions</StyledTableCell>
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [pageSize, setPageSize] = useState(20);
  const cret = useSelector((state) => state.user?.create_data);
  useEffect(() => {
    if (prop.title !== "Top Courses" || prop.title !== "Course Roster") {
      getSchoolList();
    }
    // setPathNames(loc.pathname);
    getAcademicYearList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (cret !== undefined) {
      setSearchField("");
    }
  }, [cret]);
  const [searchField, setSearchField] = useState("");
  const userstatus = courseSchedule?.filter((user) => {
    if (userStatusDrop !== "") {
      let state = false;
      if (user?.status === userStatusDrop) {
        state = true;
      }
      return state;
    } else return user;
  });
  const formattedSearchResults = userstatus?.filter((user) => {
    if (searchField) {
      let state = false;
      prop?.headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserde?.userId}`;
    axios
      .get(url, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value: item?.schoolDistrictId,
              label: item?.districtName,
            };
          });
          let schData = [
            {
              value: "00000000-0000-0000-0000-000000000000",
              label: "All School District",
            },
            ...data,
          ];
          setSchList(data?.length > 1 ? schData : data);
          setSchId(data?.length > 1 ? schId : data[0]);
        }
      })
      .catch((error) => console.log(error));
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const handleCourseStart = (cr) => {
    // courseTypes;
    let stCou = {
      courseId: cr.courseId,
      userId: LogUserde?.userId,
      status: "In Progress",
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/UserCourse/StartUserCourse`,
        stCou,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });

          if (cr?.courseTypes === "1" || cr?.courseTypes === 1) {
            navigate(`/ceu-courses/start-course/${cr?.courseId}`, {
              replace: true,
            });
          } else if (cr?.courseTypes === "2" || cr?.courseTypes === 2) {
            navigate(`/fig-lifestyle/start-course/${cr?.courseId}`, {
              replace: true,
            });
          }
        }
      });
  };
  useEffect(() => {
    if (downloadExcel) {
      onDownload();
      setDownloadExcell(false);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);
  useEffect(() => {
    if (downloadPDF) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPDF]);
  let Time = new Date();

  const handleDownloadPdf = async () => {
    setOpen(true);
    const element = tableRef.current;

    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();

    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;

        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`${prop?.title}.pdf`);
        setDownloadPDF(false);
        setOpen(false);
      });
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const back = {
    maxWidth: `${downloadPDF ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  const handleAll = (val) => {
    const newArr = stableSort(
      formattedSearchResults,
      getComparator(order, orderBy)
    )
      ?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      )
      ?.map((x) => {
        return { ...x, deletec: val };
      });
    const isSameUser = (a, b) => a.No === b.No && a.Name === b.Name;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(courseSchedule, newArr, isSameUser);
    const onlyInB = onlyInLeft(newArr, courseSchedule, isSameUser);

    const result = [...newArr, ...onlyInA, ...onlyInB];
    // console.log(result);

    setCourseSchedule(result);
  };
  const handleMainCheck = (e, index) => {
    let allMainCh = true;
    const newArr = courseSchedule.map((obj) => {
      if (obj.No === index.No) {
        return { ...obj, deletec: e };
      }
      return obj;
    });

    setCourseSchedule(newArr);
    if (e) {
      newArr?.map((x) => {
        if (x.deletec === false || x.deletec === undefined) {
          return (allMainCh = false);
        }
        return 0;
      });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  let changeDash = useSelector((state) => state.user.Drefresh);
  let dispatch = useDispatch();

  const deletemultiSc = () => {
    let Datasend = courseSchedule?.map((x) => {
      if (x.deletec === true) {
        return x.userCourseAllocationId;
      } else return 0;
    });
    Datasend = Datasend?.filter((x) => x !== 0);
    if (Datasend?.length > 0) {
      setOpenCircle(true);
      axios
        .delete(
          `${Config.API_HOST_URL_live}/api/UserCourse/DeleteMultipleAssignCourse?deletedBy=${LogUserde?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            data: Datasend,
          }
        )
        .then((response) => {
          if (response?.status === 200) {
            if (response?.data.success) {
              setOpenCircle(false);
              handleClosecsDelete();
              setCheckBoxVal(false);
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data?.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            } else {
              setOpenCircle(false);

              setDerrors({ comD: response?.data.message });
            }
          } else {
            setOpenCircle(false);
          }
        })
        .catch((e) => console.log(e));
    } else {
      setDerrors({ comD: "Please select checkbox for delete schedule course" });
    }
  };
  const handlePageChanege = (page) => {
    setCurrentPage(page);
    setCheckBoxVal(false);
    const newArr = courseSchedule.map((obj) => {
      return { ...obj, deletec: false };
    });
    setCourseSchedule(newArr);
  };

  const getAcademicYearList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const user = response?.data.payload?.map((item, i) => {
            return {
              academicYearId: item?.academicYearId ? item?.academicYearId : "",
              academicYear: item?.academicYear ? item?.academicYear : "",
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
            };
          });
          setAcdYList(user);
          let curtY = user?.filter((x) => x.isCurrentYear === "True");
          setAcdYS(curtY[0]?.academicYearId);
          getDashData(schId?.value, loc.pathname, curtY[0]?.academicYearId);
        }
      });
  };

  const handleAcademicYear = (e) => {
    setAcdYS(e);
    if (e) {
      getDashData(schId?.value, loc.pathname, e);
    } else {
      setCourseSchedule([]);
    }
  };

  return (
    <>
      {/* {loc?.pathname === "/reports" ? ( */}
      <>
        <label className="me-2">{prop?.title}</label>
        <div className="mb-4 listdata_control_sec ">
          <div className="sep_report_head  d-flex justify-content-between">
            <div className=" search_sec w-25  me-2 ">
              <div className="search_input w-100">
                <input
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <img src={search_icon} alt="" />
              </div>
            </div>
            <div className="d-flex">
              {prop?.title === "Course Schedule" ||
              prop?.title === "Top Students" ||
              prop?.title === "Course Roster" ? (
                <>
                  <select
                    name=""
                    id=""
                    className="form-select download_drop me-3 w-25"
                    value={acdYS}
                    onChange={(e) => handleAcademicYear(e.target.value)}
                  >
                    <option value="">Select Academic Year</option>
                    {acdYList &&
                      acdYList?.map((acy, i) => (
                        <option value={acy.academicYearId} key={i}>
                          {acy.academicYear}
                        </option>
                      ))}
                  </select>
                  <Autocomplete
                    disablePortal
                    className="form-select1 me-2 my-auto "
                    options={schList}
                    sx={{ width: 300 }}
                    value={schId?.label}
                    onChange={(e, newValue) => {
                      if (newValue === null) {
                        setSchId(schList[0]);
                        getDashData(schList[0]?.value, loc.pathname, acdYS);
                      } else {
                        setSchId(newValue);
                        getDashData(newValue.value, loc.pathname, acdYS);
                      }
                    }}
                    renderInput={(params, i) => (
                      <TextField
                        className="texfiled-demo"
                        key={i}
                        {...params}
                      />
                    )}
                  />
                </>
              ) : prop?.title === "Top Courses" ? (
                <select
                  name=""
                  id=""
                  className="form-select download_drop me-3 w-50"
                  value={acdYS}
                  onChange={(e) => handleAcademicYear(e.target.value)}
                >
                  <option value="">Select Academic Year</option>
                  {acdYList &&
                    acdYList?.map((acy, i) => (
                      <option value={acy.academicYearId} key={i}>
                        {acy.academicYear}
                      </option>
                    ))}
                </select>
              ) : (
                ""
              )}
              {prop?.title === "Course Roster" ? (
                <>
                  <select
                    className={`ms-auto form-select select_drop p_14_text w-50 my-auto`}
                    aria-label="Default select example"
                    value={courseRosterID}
                    onChange={(e) => {
                      setCourseRosterID(e.target.value);
                    }}
                  >
                    <option value=""> Select Course Title</option>
                    {ceuCourseList &&
                      ceuCourseList?.map((item, i) => (
                        <option key={i} value={item?.courseId}>
                          {item?.courseTitle}
                        </option>
                      ))}
                  </select>
                  <select
                    className={`ms-2 form-select select_drop p_14_text w-25 my-auto`}
                    aria-label="Default select example"
                    value={userStatusDrop}
                    onChange={(e) => {
                      setUserStatusDrop(e.target.value);
                    }}
                  >
                    <option value=""> All </option>
                    <option value="Completed"> Completed Users</option>
                    <option value="In Progress"> In Progress Users</option>
                    <option value="Scheduled"> Scheduled Users</option>
                  </select>
                </>
              ) : (
                ""
              )}
              {/* {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                <>
                  {prop?.title === "Course Schedule" ? (
                    <div className="grid_top_button_group">
                      <Popup btnName="Assign Course" />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )} */}
              {prop?.title === "Course Schedule" ? (
                <>
                  {DashBtnList?.map((check, i) => {
                    if (
                      check?.menuName === "Assign Course Button On Dashboard" &&
                      check?.isAssigned === true
                    ) {
                      return <Popup key={i} btnName="Assign Course" />;
                    }
                    return "";
                  })}
                </>
              ) : (
                ""
              )}
              <Button
                className="ms-3 delete_btn btn_space"
                onClick={() => {
                  setDownloadPDF(true);
                }}
              >
                PDF
              </Button>
              <Button
                className="ms-3 delete_btn btn_space"
                onClick={() => setDownloadExcell(true)}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </>
      {/* ) : ( */}

      {/* <div className="mb-4 d-flex justify-content-between listdata_control_sec ">
          <label className="me-2">{prop?.title}</label>
          <div className=" search_sec w-25  me-2 ">
            <div className="search_input w-100">
              <input
                onChange={(e) => {
                  setSearchField(e.target.value);
                  setCurrentPage(1);
                }}
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <img src={search_icon} alt="" />
            </div>
          </div>
          <div className="d-flex">
            {prop?.title === "Course Schedule" ||
            prop?.title === "Top Students" ||
            prop?.title === "Course Roster" ? (
              <>
                {prop?.title === "Top Students" || prop?.title === "Course Schedule" ? (
                  <select
                    name=""
                    id=""
                    className="form-select download_drop me-3 w-50"
                    value={acdYS}
                    onChange={(e) => handleAcademicYear(e.target.value)}
                  >
                    <option value="">Select Academic Year</option>
                    {acdYList &&
                      acdYList?.map((acy, i) => (
                        <option value={acy.academicYearId} key={i}>
                          {acy.academicYear}
                        </option>
                      ))}
                  </select>
                ) : ""}
                <Autocomplete
                  disablePortal
                  className="form-select1 me-2 my-auto "
                  options={schList}
                  sx={{ width: 300 }}
                  value={schId?.label}
                  onChange={(_e, newValue) => {
                    if (newValue === null) {
                      setSchId(schList[0]);
                      getDashData(
                        "00000000-0000-0000-0000-000000000000",
                        loc.pathname,
                        acdYS
                      );
                    } else {
                      setSchId(newValue);
                      getDashData(newValue.value, loc.pathname, acdYS);
                    }
                  }}
                  renderInput={(params, i) => (
                    <TextField className="texfiled-demo" key={i} {...params} />
                  )}
                />
              </>
            ) : (
              ""
            )}
            {prop?.title === "Course Roster" ? (
              <>
                <select
                  className={`ms-auto form-select select_drop p_14_text w-50 my-auto`}
                  aria-label="Default select example"
                  value={courseRosterID}
                  onChange={(e) => {
                    setCourseRosterID(e.target.value);
                  }}
                >
                  <option value=""> Select Course Title</option>
                  {ceuCourseList &&
                    ceuCourseList?.map((item, i) => (
                      <option key={i} value={item?.courseId}>
                        {item?.courseTitle}
                      </option>
                    ))}
                </select>
                <select
                  className={`ms-2 form-select select_drop p_14_text w-25 my-auto`}
                  aria-label="Default select example"
                  value={userStatusDrop}
                  onChange={(e) => {
                    setUserStatusDrop(e.target.value);
                  }}
                >
                  <option value=""> All </option>
                  <option value="Completed"> Completed Users</option>
                  <option value="In Progress"> In Progress Users</option>
                  <option value="Scheduled"> Scheduled Users</option>
                </select>
              </>
            ) : (
              ""
            )}
            {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
              <>
                {prop?.title === "Course Schedule" ? (
                  <div className="grid_top_button_group">
                    <Popup btnName="Assign Course" />
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Button
              className="ms-3 delete_btn btn_space"
              onClick={() => {
                setDownloadPDF(true);
              }}
            >
              PDF
            </Button>
            <Button
              className="ms-3 delete_btn btn_space"
              onClick={() => setDownloadExcell(true)}
            >
              Download
            </Button>
          </div>
          
        </div> */}
      {/* )} */}

      <div className="mt-3 school_district">
        <Paper className="table-box">
          <TableContainer className="" ref={tableRef} sx={back}>
            {downloadPDF && (
              <div
                className="logo_img_pdf d-flex justify-content-between align-items-center mb-5"
                style={{ padding: "0px 20px" }}
              >
                <img
                  src={require("../../../../images/Icon/20211.png")}
                  alt=""
                />
                <div style={{ marginRight: "20px" }}>
                  <label
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {prop?.title}
                  </label>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {schId?.label}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {" "}
                    {convertDate(
                      Time.toLocaleString("en-us", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                    )}{" "}
                    {Time.toLocaleTimeString()}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                    }}
                  >
                    Report Generated By:{" "}
                    {LogUserde?.firstName + " " + LogUserde?.lastName}
                  </div>
                </div>
              </div>
            )}
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(
                  formattedSearchResults,
                  getComparator(order, orderBy)
                )
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((row, index) => {
                    return (
                      <StyledTableRow className="table_body" key={index + 1}>
                        {downloadPDF || downloadExcel ? (
                          ""
                        ) : (
                          <>
                            {prop?.title === "Course Schedule" && (
                              <>
                                {LogUserde?.roleId === 1 ||
                                LogUserde?.roleId === 2 ? (
                                  <StyledTableCell className={`column_data`}>
                                    <label className="personal_detail_label">
                                      <input
                                        type="checkbox"
                                        className="login_check"
                                        checked={row.deletec ? true : false}
                                        onChange={(e) =>
                                          handleMainCheck(e.target.checked, row)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </>
                        )}
                        {prop?.headCells?.map((column) => {
                          const value = row[column?.id];
                          if (column?.id === "Name") {
                            return (
                              <StyledTableCell
                                className={`column_data ${
                                  downloadPDF && "pdf_download"
                                }`}
                                key={column?.id}
                              >
                                {/* <div className="d-flex align-items-center gap-2">
                                  <div className="img_boxs">
                                    <div className="user_img">
                                      <img
                                        src={`${row?.profileUrl ? Config?.API_HOST_URL_live + row?.profileUrl : pro_img}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <img
                                      src={
                                        row?.userStatus === "Online"
                                          ? active_img
                                          : ""
                                      }
                                      className="img-fluid dot_ac_in"
                                    />
                                  </div>
                                </div> */}
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                              </StyledTableCell>
                            );
                          }
                          if (column?.id === "StudentName") {
                            return (
                              <StyledTableCell
                                className={`column_data ${
                                  downloadPDF && "pdf_download"
                                }`}
                                key={column?.id}
                              >
                                {/* <div className="d-flex align-items-center gap-2">
                                  <div className="img_boxs">
                                    <div className="user_img">
                                      <img
                                        src={`${
                                          row?.profileUrl
                                            ? Config?.API_HOST_URL_live +
                                              row?.profileUrl
                                            : pro_img
                                        }`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <img
                                      src={
                                        row?.userStatus === "Online"
                                          ? active_img
                                          : ""
                                      }
                                      className="img-fluid dot_ac_in"
                                    />
                                  </div>
                                </div> */}
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                              </StyledTableCell>
                            );
                          }
                          if (column?.id === "Status") {
                            return (
                              <StyledTableCell
                                className={`column_data ${
                                  downloadPDF && "pdf_download"
                                }`}
                                key={column?.id}
                              >
                                <div
                                  className={` status ${
                                    row?.Status === "Completed"
                                      ? "black"
                                      : row.st === "e"
                                      ? "red"
                                      : row?.Status === "Expired" ||
                                        row?.Status === "Scheduled"
                                      ? "red"
                                      : row?.Status === "In Progress"
                                      ? "blue"
                                      : "green"
                                  }`}
                                >
                                  {row?.Status === "Completed"
                                    ? "Completed"
                                    : row?.st === "e"
                                    ? "Expired"
                                    : row?.Status === "Scheduled"
                                    ? "Scheduled"
                                    : row?.Status === "In Progress"
                                    ? "In Progress"
                                    : ""}
                                </div>
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell
                              className={`column_data ${
                                downloadPDF && "pdf_download"
                              }`}
                              key={column?.id}
                            >
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                        {downloadPDF || downloadExcel ? (
                          ""
                        ) : (
                          <>
                            {prop?.title === "Course Schedule" && (
                              <>
                                <StyledTableCell>
                                  <div className="d-flex">
                                    {LogUserde?.userId === row?.id ? (
                                      <>
                                        {row?.expire === "In Progress" ||
                                        row?.expire === "Scheduled" ? (
                                          <button
                                            type="button"
                                            className="start"
                                            onClick={() =>
                                              handleCourseStart(row)
                                            }
                                          >
                                            Start
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {LogUserde?.roleId === 1 ||
                                    LogUserde?.roleId === 2 ? (
                                      <>
                                        {row?.Status !== "Completed" && (
                                          <EditCourseSechudle data={row} />
                                        )}
                                        <button
                                          className="delete_course ms-2"
                                          type="button"
                                          onClick={() =>
                                            handleCourseUserDelete(
                                              row?.userCourseAllocationId
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </StyledTableCell>
                              </>
                            )}
                          </>
                        )}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {formattedSearchResults?.length === 0 ? (
            <div className="text-center my-3 p_20_text">Data Not Found</div>
          ) : (
            <div className="page_nation">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">show</span>
                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
                <span className="p_18_text">entries</span>
              </div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={formattedSearchResults?.length}
                pageSize={pageSize}
                onPageChange={(page) => handlePageChanege(page)}
              />
            </div>
          )}
        </Paper>
      </div>
      <BootstrapDialog
        onClose={handleClosecsDelete}
        aria-labelledby="customized-dialog-title"
        open={opencsDelete}
        className="block_popup"
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCircle}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosecsDelete}
          dividers
        >
          Delete Course Schedule
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            Please confirm you want to Delete this Course Schedule.
          </Typography>

          {derrors?.comD && (
            <span role="alert" className="error_text">
              {derrors?.comD}
            </span>
          )}
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2" onClick={deletemultiSc}>
            Confirm
          </Button>
          <Button autoFocus onClick={handleClosecsDelete} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
export default React.memo(All_TableShow);
