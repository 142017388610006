import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Swal from "sweetalert2";
import WebinarLive from "../WebinarLive";

function WebinarCard() {
  const [stateList, setStateList] = useState();
  const [webinarData, setWebinarData] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let sendData = {
      ...data,
    };
    let seData = {
      url: `/api/JoinUpcomingWebinar/CreateJoinUpcomingWebinar`,
      body: sendData,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        reset();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  useEffect(() => {
    getState();
    getWebinar();
    // eslint-disable-next-line
  }, []);

  const getState = async () => {
    let seData = {
      url: `/api/Common/GetStatesList`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data?.payload);
      }
    }
  };
  const getWebinar = async () => {
    var date1 = new Date().toLocaleDateString();
    let seData = {
      url: "/api/Webinar/GetMainSiteData",
      headers: {},
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ctData = res.data.payload
          ?.filter((x) => x?.webinarDate !== null)
          ?.filter((x) => new Date(x?.webinarDate) >= new Date(date1));
        setWebinarData(ctData);
      } else {
      }
    } else {
    }
  };
  return (
    <Suspense>
      <main>
        <section className="request-card-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title-text">JOIN AN UPCOMING WEBINAR</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grey-box">
                    <p>
                      Join us for a quick overview of how FIG K12 can help you
                      check all of the Professional Development boxes during an
                      administrative review. We will walk through tracking CEUs
                      for your employees from courses within FIG as well as
                      outside sources such as in-service days or conference
                      attendance. We will review what sets FIG apart from other
                      professional development resources and how we can help
                      your team grow professionally and personally.
                    </p>
                    <div className="row">
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Webinar</label>
                          <select
                            className="form-select"
                            {...register("webinarId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Webinar</option>
                            {webinarData?.map((st, i) => (
                              <option value={st.webinarId} key={i}>
                                {st?.title}
                              </option>
                            ))}
                          </select>
                          {errors.webinarId && (
                            <span role="alert" className="error_text">
                              {errors.webinarId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom"></div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register("firstName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span role="alert" className="error_text">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("lastName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.lastName && (
                            <span role="alert" className="error_text">
                              {errors.lastName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Email </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email "
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  "Entered value does not match email format",
                              },
                            })}
                          />
                          {errors.email && (
                            <span role="alert" className="error_text">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            className="form-select"
                            {...register("stateId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select State</option>
                            {stateList?.map((st, i) => (
                              <option value={st.stateId} key={i}>
                                {st?.stateCode} - {st?.stateName}
                              </option>
                            ))}
                          </select>
                          {errors.stateId && (
                            <span role="alert" className="error_text">
                              {errors.stateId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            {...register("phoneNo", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors.phoneNo && (
                            <span role="alert" className="error_text">
                              {errors.phoneNo.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Organization</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Organization"
                            {...register("organization", {
                              required: "This field is required",
                            })}
                          />
                          {errors.organization && (
                            <span role="alert" className="error_text">
                              {errors.organization.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Job Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Job Title"
                            {...register("jobTitle", {
                              required: "This field is required",
                            })}
                          />
                          {errors.jobTitle && (
                            <span role="alert" className="error_text">
                              {errors.jobTitle.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 padding-bottom">
                        <div className="form-group">
                          <label>Number of Employees</label>
                          <input
                            min={0}
                            type="number"
                            className="form-control"
                            placeholder="Number of Employees"
                            {...register("noOfEmployee", {
                              required: "This field is required",
                            })}
                          />
                          {errors.noOfEmployee && (
                            <span role="alert" className="error_text">
                              {errors.noOfEmployee.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="button-group">
                          <button className="primary-btn" type="submit">
                            Register
                          </button>
                          <Link
                            to={"/contactus"}
                            style={{ textDecoration: "none" }}
                            className="primary-btn"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <WebinarLive />
      </main>
    </Suspense>
  );
}

export default WebinarCard;
