import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import UserCourse from "./UserCourse";
import { DashboardRe } from "../../../../reducers/user";
import Select from "react-select";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";

function SetCource({ setOpen, courseId }) {
  const [values, setValues] = React.useState("1");
  const [dateCompletion2, setDateCompletion2] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [derrors, setDerrors] = useState({});
  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const [courseAdd, setCourseAdd] = useState([]);
  const [courseData, setCourseData] = useState(null);
const pathname = window?.location?.pathname;

  let dispatch = useDispatch();
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  const handleClose = () => {
    setDateCompletion2();
    setValues("1");
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    let is_success = true;

    if (dateCompletion2) {
      setDerrors({ D: "" });
      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      if (courseAdd.length > 0) {
        setDerrors({ course_code: "" });
      } else {
        setDerrors({ course_code: "Please select Course Category" });
        is_success = false;
      }
      let userdata = {
        ...data,
        expirationDate: convert(dateCompletion2),
        courseId: courseAdd?.map((x) => x),
        createdBy: LogUserId.userId,
      };

      function titleCase(str) {
        var splitStr = str?.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      if (is_success) {
        setOpenCircle(true);
        axios
          .post(
            `${Config.API_HOST_URL_live}/api/Group/AssignGroupToCourse`,
            userdata,
            { headers }
          )
          .then((response) => {
            if (response.data.success) {
              setOpenCircle(false);
              setDerrors({ ermsg: "" });
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              handleClose();
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data?.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            } else {
              setOpenCircle(false);
              setDerrors({ ermsg: response.data.message });
            }
          })
          .catch((error) => console.log(error));
      }
    } else {
      setDerrors({ D: "Select Date" });
    }
  };
  const [groupList, setGroupList] = useState();
  const [courseList, setCourseList] = useState();
  const [schList, setSchList] = useState();
  useEffect(() => {
    getSchoolList(LogUserId.userId);
    if (courseId !== null && courseId !== undefined) {
      getCeuCourseData(courseId, LogUserId.userId);
    }

   
    // getCourseList("00000000-0000-0000-0000-000000000000");
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (courseData !== null) {
      setTimeout(() => {
        getGroupList(courseData?.districtId);
        setValue("schoolDistrictId", courseData?.districtId);
        if (
         
          courseData?.courseLable === "AdminReview"
        ) {
          if(LogUserId?.roleId === 1 ){

            setValue("courseLable", courseData?.courseLable);
          }
        } else{

          setValue("courseLable", courseData?.courseLable);
        }
        getCourseList(courseData?.districtId, courseData?.courseLable);
        setTimeout(() => {
          setCourseAdd([courseData?.courseId]);
        }, 1000);
      }, 1000);
    }
  }, [courseData]);
  const getGroupList = async (schId) => {
    if (schId) {
      // let url = `${Config.API_HOST_URL_live}/api/Group/GetGroupsBySchoolDistrict?schoolDistrictId=${schId}`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let seData = {
        url: `/api/Group/GetGroupsBySchoolDistrict?schoolDistrictId=${schId}&userId=${LogUserId?.userId}`,
        headers: headers
      };
      const response = await GetCallApi(seData)
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              GroupId:
                item?.groupId === "" || item?.groupId === null
                  ? ""
                  : item.groupId,
              GroupName:
                item?.groupName === "" || item?.groupName === null
                  ? ""
                  : item.groupName,
            };
          });
          setGroupList(data);
        } else {
          setGroupList([]);
        }
      } else {
        setGroupList([]);
      }
      // })
      //   .catch ((error) => console.log(error));
    } else {
      setGroupList([]);
    }
  };
  const getCourseList = async (schId, cl) => {
    setCourseAdd([]);
    setCourseList([]);
    if (cl) {
      let seData = {
        url: `/api/Course/GetAssignCoursesDropDown?userId=${
          LogUserId.userId
        }&courseTypes=${
          pathname === "/ceu-courses"
            ? "1"
            : pathname === "/fig-lifestyle"
            ? "2"
            : "1"
        }&districtId=${schId}&courseLable=${cl}`,
        headers: headers,
      };
      // let url = `${Config.API_HOST_URL_live}/api/Course/GetCoursesDropDown?userId=${LogUserId.userId}&courseTypes=1`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let response = await GetCallApi(seData);
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data?.payload?.map((item) => {
            // let ct =
            //   item?.courseLable === "FigCEUCourses" ? "FIG Content" : item?.courseLable === "PartnerContent" ? "Partner Content" : item?.courseLable === "AdminReview" ? "Admin Only" : item?.courseLable === "GovernmentContent" ? "Government Content" : item?.courseLable === "FIGFaves" ? "FIG Faves" : item?.courseLable === "DistrictContent" ? "District Content" : "";
            return {
              value:
                item?.courseId === "" || item?.courseId === null
                  ? ""
                  : item.courseId,
              label:
                item?.courseTitle ?
                  item.courseTitle : ""
              // + " - " + ct : "",
            };
          });
          setCourseList(data);
        } else {

        }
      } else {

      }
    }
    // })
    // .catch((error) => console.log(error));
  };
  const getSchoolList = (userId) => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId:
                item?.schoolDistrictId === "" || item?.schoolDistrictId === null
                  ? ""
                  : item?.schoolDistrictId,
              schName:
                item?.districtName === "" || item?.districtName === null
                  ? ""
                  : item?.districtName,
            };
          });
          setSchList(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const getCeuCourseData = (courseId,userId) => {
    let url = `${Config.API_HOST_URL_live}/api/Course/GetAssignCourseInfoById?logUserId=${userId}&courseId=${courseId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response?.data?.success) {
          setCourseData(response?.data?.payload);
        }
      })
      .catch((error) => console.log(error));
  };
  const handleChangeCourceMulti = (e) => {
    setCourseAdd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleSchDrop = (e, cl) => {
    getGroupList(e);
    getCourseList(e ? e : "00000000-0000-0000-0000-000000000000", cl);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TabContext value={values}>
        <Box className="tab_group ">
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="User Course Expiration" value="1" className="tab_1" />
            <Tab label="Group Course Expiration" value="2" className="tab_2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tab_panel">
          <UserCourse
            handleClose={() => handleClose()}
            courseData={courseData}
          />
        </TabPanel>
        <TabPanel value="2" className="tab_panel">
          <div className="tab_panel_input_mt">
            <select
              className="form-select dropdown_group"
              aria-label="Default select example"
              {...register("schoolDistrictId", {
                required: {
                  value: true,
                  message: "This field is required",
                },
                onChange: (e) =>
                  handleSchDrop(
                    e.target.value,
                    getValues("courseLable") ? getValues("courseLable") : ""
                  ),
              })}
            >
              <option value=""> Select School District </option>
              {schList &&
                schList?.map((data, i) => (
                  <option key={i} value={data.schId}>
                    {data.schName}
                  </option>
                ))}
            </select>
            {errors.schoolDistrictId && (
              <span role="alert" className="error_text">
                {errors.schoolDistrictId.message}
              </span>
            )}
          </div>
          <div className="tab_panel_input_mt">
            <select
              className="form-select dropdown_group"
              aria-label="Default select example"
              {...register("groupId", {
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              // onChange={(e) => setGroupN(e.target.value)}
            >
              <option value=""> Select Group</option>
              {groupList &&
                groupList?.map((data, i) => (
                  <option key={i} value={data.GroupId}>
                    {data.GroupName}
                  </option>
                ))}
            </select>
            {errors.groupId && (
              <span role="alert" className="error_text">
                {errors.groupId.message}
              </span>
            )}
          </div>
          <div className="tab_panel_input_mt">
            <select
              className="form-select dropdown_group"
              {...register("courseLable", {
                required: "This field is required",
                onChange: (e) =>
                  getCourseList(
                    getValues("schoolDistrictId")
                      ? getValues("schoolDistrictId")
                      : "00000000-0000-0000-0000-000000000000",
                    e?.target?.value
                  ),
              })}
            >
              <option value="">Select Course Label</option>
              {LogUserId?.roleId === 1 && (
                <option value="AdminReview">Admin Only</option>
              )}
              <option value="DistrictContent">District Content</option>
              <option value="FigCEUCourses">Fig CEU Courses</option>
              <option value="PartnerContent">Partner Content</option>
              <option value="GovernmentContent">Government Content</option>
              <option value="FIGFaves">FIG Faves</option>
            </select>
            {errors.courseLable && (
              <span role="alert" className="error_text">
                {errors.courseLable.message}
              </span>
            )}
          </div>
          <div className="tab_panel_input_mt">
            <Select
              isMulti
              name="colors"
              options={courseList}
              className="course-caretory-multi-select p_14_text"
              classNamePrefix="select"
              placeholder="Select Multiple Course"
              value={courseList?.filter((obj) =>
                courseAdd?.includes(obj.value)
              )}
              onChange={handleChangeCourceMulti}
            />
            {derrors?.course_code && (
              <span role="alert" className="error_text">
                {derrors?.course_code}
              </span>
            )}
          </div>
          <div className="tab_panel_input_mt">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={dateCompletion2}
              onChange={setDateCompletion2}
              placeholderText="MM/DD/YYYY"
              className="Date_picker"
            />
            {derrors?.D && (
              <span role="alert" className="error_text">
                {derrors.D}
              </span>
            )}
            {derrors?.ermsg && (
              <span role="alert" className="error_text">
                {derrors.ermsg}
              </span>
            )}
          </div>
          <div className="tab_panel_input_mt">
            <button
              type="button"
              className="popup_btn update"
              onClick={handleSubmit(onSubmit)}
            >
              Update
            </button>
            <button
              type="button"
              className="popup_btn cancel"
              onClick={handleClose}
            >
              cancel
            </button>
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
}

export default React.memo(SetCource);
