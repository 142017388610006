import React, { useEffect, useState } from 'react'
import { ReportName, StoreGraphData } from '../../../../reducers/setting';
import Charts from '../../../Reuseable_Components/Chart/Charts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from "highcharts";
import { PostCallApi } from '../../../../Action/Action';
import Skeleton from "react-loading-skeleton";
import SkeletonBarGroup from './../../../Reuseable_Components/SkeletonBarGraph/index';

function PartnerAnalytics({ acdYS, schoolId }) {

    const [inProgress, setInProgress] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [skeleton, setSkeleton] = useState(true);

    const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

    const bargraph1 = {
        chart: {
            type: "column",
        },
        title: {
            text: "",
            align: "left",
        },
        tooltip: {
            shared: true,
            headerFormat: "<b> {point.x}</b>: ",
            pointFormat: " <b>{point.y} </b>",
        },
        legend: {
            // layout: "vertical",
            // align: "left",
            // verticalAlign: "top",
            x: 120,
            y: 70,
            floating: true,
            borderWidth: 1,
            backgroundColor: "#FFFFFF",
        },
        xAxis: {
            // plotBands: [
            //   {
            //     from: 2019,
            //   },
            // ],
            categories: inProgress?.map((x) => x?.courseTitle),
        },
        yAxis: {
            gridLineColor: "transparent",
            title: {
                text: "",
            },
            color: "#D73A3A",
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            // series: {
            //   pointStart: 2000,
            // },
            areaspline: {
                fillOpacity: 0.5,
            },
        },
        series: [{
            cursor: "pointer",
            data: inProgress?.map((x) => {
                return {
                    y: x?.statusCount,
                    courseId: x.courseId
                }
            }), color: '#86C65A',
            point: {
                events: {
                    click: function () {
                        let courseId = this.courseId;
                        let val = {
                            pageName: "Dashboard Summary Report",
                            courseId: courseId,
                            graphName: "In Progress",
                            acdYear: acdYS,
                            schId: schoolId,
                        };
                        handleDashRedirect(val);
                    },
                },
            },
        }],
    }

    const bargraph2 = {
        chart: {
            type: "column",
        },
        title: {
            text: "",
            align: "left",
        },
        tooltip: {
            shared: true,
            headerFormat: "<b> {point.x}</b>: ",
            pointFormat: " <b>{point.y} </b>",
        },
        legend: {
            // layout: "vertical",
            // align: "left",
            // verticalAlign: "top",
            x: 120,
            y: 70,
            floating: true,
            borderWidth: 1,
            backgroundColor: "#FFFFFF",
        },
        xAxis: {
            // plotBands: [
            //   {
            //     from: 2019,
            //   },
            // ],
            categories: completed?.map((x) => x?.courseTitle),
        },
        yAxis: {
            gridLineColor: "transparent",
            title: {
                text: "",
            },
            color: "#D73A3A",
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            // series: {
            //   pointStart: 2000,
            // },
            areaspline: {
                fillOpacity: 0.5,
            },
        },
        series: [{
            cursor: "pointer",
            data: completed?.map((x) => {
                return {
                    y: x?.statusCount,
                    courseId: x.courseId
                }
            }), color: '#1EBEC8',
            point: {
                events: {
                    click: function () {
                        let courseId = this.courseId;
                        let val = {
                            pageName: "Dashboard Summary Report",
                            courseId: courseId,
                            graphName: "Completed",
                            acdYear: acdYS,
                            schId: schoolId,
                        };
                        handleDashRedirect(val);
                    },
                },
            },
        }],
    }

    const bargraph3 = {
        chart: {
            type: "column",
        },
        title: {
            text: "",
            align: "left",
        },
        tooltip: {
            shared: true,
            headerFormat: "<b> {point.x}</b>: ",
            pointFormat: " <b>{point.y} </b>",
        },
        legend: {
            // layout: "vertical",
            // align: "left",
            // verticalAlign: "top",
            x: 120,
            y: 70,
            floating: true,
            borderWidth: 1,
            backgroundColor: "#FFFFFF",
        },
        xAxis: {
            // plotBands: [
            //   {
            //     from: 2019,
            //   },
            // ],
            categories: reviews?.map((x) => x?.courseTitle),
        },
        yAxis: {
            gridLineColor: "transparent",
            title: {
                text: "",
            },
            color: "#D73A3A",
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            // series: {
            //   pointStart: 2000,
            // },
            areaspline: {
                fillOpacity: 0.5,
            },
        },
        series: [{
            cursor: "pointer",
            data: reviews?.map((x) => {
                return {
                    y: x?.statusCount,
                    courseId: x.courseId
                }
            }), color: '#F79153',
            point: {
                events: {
                    click: function () {
                        let courseId = this.courseId;
                        let val = {
                            pageName: "Dashboard Summary Report",
                            courseId: courseId,
                            graphName: "Reviews",
                            acdYear: acdYS,
                            schId: schoolId,
                        };
                        handleDashRedirect(val);
                    },
                },
            },
        }],
    }

    let nav = useNavigate();
    let dispatch = useDispatch();

    const handleDashRedirect = (val) => {
        nav("/reports", { replace: true });
        dispatch(ReportName(val?.pageName));
        dispatch(StoreGraphData(val));
    }

    useEffect(() => {
        if (acdYS) {
            if (schoolId) {
                getGraphData(acdYS, schoolId);
            }
        } else {
            getGraphData(0, "00000000-0000-0000-0000-000000000000");
        }
        // eslint-disable-next-line
    }, [acdYS, schoolId])

    const getGraphData = async (acdYear, schId) => {
        setSkeleton(true)
        setCompleted([]);
        setInProgress([]);
        setReviews([]);
        let seData = {
            status: null,
            courseId: null,
            schoolDistrictId: schId,
            userId: LogUserId,
            academicYearId: parseInt(acdYear),
        }

        let apiUrl = {
            url: `/api/Dashboard/GetCourseStatusCount`,
            body: seData
        }

        const resp = await PostCallApi(apiUrl);
        setSkeleton(false);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                let allCompleted = resp?.data?.payload?.completed.map((x) => {
                    return {
                        ...x
                    }
                })
                setCompleted(allCompleted ?? []);

                let allInProgress = resp?.data?.payload?.inProgress.map((x) => {
                    return {
                        ...x
                    }
                })
                setInProgress(allInProgress ?? []);

                let allReviews = resp?.data?.payload?.reviews.map((x) => {
                    return {
                        ...x
                    }
                })
                setReviews(allReviews ?? []);
            }
        }
    }

    return (
        <>
            {skeleton ? (
                <>
                    <div className="chart_data_sec">
                        <div className="row">
                            {[...Array(3)?.keys()]?.map((_, i) => (
                                <div className={`${i === 2 ? "col-xl-12 col-lg-12 col-md-12 mt-3" : "col-xl-6 col-lg-6 col-md-12"}`} key={i}>
                                    <div className="chart_box">
                                        <Skeleton width="50%" height="30px" className="skeleton-label" />
                                        <div className="chart_box_select mt-3">
                                            <SkeletonBarGroup />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>)
                :
                <>
                    <div className="chart_data_sec">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="chart_box mt-3">
                                    <div className="chart_title_box d-flex justify-content-between mb-3">
                                        <label className="chart_title">
                                            Course In Progress
                                        </label>
                                    </div>
                                    <div className="chart_box_select">
                                        <Charts
                                            series={bargraph1}
                                            highcharts={Highcharts}
                                            position="bottom"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="chart_box mt-3">
                                    <div className="chart_title_box d-flex justify-content-between mb-3">
                                        <label className="chart_title">
                                            Course Reviews
                                        </label>
                                    </div>
                                    <div className="chart_box_select">
                                        <Charts
                                            series={bargraph3}
                                            highcharts={Highcharts}
                                            position="bottom"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="chart_box mt-3">
                                    <div className="chart_title_box d-flex justify-content-between mb-3">
                                        <label className="chart_title">
                                            Course Completed
                                        </label>
                                    </div>
                                    <div className="chart_box_select">
                                        <Charts
                                            series={bargraph2}
                                            highcharts={Highcharts}
                                            position="bottom"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PartnerAnalytics