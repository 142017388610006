import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./Index.scss";
import header_logo from "../../images/Header_logo_1.png";
import NavigateComponent from "./NavigateComponent";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../reducers/login";
import Config from "../../Utils/Config";
import pro_img from "../../images/default_profile.jpg";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import { resetSetting } from "../../reducers/setting";
import information from "../../images/Icon/information.svg";
// import pdf from "../../images/pdf.png";
import axios from "axios";
import { PostCallApi } from "./../../Action/Action";
import Swal from "sweetalert2";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotification = Boolean(anchorEl);
  const [notificationList, setNotificationList] = useState([]);
  const [readNotification, setReadNotification] = useState(false);
  let nav = useNavigate();
  let dispatch = useDispatch();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LoginId = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let LoginUserd = useSelector((state) => state.login?.LoginDetails);
  const handleClick = (event) => {
    if (notificationList?.length > 0) {
      setAnchorEl(event?.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleMessage = () => {
  //   nav("/messages", { replace: true });
  // };
  // const handleHelp = () => {
  //   nav("/help", { replace: true });
  // };
  const handleNav = async () => {
    setOpenCircle(true);
    let apiBody = {
      createdBy: LoginId?.userId,
      logOutName: LoginId?.firstName + " " + LoginId?.lastName,
      email: LoginId?.email,
      type: "Web",
    };

    let sendData = {
      url: `/api/Common/SaveUserActivityLogOut`,
      body: apiBody,
    };

    let resp = await PostCallApi(sendData);
    setOpenCircle(false);
    if (resp?.status === 200 && resp?.data?.success) {
      localStorage.clear("expireDate");
      dispatch(LoginUser({ authenticate: false }));
      dispatch(resetSetting());
      nav("/", { replace: true });
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: resp?.data?.message
          ? resp?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  useEffect(() => {
    getNotification();
    const interval = setInterval(() => getNotification(), 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [readNotification]);
  const getNotification = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Common/GetNotificationByUserId?userId=${LoginUserd.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          let notificationData = response.data.payload?.map((notify, i) => {
            // if (notify.isRead === false) {
            return {
              isRead: notify.isRead,
              notificationId: notify?.notificationId,
              notificationText: notify?.notificationText,
              userId: notify.user?.userId,
              user: notify.user?.firstName + " " + notify.user?.lastName,
              imgUser:
                notify?.user?.profileUrl === undefined ||
                  notify?.user?.profileUrl === "" ||
                  notify?.user?.profileUrl === null
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${notify?.user?.profileUrl}`,
              notificationType: notify?.notificationType,
              createdOn: notify?.createdOn,
              createdBy: notify?.createdBy,
            };
            // }
            // return 0;
          });
          notificationData = notificationData.filter(function (element) {
            return element !== undefined;
          });
          notificationData = notificationData.filter(function (element) {
            return element !== 0;
          });
          setNotificationList(notificationData);
        } else {
          setNotificationList([]);
        }
      });
  };
  const HandleNotificationRedirect = (e) => {
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Common/UpdateNotification?notificationId=${e.notificationId}`,
        {},
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setReadNotification(!readNotification);
        }
      });
    if (e?.notificationType === "Message" || e?.notificationType === "Chat") {
      nav("/messages", { replace: true });
    } else if (e?.notificationType === "Post") {
      nav("/community", { replace: true });
    } else if (e?.notificationType === "CourseComplete") {
      nav(`/certificate/report/${e?.userId}`, { replace: true });
    }
    handleClose();
  };

  const handleRead = async () => {
    let apiUrl = {
      url: `/api/Common/UpdateNotificationByUserId?userId=${LoginUserd.userId}`
    }
    let resp = await PostCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        getNotification();
        handleClose();
      }
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className={`navigation`}>
        <div className="container-fluid">
          <div className="main_wrapper">
            <Navbar expand="xl" className="nav_bar">
              <div className="mobile_toggle">
                <NavLink to="/dashboard">
                  <img
                    src={header_logo}
                    className="header_logo img-fluid"
                    alt="header_logo"
                  />
                </NavLink>
                <NavbarToggler
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12H16"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 18H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavbarToggler>
              </div>
              <Collapse isOpen={isOpen} navbar className="header_nav">
                <NavLink to="/dashboard" className="main_logo">
                  <img
                    src={header_logo}
                    className="header_logo"
                    alt="header_logo"
                  />
                </NavLink>
                <NavigateComponent />
                <Nav navbar className="nav_action align-items-center">
                  <Tooltip
                    title="FIG User Manual"
                    placement="top"
                    arrow
                    className="tooltip_manage"
                  >
                    <div>
                      <Link
                        to={`${Config.API_HOST_URL_live}/Manuals/employee_role_user_manual.pdf`}
                        className="header_search_button "
                        // className="header_search_button btn"
                        target="_blank"
                      // download
                      >
                        User Manual
                        {/* <img
                          src={pdf}
                          className="img-fluid"
                          alt=""
                          width={24}
                          height={24}
                        /> */}
                      </Link>
                    </div>
                  </Tooltip>
                  <Tooltip title="Help" placement="top-start" arrow
                    className="tooltip_manage "
                  >
                    <div>
                      <Link
                        to={"/help"}
                        className="header_search_button "
                      // onClick={handleHelp}
                      >
                        <img src={information} className="img-fluid" alt="" />
                      </Link>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title="Message"
                    placement="top-start"
                    arrow
                    className="tooltip_manage "
                  >
                    <div>
                      <Link
                        to={"/messages"}
                        className="header_search_button "
                      // onClick={handleMessage}
                      >
                        <Badge>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_6_815)">
                              <path
                                d="M23 11.3889C23.0042 13.0021 22.6273 14.5934 21.9 16.0333C21.0376 17.7588 19.7119 19.2101 18.0713 20.2247C16.4307 21.2393 14.5401 21.777 12.6111 21.7778C10.9979 21.782 9.40657 21.4051 7.96666 20.6778L1 23L3.32222 16.0333C2.59491 14.5934 2.21801 13.0021 2.22222 11.3889C2.22297 9.45995 2.76075 7.56929 3.77532 5.9287C4.78989 4.28811 6.24119 2.96239 7.96666 2.10003C9.40657 1.37273 10.9979 0.995829 12.6111 1.00003H13.2222C15.7697 1.14058 18.1759 2.21585 19.98 4.01997C21.7841 5.82409 22.8594 8.23027 23 10.7778V11.3889Z"
                                stroke="#2C2E35"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <circle cx="8" cy="12" r="1" fill="#2C2E35" />
                              <circle cx="12" cy="12" r="1" fill="#2C2E35" />
                              <circle cx="16" cy="12" r="1" fill="#2C2E35" />
                            </g>
                            <defs>
                              <clipPath id="clip0_6_815">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </Badge>
                      </Link>
                    </div>
                  </Tooltip>

                  <Tooltip
                    title="Notification"
                    placement="top-start"
                    arrow
                    className="tooltip_manage last"
                  >
                    <div>
                      <Button
                        className="header_search_button"
                        onClick={handleClick}
                        sx={{ minWidth: "max-content", padding: "0px" }}
                      >
                        <Badge
                          badgeContent={
                            notificationList
                              ? notificationList?.filter((x) => x?.isRead === false)?.length
                              : 0
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                              stroke="#2C2E35"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                              stroke="#2C2E35"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Badge>
                      </Button>
                    </div>
                  </Tooltip>

                  <Dropdown className="drop_down">
                    <Dropdown.Toggle className="header_option ">
                      <img
                        src={
                          // LoginUserd?.profileUrl === "" ||
                          //   LoginUserd?.profileUrl === null
                          //   ? pro_img
                          //   : `${Config.API_HOST_URL_live}${LoginUserd?.profileUrl}`
                          LoginUserd?.thumbnailProfileUrl === "" ||
                            LoginUserd?.thumbnailProfileUrl === null
                            ? pro_img
                            : `${Config.API_HOST_URL_live}${LoginUserd?.thumbnailProfileUrl}`
                        }
                        alt="profile"
                        className="user_profile"
                      />
                      {/* {window.innerWidth < 992 || window.innerWidth > 1200 ? ( */}
                      {/* <div className="profile_info">
                        <span className={`header_login_icon `}>
                          {LoginUserd?.firstName + " " + LoginUserd?.lastName}
                        </span>
                        <span className={`header_login_icon role d-block`}>
                          {LoginUserd?.roleName}
                        </span>
                        <span className="header_login_icon district_name d-block">
                          {LoginUserd?.schooldistrictName}
                        </span>
                      </div> */}
                      {/* ) : (
                        ""
                      )} */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* {window.innerWidth < 1200 && window.innerWidth > 992 ? (
                        <> */}
                      <di className="w-100 ">
                        <div
                          className="profile_info"
                          style={{ marginLeft: "10px", padding: "0 8px" }}
                        >
                          <span className={`header_login_icon `}>
                            {LoginUserd?.firstName + " " + LoginUserd?.lastName}
                          </span>
                          <span
                            className={`header_login_icon p_14_text role d-block`}
                            style={{ color: " #36454F" }}
                          >
                            {LoginUserd?.roleName}
                          </span>
                          <span
                            className="header_login_icon p_14_text district_name d-block"
                            style={{ color: " #36454F" }}
                          >
                            {LoginUserd?.schooldistrictName}
                          </span>
                        </div>
                      </di>
                      <Dropdown.Divider />
                      {/* </>
                      ) : (
                        ""
                      )} */}
                      <IconButton className="w-100 logout_bnt">
                        <Link
                          className="header_user_Link w-100 d-flex justify-content-start"
                          to="/edit-profile"
                        >
                          <span className="header_user_link_text1">
                            Profile
                          </span>
                        </Link>
                      </IconButton>
                      <Dropdown.Divider />
                      <IconButton className="w-100 logout_bnt">
                        <Link
                          className="header_user_Link w-100 d-flex justify-content-start"
                          to="/email-preference"
                        >
                          <span className="header_user_link_text1">
                            Email Preference
                          </span>
                        </Link>
                      </IconButton>
                      <Dropdown.Divider />
                      {/* <IconButton className="w-100 logout_bnt">
                        <Link
                          className="header_user_Link w-100 d-flex justify-content-start"
                          to="/privacy"
                        >
                          <span className="header_user_link_text1">
                            Privacy
                          </span>
                        </Link>
                      </IconButton>              
                      <Dropdown.Divider /> */}
                      <IconButton className="w-100 logout_bnt">
                        <Link
                          className="header_user_Link w-100 d-flex justify-content-start"
                          to="/logged-reset-password"
                        >
                          <span className="header_user_link_text1">
                            Reset Password
                          </span>
                        </Link>
                      </IconButton>
                      <Dropdown.Divider />
                      <IconButton
                        onClick={handleNav}
                        className="w-100 logout_bnt"
                      >
                        <span className="header_user_link_text2">Logout</span>
                      </IconButton>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </section>
      <Menu
        className="notification_list"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openNotification}
        onClose={handleClose}
      >
        {notificationList?.map((notifi, i) => {
          return (
            <MenuItem
              className={`${notifi?.isRead ? "noShow_colors" : "show_color"}`}
              key={i}
              onClick={() => HandleNotificationRedirect(notifi)}
            >
              <div className='notification_box'>
                <div className="img_box me-3">
                  <img src={notifi?.imgUser} alt="" />
                </div>
                <div className="notification_msg">
                  <div className="p_16_text">{notifi?.user}</div>
                  <div className="p_14_text">{notifi?.notificationText}</div>
                </div>
              </div>
            </MenuItem>
          );
        })}
        {notificationList?.filter((x) => x?.isRead === false)?.length > 0 ?
          <div className="mark_as_read">
            <Link onClick={handleRead}>Mark all as read</Link>
          </div>
          : <div className="mark_as_read">
            <Link onClick={handleClose}>Mark all as read</Link>
          </div>}
      </Menu>
    </>
  );
}

export default React.memo(Navigation);
