import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Config from "../../../../Utils/Config";
import Addpost from "../../../Reuseable_Components/Add_post";
import Postshow from "../../../Reuseable_Components/Post_show";
import MemberList from "./MemberList";
import pro_img from "../../../../images/default_profile.jpg";
import { useLocation } from "react-router-dom";
import Uploadpost from "../../../Reuseable_Components/Upload_post";
import InviteList from "./InviteList";

function CommunityFeed({ comGroupId, pvtId, searchField, orgId }) {
  const [postList, setPostList] = useState([]);
  const PostC = useSelector((state) => state.user.Post_data);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [likeId, setLikeId] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let location = useLocation();
  useEffect(() => {
    if (comGroupId) {
      getPostList(comGroupId);
    }
    // eslint-disable-next-line
  }, [comGroupId, PostC, likeId, pvtId]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getPostList = (comId) => {
    let doc = location.pathname?.split("/")[1];
    let totalre = doc === "community-groups" ? 0 : 20;
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityChat/GetCommunityPostDataByUserId?LikedByUserId=${LogUserId}&communityGroupId=${comId}&totalRecord=${totalre}&privacyTypeId=${pvtId}`,
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          const ptList = responce.data.payload?.map((item, i) => {
            return {
              ...item,
              key: i,
              postId: item?.communityPostId,
              name: item?.firstName + " " + item?.lastName,
              userPImg:
                item?.profileUrl === "" || item?.profileUrl === null
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
              postTypes: item?.postTypes,
              attachments: item.attachments?.map((att, i) => {
                return {
                  postId: att?.communityPostId,
                  postTransactionId: att?.communityPostTransactionId,
                  attachmentUrl: att?.attachmentUrl,
                };
              }),
              description: item?.description,
              date:
                item?.date === "" || item?.date === null
                  ? ""
                  : convert(item.date?.split("T")[0]),
              userId: item?.createdBy,
              like: item?.isLiked,
              totalLike: item?.totalLike,
              comments: item?.comments,
              totalComment: item?.totalComment,
              isCommunityPost: pvtId === 2 ? false : item?.isCommunityPost,
              communityGroupName: item?.communityGroupName,
            };
          });
          setPostList(ptList);
        } else {
          setPostList([]);
        }
      });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }
  const [comId, setComId] = useState("");
  const [CommentReply, setCommentReply] = useState(false);
  const handleComment = (id) => {
    setCommentReply(true);
    setComId(id);
    setPsEdit(false);
    if (id === comId) {
      setCommentReply(!CommentReply);
    }
  };
  const [psEd, setPsEd] = useState("");
  const [psEdit, setPsEdit] = useState(false);
  const handleEdit = (id) => {
    setCommentReply(false);
    setPsEdit(true);
    setPsEd(id);
    if (id === psEd) {
      setPsEdit(!psEdit);
    }
  };
  const filterData = postList?.filter((post) => {
    if (searchField) {
      return post?.description
        ?.toLowerCase()
        ?.includes(searchField.toLowerCase());
    } else return post;
  });
  const [readMore, setReadMore] = useState(false);
  const [readId, setReadId] = useState("");
  const handleReadMore = (id) => {
    setReadMore(true);
    setReadId(id);
    if (id === readId) {
      setReadMore(!readMore);
    }
  };
  const handleReadLess = (id) => {
    if (id === readId) {
      setReadMore(!readMore);
    }
  };
  return (
    <div className="community_feed">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12">
          <Addpost comGroupId={comGroupId} />
          {filterData?.map((item, i) => (
            <Postshow
              comGroupId={comGroupId}
              key={i}
              data={item}
              likeId={likeId}
              setLikeId={setLikeId}
              comId={comId}
              CommentReply={CommentReply}
              handleComment={handleComment}
              setCommentReply={setCommentReply}
              psEd={psEd}
              psEdit={psEdit}
              handleEdit={handleEdit}
              setPsEdit={setPsEdit}
              handleReadMore={handleReadMore}
              readMore={readMore}
              readId={readId}
              handleReadLess={handleReadLess}
            />
          ))}
          {postList?.length === 0 ? (
            <div className="text-center p_20_text mb-3">No Post Found</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12">
          {location?.pathname === "/community-old" ? (
            <Uploadpost />
          ) : (
            <>
              {orgId === LogUserId || LogUserde?.roleId === 1 ? (
                <InviteList comGroupId={comGroupId} />
              ) : (
                ""
              )}
              <MemberList comGroupId={comGroupId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CommunityFeed);
