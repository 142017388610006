import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoginDetails: { authenticate: false },
  CleverDetails: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    LoginUser(state, action) {
      state.LoginDetails = action.payload;
    },
    CleverClientId(state, action) {
      state.CleverDetails = action.payload;
    }
  },
});

export const { LoginUser, CleverClientId } = loginSlice.actions;

export default loginSlice.reducer;
