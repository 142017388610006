import React, { useEffect, useState } from "react";
import ViewGroups from "./Components/ViewGroups";
import back_img from "../../../images/Cover_photo_user_profile.png";
import "./index.scss";
import Profileimg from "./Components/ViewProfileimg";
import incomplete_img from "../../../images/incomplete_icon.png";
import complete_img from "../../../images/complete_icon.png";
import Aboutsec from "./Components/ViewAboutsec/Index";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import userimg2 from "../../../images/user10.png";
import { useParams } from "react-router";
import ViewConnection from "./Components/ViewConnection";
import ViewCourceProgress from "./Components/ViewCourceprogress";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function ViewProfile() {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [userProfile, setUserProfile] = useState({});
  const [ceuCardData, setCeuCardData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getProfileDetail();
    getMYcouses();
    // eslint-disable-next-line
  }, []);
  const getProfileDetail = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/MyProfile?userId=${id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setUserProfile(response.data?.payload);
        }
      });
  };

  const getMYcouses = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyCourseList?userId=${id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          let clist = response.data.payload?.map((item) => {
            return {
              id: item?.courseId,
              cardTitle: item?.courseTitle,
              cardimg: `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`,
              startCompleted: item?.status === null ? "" : item?.status,
              lesson:
                item?.totalLession === "" || item?.totalLession === ""
                  ? ""
                  : item?.totalLession + " Lessons",
              perCompleted: item?.progress ? item?.progress : "0",
              rating: item?.ratings ? item.ratings : "0",
              userimg:
                item?.instructor === null ||
                item?.instructor?.profileUrl === "" ||
                item?.instructor?.profileUrl === null
                  ? userimg2
                  : `${Config.API_HOST_URL_live}${item.instructor?.profileUrl}`,
              userName:
                item?.instructor === null ||
                item?.instructor?.fistName === "" ||
                item?.instructor?.fistName === null
                  ? ""
                  : item.instructor?.firstName +
                    " " +
                    item?.instructor?.lastName,
              stepActive: `0/${item?.totalLession} steps`,
              userStatus: item?.userStatus
            };
          });
          setCeuCardData(clist);
        }
      });
  };
  return (
    <>
      <section className="Profile_page main_backcolor">
        <div className="profile_header_back_img">
          <img
            className="back_img"
            src={
              userProfile?.coverPhoto === "" ||
                userProfile?.coverPhoto === null
                ? back_img
                : `${Config.API_HOST_URL_live}${userProfile?.coverPhoto}`
            }
            alt="user"
          />
        </div>
        <div className="container-fluid">
          <div className="main_wrapper">
            <>
              <div className="profile-overlap mb-3">
                <Profileimg
                  name={userProfile?.firstName + " " + userProfile?.lastName}
                  id_name="@lina_al"
                  joined={userProfile?.userJoiningDate}
                  active="Active now"
                  roleName={userProfile?.roleName}
                  followers={userProfile?.followers}
                  following={userProfile?.following}
                  profileUrl={userProfile?.profileUrl}
                  ceu_goal_percent="0"
                />
                <div className="profile_info">
                  <h2>{userProfile?.firstName + " " + userProfile?.lastName}</h2>
                  <div className="d-flex profile_details_name">
                    <p>{userProfile?.followers} followers</p>
                    <p>{userProfile?.following} following</p>
                  </div>
                </div>
              </div>
              <div className="comment_tabpanel">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    className="comment_tab_button"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab label="Intro" {...a11yProps(0)} />
                    <Tab label="Connections" {...a11yProps(1)} />
                    <Tab label="Groups" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} class="tabPanel_div">
                  <Aboutsec
                    title_text={userProfile?.aboutUs}
                    sub_title={userProfile?.schoolDistrictName}
                    coverPhoto={userProfile?.coverPhoto}
                    profileUrl={userProfile?.profileUrl}
                    incomplete_icon={incomplete_img}
                    complete_icon={complete_img}
                  />
                  <ViewCourceProgress ceuCardData={ceuCardData} />
                </TabPanel>
                <TabPanel value={value} index={1} class="tabPanel_div">
                  <ViewConnection />
                </TabPanel>
                <TabPanel value={value} index={2} class="tabPanel_div">
                  <ViewGroups />
                </TabPanel>
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  );
}
export default React.memo(ViewProfile);
