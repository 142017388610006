import React from "react";
import WebMenu from "../Pages/Shared/WebMenu";
import Footer from "../Pages/Shared/Footer";

function PublicRoute({ children }) {
  return (
    <>
      <WebMenu />
      {children}
      <Footer />
      <button
        className="indicator"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      ></button>
    </>
  );
}

export default PublicRoute;
