import React, { useEffect } from "react";
import { useState } from "react";

function Groups_list(props) {
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [grpList, setGrpList] = useState([]);
  // const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const { cgList } = props;
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };

  useEffect(() => {
    if (cgList?.length > 0) {
      setGrpList(cgList);
    }
    // eslint-disable-next-line
  }, [cgList]);

  return (
    <>
      {props.grid === true ? (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-2 mb-3" key={i}>
              <div className="grid_groups_card text-center">
                <img
                  src={item?.backimg}
                  alt="backimg"
                  className="img-fluid banner_img"
                />
                <div className="groups_card_text mb-3">
                  <img
                    src={item?.img}
                    alt="profileimg"
                    className="img-fluid group_img mb-3"
                  />
                  <h6>{item?.name}</h6>
                  <p>Group {item.communityGroupTypeName}</p>
                  {/* <p>Active 2 days ago</p> */}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {grpList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_groups_card">
                <div className="connection_card_text d-flex">
                  <img src={item?.img} alt="profileimg" className="img-fluid" />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Group Private</p>
                  </div>
                  {/* <p>Active 16 hours ago</p> */}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {grpList?.length <= 0 ? (
        <div className="text-center my-5 p_20_text"> No Data Found</div>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(Groups_list);
