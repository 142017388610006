import React, { useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
// import axios from "axios";
// import Config from "../../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { CreatePost } from "../../../../reducers/user";
import { PostCallApi } from "../../../../Action/Action";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BlockReport(props) {
  const { setOpenCir, handleCloseBlock, postId, blockPost, comGroupId } = props;
  const PostC = useSelector((state) => state.user.Post_data);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();
  useEffect(() => {
    setValue("blockCategories", "Child Abuse Images");
    // eslint-disable-next-line
  }, []);
  const handleBlockPost = async (data) => {
    setOpenCir(true);
    let bData = {
      ...data,
      postId: postId,
      requestedBy: LogUserId,
      postType:
        comGroupId !== "00000000-0000-0000-0000-000000000000"
          ? "CommunityPost"
          : "Post",
    };
    let response = await PostCallApi({
      url: `/api/BlockReportPost/CreateBlockReportPost`,
      body: bData,
      headers: headers,
    });
    // axios
    //   .post(
    //     `${Config.API_HOST_URL_live}/api/BlockReportPost/CreateBlockReportPost`,
    //     bData,
    //     { headers }
    //   )
    //   .then((response) => {
    if (response?.status === 200) {
      if (response.data.success) {
        setOpenCir(false);
        handleCloseBlock();
        setValue("blockCategories", "Child Abuse Images");
        setValue("blockingReason", "");
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        if (PostC) {
          dispatch(CreatePost(false));
        } else {
          dispatch(CreatePost(true));
        }
      } else {
        setOpenCir(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // });
  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const closeBlock = () => {
    setValue("blockingReason", "");
    handleCloseBlock();
  };
  return (
    <BootstrapDialog
      onClose={closeBlock}
      aria-labelledby="customized-dialog-title"
      open={blockPost}
      className="block_popup"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={closeBlock}
        dividers
      >
        Block Post
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography>Category</Typography>
        <select
          name=""
          id=""
          {...register("blockCategories", {
            required: "This field is required",
          })}
          className="form-select mb-3"
        >
          <option value="Child Abuse Images"> Child Abuse Images</option>
          <option value="Criminal Activities"> Criminal Activities</option>
          <option value="Hacking"> Hacking</option>
          <option value="Hate & Intolerance"> Hate & Intolerance</option>
          <option value="Illegal Drug"> Illegal Drug</option>
          <option value="Illegal Software"> Illegal Software</option>
          <option value="School Cheating">School Cheating</option>
          <option value="Self-harm">Self-harm</option>
          <option value="Weapons">Weapons</option>
          <option value="Cults">Cults</option>
          <option value="Gambling">Gambling</option>
          <option value="Nudity">Nudity</option>
          <option value="Pronography/Sexually Explicit">
            Pronography/Sexually Explicit
          </option>
          <option value="Sex education">Sex Edication</option>
          <option value="Testless">Testless </option>
          <option value="Violence">Violence </option>
          <option value="Download sites">Download Sites </option>
          <option value="Games">Games </option>
          <option value="Spam"> Spam</option>
          <option value="Other"> Other</option>
        </select>
        {errors?.blockCategories && (
          <span role="alert" className="error_text">
            {errors?.blockCategories.message}
          </span>
        )}
        <Typography>Reason</Typography>
        <textarea
          rows={5}
          className="form-control comment_field"
          placeholder="Share What's On Your Mind"
          {...register("blockingReason", {
            required: "This field is required",
            // minLength: {
            //   value: 150,
            //   message: "Min 150 Word Required",
            // },
          })}
        />
        {errors?.blockingReason && (
          <span role="alert" className="error_text">
            {errors?.blockingReason.message}
          </span>
        )}
      </DialogContent>
      <DialogActions className="popup_btn">
        <Button
          className="w-25 theme_button me-2"
          onClick={handleSubmit(handleBlockPost)}
        >
          Send
        </Button>

        <Button autoFocus onClick={closeBlock} className="w-25">
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default React.memo(BlockReport);
