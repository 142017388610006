import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/fig_CROP.png";
import facebook from "../../../images/Icon/footer-fb.svg";
import instagram from "../../../images/Icon/footer-insta.svg";
import linkedin from "../../../images/Icon/footer-linkedin.svg";
import rightarrow from "../../../images/right-arrow.svg";
import "./index.scss";
import { GetCallApi } from "../../../Action/Action";

function Footer() {
  const [openCircle, setOpenCircle] = React.useState(false);
  const [footerData, setFooterData] = useState();
  useEffect(() => {
    //startTimer();
    setOpenCircle(true);

    getftData();
    // eslint-disable-next-line
  }, []);
  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading3").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading3").innerHTML = "";
  // }, []);
  const getftData = async () => {
    let seData = {
      url: `/api/Footer/GetAllFooterListWithOutToken`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data?.success) {
        let ftData = res.data.payload;
        setFooterData(ftData);
        //stopTimer();
        setOpenCircle(false);
      } else {
        //stopTimer();
        setOpenCircle(false);
      }
    } else {
      //stopTimer();
      setOpenCircle(false);
    }
  };
  return (
    <>
      <div className="footer_page">
        {/* <div
          id="loading3"
          className="h6_text p-5 text-center"
          style={{ display: !openCircle ? "none" : "block" }}
        ></div> */}
        {!openCircle ? (
          <>
            <div className="container">
              <div className="footer main_backcolor">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src={Logo}
                      className="img-fluid footer_logo"
                      alt="FIG"
                      style={{ width: "50%" }}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="links">
                      <Link to="/">HOME</Link>
                      <Link to="/about">About</Link>
                      <Link to="/ceu">CEU Courses</Link>
                      <Link to="/figlifestyle">FIG LIFESTYLE</Link>
                      <Link to="/coaches">COACHES</Link>
                      <Link to="/free">FREE Sources</Link>
                      {/* <Link to="/meet-team">Meet Our Team</Link> */}
                      <Link to="/contactus">CONTACT US</Link>
                      <Link to="/privacy">Privacy Policy</Link>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <h3>LOCATION</h3>
                    <p>{footerData?.footerAddress}</p>
                    <Link
                      to={`tel: ${footerData?.phoneNumber}`}
                      className="mobile-text"
                    >
                      {footerData?.phoneNumber}
                    </Link>
                    <div className="social_links">
                      <Link to={footerData?.facebookLink} target="_blank">
                        <img src={facebook} alt="" />
                      </Link>
                      <Link to={footerData?.instagramLink} target="_blank">
                        <img src={instagram} alt="" />
                      </Link>
                      <Link to={footerData?.linkedinLink} target="_blank">
                        <img src={linkedin} alt="" />
                      </Link>
                    </div>
                    <div className="signin-link-text">
                      <Link to="Login">
                        SIGN IN TO FIG{" "}
                        <img src={rightarrow} alt="right-arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-footer">
              <p>Copyright © 2023 FIG K12 - All Rights Reserved.</p>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export default React.memo(Footer);
