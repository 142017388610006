export default {
  HOST_URL_Live_SSO: "https://fig.sso.id",
  SSO_ClientId: "yXdltQCCCzpPnYGofWncE",
  // SSO_ClientId: "jOVcjTphvHVnaOHbRGtWR",
  // API_HOST_URL_live: "https://localhost:7076",
  // API_HOST_URL_live: "http://figappapi.vidhaninfotech.com",
  // HOST_URL_live_clever: "http://thefigapp.vidhaninfotech.com",
  // API_CHAT_URL_live: "http://figappapi.vidhaninfotech.com/chathub",
  API_CHAT_URL_live: "https://api.figk-12.com/chathub",
  API_HOST_URL_live: "https://api.figk-12.com",
  HOST_URL_live_clever: "https://figk-12.com",
};
