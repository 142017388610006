import React, { useState } from "react";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import send_icon from "../../../images/Icon/send_mes.svg";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";
import pro_img from "../../../images/default_profile.jpg";
import Swal from "sweetalert2";

function Comments(props) {
  const { postId, setCommentReply, comList, likeId, setLikeId } = props;
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const onSubmit = (data) => {
    setOpen(true);
    let cmtData = {};
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    let url = "";
    url = `${Config.API_HOST_URL_live}/api/CommunityComment/CreateCommunityComment`;
    cmtData = {
      communityPostId: postId,
      comment: data?.comment,
      commentByUserId: LogUserId,
    };

    axios
      .post(url, cmtData, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          setCommentReply(false);
          setValue("comment", "");
          setOpen(false);
          if (likeId) {
            setLikeId(false);
          } else {
            setLikeId(true);
          }
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          setOpen(false);
        }
      });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="reply_comment_box px-3" key={postId}>
        <form className="d-flex" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            name=""
            className="form-control"
            placeholder="Type here ......"
            {...register("comment", {
              required: "This field is required for Comment",
            })}
          />
          <IconButton type="submit" className="pst_btn">
            <img src={send_icon} alt="" />
          </IconButton>
        </form>
        {errors.comment && (
          <span role="alert" className="error_text">
            {errors.comment.message}
          </span>
        )}
        {comList?.length > 0
          ? comList?.map((item) => (
              <>
                <div className="d-flex px-3 my-2">
                  <img
                    src={
                      item?.profileUrl === "" ||
                      item?.profileUrl === null ||
                      item?.profileUrl === undefined
                        ? pro_img
                        : `${Config.API_HOST_URL_live}${item?.profileUrl}`
                    }
                    alt=""
                    className="img-fluid post_u_img me-3 m-auto"
                  />
                  <div className="last_comment w-100">
                    <div className="comment_title d-flex">
                      <p>
                        <b className="text-capitalize">{item?.userName}</b>{" "}
                        <span className="d-block">
                          {item?.comment}{" "}
                          {item?.commentByUserId === LogUserId
                            ? "(You Commented)"
                            : ""}
                        </span>
                      </p>
                    </div>
                    <span className="text-muted">{item?.date}</span>
                  </div>
                </div>
              </>
            ))
          : ""}
      </div>
    </>
  );
}
export default React.memo(Comments);
