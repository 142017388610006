import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import reimg1 from "../../../images/recipe_img.png";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import { GetCallApi } from "../../../Action/Action";

// const CEUCard = lazy(() => import("../CEU_Cources/Card"));
// const ReSubScribeForm = lazy(() => import("./ReSubScribe_Form"));
// const DisplayMenuRecipe = lazy(() => import("./displayMenu"));
const ReCard = lazy(() => import("./ReCard"));

const renderLoader = () => <p></p>;
function Recipe() {
  // const [searchField, setSearchField] = useState("");
  // const [ceuSearchData, setCeuSearchData] = useState([
  //   {
  //     id: 1,
  //     cardimg: trendimg1,
  //     cardTitle: "Dealing With Your Pesky Person.....",
  //     lesson: "4 Lessons",
  //   },
  //   {
  //     id: 2,
  //     cardimg: trendimg2,
  //     cardTitle: "Anger Management",
  //     lesson: "14 Lessons",
  //   },
  //   {
  //     id: 3,
  //     cardimg: trendimg3,
  //     cardTitle: "Generational Diversity in the Workplace....",
  //     lesson: "5 Lessons",
  //   },
  //   {
  //     id: 4,
  //     cardimg: trendimg4,
  //     cardTitle: "Greening Your Operation",
  //     lesson: "3 Lessons",
  //   },
  //   {
  //     id: 5,
  //     cardimg: trendimg5,
  //     cardTitle: "Living Your Best Life Through Self....",
  //     lesson: "1 Lessons",
  //   },
  //   {
  //     id: 6,
  //     cardimg: trendimg6,
  //     cardTitle: "Let’s Get Organized!",
  //     lesson: "10 Lessons",
  //   },
  //   {
  //     id: 7,
  //     cardimg: trendimg7,
  //     cardTitle: "School Nutrition 101 – Orientation",
  //     lesson: "11 Lessons",
  //   },
  // ]);
  const [searchRecipe, setSearchRecipe] = useState("");
  const [open, setOpen] = useState(false);
  const [catS, setCatS] = useState();
  const [subCatS, setSubCatS] = useState();
  const [catList, setCatList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [ceuCardData, setCeuCardData] = useState([]);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetSubCategory(catS?.value);

    // eslint-disable-next-line
  }, [catS]);
  useEffect(() => {
    getRecipeList(catS, subCatS);
    // eslint-disable-next-line
  }, [catS, subCatS]);
  const getRecipeList = async (catId, subCatId) => {
    let recipeDetails = {
      catId: catId?.value ? catId?.value : 0,
      subCatId: subCatId?.value ? subCatId?.value : 0,
    };
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Recipe/ActiveReceipeList?categoryId=${recipeDetails?.catId}&subcategoryId=${recipeDetails?.subCatId}&userId=${LogUserId}`,
    //     {
    //       headers,
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/Recipe/ActiveReceipeList?categoryId=${recipeDetails?.catId}&subcategoryId=${recipeDetails?.subCatId}&userId=${LogUserId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        let user = response?.data.payload?.map((item, i) => {
          return {
            RecipeImg: item?.coverImage
              ? `${Config.API_HOST_URL_live}${item?.coverImage}`
              : reimg1,
            Recipename: item?.name ? item?.name : "",
            isVeg: item?.isVeg,
            des: item?.shortDescription ? item?.shortDescription : "",
            CreatedBy: item?.createdBy ? item?.createdBy : "",
            comment: item?.totalComments,
            rating: item?.rating,
            date: convertDate(item?.createdOn?.split("T")[0]),
            fav: item?.myFavouriteRecipe ? true : false,
            course: item?.course,
            recipeId: item?.recipeId,
          };
        });
        setCeuCardData(user);
      } else {
        setOpen(false);
        setCeuCardData([]);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    //   } else {
    //     setOpen(false);
    //     setCeuCardData();
    //   }
    // });
  };
  const getCategory = () => {
    axios
      .get(`${Config.API_HOST_URL_live}/api/Category/GetActiveCategory?createdBy=${LogUserId}`, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          const listdata = response.data.payload;
          const main = listdata?.map((item) => {
            return {
              value: item?.categoryId,
              label: item?.categoryName,
            };
          });
          setCatList(main);
        } else {
          setCatList([]);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const GetSubCategory = (id) => {
    let url = "";
    if (id) {
      url = `${Config.API_HOST_URL_live}/api/SubCategories/GetSubCategoriesDataByCategoriesId?categoriesId=${id}`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/SubCategories/GetActiveSubCategories`;
    }
    axios
      .get(url, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          const listdata = response.data.payload;
          const main = listdata?.map((item) => {
            return {
              value: item?.subCategoriesId
                ? item?.subCategoriesId
                : item?.subCategoryId,
              label: item?.subName ? item?.subName : item.subCategoryName,
            };
          });
          setSubCatList(main);
        } else {
          setSubCatList([]);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const filteredDataRecipe = ceuCardData?.filter((user) => {
    if (searchRecipe) {
      return user.Recipename.toLowerCase().includes(searchRecipe.toLowerCase());
    } else return user;
  });
  // const filteredData = ceuSearchData?.filter((user) => {
  //   if (searchField) {
  //     return user.cardTitle.toLowerCase().includes(searchField.toLowerCase());
  //   } else return user;
  // });
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "long" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }

  const favRecipe = (rd) => {
    let fvr = {
      recipeId: rd?.recipeId,
      createdBy: LogUserId,
      favourite: rd?.fav ? false : true,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/MyFavouriteRecipe/CreateMyFavouriteRecipe`,
        fvr,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            getRecipeList(catS, subCatS);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="recipe_page">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="title_for_Page_head">Recipe</div>
              <div className="recipe_wrapper">
                {/* <DisplayMenuRecipe /> */}

                <div className="d-flex justify-content-between re_head_title_box">
                  <h6 className="h6_text w-25">Search Recipes</h6>
                  <div className="w-25 sec_se me-3">
                    <Autocomplete
                      disablePortal
                      options={catList}
                      className="me-3 search"
                      sx={{ width: 200 }}
                      value={catS?.label}
                      onChange={(e, newValue) => {
                        setCatS(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Category" />
                      )}
                    />
                  </div>
                  <div className="w-25 sec_se me-3">
                    <Autocomplete
                      disablePortal
                      options={subCatList}
                      className="me-3 search"
                      sx={{ width: 200 }}
                      value={subCatS?.label}
                      onChange={(e, newValue) => {
                        setSubCatS(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Sub Category" />
                      )}
                    />
                  </div>
                  <div className="search_box w-25">
                    <input
                      type="text"
                      className="form-cotrol w-100"
                      placeholder="Search By Name"
                      onChange={(e) => setSearchRecipe(e.target.value)}
                    />
                    <img src={search_icon} alt="" />
                  </div>
                  {/* <div className="w-25">
                    <select class="form-select">
                      <option selected value="1">
                        Sort by : Latest
                      </option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div> */}
                </div>
                <hr className="tabpannel_hr" />
                <div className="recipe_wrap_box">
                  <div className="row recipe_card_main_box">
                    <ReCard recipeData={filteredDataRecipe} onfav={favRecipe} />
                  </div>
                  {/* <div className="mt-5">
                    <Sidelist
                      title="Trending Recipes"
                      filteredData={filteredData}
                      setSearchField={setSearchField}
                    />
                  </div>
                  <div className="mt-5">
                    <Sidelist
                      title="Trending Videos"
                      cls="trevi"
                      filteredData={filteredData}
                      setSearchField={setSearchField}
                    />
                  </div> */}
                </div>
                {/* <div className="re_subscribe_box">
                  <ReSubScribeForm />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(Recipe);
