import React, { Suspense, lazy } from "react";
import "./index.scss";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CourseReport from "./CourseReport";
import QuizSubmittedList from "./QuizSubmittedList";
import InActiveUser from "./InActiveUser";
import SepratePageTableData from "../Dashboard/SepratePage_TableData";
import GoalReport from "./GoalReport";
import SuperAdminReport from "./SuperAdminReport";
import UserActivityReport from "./UserActivityReport";
import DashboardSummaryReport from "./DashboardSummaryList/index";
const AllUserReport = lazy(() => import("../Dashboard/AllUserReport"));
const ReportTable = lazy(() => import("./ReportTable"));
const DisplayReportMenu = lazy(() => import("./DisplayReportMenu"));
const AllCertificateDash = lazy(() => import("../Dashboard/AllCertificate"));
const renderLoader = () => <p></p>;

function Report(props) {
  const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <div className="report_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="report_page_box">
                <DisplayReportMenu />
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                  }}
                >
                  {ReportMenuName === "List of users" ? (
                    <div className="tabpannel_content">
                      <AllUserReport />
                    </div>
                  ) : ReportMenuName === "Individual detail report list" ? (
                    <div className="tabpannel_content">
                      <ReportTable />
                    </div>
                  ) : ReportMenuName === "CEU Tracking Report" ? (
                    <div className="tabpannel_content">
                      <ReportTable />
                    </div>
                  ) : ReportMenuName === "Course Catalog" ? (
                    <div className="tabpannel_content">
                      <CourseReport />
                    </div>
                  ) : ReportMenuName === "Quiz Submitted" ? (
                    <div className="tabpannel_content">
                      <QuizSubmittedList />
                    </div>
                  ) : ReportMenuName === "InActive Users" ? (
                    <div className="tabpannel_content">
                      <InActiveUser />
                    </div>
                  ) : ReportMenuName === "Course Schedule" ? (
                    <div className="tabpannel_content">
                      <SepratePageTableData title="Course Schedule" />
                    </div>
                  ) : ReportMenuName === "Top Courses" ? (
                    <div className="tabpannel_content">
                      <SepratePageTableData title="Top Courses" />
                    </div>
                  ) : ReportMenuName === "Course Roster" ? (
                    <div className="tabpannel_content">
                      <SepratePageTableData title="Course Roster" />
                    </div>
                  ) : ReportMenuName === "Top Students" ? (
                    <div className="tabpannel_content">
                      <SepratePageTableData title="Top Students" />
                    </div>
                  ) : ReportMenuName === "Certification Expiration" ? (
                    <div className="tabpannel_content">
                      <AllCertificateDash title="Certification Expiration" />
                    </div>
                  ) : ReportMenuName === "Goal Report" ? (
                    <div className="tabpannel_content">
                      <GoalReport title="Goal Report" />
                    </div>
                  ) : ReportMenuName === "District Report" ? (
                    <div className="tabpannel_content">
                      <SuperAdminReport title="District Report" />
                    </div>
                  ) : ReportMenuName === "User Activity Report" ? (
                    <div className="tabpannel_content">
                      <UserActivityReport />
                    </div>
                  ) : ReportMenuName === "Dashboard Summary Report" ? (
                    <div className="tabpannel_content">
                      <DashboardSummaryReport />
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(Report);
