import React, { Suspense, lazy, useState } from "react";
import { TabPanel } from "@mui/lab";
const CommunityMember = lazy(() => import("../CommunityMember"));
const AssignUserGroup = lazy(() => import("../CreateComGroup/AssignUserGroup"));
const CommunityFeed = lazy(() => import("../CommunityFeed"));
const renderLoader = () => <p></p>;

function TabComponent({ id, orgId }) {
  const [searchField, setSearchField] = useState();

  return (
    <Suspense fallback={renderLoader()}>
      <div className="view_tabs">
        <TabPanel value="1">
          <div className="community_group ">
            <input
              type="search"
              className="form-control"
              placeholder=" Search Conversations"
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
          <CommunityFeed
            comGroupId={id}
            pvtId={2}
            searchField={searchField}
            orgId={orgId}
          />
        </TabPanel>
        <TabPanel value="2">
          <CommunityMember comGroupId={id} orgId={orgId} />
        </TabPanel>
        <TabPanel value="3">
          <AssignUserGroup comGroupId={id} />
        </TabPanel>
      </div>
    </Suspense>
  );
}

export default React.memo(TabComponent);
