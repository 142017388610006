import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../Utils/Config";
import pro_img from "../../../../images/default_profile.jpg";
import backimg12 from "../../../../images/group_card_back12.png";
import lockOpen_icon from "../../../../images/Icon/lock-open.png";
import all_icon from "../../../../images/Icon/all_icon.png";
import { DashboardRe } from "../../../../reducers/user";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import CropmodelImage from "../../CropImg/cropmodel";
import "./index.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};

function CreateGoal(props) {
  const { title, edit, completed } = props;
  const [open, setOpen] = React.useState(false);
  const [grpList, setGrpList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // const [goalImg, setGoalImg] = useState();

  let dispatch = useDispatch();

  let changeDash = useSelector((state) => state.user.Drefresh);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    // setGoalImg();
    setOpen(false);
  };
  const onSubmit = async (data) => {
    let fileHeader = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    };
    let seData1 = {
      url: `/api/SchoolAcademicYear/GetCurrentAcademicYear`,
      headers: headers,
    };
    let ayId;
    let res1 = await GetCallApi(seData1);
    if (res1.status === 200) {
      if (res1.data.success) {
        ayId = res1.data.payload[0]?.academicYearId;
        let sendData = {
          ...data,
          createdBy: LogDetail?.userId,
          userId: LogDetail?.userId,
          status: completed
            ? "Completed"
            : data?.goalId
            ? "Updated"
            : "Created",
          postTypeId: 4,
          privacyTypeId:
            parseInt(data?.communityGroupId) === 1
              ? 1
              : parseInt(data?.communityGroupId) === 3
              ? 3
              : 2,
          communityGroupId:
            parseInt(data?.communityGroupId) === 1 ||
            parseInt(data?.communityGroupId) === 3
              ? "00000000-0000-0000-0000-000000000000"
              : data?.communityGroupId,
          academicYearId: ayId,
        };
        if (completed) {
          sendData = { ...sendData, completeDate: data.completionDate };
          delete sendData.completionDate;
        }
        let seData = {
          url: completed ? "/api/Goal/CompleteGoal" : `/api/Goal/CreateGoal`,
          body: sendData,
          headers: fileHeader,
          // headers: completed ? fileHeader : headers,
        };
        let res = await PostCallApi(seData);
        if (res?.status === 200) {
          if (res?.data.success) {
            handleClose();
            if (changeDash) {
              dispatch(DashboardRe(false));
            } else {
              dispatch(DashboardRe(true));
            }
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res?.data?.message
                ? res?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      }
    }
  };
  useEffect(() => {
    if (open) {
      getGrpList();
      GetGoalCategory();
      if (edit) {
        setGoalvalues();
      }
    }
    // eslint-disable-next-line
  }, [open]);

  const getGrpList = async () => {
    let url = "";
    if (LogDetail.roleId === 1) {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/CommunityGroupList`;
    } else {
      url = `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${LogDetail?.userId}`;
    }
    let seeData = {
      url: `/api/Common/GetAssessTypeByUserId?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let access;
    let res = await GetCallApi(seeData);
    if (res?.status === 200) {
      if (res.data.success) {
        access = res?.data.payload?.catypeId;
      }
    }
    let seData = {
      url: url,
      headers: headers,
    };
    let response = await GetCallApi(seData);
    if (response?.status === 200) {
      if (response.data.success) {
        const cgList = response.data.payload?.map((item) => {
          return {
            value: item?.communityGroupId,
            label: item?.communityGroupName,
            img:
              item?.groupImageUrl === "" || item?.groupImageUrl === null
                ? pro_img
                : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
            backimg:
              item?.groupCoverImage === "" || item?.groupCoverImage === null
                ? backimg12
                : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
            organizerUserId: item?.organizerUserId,
          };
        });
        let main = [
          { value: "1", label: "All", img: all_icon },
          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
          ...cgList,
        ];
        if (LogDetail?.roleId !== 1) {
          if (access === 2) {
            main = main?.filter((x) => x.value !== "1");
          }
        }
        setGrpList(main);
        setTimeout(() => {
          if (edit?.communityGroupId) {
            if (
              edit?.communityGroupId === "00000000-0000-0000-0000-000000000000"
            ) {
              setValue("communityGroupId", edit?.privacyTypeId);
            } else {
              setValue("communityGroupId", edit?.communityGroupId);
            }
          }
        }, 500);
      } else {
        let main = [
          { value: "1", label: "All", img: all_icon },

          {
            value: "3",
            label: "My Connections",
            img: lockOpen_icon,
          },
        ];
        if (LogDetail?.roleId !== 1) {
          if (access === 2) {
            main = main?.filter((x) => x.value !== "1");
          }
        }
        setGrpList(main);
        setTimeout(() => {
          if (edit?.communityGroupId) {
            if (
              edit?.communityGroupId === "00000000-0000-0000-0000-000000000000"
            ) {
              setValue("communityGroupId", edit?.privacyTypeId);
            } else {
              setValue("communityGroupId", edit?.communityGroupId);
            }
          }
        }, 500);
      }
    }
  };
  const GetGoalCategory = async () => {
    let seData = {
      url: `/api/GoalCategory/GetAllGoalCategory`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let svCat = res.data.payload?.filter((x) => x.isActive === true);
        setCategoryList(svCat);
        setTimeout(() => {
          if (edit?.goalCategoryId) {
            setValue("goalCategoryId", edit?.goalCategoryId);
            // handleGC(edit?.goalCategoryId);
          }
        }, 500);
      }
    }
  };
  const setGoalvalues = () => {
    setValue("goalId", edit?.goalId);
    setValue("goalName", edit?.goalName);
    setTimeout(() => {
      if (edit?.editDate) {
        setValue(
          "completionDate",
          edit?.editDate ? edit?.editDate?.split("T")[0] : ""
        );
      }
      setValue(
        "targetCompletionDate",
        edit?.targeteditDate ? edit?.targeteditDate?.split("T")[0] : ""
      );
    }, 500);
    setValue("goalDescriptions", edit?.goalDescription);
    setValue("coverUpload1", edit?.imageUrl);
  };
  // const handleGC = (e) => {
  //   if (e) {
  //     let gim = categoryList?.filter((x) => x.goalCategoryId === parseInt(e))[0]
  //       ?.imageUrl;
  //     setGoalImg(`${Config.API_HOST_URL_live}${gim}`);
  //   } else {
  //     setGoalImg();
  //   }
  // };

  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1, setCroppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        if (completed) {
          setValue("GoalCompletionImage", url);
        } else {
          setValue("GoalImage", url);
        }
        setValue("coverUpload1", url.name);
        setCroppedImageUrl1(url);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  return (
    <>
      {title ? (
        <Button className="theme_btn my-auto" onClick={handleOpen}>
          Create Goal
        </Button>
      ) : completed ? (
        <button
          type="button"
          className="btn_complete mx-2"
          onClick={() => handleOpen(edit)}
        >
          Complete
        </button>
      ) : (
        <button
          type="button"
          className="btn_edit mx-2"
          onClick={() => handleOpen(edit)}
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8899 3.00006C18.1525 2.73741 18.4643 2.52907 18.8075 2.38693C19.1507 2.24479 19.5185 2.17163 19.8899 2.17163C20.2613 2.17163 20.6291 2.24479 20.9723 2.38693C21.3154 2.52907 21.6272 2.73741 21.8899 3.00006C22.1525 3.2627 22.3609 3.57451 22.503 3.91767C22.6452 4.26083 22.7183 4.62862 22.7183 5.00006C22.7183 5.37149 22.6452 5.73929 22.503 6.08245C22.3609 6.42561 22.1525 6.73741 21.8899 7.00006L8.38989 20.5001L2.88989 22.0001L4.38989 16.5001L17.8899 3.00006Z"
              stroke="#1EBEC8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="goal-popup">
          <div className="title-header">
            {title ? "Create" : completed ? "Complete" : "Edit"} Goal
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {src1 && (
                <CropmodelImage
                  src={src1}
                  filename={filename1}
                  setCroppedImageUrl={setCroppedImageUrlCallback1}
                  openCropModal={openCropModal1}
                  setCropModal={setCropModal1}
                />
              )}
              {completed ? (
                <>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">
                        Actual Completion Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("completionDate", {
                          required: "This field is required",
                          valueAsDate: true,
                          onChange: (e) => console.log(e.target.value),
                        })}
                      />
                      {errors.completionDate && (
                        <span role="alert" className="error_text">
                          {errors.completionDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Upload Image</label>
                    <div className="upload_img">
                      <input
                        type="text"
                        className="form-control upload_box"
                        placeholder="No file Chosen"
                        readOnly
                        {...register("coverUpload1")}
                      />
                      <input
                        type="file"
                        id="school_img_upload_2"
                        accept="image/*"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={handleFile1}
                      />
                      <label
                        htmlFor="school_img_upload_2"
                        className="upload_img_label mb-0 "
                      >
                        <img src={uploadimg} className="img-fluid" alt="" />
                      </label>
                    </div>
                    {errors?.coverUpload1 && (
                      <span role="alert" className="error_text">
                        {errors.coverUpload1.message}
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="col-md-12 text-center">
                    <img
                      src={goalImg ? goalImg : ""}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "";
                      }}
                      alt=""
                      className="img-fluid"
                      style={{ height: "200px " }}
                    />
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Goal Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Goal Title"
                        {...register("goalName", {
                          required: "This field is required",
                        })}
                      />
                      {errors.goalName && (
                        <span role="alert" className="error_text">
                          {errors.goalName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">
                        Target Completion Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("targetCompletionDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.targetCompletionDate && (
                        <span role="alert" className="error_text">
                          {errors.targetCompletionDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">
                        Actual Completion Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("completionDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.completionDate && (
                        <span role="alert" className="error_text">
                          {errors.completionDate.message}
                        </span>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Goal Category</label>

                      <select
                        className="form-select"
                        {...register("goalCategoryId", {
                          required: "This field is required",
                          valueAsNumber: true,
                          // onChange: (e) => handleGC(e.target.value),
                        })}
                      >
                        <option value="">Select Goal Category</option>
                        {categoryList &&
                          categoryList?.map((x, i) => (
                            <option value={x?.goalCategoryId} key={i}>
                              {x?.categoryName}
                            </option>
                          ))}
                      </select>
                      {errors.goalCategoryId && (
                        <span role="alert" className="error_text">
                          {errors.goalCategoryId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Community</label>
                      <select
                        className="form-select"
                        {...register("communityGroupId", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select Community</option>
                        {grpList &&
                          grpList?.map((x, i) => (
                            <option value={x?.value} key={i}>
                              {x?.label}
                            </option>
                          ))}
                      </select>
                      {errors.communityGroupId && (
                        <span role="alert" className="error_text">
                          {errors.communityGroupId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" col-md-12 mb-3">
                    <div className="form-group">
                      <label className="form-label">Upload Image</label>
                      <div className="upload_img">
                        <input
                          type="text"
                          className="form-control upload_box"
                          placeholder="No file Chosen"
                          readOnly
                          {...register("coverUpload1")}
                        />
                        <input
                          type="file"
                          id="school_img_upload_2"
                          accept="image/*"
                          className="form-control"
                          style={{ display: "none" }}
                          onChange={handleFile1}
                        />
                        <label
                          htmlFor="school_img_upload_2"
                          className="upload_img_label mb-0 "
                        >
                          <img src={uploadimg} className="img-fluid" alt="" />
                        </label>
                      </div>
                      {errors?.coverUpload1 && (
                        <span role="alert" className="error_text">
                          {errors.coverUpload1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        type="textarea"
                        className="form-control"
                        placeholder="Description"
                        {...register("goalDescriptions", {
                          required: "This field is required",
                        })}
                      />
                      {errors.goalDescriptions && (
                        <span role="alert" className="error_text">
                          {errors.goalDescriptions.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-12 mt-3">
                <div className="form-group d-flex border-0">
                  <Button className="save-btn me-3" type="submit">
                    Save
                  </Button>
                  <Button className="cancel-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default CreateGoal;
