import React from "react";
import "../../index.scss";
import RegisterList from "./RegisterList";

function RegisterConference(props) {
  const { usList, districtName, districtDetails } = props;

  return (
    <>
      <div className="row">
        <RegisterList
          usList={usList}
          districtName={districtName}
          districtDetails={districtDetails}
        />
      </div>
    </>
  );
}
export default React.memo(RegisterConference);
