import React from "react";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div
      className={
        isDanger ? "countdown danger days-block" : "countdown days-block"
      }
    >
      <div className="date">
        {/* <span className="number">
          {isNaN(value)
            ? ""
            : value?.toString()?.length === 2
            ? value?.toString()?.slice(0, 1)
            : value?.toString()?.length === 1
            ? 0
            : ""}
        </span>
        {isNaN(value) ? (
          ""
        ) : value?.toString()?.length === 2 ? (
          <span className="number">{value?.toString()?.slice(1, 2)}</span>
        ) : value?.toString()?.length === 1 ? (
          <span className="number">{value?.toString()?.slice(0, 1)}</span>
        ) : (
          <span className="number">{value}</span>
        )} */}
        {value
          ?.toString()
          ?.split("")
          ?.map((v, i) => {
            if (value?.toString()?.length === 1) {
              return (
                <React.Fragment key={i}>
                  <span className="number">0</span>
                  <span className="number">{v}</span>
                </React.Fragment>
              );
            }
            return (
              <span className="number" key={i}>
                {v}
              </span>
            );
          })}
      </div>
      <p>{type}</p>
    </div>
  );
};

export default DateTimeDisplay;
