import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { PostCallApi } from "../../../../../../../Action/Action";
import Swal from "sweetalert2";
import Invoice from "./Invoice";
import { AttendeeRegistrationConference } from "../../../../../../../reducers/Common";
import useResponsiveFontSize from "../../../../../../Reuseable_Components/useResponsiveFontSize";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ReactQuill from "react-quill";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function PaymentProcess(props) {
  const {
    companyData,
    conferenceId,
    handleReset,
    districtName,
    districtDetails,
  } = props;
  const [clientPublicKey, setClientPublicKey] = useState();
  const [acBtn, setAcBtn] = useState(true);
  const [showInvoise, setShowInvoise] = useState(false);
  const [allInVData, setAllInVData] = useState();
  // const [promoList, setPromoList] = useState();
  //   const [expanded, setExpanded] = React.useState("");
  const [payment, setPayment] = useState();

  const [subtotal, setSubTotal] = useState("");
  const [discountValue, setDiscountValue] = useState();
  const [diableInvoiceSubmit, setDiableInvoiceSubmit] = useState(false);
  const [withDiscountSubmit, setWithDiscountSubmit] = useState(false);

  const { setValue } = useForm();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let ATRC = useSelector((state) => state.Common?.ATRC);
  let dispatch = useDispatch();

  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.eventFees);
      setSubTotal(companyData?.eventFees);
    }
    // eslint-disable-next-line
  }, [companyData]);
  //   const handleChange = (panel) => (event, newExpanded) => {
  //     setExpanded(newExpanded ? panel : false);
  //   };
  const CLSec = async () => {
    if (parseInt(subtotal) === 0) {
      setWithDiscountSubmit(true);
      let paymentData = {
        ...companyData,
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: subtotal,
        discountAmount: discountValue?.discountValue,
        promoCodeId: discountValue?.promoCodeId,
        conferenceAttendeePriceTypeId: companyData?.orType,
        paymentMode: "Online",
      };
      let sveCC = {
        url: `/api/MemberManagement/MMProcessPayment`,
        body: paymentData,
        headers: headers,
      };
      let res = await PostCallApi(sveCC);
      if (res?.status === 200) {
        if (res.data.isPaid) {
          let seData1 = {
            url: `/api/MemberManagement/CreateMemberManagement`,
            body: {
              ...companyData,
              totalPrice: subtotal,
              discountAmount: discountValue?.discountValue,
              promoCodeId: discountValue?.promoCodeId,
              memberManagementPaymentId: res?.data.paymentId,
              PaymentStatus: "Success",
              associationMemberDetails: {
                associationMemberName: districtName,
                state: districtDetails?.state,
                email: districtDetails?.email,
                phone: districtDetails?.phoneNumber,
                type: "LEA",
              },

              // memberManagementPaymentId: res?.data.paymentId,
            },
            headers: headers,
          };
          let resv = await PostCallApi(seData1);
          setWithDiscountSubmit(false);
          if (resv?.status === 200) {
            if (resv?.data?.success) {
              let rv = resv?.data?.payload;
              setAllInVData({
                ...ATRC,
                subtotal: subtotal,
                discountValue: discountValue?.discountValue,
                memberManagementId: rv?.memberManagementId,
              });
              setShowInvoise(true);
              dispatch(AttendeeRegistrationConference());
            }
          }
        }
      }
    } else {
      setPayment(true);
    }
  };

  const PCSec = async () => {
    let seData = {
      url: `/api/Conforence/GetPaymentPublicKey?conferenceId=${conferenceId}`,
      body: {},
      headers: headers,
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const clientPub = res.data?.payload?.publicKey;

        setClientPublicKey(clientPub);
      } else {
        SwalError(res?.data?.message);
      }
    } else {
      SwalError(res?.data?.message);
    }
  };
  const SwalError = (text) => {
    Swal.fire({
      position: "top-end",
      width: 400,
      // icon: "success",
      text: text ? text : "Something went wrong please try again.",
      showConfirmButton: false,
      timer: 1500,
      color: "#ffffff",
      background: "red",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutRight",
      },
    });
  };
  const RequestInvoice = async (data) => {
    setDiableInvoiceSubmit(true);
    let sendUs = {
      url: `/api/MemberManagement/ZeroPaymentMemberManagement`,
      // url: `/api/MemberManagement/PayMemberManagementInvoice`,
      body: {
        conferenceId: companyData?.conferenceId,
        createdBy: LogDetails?.userId,
        amount: subtotal,
        discountAmount: discountValue?.discountValue,
        promoCodeId: discountValue?.promoCodeId,
        // memberManagementId: companyData?.MemberManagementId,
        conferenceAttendeePriceTypeId: companyData?.orType,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        userType: "User",
      },
      headers: headers,
    };

    let reseponse = await PostCallApi(sendUs);
    if (reseponse?.status === 200) {
      if (reseponse.data.success) {
        const rv = reseponse?.data?.payload;

        let seData = {
          url: `/api/MemberManagement/AddMemberManagementInvoice`,
          body: {
            ...data,
            createdBy: LogDetails?.userId,
            totalAmount: subtotal,
            addressLine1: data?.address1,
            zipCode: data?.zip,
            invoiceInstructions: data?.editorValue,
            invoiceStatus: "Approve",
            discountValue: discountValue?.discountValue,
            paymentAddress: ATRC?.paymentAddress,
            conferenceId: ATRC?.conferenceId,
            conferenceAttendeePriceTypeId: ATRC?.orType,
            conferenceName: ATRC?.conferenceName,
            ConferenceQuantity: ATRC?.ConferenceQuantity.toString(),
            conferenceMembersPrice: ATRC?.conferenceMembersPrice.toString(),
            mmevtList: ATRC?.evtList?.map((x) => {
              let pr = x?.attendeeFees * parseInt(x?.Ql);
              return {
                eventName: x?.eventName,
                quantity: x?.Ql.toString(),
                price: pr.toString(),
              };
            }),
            mmGroupUserDetailsList: ATRC?.lstUserId,
          },
          headers: headers,
        };
        let res = await PostCallApi(seData);
        if (res?.status === 200) {
          if (res?.data?.success) {
            // setAllInVData({
            //   ...ATRC,
            //   subtotal: subtotal,
            //   discountValue: discountValue?.discountValue,
            //   data,
            //   memberManagementInvoiceId:
            //     res?.data?.payload?.memberManagementInvoiceId,
            // });
            let seData1 = {
              url: `/api/MemberManagement/CreateMemberManagement`,
              body: {
                ...companyData,
                memberManagementPaymentId: rv?.memberManagementPaymentId,
                memberManagementInvoiceId:
                  res?.data?.payload?.memberManagementInvoiceId,
                discountAmount: discountValue?.discountValue,
                promoCodeId: discountValue?.promoCodeId,
                associationMemberDetails: {
                  associationMemberName: districtName,
                  state: districtDetails?.state,
                  email: districtDetails?.email,
                  phone: districtDetails?.phoneNumber,
                  type: "LEA",
                },
              },
              headers: headers,
            };
            let resv = await PostCallApi(seData1);
            setDiableInvoiceSubmit(false);
            if (resv?.status === 200) {
              if (resv?.data?.success) {
                setAllInVData({
                  ...ATRC,
                  subtotal: subtotal,
                  discountValue: discountValue?.discountValue,
                  data,
                  memberManagementInvoiceId:
                    res?.data?.payload?.memberManagementInvoiceId,
                  memberManagementId: resv?.data?.payload?.memberManagementId,
                });
                dispatch(AttendeeRegistrationConference());
                setShowInvoise(true);
              }
            }
          }
        }
      }
    }
  };

  const ShowInvoice = ({ RequestInvoice, diableInvoiceSubmit }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const [editorValue, setEditorValue] = useState("");
    const [derror, setDerror] = useState();

    const handleEditotValue = (value) => {
      if (value?.length > 1000) {
        setDerror({ ed: "Max 1000 character" });
      } else {
        setDerror({ ed: "" });
        setEditorValue(value);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data, editorValue });
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              Email Invoice To
              <span className="red-color"> *</span>
            </label>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                {...register("email", {
                  required: "This field is require",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors?.email && (
                <span className="error_text">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <div>
                <label className="form-label">Address</label>
              </div>
              <label className="form-label">
                Name<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                {...register("name", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter max 500 charactor",
                  },
                })}
              />
              {errors?.name && (
                <span className="error_text">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3 mt-auto">
            <div className="form-group  mb-3">
              <label className="form-label">
                Address Line 1<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                {...register("address1", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.address1 && (
                <span className="error_text">{errors.address1.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <label className="form-label">PO Number </label>
              <input
                type="text"
                placeholder="Enter PO Number"
                className="form-control"
                {...register("purchaseOrderNumber", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.purchaseOrderNumber && (
                <span className="error_text">
                  {errors.purchaseOrderNumber.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                City<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                {...register("city", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.city && (
                <span className="error_text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                State<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter State"
                {...register("State", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.State && (
                <span className="error_text">{errors.State.message}</span>
              )}
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                Zip Code
                <span className="red-color"> *</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "You can enter max 10 charactor",
                  },
                })}
              />
              {errors?.zip && (
                <span className="error_text">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="form-label">Invoice Introduction</label>
            <ReactQuill
              value={editorValue}
              onChange={(value) => handleEditotValue(value)}
              // style={{ height: "200px", overflow: "auto" }}
              className="ql_show"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              theme="snow"
            />
            {derror?.ed && (
              <span role="alert" className="d-block error_text">
                {derror.ed}
              </span>
            )}
          </div>
          <div className="col-md-12 mt-3">
            {/* <h3>Coming soon for payment process</h3> */}

            <Button
              className="theme_btn "
              type="submit"
              disabled={diableInvoiceSubmit}
            >
              {diableInvoiceSubmit ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#fff",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Submit"
              )}
            </Button>
            <Button className="Can_cel_btn ms-3" onClick={() => handleReset()}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const DiscountApply = ({
    discountValue,
    handlePromo,
    handleRemoveDC,
    diableInvoiceSubmit,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    return (
      <form onSubmit={handleSubmit(handlePromo)} className="mt-3">
        <div className="row">
          <div className="col-md-10">
            <div className="form-group">
              <label className="form-label">Discount Code</label>
              <input
                type="text"
                placeholder="Enter Discount Code"
                className="form-control"
                disabled={discountValue ? true : false}
                {...register("promoCode", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors.promoCode && (
                <span role="alert" className="error_text">
                  {errors.promoCode.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-2 my-auto text-end pe-3">
            {discountValue ? (
              <Button className="Can_cel_btn " onClick={() => handleRemoveDC()}>
                Remove
              </Button>
            ) : (
              <Button
                className="theme_btn"
                type="submit"
                disabled={discountValue ? true : false}
              >
                {diableInvoiceSubmit ? (
                  <CircularProgress
                    color="inherit"
                    sx={{
                      color: "#fff",
                      marginLeft: "0.5rem",
                      height: "23px !important",
                      width: "23px !important",
                    }}
                  />
                ) : (
                  "Apply"
                )}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  };
  const applyPromo = async (data) => {
    setDiscountValue();
    setDiableInvoiceSubmit(true);

    let seData = {
      url: `/api/MemberManagement/GetAmountAfterApplyPromoCodeOnConference`,
      body: data,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    setDiableInvoiceSubmit(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let am = res.data.payload;
        setSubTotal(am?.finalAmount);
        setDiscountValue(am);
      } else {
        setSubTotal(companyData?.subtotal);

        SwalError(res?.data?.message);
      }
    } else {
      SwalError(res?.data?.message);
    }
  };
  const handlePromo = (d) => {
    // setPromoSelect(d);
    if (d) {
      let send = {
        amount: subtotal,
        conferenceId: companyData?.conferenceId,
        // promoCodeId: d?.promoCodeId,
        userId: LogDetails?.userId,
        promoCode: d?.promoCode,
      };
      applyPromo(send, d);
    } else {
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Please enter discount code",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
      setSubTotal(companyData?.subtotal);
    }
  };

  const handleRemoveDC = () => {
    setDiscountValue();
    setSubTotal(companyData?.subtotal);
  };
  const handlePay = () => {
    CLSec();
    PCSec();
  };

  const CheckoutForm = (props) => {
    const { setAllInVData, setShowInvoise } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [dis, setDis] = useState(false);
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 500);
    }, []);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;
          let paymentData = {
            ...companyData,
            userId: LogDetails?.userId,
            email: LogDetails?.email,
            amount: subtotal,
            discountAmount: discountValue?.discountValue,
            promoCodeId: discountValue?.promoCodeId,
            conferenceAttendeePriceTypeId: companyData?.orType,
            paymentMode: "Online",
          };
          let sveCC = {
            url: `/api/MemberManagement/MMProcessPayment`,
            body: { ...paymentData, PaymentMethodId: sourceId },
            headers: headers,
          };
          setDis(true);
          let res = await PostCallApi(sveCC);
          if (res?.status === 200) {
            if (res.data.isPaid) {
              let seData1 = {
                url: `/api/MemberManagement/CreateMemberManagement`,
                body: {
                  ...companyData,
                  totalPrice: subtotal,
                  discountAmount: discountValue?.discountValue,
                  promoCodeId: discountValue?.promoCodeId,
                  memberManagementPaymentId: res?.data.paymentId,
                  PaymentStatus: "Success",
                  associationMemberDetails: {
                    associationMemberName: districtName,
                    state: districtDetails?.state,
                    email: districtDetails?.email,
                    phone: districtDetails?.phoneNumber,
                    type: "LEA",
                  },
                  // memberManagementPaymentId: res?.data.paymentId,
                },
                headers: headers,
              };
              let resv = await PostCallApi(seData1);
              if (resv?.status === 200) {
                if (resv?.data?.success) {
                  Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: "Registration Successful",
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background:
                      "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutRight",
                    },
                  });

                  let rv = resv?.data?.payload;
                  setAllInVData({
                    ...ATRC,
                    subtotal: subtotal,
                    discountValue: discountValue?.discountValue,
                    data,
                    memberManagementId: rv?.memberManagementId,
                  });
                  dispatch(AttendeeRegistrationConference());
                  setShowInvoise(true);

                  setDis(false);
                } else {
                  setDis(false);
                }
              } else {
                setDis(false);
              }
            } else {
              SwalError(res?.data?.message);
            }
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };
    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <h3>Coming soon for payment process</h3> */}

        <Button className="theme_btn" type="submit" disabled={!stripe || dis}>
          {dis ? (
            <CircularProgress
              color="inherit"
              sx={{
                color: "#fff",
                marginLeft: "0.5rem",
                height: "23px !important",
                width: "23px !important",
              }}
            />
          ) : (
            "Complete Payment"
          )}
        </Button>
        <Button className="Can_cel_btn ms-3" onClick={() => handleReset()}>
          Cancel
        </Button>
      </form>
    );
  };
  const stripePromise = loadStripe(clientPublicKey);

  return (
    <>
      {showInvoise ? (
        <Invoice
          handleReset={handleReset}
          ATRC={allInVData}
          districtName={districtName}
        />
      ) : (
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="title attend-title mb-3">Payment Details</div>
              <div className="col-md-12">
                <div className="conference_details border-bottom-0 mb-4">
                  <ul>
                    <li>
                      Fees
                      <span>
                        {companyData?.eventFees?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </span>
                    </li>{" "}
                    {discountValue ? (
                      <li>
                        Discount
                        <span>
                          -{" "}
                          {discountValue
                            ? discountValue.discountValue?.toLocaleString(
                                "en-US",
                                { style: "currency", currency: "USD" }
                              )
                            : ""}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <div className="subtotal">
                    <p>
                      Total{" "}
                      <span>
                        {subtotal
                          ? subtotal?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "0.00"?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </span>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      {!payment && (
                        <DiscountApply
                          handlePromo={handlePromo}
                          handleRemoveDC={handleRemoveDC}
                          discountValue={discountValue}
                          diableInvoiceSubmit={diableInvoiceSubmit}
                        />
                      )}
                    </div>
                    <div className="col-md-2 text-end my-auto">
                      {!payment && (
                        <div className="mt-3 ">
                          <Button
                            className="theme_btn"
                            onClick={handlePay}
                            disabled={diableInvoiceSubmit}
                          >
                            {withDiscountSubmit ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Pay"
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {payment ? (
                <>
                  <div className="d-flex gap-3 mb-3">
                    <Button
                      className={`${acBtn ? "theme_btn" : "Can_cel_btn"}`}
                      onClick={() => setAcBtn(true)}
                    >
                      {" "}
                      Pay Online
                    </Button>
                    <Button
                      className={`${!acBtn ? "theme_btn" : "Can_cel_btn"}`}
                      onClick={() => setAcBtn(false)}
                    >
                      Request Invoice
                    </Button>
                  </div>
                  {acBtn ? (
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        setAllInVData={setAllInVData}
                        setShowInvoise={setShowInvoise}
                      />
                    </Elements>
                  ) : (
                    <ShowInvoice
                      RequestInvoice={RequestInvoice}
                      diableInvoiceSubmit={diableInvoiceSubmit}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentProcess;
