import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import header_logo from "../../../images/login_logo.png";
import SEO_Sign from "../../../images/SEO_Sign.png";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
// import axios from "axios";
// import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import Swal from "sweetalert2";

function Certificate_New() {
  let componentRef = useRef();
  const [open, setOpen] = useState(false);

  const { courseid, userid, ayid } = useParams();
  const [certiData, setCertiData] = useState();
  const [derror, setDerror] = useState({});
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  useEffect(() => {
    if (courseid && userid && ayid) {
      getCertiData(courseid, userid, ayid);
    }
    // eslint-disable-next-line
  }, [courseid, userid, ayid]);
  const getCertiData = async (id, userId, ayId) => {
    if (id && userId && ayId) {
      setOpen(true);
      // axios
      //   .get(
      //     `${Config.API_HOST_URL_live}/api/Report/GetCertificateDataInReport?userID=${userId}&courseId=${id}&logginUserId=${LogUserId}&academicYearId=${ayId}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //         "Content-Type": "application/json",
      //         Accept: "*/*",
      //       },
      //     }
      //   )
      //   .then((response) => {

      const sendData = {
        url: `/api/Report/GetCertificateDataInReport?userID=${userId}&courseId=${id}&logginUserId=${LogUserId}&academicYearId=${ayId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }

      let response = await GetCallApi(sendData)
      setOpen(false);
      if (response?.status === 200) {
        if (response.data.success) {
          // setOpen(false);
          setCertiData(response.data?.payload);
          setDerror({ pageErr: "" });
          // } else {
          //   setOpen(false);
          // }
        } else {
          setDerror({ pageErr: "You do not have access to this page" });
          setOpen(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response?.data?.message
              ? response?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        setCertiData();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
      //     });
      // } else {
      //   setDerror({ pageErr: "Missing key's" });
      //   setCertiData();
      // }
    };
  }
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="certificate_page main_backcolor">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="col-md-8 m-auto">
              {derror?.pageErr === "" ? (
                <>
                  <div className="button_div text-center mb-3">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="btn_theme"
                          data-testid="Print-iconButton"
                          aria-label="Print"
                          title="Print"
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                  <div
                    className="certificate_wrapper"
                    ref={(el) => (componentRef = el)}
                    style={{
                      background:
                        "linear-gradient(to top, rgb(153 223 219), rgb(14, 210, 247))",
                      padding: "1.8rem 2rem",
                    }}
                  >
                    <div
                      className="title"
                      style={{ textAlign: "center", marginBottom: "1rem" }}
                    >
                      <h1
                        style={{
                          fontSize: "84px",
                          fontWeight: "900",
                          color: "#fff",
                          fontFamily: "Corbel",
                          letterSpacing: "1.5",
                        }}
                      >
                        Certificate of Completion
                      </h1>
                    </div>
                    <div
                      className="certificate_contect"
                      style={{ textAlign: "center", position: "relative" }}
                    >
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          Awarded to
                        </h3>
                        <p
                          style={{
                            fontSize: "19px",
                            fontWeight: "500",
                            margin: "0",
                            textTransform: "capitalize",
                            color: "#fff",
                          }}
                        >
                          {certiData?.userName}
                        </p>
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          For Completing the Course
                        </h3>
                        <p
                          style={{
                            fontSize: "19px",
                            fontWeight: "500",
                            margin: "0",
                            color: "#fff",
                          }}
                        >
                          {certiData?.courseName}
                        </p>
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          Key Area
                        </h3>
                        {certiData?.mainCategory?.map((mcat, i) => (
                          <p
                            style={{
                              fontSize: "19px",
                              fontWeight: "500",
                              margin: "0",
                              color: "#fff",
                            }}
                            key={i}
                          >
                            {mcat?.courseCategoryCode} - {mcat?.categoryName}
                          </p>
                        ))}
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          learning Objective
                        </h3>
                        {certiData?.learningObjective?.map((mcat, i) => (
                          <p
                            style={{
                              fontSize: "19px",
                              fontWeight: "500",
                              margin: "0",
                              color: "#fff",
                            }}
                            key={i}
                          >
                            {mcat?.courseCategoryCode} - {mcat?.categoryName}
                          </p>
                        ))}
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          training Topic
                        </h3>
                        {certiData?.trainingTopic?.map((mcat, i) => (
                          <p
                            style={{
                              fontSize: "19px",
                              fontWeight: "500",
                              margin: "0",
                              color: "#fff",
                            }}
                            key={i}
                          >
                            {mcat?.courseCategoryCode} - {mcat?.categoryName}
                          </p>
                        ))}
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          Date of Completion
                        </h3>
                        <p
                          style={{
                            fontSize: "19px",
                            fontWeight: "500",
                            margin: "0",
                            color: "#fff",
                          }}
                        >
                          {certiData?.dateOfCompletion === "" ||
                            certiData?.dateOfCompletion === null
                            ? ""
                            : convert(
                              certiData?.dateOfCompletion?.split("T")[0]
                            )}
                        </p>
                      </div>
                      <div className="list" style={{ marginBottom: "20px" }}>
                        <h3
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                            color: "#fff",
                            textTransform: "uppercase",
                            letterSpacing: "6px",
                            fontFamily: "unset",
                          }}
                        >
                          CEU Value
                        </h3>
                        <p
                          style={{
                            fontSize: "19px",
                            fontWeight: "500",
                            margin: "0",
                            color: "#fff",
                          }}
                        >
                          {certiData?.ceuValue}
                        </p>
                      </div>
                      <div
                        className="footer d-flex justify-content-between"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          width: "100%",
                        }}
                      >
                        <img src={SEO_Sign} className="img-fluid" alt="seo" />
                        <img
                          src={header_logo}
                          className="img-fluid"
                          style={{
                            margin: "auto 0 0 auto",
                          }}
                          alt="header"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="err_msg">{derror?.pageErr}</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default React.memo(Certificate_New);
