import React, { Suspense, lazy } from "react";
import "./index.scss";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
const EmailTogglebutton = lazy(() => import("./EmailTogglebutton"));
const renderLoader = () => <p></p>;

function EmailPreference(props) {
  const [mentionComment, setMentionComment] = useState(false);
  const [completeCourse, setCompleteCourse] = useState(false);
  const [recieveMessage, setRecieveMessage] = useState(false);
  const [connectionRequestS, setConnectionRequestS] = useState(false);
  const [connectionRequestA, setConnectionRequestA] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  // const [startCourse, setStartCourse] = useState(false);
  const [blockPost, setBlockPost] = useState(false);
  const [blockPostDelete, setBlockPostDelete] = useState(false);
  const [certificateCreate, setCertificateCreate] = useState(false);
  // const [chat, setChat] = useState(false);
  const [assignCourse, setAssignCourse] = useState(false);
  const [newCourse, setNewCourse] = useState(false);
  const [csasIns, setCsasIns] = useState(false);
  const [ceIns, setCeIns] = useState(false);
  const [ceUser, setCeUser] = useState(false);
  const [cexIns, setCexIns] = useState(false);
  const [cexUser, setCexUser] = useState(false);
  const [cexpIns, setCexpIns] = useState(false);
  const [cexpUser, setCexpUser] = useState(false);
  const [duedIns, setDuedIns] = useState(false);
  const [duedUser, setDuedUser] = useState(false);
  const [crPost, setCrPost] = useState(false);
  const [crCourse, setCrCourse] = useState(false);
  const [updCourse, setUpdCourse] = useState(false);
  const [cexrIns, setCexrIns] = useState(false);
  const [cexrUser, setCexrUser] = useState(false);
  const [csasUser, setCsasUser] = useState(false);
  const [startCourseUser, setStartCourseUser] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogUserd = useSelector((state) => state.login?.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getEmailTDetail();
    // eslint-disable-next-line
  }, []);
  const getEmailTDetail = () => {
    setOpenCircle(true);
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/EmailPreference/GetEmailPreferenceByuserId?userId=${LogUserd?.userId}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let epd = res.data.payload;
            setMentionComment(epd?.whenSomeoneMentionsThemInCommentOrPost);
            setCompleteCourse(epd?.whenTheyCompleteIscourse);
            setRecieveMessage(epd?.whenTheyIsRecevieMessage);
            setConnectionRequestS(epd?.whenSomeoneSendsThemIsConnectionRequest);
            setConnectionRequestA(
              epd?.whenSomeoneAcceptsTheirIsConnectionRequest
            );
            setChangePassword(epd?.whenTheyChangeTheirIsPassword);
            setBlockPost(epd?.whenSomeOneBlockPost);
            setBlockPostDelete(epd?.whenTheyBlockPostDelete);
            setNewCourse(epd?.whenSomeOneSendAssignCourseToCreated);
            setAssignCourse(epd?.whenSomeOneSendAssignCourseToUser);
            setCsasIns(epd?.whenTheyAssignedCourseInstructore);
            setCeIns(epd?.whenTheyCertificateEarnedInstructore);
            setCeUser(epd?.whenTheyCertificateEarnedUser);
            setCexIns(epd?.whenTheyCertificateExpiredCreated);
            setCexUser(epd?.whenTheyCertificateExpiredUser);
            setDuedIns(epd?.whenTheyCourseDueDateExpiredInstructore);
            setDuedUser(epd?.whenTheyCourseDueDateExpiredUser);
            setCertificateCreate(epd?.whenTheyCreateCertificate);
            setCrCourse(epd?.whenTheyCreateCourse);
            setCrPost(epd?.whenTheyCreateIsPost);
            setCexpIns(epd?.whenTheyExpiringCertificateEnteredInstructore);
            setCexpUser(epd?.whenTheyExpiringCertificateEnteredUser);
            // setStartCourse(epd?.whenTheyStartCourse);
            setUpdCourse(epd?.whenTheyUpdateCourse);
            setCexrIns(epd?.whentheyReminderExpiringCertificateInstructore);
            setCexrUser(epd?.whentheyReminderExpiringCertificateTeamMember);
            setCsasUser(epd?.whenTheyCourseAssignedUser);
            setStartCourseUser(epd?.whenTheyStartCourse);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => console.log(e));
  };
  const onSubmit = () => {
    let Em = {
      userId: LogUserd?.userId,
      whenSomeoneMentionsThemInCommentOrPost: mentionComment,
      whenTheyCompleteIscourse: completeCourse,
      whenTheyIsRecevieMessage: recieveMessage,
      whenSomeoneSendsThemIsConnectionRequest: connectionRequestS,
      whenSomeoneAcceptsTheirIsConnectionRequest: connectionRequestA,
      whenTheyChangeTheirIsPassword: changePassword,
      whenSomeOneBlockPost: blockPost,
      whenSomeOneSendAssignCourseToCreated: newCourse,
      whenSomeOneSendAssignCourseToUser: assignCourse,
      whenTheyAssignedCourseInstructore: csasIns,
      whenTheyBlockPostDelete: blockPostDelete,
      whenTheyCertificateEarnedInstructore: ceIns,
      whenTheyCertificateEarnedUser: ceUser,
      whenTheyCertificateExpiredCreated: cexIns,
      whenTheyCertificateExpiredUser: cexUser,
      whenTheyCourseAssignedUser: csasUser,
      whenTheyCourseDueDateExpiredInstructore: duedIns,
      whenTheyCourseDueDateExpiredUser: duedUser,
      whenTheyCreateCertificate: certificateCreate,
      whenTheyCreateCourse: crCourse,
      whenTheyCreateIsPost: crPost,
      whenTheyExpiringCertificateEnteredInstructore: cexpIns,
      whenTheyExpiringCertificateEnteredUser: cexpUser,
      // whenTheyStartCourse: startCourse,
      whenTheyUpdateCourse: updCourse,
      whentheyReminderExpiringCertificateInstructore: cexrIns,
      whentheyReminderExpiringCertificateTeamMember: cexrUser,
      whenTheyStartCourse: startCourseUser,
      createdBy: LogUserd?.userId,
    };
    setOpenCircle(true);
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/EmailPreference/CreateUpdateEmailPreference`,
        Em,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);

            function titleCase(str) {
              var splitStr = str?.toLowerCase().split(" ");
              for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] =
                  splitStr[i].charAt(0).toUpperCase() +
                  splitStr[i].substring(1);
              }
              return splitStr.join(" ");
            }
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(res.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            getEmailTDetail();
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="email_preference main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="email_preference_title">Email Preference</div>
              <div className="row">
                <div className="col-md-12">
                  <div className="email_preference_wrapper">
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Activity Feed</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Receive Notification when create post</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={crPost ? "1" : "0"}
                                onToggleBtn={setCrPost}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A member mentions you in comment or post</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={mentionComment ? "1" : "0"}
                                onToggleBtn={setMentionComment}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Some one Block Post</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={blockPost ? "1" : "0"}
                                onToggleBtn={setBlockPost}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Block Post Delete</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={blockPostDelete ? "1" : "0"}
                                onToggleBtn={setBlockPostDelete}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                A member replies to an update or comment you've
                                posted
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Course</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Receive Notification When Create A Course</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={crCourse ? "1" : "0"}
                                onToggleBtn={setCrCourse}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Receive Notification When Course Assign to Me{" "}
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={csasUser ? "1" : "0"}
                                onToggleBtn={setCsasUser}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Receive Notification When Update A Course</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={updCourse ? "1" : "0"}
                                onToggleBtn={setUpdCourse}
                              />
                            </div>
                          </div>
                        </div>{" "}
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Receive Notification When Starting A Course
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={startCourse ? "1" : "0"}
                                onToggleBtn={setStartCourse}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Receive Notification When Complete A Course
                                {/* Complete Course */}
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={completeCourse ? "1" : "0"}
                                onToggleBtn={setCompleteCourse}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Receive Assigned Course Notification</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={assignCourse ? "1" : "0"}
                                onToggleBtn={setAssignCourse}
                              />
                            </div>
                          </div>
                        </div>{" "} */}
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Receive New Course Added Notification</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={newCourse ? "1" : "0"}
                                onToggleBtn={setNewCourse}
                              />
                            </div>
                          </div>
                        </div>{" "} */}
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification Assigned Course Supervisor
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={csasIns ? "1" : "0"}
                                onToggleBtn={setCsasIns}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification Course Due Date Expired
                                Supervisor
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={duedIns ? "1" : "0"}
                                onToggleBtn={setDuedIns}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Recieve Notification When Course Due Date
                                Expired
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={duedUser ? "1" : "0"}
                                onToggleBtn={setDuedUser}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                When They Start Course User
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={startCourseUser ? "1" : "0"}
                                onToggleBtn={setStartCourseUser}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Certificate</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification Certificate Earned Supervisor
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={ceIns ? "1" : "0"}
                                onToggleBtn={setCeIns}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Recieve Notification When Certificate Earned
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={ceUser ? "1" : "0"}
                                onToggleBtn={setCeUser}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification Certificate Expiring
                                Created
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexpIns ? "1" : "0"}
                                onToggleBtn={setCexpIns}
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Recieve Notification When Certificate Expiring
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexpUser ? "1" : "0"}
                                onToggleBtn={setCexpUser}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification Certificate Expired Supervisor
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexIns ? "1" : "0"}
                                onToggleBtn={setCexIns}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Recieve Notification When Certificate Expired
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexUser ? "1" : "0"}
                                onToggleBtn={setCexUser}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Receive Notification When Certificate Created
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={certificateCreate ? "1" : "0"}
                                onToggleBtn={setCertificateCreate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Send Notification When Reminder Of Expiring
                                Certificate To Supervisor
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexrIns ? "1" : "0"}
                                onToggleBtn={setCexrIns}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Receive Notification When Reminder Of Expiring
                                Certificate
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={cexrUser ? "1" : "0"}
                                onToggleBtn={setCexrUser}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Messages</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A member sends you a new message</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={recieveMessage ? "1" : "0"}
                                onToggleBtn={setRecieveMessage}
                              />
                            </div>
                          </div>
                        </div>{" "}
                        {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A new member started Chat</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={chat ? "1" : "0"}
                                onToggleBtn={setChat}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Social Groups</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A member invites you to join a group</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Group information is updated</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                You are promoted to a group organizer or
                                moderator
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                A member requests to join a private group you
                                organize
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                Your request to join a group has been approved
                                or denied
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Forums</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>Change Password</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={changePassword ? "1" : "0"}
                                onToggleBtn={setChangePassword}
                              />
                            </div>
                          </div>
                        </div> */}
                    {/* <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                A member replies to a discussion you are
                                subscribed to
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>
                                A member creates a discussion in a forum you are
                                subscribed to
                              </h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                checked={true ? "1" : "0"}
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                              />
                            </div>
                          </div>
                        </div> */}
                    {/* </div>
                    </div> */}
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Connections</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A member invites you to connect</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={connectionRequestS ? "1" : "0"}
                                onToggleBtn={setConnectionRequestS}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>A member accepts your connection request</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={connectionRequestA ? "1" : "0"}
                                onToggleBtn={setConnectionRequestA}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="email_preference_section">
                      <div className="title d-flex">
                        <span>Password</span>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 set_margin_bottom">
                          <div className="d-flex preference_content">
                            <div className="text">
                              <h4>When Password Change</h4>
                            </div>
                            <div className="switcher">
                              <EmailTogglebutton
                                disabled={false}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                                checked={changePassword ? "1" : "0"}
                                onToggleBtn={setChangePassword}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="email_preference_section p-3 d-flex justify-content-end">
                      <Button className="theme_btn" onClick={onSubmit}>
                        Save Changes
                      </Button>
                      {/* <Button className="delete_btn">Cancel</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(EmailPreference);
