import React, { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
import user_img from "../../../../images/user13.png";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import Config from "../../../../Utils/Config";
import axios from "axios";
import { useSelector } from "react-redux";
import backimg12 from "../../../../images/group_card_back12.png";
import userimg13 from "../../../../images/user13.png";
import TabComponent from "./tabComponent";

const renderLoader = () => <p></p>;

function ComGroupDetail() {
  const { id } = useParams();
  const [values, setValues] = React.useState("1");
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [grpList, setGrpList] = useState({});
  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  useEffect(() => {
    getGrpList();
    // eslint-disable-next-line
  }, []);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getGrpList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const cgList = response.data.payload?.filter(
            (item) => item?.communityGroupId === id
          );

          setGrpList(cgList[0]);
        }
      });
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <div className="community_group_detail_page">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="community_group__detail_title">
                Community Group Detail
              </div>
              <div className="community_group_detail_main_box">
                <div className="main_img_detail_box">
                  <div className="group_back_img_box">
                    <img
                      src={
                        grpList?.groupCoverImage === "" ||
                        grpList?.groupCoverImage === null
                          ? backimg12
                          : `${Config.API_HOST_URL_live}${grpList?.groupCoverImage}`
                      }
                      alt=""
                      className="img-fluid back_img"
                    />
                  </div>
                  <div className="group_img_detail_box">
                    <div className="group_pro_img w-25">
                      <img
                        src={
                          grpList?.groupImageUrl === "" ||
                          grpList?.groupImageUrl === null
                            ? userimg13
                            : `${Config.API_HOST_URL_live}${grpList?.groupImageUrl}`
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="group_name_pri_de w-75">
                      <div className="name_de_box">
                        <div className="title_group_name">
                          <h3>{grpList?.communityGroupName}</h3>                        
                          <span className="descrip">
                            This is a {grpList?.communityGroupTypeName} group
                            for {grpList?.communityGroupName} members
                          </span>
                          <div className="organi p_14_text">
                            Organizer:{" "}
                            <img
                              src={user_img}
                              alt=""
                              className="img-fluid org_img"
                            />
                          </div>
                        </div>
                        <div className="btn_group">
                          <Button className="btn_privacy ">
                            {grpList?.communityGroupTypeName}
                          </Button>                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab_box">
                  <TabContext value={values}>
                    <Box className="tab_group ">
                      {grpList?.organizerUserId === LogUserId ||
                      LogUserde?.roleId === 1 ? (
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Feed" value="1" />
                          <Tab label="Members" value="2" />
                          {/* <Tab label="Send Invites" value="3" /> */}
                        </TabList>
                      ) : (
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Feed" value="1" />
                          <Tab label="Members" value="2" />
                        </TabList>
                      )}
                    </Box>
                    <TabComponent id={id} orgId={grpList?.organizerUserId} />
                  </TabContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(ComGroupDetail);
