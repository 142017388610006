import React, { useEffect, useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import "./Index.scss";
import { useSelector } from "react-redux";

function NavigateComponent() {
  const location = useLocation();
  const [pathname, setPathname] = useState();
  let TopMenuList = useSelector((state) => state.setting?.TopMenuList);
  useEffect(() => {
    setPathname(location?.pathname);
    // eslint-disable-next-line
  }, [location]);
  return (
    <>
      <Nav
        navbar
        className="header_item"
        style={{
          display: "flex",
          listStyle: "none",
          justifyContent: "space-around",
        }}
      >
        {TopMenuList?.filter((x) => x.old !== true)?.map((menu, i) => (
          <NavItem className="header_active" key={i}>
            <NavLink
              to={menu?.menuUrl}
              className={`header_link ${
                pathname === menu?.menuUrl ? "active" : ""
              }`}
            >
              {menu?.menuName}
            </NavLink>
          </NavItem>
        ))}
        {/* <NavItem className="header_active">
          <NavLink
            to="/dashboard"
            className={`header_link ${
              pathname === "/dashboard" ? "active" : ""
            }`}
          >
            Dashboard
          </NavLink>
        </NavItem>
        <NavItem className="header_active">
          <NavLink
            to="/ceu-courses"
            className={`header_link ${
              pathname === "/ceu-courses" ? "active" : ""
            }`}
          >
            CEU Courses
          </NavLink>
        </NavItem> */}
        {/* <NavItem className="header_active">
          <NavLink
            to="/recipe"
            className={`header_link ${pathname === "/recipe" ? "active" : ""}`}
          >
            Recipe
          </NavLink>
        </NavItem> */}
        {/* <NavItem className="header_active">
          <NavLink
            to="/fig-lifestyle"
            className={`header_link ${
              pathname === "/fig-lifestyle" ? "active" : ""
            }`}
          >
            FIG Lifestyle
          </NavLink>
        </NavItem>{" "}
        <NavItem className="header_active">
          <NavLink
            to="/community"
            className={`header_link ${
              pathname === "/community" ? "active" : ""
            }`}
          >
            Community
          </NavLink>
        </NavItem>
        <NavItem className="header_active">
          <NavLink
            to="/fig-family"
            className={`header_link ${
              pathname === "/fig-family" ? "active" : ""
            }`}
          >
            FIG Family
          </NavLink>
        </NavItem>
        <NavItem className="header_active">
          <NavLink
            to="/preferred-partners"
            className={`header_link ${
              pathname === "/preferred-partners" ? "active" : ""
            }`}
          >
            Preferred Partners
          </NavLink>
        </NavItem>
        <NavItem className="header_active">
          <NavLink
            to="/reports"
            className={`header_link ${pathname === "/reports" ? "active" : ""}`}
          >
            Reports
          </NavLink>
        </NavItem>{" "}
        <NavItem className="header_active">
          <NavLink
            to="/settings"
            className={`header_link ${
              pathname === "/settings" ? "active" : ""
            }`}
          >
            Settings
          </NavLink>
        </NavItem> */}
        {/* <NavItem className="header_active">
          <NavLink
            to="/training"
            className={`header_link ${
              pathname === "/training" ? "active" : ""
            }`}
          >
            Training
          </NavLink>
        </NavItem> */}
      </Nav>
    </>
  );
}

export default React.memo(NavigateComponent);
