import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Autocomplete, Button, TextField } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import CreateData from "./Create_Data";
import { useDispatch, useSelector } from "react-redux";
import { CreteDataFor, CreteQuestionQuiz } from "../../../reducers/user";
import { styled } from "@mui/material/styles";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import Config from "../../../Utils/Config";
import axios from "axios";
import Swal from "sweetalert2";
import { BiSolidMinusSquare, BiSolidPlusSquare } from "react-icons/bi";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import active_img from "../../../images/Icon/active_green.svg";
import pro_img from "../../../images/default_profile.jpg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const showReadMore = (t, des) => {
  Swal.fire({
    position: "center",
    width: window.innerWidth > 768 ? "50%" : "90%",
    title: t,
    text: des,
    confirmButtonColor: "#fff",
    // confirmButtonTextColor: "#000000",
    color: "#ffffff",
    background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
  });
};

function SchoolDistrict(prop) {
  const [checkBoxVal, setCheckBoxVal] = useState(false);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("GroupName");
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [courseSchedule, setCourseSchedule] = useState([]);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const [webinarData, setWebinarData] = useState([]);
  const [evId, setEvId] = useState();
  const [openView, setOpenView] = useState(false);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: prop?.title ? prop?.title : "Table",
    sheet: "Users",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const cret = useSelector((state) => state.user?.create_data);
  const [createData, setCreateData] = useState(false);
  const MenuName = useSelector((state) => state.setting?.MenuName);

  const handleCloseView = () => {
    setOpenView(false);
    setEvId();
  };

  const handleOpenView = (r) => {
    setOpenView(true);
    setEvId(r?.id);

    if (r?.id) {
      if (r?.id === evId) {
        setOpenView(false);
      }
    }
  };

  useEffect(() => {
    if (downloadExcel) {
      onDownload();
      setDownloadExcel(false);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);

  useEffect(() => {
    setCourseSchedule(prop?.dataValues);
    setCheckBoxVal(false);
  }, [prop?.dataValues]);

  const handleAll = (val) => {
    const newArr = stableSort(
      formattedSearchResults,
      getComparator(order, orderBy)
    )
      ?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      )
      ?.map((x) => {
        return { ...x, add: val };
      });
    const isSameUser = (a, b) => a.No === b.No && a.Name === b.Name;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(courseSchedule, newArr, isSameUser);
    const onlyInB = onlyInLeft(newArr, courseSchedule, isSameUser);

    const result = [...newArr, ...onlyInA, ...onlyInB];
    // console.log(result);

    setCourseSchedule(result);
  };
  const handleMainCheck = (e, index, item) => {
    let allMainCh = true;
    const newArr = courseSchedule.map((obj) => {
      if (obj.No === index.No) {
        return { ...obj, add: e };
      }
      return obj;
    });

    setCourseSchedule(newArr);
    if (e) {
      stableSort(newArr, getComparator(order, orderBy))
        ?.slice(
          (currentPage - 1) * pageSize,
          (currentPage - 1) * pageSize + pageSize
        )
        ?.map((x) => {
          if (x.add === false || x.add === undefined) {
            return (allMainCh = false);
          }
          return 0;
        });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  function descendingComparator(a, b, orderBy) {
    if (
      orderBy === "seqNo" ||
      orderBy === "CategoryCode" ||
      orderBy === "Userpd" ||
      orderBy === "RequiredCEU" ||
      orderBy === "lessonNu" ||
      orderBy === "TotalQuiz"
    ) {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
    } else {
      if (
        b[orderBy]?.toString()?.toLowerCase() <
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy]?.toString()?.toLowerCase() >
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
      handlePageChanege(currentPage);
    };
    return (
      <TableHead>
        <TableRow>
          {prop?.title === "User List" ||
            prop?.title === "Webinar Join Request List" ? (
            <>
              {!downloadExcel &&
                (LogUserde?.roleId === 1 || LogUserde?.roleId === 2) ? (
                <StyledTableCell>
                  <label className="personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      disabled={
                        formattedSearchResults?.length === 0 ? true : false
                      }
                      checked={checkBoxVal}
                      onChange={(e) => {
                        handleAll(e.target.checked);
                        setCheckBoxVal(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </StyledTableCell>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {prop?.headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {downloadExcel ? (
                  ""
                ) : (
                  <>
                    {orderBy === headCell?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {downloadExcel ? (
            ""
          ) : (
            <>
              {prop?.action === false ? (
                ""
              ) : (
                <StyledTableCell>Actions</StyledTableCell>
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChanege = (page) => {
    setCurrentPage(page);
    setCheckBoxVal(false);
    const newArr = courseSchedule.map((obj) => {
      return { ...obj, add: false };
    });
    setCourseSchedule(newArr);
  };
  useEffect(() => {
    if (cret !== undefined) {
      setCreateData(cret);
      setSearchField("");
    }
  }, [cret]);
  const handleCreate = (data) => {
    setCreateData(true);
    dispatch(CreteDataFor(true));
    if (prop?.btn_name === "Create Course") {
      dispatch(CreteQuestionQuiz({ createQuestion: false }));
    }
  };
  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = courseSchedule?.filter((user) => {
    if (searchField) {
      let state = false;
      prop?.headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  useEffect(() => {
    setCurrentPage(1);
    setEvId()
  }, [MenuName]);

  const [schList, setSchList] = useState([]);
  useEffect(() => {
    if (prop?.title === "Job Title List" || prop?.title === "User List") {
      getSchoolList();
    } else if (prop?.title === "Webinar Join Request List") {
      getWebinar();
    }
    // eslint-disable-next-line
  }, [prop.title]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { schId, setSchId, webinarSelect, setWebinarSelect } = prop;
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserde?.userId}`;
    axios
      .get(url, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value: item?.schoolDistrictId,
              label: item?.districtName,
            };
          });
          let schData = [
            {
              value: "00000000-0000-0000-0000-000000000000",
              label: "All School District",
            },
            ...data,
          ];
          setSchList(schData);
          setSchId(data?.length > 1 ? schId : data[0]);
        }
      })
      .catch((error) => console.log(error));
  };
  const getWebinar = async () => {
    let seData = {
      url: "/api/Webinar/GetWebinarData",
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ctData = res.data.payload
          ?.filter((x) => x.isActive === true)
          ?.map((item) => {
            return {
              value: item?.webinarId,
              label: item?.title,
            };
          });
        let schData = [
          {
            value: "00000000-0000-0000-0000-000000000000",
            label: "All Webinar",
          },
          ...ctData,
        ];
        setWebinarData(schData);
      } else {
      }
    } else {
    }
  };

  const sendMail = async (val) => {
    let userList = courseSchedule
      ?.filter((x) => x.add === true)
      ?.map((x) => x.userId);
    let userEmail = courseSchedule
      ?.filter((x) => x.add === true)
      ?.map((x) => x.email);
    // axios
    //   .post(
    //     `${Config.API_HOST_URL_live}/api/User/sendBulkWelcomeMail`,
    //     userList,
    //     { headers }
    //   )
    //   .then((response) => {
    let seData = {
      url:
        val === "User"
          ? "/api/User/sendBulkWelcomeMail"
          : "/api/Webinar/SendWebinarBulkMail",
      body:
        val === "User"
          ? userList
          : { webinarId: webinarSelect?.value, email: userEmail },
      headers: headers,
    };
    let response = await PostCallApi(seData);
    if (response?.status === 200) {
      if (response.data.success) {
        setCheckBoxVal(false);
        const newArr = courseSchedule.map((obj) => {
          return { ...obj, add: false };
        });
        setCourseSchedule(newArr);
        function titleCase(str) {
          var splitStr = str?.toLowerCase().split(" ");
          for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] =
              splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
          return splitStr.join(" ");
        }
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // })
    // .catch((error) => console.log(error));
  };
  return (
    <>
      {createData === false ? (
        <>
          <div className="mb-4 d-flex justify-content-between listdata_control_sec">
            <label>{prop?.title}</label>
            {prop?.title === "User List" ? (
              <>
                {LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                  <>
                    {courseSchedule?.filter((x) => x.add === true)[0]
                      ?.userId !== undefined ? (
                      <Button
                        type="button"
                        className="cource_reschedule_button btn_space ms-auto me-4"
                        onClick={() => sendMail("User")}
                      >
                        Send Mail
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : prop?.title === "Webinar Join Request List" ? (
              <>
                {LogUserde?.roleId === 1 ? (
                  <>
                    {courseSchedule?.filter((x) => x.add === true)?.length >
                      0 ? (
                      <>
                        {webinarSelect?.value !==
                          "00000000-0000-0000-0000-000000000000" ? (
                          <Button
                            type="button"
                            className="cource_reschedule_button btn_space ms-auto me-4"
                            onClick={() => sendMail()}
                          >
                            Send Mail
                          </Button>
                        ) : (
                          <div className="text-center d-flex align-items-center ms-auto me-4">
                            <span role="alert" className="error_text">
                              Plese Select Webinar to send Mail
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {prop?.title === "User List" || prop?.title === "Job Title List" ? (
              LogUserde?.roleId === 1 || LogUserde?.roleId === 2 ? (
                <div className="w-25 ms-auto me-4">
                  <Autocomplete
                    disablePortal
                    className="form-select1 "
                    options={schList}
                    sx={{ width: 200 }}
                    value={schId?.label}
                    onChange={(e, newValue) => {
                      if (newValue === null) {
                        setSchId(schList[0]);
                      } else {
                        setSchId(newValue);
                      }
                    }}
                    renderInput={(params, i) => (
                      <TextField
                        className="texfiled-demo"
                        key={i}
                        {...params}
                      />
                    )}
                  />
                </div>
              ) : (
                ""
              )
            ) : prop?.title === "Webinar Join Request List" ? (
              <div className="w-25 ms-auto me-4">
                <Autocomplete
                  disablePortal
                  className="form-select1 "
                  options={webinarData}
                  sx={{ width: 200 }}
                  value={webinarSelect?.label}
                  onChange={(e, newValue) => {
                    if (newValue === null) {
                      setWebinarSelect(webinarData[0]);
                    } else {
                      setWebinarSelect(newValue);
                    }
                  }}
                  renderInput={(params, i) => (
                    <TextField className="texfiled-demo" key={i} {...params} />
                  )}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={` search_sec w-25 ${((prop?.title === "Job Title List" ||
                prop?.title === "User List" ||
                prop?.title === "Webinar Join Request List") &&
                LogUserde?.roleId === 1) ||
                LogUserde?.roleId === 2
                ? ""
                : "ms-auto"
                } me-4`}
            >
              <div className="search_input w-100">
                <input
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <img src={search_icon} alt="" />
              </div>
            </div>
            <div className="d-flex">
              <Button
                type="button"
                className="cource_reschedule_button light_btn"
                onClick={() => setDownloadExcel(true)}
              >
                Download
              </Button>
              {prop?.title === "Block & Report List" ||
                prop?.title === "Demonstration Form List" ||
                prop?.title === "Webinar Join Request List" ||
                prop?.title === "Proposal Form List" ? (
                ""
              ) : prop?.title === "District List" ? (
                <>
                  {LogUserde?.roleId === 1 ? (
                    <Button
                      className="cource_reschedule_button btn_space"
                      onClick={(d) => handleCreate(prop?.btn_name)}
                    >
                      {prop?.btn_name}
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Button
                  className="cource_reschedule_button btn_space"
                  onClick={(d) => handleCreate(prop?.btn_name)}
                >
                  {prop?.btn_name}
                </Button>
              )}
            </div>
          </div>
          <hr className="tabpannel_hr" />
          <div className="mt-3 school_district">
            <Paper className="table-box">
              <TableContainer className="" ref={tableRef}>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(
                      formattedSearchResults,
                      getComparator(order, orderBy)
                    )
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <StyledTableRow className="table_body">
                              {prop?.title === "User List" ||
                                prop?.title === "Webinar Join Request List" ? (
                                <>
                                  {!downloadExcel &&
                                    (LogUserde?.roleId === 1 ||
                                      LogUserde?.roleId === 2) ? (
                                    <StyledTableCell className="column_data">
                                      <label className="personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          checked={item.add ? true : false}
                                          onChange={(e) => {
                                            handleMainCheck(
                                              e.target.checked,
                                              item
                                            );
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </StyledTableCell>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}

                              {prop?.headCells?.map((column) => {
                                const value = item[column?.id];

                                if (downloadExcel) {
                                  if (
                                    column?.id === "CourseImg" ||
                                    column?.id === "iconimg" ||
                                    column?.id === "eveImage" ||
                                    column?.id === "RecipeImg"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        className="column_data"
                                        key={column?.id}
                                      >
                                        {column?.format &&
                                          typeof value === "number"
                                          ? column?.format(value)
                                          : value}
                                      </StyledTableCell>
                                    );
                                  }
                                }
                                if (!downloadExcel) {
                                  if (
                                    column?.widthFix ||
                                    item?.Answer?.length > 50
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="column_data"
                                        key={column?.id}
                                        style={{ width: "500px", wordBreak: "break-all" }}
                                      >
                                        {value ? value?.slice(0, 50) : ""}
                                        {value
                                          ? value?.length > 50 && (
                                            <span
                                              className="description cr"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                showReadMore(
                                                  column?.label,
                                                  value
                                                )
                                              }
                                            >
                                              ...read more
                                            </span>
                                          )
                                          : ""}

                                      </StyledTableCell>
                                    );
                                  }
                                }
                                return (
                                  <StyledTableCell
                                    className="column_data"
                                    key={column?.id}
                                  >
                                    {/* {prop?.title === "User List" &&
                                    column?.id === "firstName" ? (
                                      <div className="d-flex  align-items-center gap-2">
                                        <div className="img_boxs">
                                          <div className="user_img">
                                            <img
                                              src={`${
                                                item?.profileUrl
                                                  ? Config?.API_HOST_URL_live +
                                                    item?.profileUrl
                                                  : pro_img
                                              }`}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </div>
                                          <img
                                            src={
                                              item?.userStatus === "Online"
                                                ? active_img
                                                : ""
                                            }
                                            className="img-fluid dot_ac_in"
                                          />
                                        </div>
                                        {column?.format &&
                                        typeof value === "number"
                                          ? column?.format(value)
                                          : value}
                                      </div>
                                    ) : column?.format &&
                                      typeof value === "number" ? (
                                      column?.format(value)
                                    ) : (
                                      value
                                    )} */}
                                    {column?.format && typeof value === "number"
                                      ? column?.format(value)
                                      : value}
                                  </StyledTableCell>
                                );
                              })}
                              {downloadExcel ? (
                                ""
                              ) : (
                                <>
                                  {prop?.action === false ? (
                                    ""
                                  ) : (
                                    <StyledTableCell
                                      className="column_data"
                                    >
                                      <div className="edit_delete">
                                        {prop?.plusIcon && (
                                          <>
                                            {item?.groupUserList?.length > 0 ? (
                                              item?.id === evId ? (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  className="me-2"
                                                  onClick={() =>
                                                    handleCloseView()
                                                  }
                                                >
                                                  <BiSolidMinusSquare
                                                    fontSize={38}
                                                    color="#d91f2d"
                                                  />
                                                </div>
                                              ) : (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  className="me-2"
                                                  onClick={() =>
                                                    handleOpenView(item)
                                                  }
                                                >
                                                  <BiSolidPlusSquare
                                                    fontSize={38}
                                                    color="#73c04d"
                                                  />
                                                </div>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                        {item?.Action}
                                      </div>
                                    </StyledTableCell>
                                  )}
                                </>
                              )}
                            </StyledTableRow>
                            {downloadExcel ? (
                              <>
                                {item?.groupUserList?.length > 0 ? (
                                  <StyledTableRow className="table_body">
                                    <StyledTableCell colSpan={8}>
                                      <Table>
                                        <TableHead>
                                          <StyledTableRow>
                                            <StyledTableCell></StyledTableCell>
                                            {prop?.subHeadCells
                                              ?.filter(
                                                (x) => x?.id !== "Action"
                                              )
                                              ?.map((head, ind) => (
                                                <StyledTableCell key={ind}>
                                                  {head?.label}
                                                </StyledTableCell>
                                              ))}
                                          </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                          {item?.groupUserList?.length > 0 ? (
                                            item?.groupUserList?.map(
                                              (us, i) => (
                                                <StyledTableRow
                                                  className="table_body"
                                                  key={i}
                                                >
                                                  <StyledTableCell></StyledTableCell>
                                                  {prop?.subHeadCells
                                                    ?.filter(
                                                      (x) => x?.id !== "Action"
                                                    )
                                                    ?.map((col) => {
                                                      const value = us[col?.id];
                                                      return (
                                                        <StyledTableCell
                                                          key={col?.id}
                                                        >
                                                          {value}
                                                        </StyledTableCell>
                                                      );
                                                    })}
                                                </StyledTableRow>
                                              )
                                            )
                                          ) : (
                                            <StyledTableRow>
                                              <StyledTableCell colSpan={8}>
                                                <div className="er_nodata">
                                                  No Data Found
                                                </div>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : openView && item?.id === evId ? (
                              <StyledTableRow className="table_body">
                                <StyledTableCell colSpan={8}>
                                  <Table>
                                    <TableHead>
                                      <StyledTableRow>
                                        {prop?.subHeadCells?.map((head, ind) => (
                                          <StyledTableCell key={ind}>
                                            {head?.label}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item?.groupUserList?.length > 0 ? (
                                        item?.groupUserList?.map((us, i) => (
                                          <StyledTableRow
                                            className="table_body"
                                            key={i}
                                          >
                                            {prop?.subHeadCells?.map((col) => {
                                              const value = us[col?.id];
                                              return (
                                                <StyledTableCell key={col?.id}>
                                                  {value}
                                                </StyledTableCell>
                                              );
                                            })}
                                          </StyledTableRow>
                                        ))
                                      ) : (
                                        <StyledTableRow>
                                          <StyledTableCell colSpan={8}>
                                            <div className="er_nodata">
                                              No Data Found
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {formattedSearchResults?.length === 0 || formattedSearchResults === undefined ? (
                <div className="er_nodata">No Data Found</div>
              ) : (
                <div className="page_nation">
                  <div className="rowsPerPageOptions">
                    <span className="p_18_text">show</span>
                    <select
                      className="pagination_select p_16_text"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value));
                        setCurrentPage(1);
                      }}
                    >
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                    <span className="p_18_text">entries</span>
                  </div>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={formattedSearchResults?.length}
                    pageSize={pageSize}
                    onPageChange={(page) => handlePageChanege(page)}
                  />
                </div>
              )}
            </Paper>
          </div>
        </>
      ) : (
        <CreateData title={prop?.btn_name} editId={prop?.editId} />
      )}
    </>
  );
}
export default React.memo(SchoolDistrict);
