import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import "./index.scss";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import axios from "axios";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import Swal from "sweetalert2";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import AssignUserGroup from "./AssignUserGroup";
import CropmodelImage from "../../../Reuseable_Components/CropImg/cropmodel";
import CropFix from "../../../Reuseable_Components/CropImg/CropFix";
import { PostCallApi } from "../../../../Action/Action";

function CreateCommunityGroup({ editId }) {
  const [open, setOpen] = useState(false);
  const [schList, setSchList] = useState();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [comGroupId, setComGroupId] = useState("");
  // const [derror, setDerror] = useState();
  // const [fixSize, setFixSize] = useState({ fixwidth: "200", fixheight: "200" });
  const fixSize = { fixwidth: "200", fixheight: "200" };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    // let url = `${Config.API_HOST_URL_live}/api/CommunityGroups/CreateCommunityGroups`;
    let url = `/api/CommunityGroups/CreateCommunityGroups`;
    let comData = {};
    if (editId === undefined) {
      if (data?.CommunityGroupTypeId === 1) {
        comData = {
          ...data,
          createdBy: LogUserId,
          OrganizerUserId: LogUserId,
          // GroupTypeId: "1",
        };
      } else {
        comData = {
          ...data,
          schoolDistrictId: "00000000-0000-0000-0000-000000000000",
          createdBy: LogUserId,
          OrganizerUserId: LogUserId,
          // GroupTypeId: "1",
        };
      }
    } else {
      if (data?.CommunityGroupTypeId === 1) {
        comData = {
          ...data,
          createdBy: LogUserId,
          OrganizerUserId: LogUserId,
          communityGroupId: editId,
        };
      } else {
        comData = {
          ...data,
          communityGroupId: editId,
          schoolDistrictId: "00000000-0000-0000-0000-000000000000",
          createdBy: LogUserId,
          OrganizerUserId: LogUserId,
        };
      }
    }
    setOpen(true);
    // axios
    //   .post(url, comData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "multipart/form-data",
    //       Accept: "*/*",
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    let seData = {
      url: url,
      body: comData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };

    let response = await PostCallApi(seData);
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        const gId = response.data?.payload;
        setComGroupId(gId?.communityGroupId);
        // Accept;
        if (editId === undefined) {
          let inData = {
            communityGroupId: gId?.communityGroupId,
            userId: LogUserId,
            communityRoleId: 1,
            status: "Accepted",
            createdBy: LogUserId,
          };
          axios
            .post(
              `${Config.API_HOST_URL_live}/api/CommunityGroupUser/CreateCommunityGroupUser`,
              inData,
              { headers }
            )
            .then((response) => {
              if (response.data.success) {
              }
            });
        }
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        handleNext();
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // } else {
    //   setOpen(false);
    // }
    // });
  };
  useEffect(() => {
    getSchoolList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (editId !== undefined) {
      if (schList?.length > 0) {
        getComGroup(editId);
      }
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [editId, schList]);
  const [gtValid, setGtValid] = useState();

  const getComGroup = (id) => {
    setOpen(true);
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CommunityGroups/EditCommunityGroups?CommunityGroupId=${id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const cmtGrp = response.data?.payload;
          setValue(
            "CommunityGroupTypeId",
            cmtGrp?.communityGroupTypeId ? cmtGrp?.communityGroupTypeId : 1
          );
          setGtValid(
            cmtGrp?.communityGroupTypeId ? cmtGrp?.communityGroupTypeId : 1
          );
          setValue("schoolDistrictId", cmtGrp?.schoolDistrictId);
          setValue("CommunityGroupName", cmtGrp?.communityGroupName);
          setValue(
            "imgUpload",
            cmtGrp?.groupImageUrl ? cmtGrp?.groupImageUrl.split("/")[4] : ""
          );
          setValue(
            "backimgUpload",
            cmtGrp?.groupCoverImage ? cmtGrp?.groupCoverImage.split("/")[4] : ""
          );
          setValue("Description", cmtGrp?.description);
          setOpen(false);
        } else {
          setOpen(false);
        }
      });
  };
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId: item?.schoolDistrictId,
              schName: item?.districtName,
            };
          });
          setSchList(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("groupImageUrl", url);
        setValue("imgUpload", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    // for err
    //  if (file?.size > 5242880 && is_succes === true) {
    //    setDerror({ img: ` Select less than 5mb img` });
    //    is_succes = false;
    //  }
    //  if (is_succes) {
    //    setDerror({ img: ` ` });
    //    setValue("groupImageUrl", file);
    //    setValue("imgUpload", file?.name);
    //  }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1, setCroppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("GroupCoverImage", url);
        setValue("backimgUpload", url.name);
        setCroppedImageUrl1(url);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {src && (
              <CropFix
                src={src}
                filename={filename}
                setCroppedImageUrl={setCroppedImageUrlCallback}
                openCropModal={openCropModal}
                setCropModal={setCropModal}
                fixSize={fixSize}
              />
              // <CropmodelImage
              //   src={src}
              //   filename={filename}
              //   setCroppedImageUrl={setCroppedImageUrlCallback}
              //   openCropModal={openCropModal}
              //   setCropModal={setCropModal}
              // />
            )}
            {src1 && (
              <CropmodelImage
                src={src1}
                filename={filename1}
                setCroppedImageUrl={setCroppedImageUrlCallback1}
                openCropModal={openCropModal1}
                setCropModal={setCropModal1}
              />
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="community_group_create_form"
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label"> Group Type</label>
                  <select
                    className={`form-select`}
                    aria-label="Default select example"
                    {...register("CommunityGroupTypeId", {
                      required: "This field is required",
                      valueAsNumber: true,
                      onChange: (e) => setGtValid(e.target.value),
                    })}
                  >
                    <option value=""> Select Group Type</option>
                    <option value="1"> Private</option>
                    <option value="2"> Public</option>
                  </select>
                  {errors.CommunityGroupTypeId && (
                    <span role="alert" className="error_text">
                      {errors.CommunityGroupTypeId.message}
                    </span>
                  )}
                </div>
                {gtValid === "1" || gtValid === 1 ? (
                  <div className="form-group col-md-6">
                    <label className="form-label">School District Name</label>
                    <select
                      className={`form-select`}
                      aria-label="Default select example"
                      {...register("schoolDistrictId", {
                        required: "This field is required",
                      })}
                    >
                      <option value=""> Select School District Name</option>
                      {schList &&
                        schList?.map((data, i) => (
                          <option key={i} value={data?.schId}>
                            {data?.schName}
                          </option>
                        ))}
                    </select>
                    {errors.schoolDistrictId && (
                      <span role="alert" className="error_text">
                        {errors.schoolDistrictId.message}
                      </span>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group col-md-6">
                  <label className="form-label">Group Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Group Name"
                    {...register("CommunityGroupName", {
                      required: "This field is required",
                      pattern: {
                        value: /[A-Za-z]{3}/,
                        message: "Enter only Aphabets",
                      },
                    })}
                  />
                  {errors.CommunityGroupName && (
                    <span role="alert" className="error_text">
                      {errors.CommunityGroupName.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Group Image </label>
                  <div className="upload_img">
                    <input
                      type="text"
                      className="form-control upload_box"
                      placeholder="No file Chosen"
                      readOnly
                      {...register("imgUpload", {
                        // required: {
                        //   value: true,
                        //   message: "Please Upload File",
                        // },
                      })}
                    />
                    <input
                      type="file"
                      id="grp_img_upload"
                      accept="image/*"
                      className="form-control"
                      style={{ display: "none" }}
                      // onChange={handleimg}
                      onChange={handleFile}
                    />
                    <label
                      htmlFor="grp_img_upload"
                      className="upload_img_label mb-0 "
                    >
                      <img src={uploadimg} className="img-fluid" alt="" />
                    </label>
                  </div>
                  {errors?.imgUpload && (
                    <span role="alert" className="error_text">
                      {errors.imgUpload.message}
                    </span>
                  )}
                  {/* {derror?.img && (
                    <span role="alert" className="error_text">
                      {derror?.img}
                    </span>
                  )} */}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Group Cover Image </label>
                  <div className="upload_img">
                    <input
                      type="text"
                      className="form-control upload_box"
                      placeholder="No file Chosen"
                      readOnly
                      {...register("backimgUpload", {
                        // required: {
                        //   value: true,
                        //   message: "Please Upload File",
                        // },
                      })}
                    />
                    <input
                      type="file"
                      id="grp_img_upload_2"
                      accept="image/*"
                      className="form-control"
                      style={{ display: "none" }}
                      placeholder="User You Want To Be In District"
                      // onChange={handleimg2}
                      onChange={handleFile1}
                    />
                    <label
                      htmlFor="grp_img_upload_2"
                      className="upload_img_label mb-0 "
                    >
                      <img src={uploadimg} className="img-fluid" alt="" />
                    </label>
                  </div>
                  {errors?.backimgUpload && (
                    <span role="alert" className="error_text">
                      {errors.backimgUpload.message}
                    </span>
                  )}
                  {/* {derror?.bgimg && (
                    <span role="alert" className="error_text">
                      {derror?.bgimg}
                    </span>
                  )} */}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Group Description</label>
                  <textarea
                    rows="5"
                    className="form-control"
                    placeholder="Enter your information"
                    {...register("Description", {
                      required: "This field is required",
                    })}
                  />
                  {errors.Description && (
                    <span role="alert" className="error_text">
                      {errors.Description.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-12 border-0 px-3">
                  <Button
                    className="com_c_btn"
                    type="submit"
                    // onClick={() => handleNext()}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </form>
          </>
        );
      case 1:
        return <AssignUserGroup comGroupId={comGroupId} />;
      default:
        return "";
    }
  };
  const steps = ["Create Group", "Invite Users"];
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={`stepper_box_com `}>
        <Box sx={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="stepper_box_label"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="create_heding_group">
            <h3>{editId === undefined ? "Create" : "Edit"} Group</h3>
          </div>
          {getStepContent(activeStep)}
        </Box>
      </div>
    </>
  );
}

export default React.memo(CreateCommunityGroup);
