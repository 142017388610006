import React from "react";
import "./index.scss";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReportName, SettingName } from "../../../reducers/setting";
import { CreteDataFor } from "../../../reducers/user";
import { useLocation } from "react-router-dom";

function SmallPop({
  anchorEl,
  open,
  opti,
  handleClose,
  options,
  className,
  optionsMenu,
}) {
  let dispatch = useDispatch();
  let location = useLocation();
  const MenuName = useSelector((state) => state.setting?.MenuName);
  const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);
  const handleName = (name) => {
    if (location.pathname === "/reports") {
      dispatch(ReportName(name));
    } else {
      dispatch(SettingName(name));
      dispatch(CreteDataFor(false));
    }
    handleClose();
  };
  const handleclosepop = () => {
    handleClose();
  };
  return (
    <>
      {open ? (
        <Menu
          className={`blockreport_box ${className}`}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleclosepop}
        >
          {options?.map((option) => (
            <MenuItem key={option}>
              <div className="bac">{option}</div>
            </MenuItem>
          ))}
          {opti?.map((option) => (
            <MenuItem key={option}>
              <div className="bac">{option}</div>
            </MenuItem>
          ))}
          {optionsMenu?.map((option, i) => (
            <MenuItem
              key={i + 1}
              onClick={() => handleName(option?.menuName)}
              className={`
          ${
            location.pathname === "/reports"
              ? option?.menuName === ReportMenuName && "linear"
              : option?.menuName === MenuName && "linear"
          }`}
            >
              <div className="bac">{option?.menuName}</div>
            </MenuItem>
          ))}
        </Menu>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(SmallPop);
