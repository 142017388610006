import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
// import organic from "../../images/home-banner.png";
import rightarrow from "../../images/right-arrow.svg";
// import catelog from "../../images/catelog-img.png";
// import catelog1 from "../../images/catelog-img1.png";
// import catelog2 from "../../images/catelog-img2.png";
// import catelog3 from "../../images/catelog-img3.png";
// import lifestyle from "../../images/lifestyle-img.png";
// import lifestyle1 from "../../images/lifestyle-img1.png";
// import lifestyle2 from "../../images/lifestyle-img2.png";
// import ashleyimg from "../../images/ashley-img.png";
// import beckiimg from "../../images/becki-img.png";
// import claudaimg from "../../images/clauda-img.png";
// import daliaimg from "../../images/dalia-img.png";
import moneysend from "../../images/moneysend.svg";
// import diagram from "../../images/diagram.svg";
// import profile from "../../images/profile2user.svg";
// import statusup from "../../images/statusup.svg";
// import commandsquare from "../../images/commandsquare.svg";
// import lifebuoy from "../../images/lifebuoy.svg";
// import wellcome from "../../images/wellcome-video-img.png";
// import ccps from "../../images/ccps-img.png";
// import lifetime from "../../images/life-time-img.png";
// import throwdown from "../../images/throwdown-img.png";
// import nutrition from "../../images/nutrition-img.png";
// import publicschool from "../../images/publicschool-img.png";
// import fig from "../../images/fig-client.png";
// import swan from "../../images/swan-img.png";
// import opentext from "../../images/opentext-img.png";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../Action/Action";
import Config from "../../Utils/Config";
import CountdownTimer from "../../Modules/Reuseable_Components/CountDown/CountdownTimer";
import ReactPlayer from "react-player";
// import cardimg1 from "../../images/fig_CROP.png";
import cardimg3 from "../../images/fig_CROP.png";
import cardimg1 from "../../images/course_not_found_final_1.png";
import Swal from "sweetalert2";
import cardimg2 from "../../images/default_profile.jpg";
import { CircularProgress } from "@mui/material";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import Charts from "../../Modules/Reuseable_Components/Chart/Charts";
// import mapDataIE from "@highcharts/map-collection/countries/us/us-all.topo.json";
// import DistrictDataJson from "./districtData.json";
import mapDataIE from "@highcharts/map-collection/countries/us/custom/us-small.topo.json";
highchartsMap(Highcharts); // Initialize the map module

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [homeData, setHomeData] = useState();
  const [disMap, setDisMap] = useState();
  const NOW_IN_MS = new Date().getTime();

  useEffect(() => {
    // startTimer();
    setIsLoading(true);
    setOpenCircle(!openCircle);
    setTimeout(() => {
      getHomeData();
      getDisMap();
    }, 200);
    // eslint-disable-next-line
  }, []);
  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading").innerHTML = "";
  // }, []);
  const getDisMap = async () => {
    setIsLoading1(true);
    let seData = {
      url: "/api/DistrictMap/GetWithOutTokenDistrictMapList",
    };
    let res = await GetCallApi(seData);
    setIsLoading1(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let dis = res.data.payload;
        setDisMap(dis);
      }
    }
  };
  const getHomeData = async () => {
    let seData = {
      url: `/api/Home/GetHomeDataWithOutToken`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let abData = res.data.payload;
        // var offset = new Date().getTimezoneOffset();
        let date = new Date(abData?.homeDate).toLocaleDateString("en-US", {
          year: "numeric",
          day: "2-digit",
          month: "2-digit",
        });
        let std = new Date(date);
        std.setMinutes(std.getMinutes());
        const diffTime = Math.abs(std - new Date());
        setHomeData({
          ...abData,
          diffTime: new Date(date) > new Date() ? NOW_IN_MS + diffTime : 0,
        });
        // stopTimer();
        setOpenCircle(false);
        setIsLoading(false);
      } else {
        // stopTimer();
        setOpenCircle(false);
        setIsLoading(false);
      }
    } else {
      // stopTimer();
      setOpenCircle(false);
      setIsLoading(false);
    }
  };
  const handleBtn = () => {
    window.open(homeData?.buttonLink, "_blank");
  };
  const showReadMore = (t, des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: t,
      text: des,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  const mapOptions = {
    chart: {
      // map: mapDataIE,
      // map: "countries/us/custom/us-small",
      map: "countries/us/us-all",
      events: {
        render() {
          let chart = this;
          chart.series[0].points.forEach((p) => {
            if (p?.value > 4) {
              // p.color = "#20bec9";
              p.dataLabel.css({
                color: "white",
                borderColor: "White",
              });
            } else {
              // p.color = "#eeeeee";
            }
          });
        },
      },
    },
    title: {
      text: " ",
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      // enabled: false,
      enabled: true,
    },
    // tooltip: {
    //   headerFormat: "",
    //   pointFormat: "School District: {point.name} ",
    //   // pointFormat: " {point.name} lat: {point.lat}, lon: {point.lon}",
    // },
    // plotOptions: {
    //   series: {
    //     dataLabels: {
    //       allowOverlap: true,
    //     },
    //   },
    // },
    series: [
      {
        // Use the gb-all map with no data as a basemap
        name: "USA",
        dataLabels: {
          enabled: true,
          format: "{point.properties.postal-code}",
          style: {
            fontSize: "10px",
          },
          // format: "{point.properties.hc-a2}",
        },
        mapData: mapDataIE,
        borderColor: "#A0A0A0",
        nullColor: "rgba(200, 200, 200, 0.3)",
        showInLegend: false,
        color: "#20bec9",
        tooltip: {
          headerFormat: "",
          pointFormat: "Country: {point.name} ",
          // pointFormat: "",
        },
        enableMouseTracking: false,
        data: disMap?.map((x) => {
          return {
            "hc-key": `us-${x?.stateCode.toLowerCase()}`,
            value: 5,
          };
        }),
        // data: [
        //   { "hc-key": "us-ca", value: 5 },
        //   { "hc-key": "us-az", value: 5 },
        //   { "hc-key": "us-me", value: 5 },
        //   { "hc-key": "us-ny", value: 5 },
        //   { "hc-key": "us-oh", value: 5 },
        //   { "hc-key": "us-in", value: 5 },
        //   { "hc-key": "us-ky", value: 5 },
        //   { "hc-key": "us-ky", value: 5 },
        //   { "hc-key": "us-va", value: 5 },
        //   { "hc-key": "us-nj", value: 5 },
        //   { "hc-key": "us-nc", value: 5 },
        //   { "hc-key": "us-sc", value: 5 },
        //   { "hc-key": "us-ga", value: 5 },
        //   { "hc-key": "us-al", value: 5 },
        //   { "hc-key": "us-fl", value: 5 },
        // ],
      },
      // {
      //   name: "Separators",
      //   type: "mapline",
      //   nullColor: "#707070",
      //   showInLegend: false,
      //   enableMouseTracking: false,
      // },
      {
        name: "Separators",
        type: "mapline",
        nullColor: "#707070",
        showInLegend: false,
        enableMouseTracking: false,
        data: Highcharts.geojson(mapDataIE, "mapline"),
        color: "silver",
        accessibility: {
          enabled: false,
        },
      },

      {
        // Specify points using lat/lon
        type: "mappoint",
        // type: "mapbubble",
        name: "School District",
        tooltip: {
          headerFormat: "",
          pointFormat: "School District: {point.schooldistrict} ",
          // pointFormat: " {point.name} lat: {point.lat}, lon: {point.lon}",
        },
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{xDescription}. Lat: {point.lat:.2f}, lon: {point.lon:.2f}.",
          },
        },
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        color: "#f79155",
        data: disMap,
        // data: DistrictDataJson.district,
      },
    ],
  };

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#86c65a", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* <div
        id="loading"
        className="h6_text p-5 text-center"
        style={{
          height: openCircle ? "100vh" : "",
          display: !openCircle ? "none" : "block",
        }}
      ></div> */}
      {/* {!openCircle ? ( */}
      <main>
        <section className="homepage">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="banner-content">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title mb-4"></div>
                    </div>
                  ) : (
                    <>
                      <h1>
                        <span>{homeData?.homeTitle} </span>{" "}
                      </h1>
                    </>
                  )}
                  {isLoading ? (
                    <div className="row">
                      <div className="col-md-3">
                        <div className="skeleton-loader inner-loader-rounded mt-5">
                          <div className="skeleton-round"></div>
                          <div className="skeleton-round"></div>
                        </div>
                        <div className="skeleton-loader mt-5">
                          <div className="skeleton-line"></div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="skeleton-loader inner-loader-rounded mt-5">
                          <div className="skeleton-round"></div>
                          <div className="skeleton-round"></div>
                        </div>
                        <div className="skeleton-loader mt-5">
                          <div className="skeleton-line"></div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="skeleton-loader inner-loader-rounded mt-5">
                          <div className="skeleton-round"></div>
                          <div className="skeleton-round"></div>
                        </div>
                        <div className="skeleton-loader mt-5">
                          <div className="skeleton-line"></div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="skeleton-loader inner-loader-rounded mt-5">
                          <div className="skeleton-round"></div>
                          <div className="skeleton-round"></div>
                        </div>
                        <div className="skeleton-loader mt-5">
                          <div className="skeleton-line"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <CountdownTimer targetDate={homeData?.diffTime} />
                    </>
                  )}
                  {isLoading ? (
                    <div className="skeleton-loader mt-5">
                      <div className="skeleton-btn"></div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="primary-btn"
                        onClick={handleBtn}
                        target="_blank"
                      >
                        {homeData?.buttonName}
                        <img src={rightarrow} alt="right-arrow" />{" "}
                      </button>
                    </>
                  )}

                  {/* // <div className="banner-content">
                //   <h1>
                //     <span>
                //       {homeData?.homeTitle}{" "}
                //     </span>{" "}
                //   </h1>
                //   <CountdownTimer targetDate={homeData?.diffTime} />
                //   <button
                //     className="primary-btn"
                //     onClick={handleBtn}
                //     target="_blank"
                //   >
                //     {homeData?.buttonName}
                //     <img src={rightarrow} alt="right-arrow" />{" "}
                //   </button>
                // </div> */}
                </div>
              </div>
              <div className="col-lg-5 right-banner">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <>
                    <img
                      src={
                        homeData?.homeImageUrl
                          ? `${Config.API_HOST_URL_live}${homeData?.homeImageUrl}`
                          : cardimg3
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = cardimg3;
                      }}
                      alt=""
                      className="banner-desktop-img"
                    />
                  </>
                )}
              </div>
              {/* <div>
                <p> Time : {date.toLocaleTimeString()}</p>
                <p> Date : {date.toLocaleDateString()}</p>
              </div> */}
            </div>
          </div>
        </section>

        {/* Start Eelcome Section  */}
        <section className="welcome-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title-text">WELCOME</h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-line mt-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-btn mt-3"></div>
                  </div>
                ) : (
                  <>
                    <p className="wl_des">
                      {homeData?.welComeDescription?.slice(0, 285)}
                      {homeData?.welComeDescription?.length > 285 && (
                        <span
                          className="description cr"
                          onClick={() =>
                            showReadMore(
                              "Welcome",
                              homeData?.welComeDescription
                            )
                          }
                        >
                          ...read more
                        </span>
                      )}
                    </p>
                    <button className="primary-btn">
                      <Link
                        to={"/contactus"}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        FIND OUT MORE
                      </Link>
                      {/* <img src={rightarrow} alt="right-arrow" />{" "} */}
                    </button>
                  </>
                )}

                {isLoading ? (
                  <div className="skeleton-loader mt-5"> {box} </div>
                ) : (
                  <>
                    {homeData?.videoUrl ? (
                      <ReactPlayer
                        url={homeData.videoUrl}
                        className="react-player mt-5"
                        volume={1}
                        width="100%"
                        height="620px"
                        controls
                      // responsive
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End Eelcome Section  */}

        {/* Start CEU COURSE CATALOG Section */}
        <section className="course-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title-text">CEU COURSE CATALOG </h2>
                {/* <img src={rightarrow} alt="right-arrow" /> */}
              </div>
            </div>
            <div className="row">
              {isLoading ? (
                <>
                  <div className="d-flex gap-3">
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                  </div>
                </>
              ) : (
                homeData?.ceuCourseData?.map((x, i) => (
                  <div className="col-md-3 col-sm-6" key={i}>
                    <Link to={`ceu/${x.courseId}`}>
                      <div className={`courses-images ${i % 2 ? "mt-60" : ""}`}>
                        <img
                          // src={
                          //   x.imageOrVideoUrl
                          //     ? `${Config.API_HOST_URL_live}${x?.imageOrVideoUrl}`
                          //     : cardimg1
                          // }
                          src={
                            x.thumbnailCourseUrl
                              ? `${Config.API_HOST_URL_live}${x?.thumbnailCourseUrl}`
                              : cardimg1
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = cardimg1;
                          }}
                          width="100%"
                          height={250}
                          alt="Catelog"
                        />
                        <p className="figlifestule-hover-text">
                          {x?.courseTitle}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))
              )}
              <div className="right-btn  mt-5">
                {isLoading ? (
                  <div className="skeleton-loader align-items-end mt-5">
                    <div className="skeleton-btn"></div>
                  </div>
                ) : (
                  <>
                    <Link to={"/ceu"} className="secondary-btn ">
                      READ MORE <img src={rightarrow} alt="right-arrow" />{" "}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End CEU COURSE CATALOG Section */}

        {/* Start FIG LIFESTYLE COURSES */}
        <section className="figlifestyle-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title-text">FIG LIFESTYLE COURSES</h2>
              </div>
            </div>
            <div className="row curve-image">
              {isLoading ? (
                <>
                  <div className="d-flex gap-3">
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                    <div className="skeleton-loader">{box}</div>
                  </div>
                </>
              ) : (
                homeData?.figLifeStyleCourseData?.map((x, i) => (
                  <div
                    key={i}
                    className={`col-md-3 col-sm-6 mb-3  ${i % 2 ? " life-img-center" : "life-img-bottom"
                      } `}
                  >
                    <Link to={`figlifestyle/${x.courseId}`}>
                      <img
                        // src={
                        //   x.imageOrVideoUrl
                        //     ? `${Config.API_HOST_URL_live}${x?.imageOrVideoUrl}`
                        //     : cardimg1
                        // }
                        src={
                          x.thumbnailCourseUrl
                            ? `${Config.API_HOST_URL_live}${x?.thumbnailCourseUrl}`
                            : cardimg1
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg1;
                        }}
                        width="100%"
                        height={250}
                        alt="Figlifesyle"
                      />
                      <p className="figlifestule-hover-text">
                        {x?.courseTitle}
                      </p>
                    </Link>
                  </div>
                ))
              )}

              <div className="right-btn mt-5">
                {isLoading ? (
                  <div className="skeleton-loader align-items-end mt-5">
                    <div className="skeleton-btn"></div>
                  </div>
                ) : (
                  <>
                    <Link to={"/figlifestyle"} className="secondary-btn">
                      READ MORE <img src={rightarrow} alt="right-arrow" />{" "}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End FIG LIFESTYLE COURSES */}

        {/* Start MEET OUR COACHES */}
        <section className="meet-section">
          <div className="container">
            <div className="row">
              <h2 className="title-text">MEET OUR COACHES</h2>
              {isLoading ? (
                <>
                  <div className="d-flex gap-3">
                    <div className="skeleton-loader">
                      <div className="skeleton-box-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-box-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-box-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-box-round"></div>
                    </div>
                  </div>
                </>
              ) : (
                homeData?.instructoreData?.map((x, i) => (
                  <div className="col-md-3" key={i}>
                    <div className="meet-images">
                      <div className="images">
                        <img
                          src={
                            x.thumbnailProfileUrl
                              ? `${Config.API_HOST_URL_live}${x?.thumbnailProfileUrl}`
                              : cardimg2
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = cardimg2;
                          }}
                          alt=""
                        />
                      </div>
                      <p>{x?.userName}</p>
                      <p className="meet-pera">
                        {x?.aboutUs?.slice(0, 85)}
                        {x?.aboutUs?.length > 85 && (
                          <span
                            className="description cr"
                            onClick={() =>
                              showReadMore(x?.userName, x?.aboutUs)
                            }
                          >
                            ...read more
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                ))
              )}
              <div className="right-btn mt-5">
                {isLoading ? (
                  <div className="skeleton-loader align-items-end">
                    <div className="skeleton-btn mb-4"></div>
                  </div>
                ) : (
                  <>
                    <Link to={"/coaches"} className="secondary-btn">
                      READ MORE <img src={rightarrow} alt="right-arrow" />{" "}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End MEET OUR COACHES */}

        {/* Start CONTACT US */}
        <section className="contactus-section">
          <div className="container">
            <div className="row contact">
              <div className="col-lg-4 col-md-12 contact-block">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-btn mb-4"></div>
                  </div>
                ) : (
                  <>
                    <Link to={"/contactus"} className="contact-btn">
                      CONTACT US
                    </Link>
                  </>
                )}
              </div>
              <div className="col-lg-8 col-md-12 text-center">
                {isLoading ? (
                  <div className="skeleton-loader align-items-center">
                    <div className="skeleton-line skeleton-title w-100 mb-4"></div>
                    <div className="skeleton-line skeleton-title mb-4"></div>
                  </div>
                ) : (
                  <>
                    <h5>{homeData?.contactTitle}</h5>
                    <h2>{homeData?.contactText}</h2>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End CONTACT US */}

        {/* Start WHAT WE DO  */}
        <section className="whatwe-sectiom">
          <div className="container">
            <div className="row">
              <h2 className="title-text">WHAT WE DO</h2>
              {isLoading ? (
                <div className="skeleton-loader">
                  <div className="skeleton-line mt-4"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line mb-4"></div>
                </div>
              ) : (
                <>
                  <h3>{homeData?.whatWeDoTitle}</h3>
                  <p className="wwd_des">
                    {homeData?.whatWeDoDescription?.slice(0, 400)}
                    {homeData?.whatWeDoDescription?.length > 400 && (
                      <span
                        className="description cr"
                        onClick={() =>
                          showReadMore(
                            homeData?.whatWeDoTitle,
                            homeData?.whatWeDoDescription
                          )
                        }
                      >
                        ...read more
                      </span>
                    )}
                  </p>
                </>
              )}
              {isLoading ? (
                <>
                  <div className="col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">
                      <div className="skeleton-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">
                      <div className="skeleton-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">
                      <div className="skeleton-round"></div>
                    </div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                </>
              ) : homeData?.whatWeDolst?.length > 0 ? (
                homeData?.whatWeDolst?.map((text, i) => (
                  <div className="col-lg-4 col-md-6 margin-bottom" key={i}>
                    {/* <div className={text?.boxstyle === marginbottom ? marginbottom : margintop}></div> */}
                    <div className="wharwe-box">
                      <div className="top-content">
                        <div className="icon">
                          <img
                            src={
                              text?.imageUrl
                                ? `${Config.API_HOST_URL_live}${text?.imageUrl}`
                                : moneysend
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = moneysend;
                            }}
                            alt=""
                          />
                        </div>
                        <h5>{text?.title}</h5>
                      </div>
                      <div className="bottom-content">
                        <p>
                          {text?.whatWeDoDescription?.slice(0, 380)}
                          {text?.whatWeDoDescription?.length > 380 && (
                            <span
                              className="description cr"
                              onClick={() =>
                                showReadMore(
                                  text?.title,
                                  text?.whatWeDoDescription
                                )
                              }
                            >
                              ...read more
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3>
                  Keep watching, we are getting some exciting things for you.
                </h3>
              )}
            </div>
          </div>
        </section>
        {/* End WHAT WE DO  */}
        {/* start our district */}
        <section className="whatwe-sectiom">
          <div className="container">
            <div className="row">
              <h2 className="title-text">OUR PARTNER DISTRICTS</h2>

              {isLoading1 ? (
                <div className="skeleton-loader">{box}</div>
              ) : (
                <div>
                  {/* <div style={{ height: "600px" }}> */}
                  <Charts
                    series={mapOptions}
                    highcharts={Highcharts}
                    position="bottom"
                    constructorType={"mapChart"}
                  />
                  {/* <iframe
                    style={{ border: "1px solid #777" }}
                    src="https://indd.adobe.com/embed/282ef40b-266c-49a6-9aa2-c24785844831?startpage=1&allowFullscreen=false"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen=""
                    title="district-map"
                    className="district-map"
                  ></iframe> */}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* end our district */}

        {/* Strat OUR CLIENTS */}
        {/* <section className="client-section">
          <div className="container">
            <div className="row">
              <h2 className="title-text">OUR CLIENTS</h2>
              {isLoading ? (
                <>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                  </div>
                </>
              ) : homeData?.ourClients?.length > 0 ? (
                homeData?.ourClients?.map((oc, i) => (
                  <div className="col-lg-3 col-sm-6 mb-30" key={i}>
                    <div className="client-box">
                      <img
                        //src={
                        //  oc?.districtLogo
                        //    ? `${Config.API_HOST_URL_live}${oc?.districtLogo}`
                        //    : cardimg2
                        //}
                         src={
                          oc?.thumbnailLogoUrl
                            ? `${Config.API_HOST_URL_live}${oc?.thumbnailLogoUrl}`
                            : cardimg2
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg2;
                        }}
                        alt=""
                      />
                      <h6 className="mt-3">{oc?.districtName}</h6>
                    </div>
                  </div>
                ))
              ) : (
                <h3>
                  Keep watching, we are getting some exciting things for you.
                </h3>
              )}
            
            </div>
          </div>
        </section> */}
        {/* End OUR CLIENTS */}
      </main>
      {/* ) : (
        ""
      )} */}
    </Suspense>
  );
}

export default Home;
