import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import { useSelector } from "react-redux";
import Config from "../../../Utils/Config";
// import axios from "axios";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import pro_img from "../../../images/default_profile.jpg";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../../Action/Action";
import Swal from "sweetalert2";

const renderLoader = () => <p></p>;

const PreferredPartners = () => {
  const [open, setOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [districtList, setDistrictList] = useState([]);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const headCells = ["userName", "schoolDistrictName"];
  const formattedSearchResults = districtList?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  useEffect(() => {
    getSchoolDistrictList();
    // eslint-disable-next-line
  }, []);

  const getSchoolDistrictList = async () => {
    setDistrictList();
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetPreferredPartners?userId=${LogUserde.userId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/SchoolDistrict/GetPreferredPartners?userId=${LogUserde.userId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        const data = response?.data.payload?.map((item) => {
          return {
            Logo: item?.isUser
              ? item?.userProfileURL
                ? `${Config.API_HOST_URL_live}${item?.userProfileURL}`
                : pro_img
              : item?.schoolDistrictLogo
                ? `${Config.API_HOST_URL_live}${item?.schoolDistrictLogo}`
                : pro_img,
            aboutUs: item?.isUser ? item?.userAboutUs : item?.schoolAboutUs,
            ...item,
          };
        });
        setDistrictList(data);
      } else {
        setOpen(false);
        setDistrictList()
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    //   } else {
    //     setOpen(false);
    //     setDistrictList();
    //   }
    // })
    // .catch((e) => {
    //   setOpen(false);
    //   console.log(e);
    // });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="preferred_partners_page main_baclcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="fig_family_box">
                <div className="listdata_control_sec mb-2 d-flex justify-content-between">
                  <div className="title">Preferred Partners</div>
                  <div className="search_sec w-25">
                    <div className="search_input w-100">
                      <input
                        onChange={(e) => {
                          setSearchField(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <img src={search_icon} alt="" />
                    </div>
                  </div>
                </div>
                <div className=" school_district">
                  <div className="row">
                    {formattedSearchResults &&
                      formattedSearchResults
                        ?.slice(
                          (currentPage - 1) * pageSize,
                          (currentPage - 1) * pageSize + pageSize
                        )
                        ?.map((district, i) => (
                          <div
                            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mb-3"
                            key={i}
                          >
                            <Link
                              className="view_btn"
                              to={
                                district?.isUser
                                  ? `/preferred-partners/user/${district.userId}`
                                  : `/preferred-partners/${district.schoolDistrictId}`
                              }
                            >
                              <div className="district_box">
                                <div className="img_box">
                                  <img
                                    src={district?.Logo}
                                    alt=""
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = pro_img;
                                    }}
                                  />
                                </div>
                                <div className="details">
                                  <div className="d_boc">
                                    <div className="d_name">
                                      {district?.isUser
                                        ? district.userName
                                        : district?.schoolDistrictName}
                                    </div>
                                    <div className="txt">
                                      {district?.aboutUs?.slice(0, 85)}
                                      {district?.aboutUs?.length > 85 && "..."}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            {/* <div className="district_box">
                              <div className="img_d">
                                <div className="img_box">
                                  <img
                                    src={district?.Logo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="details">
                                <div className="d_boc">
                                  <div className="d_name">
                                    {district?.schoolDistrictName}
                                  </div>
                                  <div className="txt">{district?.state}</div>
                                  <div className="txt email">
                                    {district?.email}
                                  </div>
                                  <div className="txt">{district?.phone}</div>
                                </div>
                                <Link
                                  className="view_btn"
                                  to={`/preferred-partners/${district.schoolDistrictId}`}
                                >
                                  View
                                </Link>
                              </div>
                            </div> */}
                          </div>
                        ))}
                  </div>
                  {formattedSearchResults?.length === 0 ? (
                    <div className="text-center my-3 p_20_text">
                      Data Not Found
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="page_nation">
                    <div className="rowsPerPageOptions">
                      <span className="p_18_text">show</span>
                      <select
                        className="pagination_select p_16_text"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                      </select>
                      <span className="p_18_text">entries</span>
                    </div>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={formattedSearchResults?.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
};

export default PreferredPartners;
