import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import back_img from "../../../images/login_in_Background.svg";
import Login_img from "../../../images/login_logo.png";
import Config from "../../../Utils/Config";
import { CircularProgress } from "@mui/material";
import { resetSetting } from "../../../reducers/setting";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../../reducers/login";
import Swal from "sweetalert2";

function CleverLogin() {
  let Location = useLocation();
  const [derror, setDerror] = useState({});
  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    getToken(Location?.search?.split("code=")[1]);
    // eslint-disable-next-line
  }, []);
  function titleCase(str) {
    var splitStr = str.toLowerCase()?.split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const getToken = (t) => {
    let data = {
      code: t,
      redirect_uri: `${Config.HOST_URL_live_clever}/clever-login`,
      secretToken: "FgGr@w!213846",
    };
    axios
      .post(`${Config?.API_HOST_URL_live}/api/Login/LogInToClever`, data)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data.success) {
            setDerror({});
            let token = response.data.message;
            getCleverloGinfo(token);
          } else {
            setDerror({ log_er: response?.data.message });
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setDerror({ log_er: "Authentication false" });
      });
  };
  const getCleverloGinfo = (id) => {
    axios
      .get("https://api.clever.com/v3.0/me", {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${id}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setDerror({});
          let uId = response.data?.data?.id;
          axios
            .get(`https://api.clever.com/v3.0/users/${uId}`, {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${id}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                let rsD = response.data.data;
                let rsDr = response.data.data?.roles;
                let userData = {
                  roleName: rsDr?.staff
                    ? "staff"
                    : rsDr?.student
                    ? "student"
                    : rsDr?.teacher
                    ? "teacher"
                    : "",
                  firstName: rsD?.name?.first,
                  lastName: rsD?.name?.last,
                  email: rsD?.email,
                };
                axios
                  .post(
                    `${Config.API_HOST_URL_live}/api/Login/LoginUsingClever`,
                    userData,
                    {
                      headers: {
                        accept: "*/*",
                        ContentType: "application/json",
                      },
                    }
                  )
                  .then((res1) => {
                    if (res1.status === 200) {
                      setDerror({});
                      if (res1.data.success) {
                        let loginDeatil = {
                          ...res1.data?.payload,
                          authenticate: true,
                        };
                        dispatch(resetSetting());
                        dispatch(LoginUser(loginDeatil));
                        navigate("/dashboard", { replace: true });
                        Swal.fire({
                          position: "top-end",
                          width: 400,
                          // icon: "success",
                          text: titleCase(res1.data?.message),
                          showConfirmButton: false,
                          timer: 1500,
                          color: "#ffffff",
                          background:
                            "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                          showClass: {
                            popup: "animate__animated animate__fadeInDown",
                          },
                          hideClass: {
                            popup: "animate__animated animate__fadeOutRight",
                          },
                        });
                      } else {
                        setDerror({ log_er: "Authentication false" });
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setDerror({ log_er: "Authentication false" });
                  });
              } else {
                setDerror({ log_er: "Authentication false" });
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        console.log(error);
        setDerror({ log_er: "Authentication false" });
      });
  };
  return (
    <section className="login_1">
      <div className="row m-0">
        <div className="col-md-6 p-0">
          <img src={back_img} alt="" className="img_back" />
        </div>
        <div className="col-md-6 p-0 d-flex">
          <form className="form_box">
            <div className="left_logo">
              <img className="img-fluid" src={Login_img} alt="" />
            </div>
            <div className="logo_details">
              <h3 className="p_20_text">
                Please wait while we authenticate your details and logging you
                in....
              </h3>
            </div>

            {derror?.log_er ? (
              <>
                <div className="mb-4">
                  <span role="alert" className="d-block error_text">
                    {derror.log_er}
                  </span>
                </div>
                <div>
                  <Link to="/" className="back_btn">
                    Back to Login
                  </Link>
                </div>
              </>
            ) : (
              <CircularProgress sx={{ color: "#86c65a" }} />
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default CleverLogin;
