import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Index.scss";
import Edit_icon_new from "../../../../../images/Icon/Edit_icon_new.svg";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import Swal from "sweetalert2";

export default function About_sec(props) {
  let LoginUserde = useSelector((state) => state.login?.LoginDetails);
  const [editAbout, setEditAbout] = useState(false);
  const { abtChange, setAbtChange } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const handleEdit = () => {
    setEditAbout(true);
    if (props?.title_text !== null || props?.title_text !== "") {
      setValue("aboutUs", props?.title_text);
    }
  };
  const handleClose = () => {
    setEditAbout(false);
  };
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    let abtData = {
      ...data,
      userId: LoginUserde?.userId,
      createdBy: LoginUserde?.userId,
    };
    axios
      .post(`${Config.API_HOST_URL_live}/api/UserProfile/EditAbout`, abtData, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            handleClose();
            setAbtChange(!abtChange);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(res.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
          }
        }
      })
      .catch((e) => console.log(e));
  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
      <div className="profile_about_section_user">
        <div className="header_edit mb-3">
          <h3>About </h3>
          <button type="button" className="ms-3 back_tra" onClick={handleEdit}>
            <img src={Edit_icon_new} alt="" />
          </button>
        </div>
        <div className="scroll_box">
          {editAbout ? (
            <form onSubmit={handleSubmit(onSubmit)} className="form_about mb-3">
              <div className="form-group">
                <label className="form-label">About </label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control"
                  {...register("aboutUs", {
                    required: "This field is required",
                  })}
                ></textarea>
                {errors.aboutUs && (
                  <span role="alert" className="error_text">
                    {errors.aboutUs.message}
                  </span>
                )}
              </div>
              <div className="form-group mt-3">
                <Button className="submit_btn me-3" type="submit">
                  Update
                </Button>
                <Button className="cancel_btn  " onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div className="mb-4 about_text">{props?.title_text}</div>
          )}
          <div className="about_detail_box w-100 mb-3">
            <h3> {props?.sub_title}</h3>
          </div>
          <div className="about_detail_completed">
            {LoginUserde?.profileUrl === "" ||
            LoginUserde?.profileUrl === null ? (
              <div className="incomplete-details_line d-flex justify-content-between mb-3">
                <div className="incomplete_title">
                  <img
                    src={props?.incomplete_icon ? props?.incomplete_icon : ""}
                    className="me-3"
                    alt=""
                  />
                  <p className="incomplete_text">Profile Photo</p>
                </div>
                <div className="incomplete_value">0/1</div>
              </div>
            ) : (
              <div className="complete-details_line d-flex justify-content-between mb-3">
                <div className="complete_title">
                  <img
                    src={props?.complete_icon ? props?.complete_icon : ""}
                    className="me-3"
                    alt=""
                  />
                  <p className="complete_text">Profile Photo</p>
                </div>
                <div className="complete_value">1/1</div>
              </div>
            )}

            {LoginUserde?.coverPhoto === "" ||
            LoginUserde?.coverPhoto === null ? (
              <div className="incomplete-details_line d-flex justify-content-between mb-3">
                <div className="incomplete_title">
                  <img
                    src={props?.incomplete_icon ? props?.incomplete_icon : ""}
                    className="me-3"
                    alt=""
                  />
                  <p className="incomplete_text">Cover Photo</p>
                </div>
                <div className="incomplete_value">0/1</div>
              </div>
            ) : (
              <div className="complete-details_line d-flex justify-content-between mb-3">
                <div className="complete_title1">
                  <img
                    src={props?.complete_icon ? props?.complete_icon : ""}
                    className="me-3"
                    alt=""
                  />
                  <p className="complete_text">Cover Photo</p>
                </div>
                <div className="complete_value">1/1</div>
              </div>
            )}
          </div>
        </div>
      </div>
  );
}
