import React from "react";
import Circulerprogress from "../../../../Reuseable_Components/Circuler_progress";
import "./index.scss";

export default function ViewCourceProgress(props) {
  const { ceuCardData } = props;

  return (
    <div className="viewCource_section">
      <h3>Courses In Progress</h3>
      <div className="scroll_box">
        {ceuCardData?.map((item, i) => (
          <div className="cource_detail_box" key={i}>
            <Circulerprogress value={item?.perCompleted} />
            <div className="cource_detail_text">
              <p>{item?.cardTitle}</p>
              <p>
                {item?.perCompleted}%{" "}
                {item?.perCompleted === 100 ? "Completed" : "Progress"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
