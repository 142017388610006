import React, { useState } from "react";
import Swal from "sweetalert2";
import Pagination from "../../../../../Reuseable_Components/Paginations/Pagination";
import { Button } from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../../../../Action/Action";
import cardimg1 from "../../../../../../images/course_not_found_final_1.png";

function DistrictCourse({ data }) {
  let Pagesize = 8;
  const { id } = useParams();
  let { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let nav = useNavigate();
  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const startCourse = async (id) => {
    let stCou = {
      courseId: id?.courseId,
      userId: LogUserId,
      status: "In Progress",
    };
    let seData = {
      url: `/api/UserCourse/StartUserCourse`,
      body: stCou,
      headers: headers,
    };
    let response = await PostCallApi(seData);
    if (response?.status === 200) {
      if (response.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });

        if (id?.courseTypes === "1" || id?.courseTypes === 1) {
          nav(`/ceu-courses/start-course/${id?.courseId}`, { replace: true });
        } else if (id?.courseTypes === "2" || id?.courseTypes === 2) {
          nav(`/fig-lifestyle/start-course/${id?.courseId}`, {
            replace: true,
          });
        }
      }
    }
  };
  const completeCourse = (id) => {
    if (id?.courseTypes === "1" || id?.courseTypes === 1) {
      nav(`/ceu-courses/complete-course/${id?.courseId}`, { replace: true });
    } else if (id?.courseTypes === "2" || id?.courseTypes === 2) {
      nav(`/fig-lifestyle/complete-course/${id?.courseId}`, { replace: true });
    }
  };
  return (
    <>
      {data &&
        data
          ?.slice(
            (currentPage - 1) * Pagesize,
            (currentPage - 1) * Pagesize + Pagesize
          )
          .map((item, i) => (
            <div className={`col-xl-4 col-lg-4 col-md-6 mb-5`} key={i}>
              <div className={`card_detail_box `}>
                <div className="img_box_text " style={{ cursor: "inherit" }}>
                  {pathname?.split("/")[1] === "fig-family" ? (
                    id === LogUserde?.schoolDistrictId ? (
                      <Link
                        to={
                          item?.courseTypes === "1" || item?.courseTypes === 1
                            ? `/ceu-courses/${item?.courseId}`
                            : `/fig-lifestyle/${item?.courseId}`
                        }
                      >
                        <img
                          src={item?.cardimg}
                          className="img-fluid main_card_img"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = cardimg1;
                          }}
                        />
                      </Link>
                    ) : LogUserde?.roleId === 1 ? (
                      <Link
                        to={
                          item?.courseTypes === "1" || item?.courseTypes === 1
                            ? `/ceu-courses/${item?.courseId}`
                            : `/fig-lifestyle/${item?.courseId}`
                        }
                      >
                        <img
                          src={item?.cardimg}
                          className="img-fluid main_card_img"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = cardimg1;
                          }}
                        />
                      </Link>
                    ) : (
                      <img
                        src={item?.cardimg}
                        className="img-fluid main_card_img"
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg1;
                        }}
                      />
                    )
                  ) : (
                    <Link
                      to={
                        item?.courseTypes === "1" || item?.courseTypes === 1
                          ? `/ceu-courses/${item?.courseId}`
                          : `/fig-lifestyle/${item?.courseId}`
                      }
                    >
                      <img
                        src={item?.cardimg}
                        className="img-fluid main_card_img"
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg1;
                        }}
                      />
                    </Link>
                  )}
                  {/* <div className="author_name">
                    <span>Offered By: {item?.userName}</span>
                  </div> */}
                </div>
                <div className="card_details">
                  <div className={`card_title tr`}>{item?.cardTitle}</div>
                  <div className="mt-3">
                    <span className="description ">
                      Description: {item?.des?.slice(0, 85)}
                    </span>
                    {item?.des?.length > 85 && (
                      <span
                        className="description cr"
                        onClick={() => showReadMore(item?.des)}
                      >
                        ...read more
                      </span>
                    )}
                  </div>
                </div>
                {pathname?.split("/")[1] === "fig-family" ? (
                  id === LogUserde?.schoolDistrictId ? (
                    item?.startCompleted === "In Progress" ||
                      item?.startCompleted === "Scheduled" ||
                      item?.startCompleted === "" ? (
                      <Button
                        className="st_btn mt-auto w-100"
                        onClick={() => startCourse(item)}
                      >
                        Start Course
                      </Button>
                    ) : item?.startCompleted === "Completed" ? (
                      <Button
                        className="st_btn mt-auto w-100 ora"
                        onClick={() => completeCourse(item)}
                      >
                        Start Again
                      </Button>
                    ) : (
                      <Button
                        className="st_btn mt-auto w-100"
                        onClick={() => startCourse(item)}
                      >
                        Start Course
                      </Button>
                    )
                  ) : LogUserde?.roleId === 1 ? (
                    item?.startCompleted === "In Progress" ||
                      item?.startCompleted === "Scheduled" ||
                      item?.startCompleted === "" ? (
                      <Button
                        className="st_btn mt-auto w-100"
                        onClick={() => startCourse(item)}
                      >
                        Start Course
                      </Button>
                    ) : item?.startCompleted === "Completed" ? (
                      <Button
                        className="st_btn mt-auto w-100 ora"
                        onClick={() => completeCourse(item)}
                      >
                        Start Again
                      </Button>
                    ) : (
                      <Button
                        className="st_btn mt-auto w-100"
                        onClick={() => startCourse(item)}
                      >
                        Start Course
                      </Button>
                    )
                  ) : (
                    ""
                  )
                ) : item?.startCompleted === "In Progress" ||
                  item?.startCompleted === "Scheduled" ||
                  item?.startCompleted === "" ? (
                  <Button
                    className="st_btn mt-auto w-100"
                    onClick={() => startCourse(item)}
                  >
                    Start Course
                  </Button>
                ) : item?.startCompleted === "Completed" ? (
                  <Button
                    className="st_btn mt-auto w-100 ora"
                    onClick={() => completeCourse(item)}
                  >
                    Start Again
                  </Button>
                ) : (
                  <Button
                    className="st_btn mt-auto w-100"
                    onClick={() => startCourse(item)}
                  >
                    Start Course
                  </Button>
                )}
              </div>
            </div>
          ))}
      {data?.length > 0 ? (
        <div className="d-flex justify-content-end">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={Pagesize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <div className="h6_text text-center">No Data Found</div>
      )}
    </>
  );
}

export default DistrictCourse;
