import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../../../../Utils/Config";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import pro_img from "../../../../../../images/default_profile.jpg";
import active_img from "../../../../../../images/Icon/active_green.svg";

function Connection_list(props) {
  const { usList, setAbtChange, abtChange } = props;
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [usId, setUsId] = useState("");
  const [ucId, setUcId] = useState("");
  const [derror, setDerror] = useState();
  const [allSerror, setAllSerror] = useState();
  const [eId, setEId] = useState();
  const [userList, setUserList] = useState([]);
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (usList) {
      setUserList(usList);
    }
  }, [usList]);
  const SendReq = (req) => {
    setUsId(req);
    setEId("");
    setDerror({ re_cancel: "" });
    let userData = {
      sentRequestUserId: LogUserId,
      receiveRequestUserId: req,
      Status: "Pending",
      message: "I would like to add you as my connection.",
      createdBy: LogUserId,
    };
    axios
      .post(`${Config.API_HOST_URL_live}/api/Requests/SendRequest`, userData, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          setUsId("");
          setAbtChange(!abtChange);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          setUsId("");
        }
      });
  };
  const CancelReq = (req, newId) => {
    setUcId(req);
    setEId("");
    setDerror({ re_cancel: "" });
    let userData = {
      sentRequestUserId: LogUserId,
      receivedRequestUserId: req,
      Status: "Cancel",
      sentRequestId: newId,
      receivedRequestId: "00000000-0000-0000-0000-000000000000",
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Requests/CancelSentRequest`,
        userData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setUcId("");
          setAbtChange(!abtChange);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          setDerror({ re_cancel: "" });
        } else {
          setEId(req);
          setDerror({ re_cancel: response?.data.message });
          setUcId("");
        }
      });
  };

  // const FilterList = usList?.filter((user) => {
  //   if (props?.searchField) {
  //     return user?.name
  //       ?.toString()
  //       ?.toLowerCase()
  //       ?.includes(props?.searchField.toLowerCase());
  //   } else return user;
  // });
  const searchParam = ["name", "jobTitle", "siteLocation"];

  const FilterList = userList?.filter((user) => {
    if (props?.searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(props?.searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  const handleAll = (e) => {
    const newArr = FilterList.map((obj) => {
      return { ...obj, request: e };
    });
    setUserList(newArr);
    setCheckBoxVal(e);
  };
  const handleMainCheck = (e, index) => {
    let allMainCh = true;
    const newArr = FilterList.map((obj) => {
      if (obj.userId === index.userId) {
        return { ...obj, request: e };
      }
      return obj;
    });

    setUserList(newArr);
    if (e) {
      newArr?.map((x) => {
        // if(x.status)
        if (x.request === false || x.request === undefined) {
          return (allMainCh = false);
        }

        return 0;
      });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  const sendMultiReq = () => {
    let uList = userList?.filter((x) => x.userId !== LogUserId);
    uList = uList?.filter(
      (x) =>
        x.status === "Connect" ||
        x.status === "Canceled" ||
        x?.status === "Rejected"
    );
    uList = uList?.filter((x) => x.request === true);
    if (uList?.length > 0) {
      uList?.map((user, i) => {
        setOpenCircle(true);
        setAllSerror({ al: "" });
        let sqData = {
          sentRequestUserId: LogUserId,
          receiveRequestUserId: user?.userId,
          Status: "Pending",
          message: "I would like to add you as my connection.",
          createdBy: LogUserId,
        };
        axios
          .post(
            `${Config.API_HOST_URL_live}/api/Requests/SendRequest`,
            sqData,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.success) {
              setOpenCircle(false);
              setCheckBoxVal(false);
              setAbtChange(!abtChange);
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: response.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            }
          });
        return "";
      });
    } else {
      setAllSerror({ al: "You have to select at least one user." });
      setOpenCircle(false);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="check_box">
        <label className="personal_detail_label" style={{ paddingLeft: 30 }}>
          <input
            type="checkbox"
            className="login_check"
            checked={checkBoxVal}
            onChange={(e) => handleAll(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>
        <Button className="connect_all" onClick={sendMultiReq}>
          Connect to all
        </Button>
        {allSerror?.al && (
          <span role="alert" className={`d-block error_text`}>
            {allSerror.al}
          </span>
        )}
      </div>
      {props.grid === true ? (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-lg-3 col-md-3 mb-3" key={i}>
              <div className="grid_connection_card text-center figfamily_district_connect_users">
                {item.userId === LogUserId ? (
                  ""
                ) : (
                  <>
                    {item.status === "Connect" ||
                    item.status === "Canceled" ||
                    item?.status === "Rejected" ? (
                      <label
                        className="personal_detail_label"
                        style={{ paddingLeft: 30 }}
                      >
                        <input
                          type="checkbox"
                          className="login_check"
                          checked={item.request}
                          onChange={(e) =>
                            handleMainCheck(e.target.checked, item)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    ) : (
                      ""
                    )}
                  </>
                )}
                <div
                  className="connection_card_text click_u"
                  onClick={() => handleViewProfile(item?.userId)}
                >
                  <div
                    className="img_boxs m-auto"
                  >
                    <div className="user_img">
                      <img
                        src={item?.img ? item?.img : pro_img}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = pro_img;
                        }}
                        className="img-fluid mb-3 user_img_p"
                        alt="user"
                      />
                    </div>
                    <img
                      src={item?.userStatus === "Online" ? active_img : ""}
                      className="img-fluid dot_ac_in"
                    />
                  </div>
                  <h6>{item?.name}</h6>
                  <p className="mb-1">{item?.jobTitle}</p>
                  <p>{item?.siteLocation}</p>
                  {/* <p>
                    Joined{" "}
                    {item?.join ? convert(item?.join?.split("T")[0]) : ""}
                  </p> */}
                  {item.userId === eId ? (
                    <>
                      {derror?.re_cancel && (
                        <span role="alert" className={`d-block error_text`}>
                          {derror.re_cancel}
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {item.userId === LogUserId ? (
                  ""
                ) : (
                  <>
                    {item.status === "Pending" ? (
                      <Button
                        className="cancel"
                        onClick={() => CancelReq(item?.userId, item?.sId)}
                        disabled={item?.userId === ucId ? true : false}
                      >
                        {item?.userId === ucId ? "---" : "Cancel"}
                      </Button>
                    ) : item.status === "Connect" ||
                      item.status === "Canceled" ||
                      item?.status === "Rejected" ? (
                      <Button
                        className="connect"
                        onClick={() => SendReq(item?.userId)}
                        disabled={item?.userId === usId ? true : false}
                      >
                        {" "}
                        {item?.userId === usId ? "---" : "Connect"}
                      </Button>
                    ) : (
                      <div className="con">Connected</div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {FilterList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_connection_card  figfamily_district_connect_list_users">
                <div className="connection_card_text d-flex align-items-center">
                  {item.userId === LogUserId ? (
                    ""
                  ) : (
                    <>
                      {item.status === "Connect" ||
                      item.status === "Canceled" ||
                      item?.status === "Rejected" ? (
                        <div className="me-2">
                          <label
                            className="personal_detail_label"
                            style={{ paddingLeft: 30 }}
                          >
                            <input
                              type="checkbox"
                              className="login_check"
                              checked={item.request}
                              onChange={(e) =>
                                handleMainCheck(e.target.checked, item)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <div
                    className="d-flex click_u"
                    onClick={() => handleViewProfile(item?.userId)}
                  >
                    <div
                      className="img_boxs"
                
                    >
                      <div className="user_img">
                        <img
                          src={item?.img ? item?.img : pro_img}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = pro_img;
                          }}
                          className="img-fluid user_img_p"
                          alt="user"
                        />
                      </div>
                      <img
                        src={item?.userStatus === "Online" ? active_img : ""}
                        className="img-fluid dot_ac_in"
                      />
                    </div>

                    <div className="connection_card_title">
                      <h6>{item?.name}</h6>
                      {/* <p className="mt-2">
                      Joined{" "}
                      {item?.join ? convert(item?.join?.split("T")[0]) : ""}
                    </p> */}
                    </div>
                    <div className="my-auto connection_card_title">
                      <p className="mb-1">{item?.jobTitle} </p>
                      <p>{item?.siteLocation}</p>
                    </div>
                  </div>
                  {item.userId === eId ? (
                    <>
                      {derror?.re_cancel && (
                        <span role="alert" className={`d-block error_text`}>
                          {derror.re_cancel}
                        </span>
                      )}{" "}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {item.userId === LogUserId ? (
                  ""
                ) : (
                  <>
                    {item.status === "Pending" ? (
                      <Button
                        className="cancel"
                        onClick={() => CancelReq(item?.userId, item?.sId)}
                      >
                        {item?.userId === ucId ? "---" : "Cancel"}
                      </Button>
                    ) : item.status === "Connect" ||
                      item.status === "Canceled" ||
                      item?.status === "Rejected" ? (
                      <Button
                        className="connect"
                        onClick={() => SendReq(item?.userId)}
                      >
                        {" "}
                        {item?.userId === usId ? "---" : "Connect"}
                      </Button>
                    ) : (
                      <div className="con">Connected</div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default React.memo(Connection_list);
