import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import cardimg1 from "../../../images/ceu_couce_card_img.png";
import CourceReviews from "./CourceReviews/Index";
import { useParams } from "react-router-dom";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";

const CardCategoryBox = lazy(() => import("./CardCategoryBox"));
const CourseAuther = lazy(() => import("./CourseAuther"));
// const CourseRating = lazy(() => import("./CourseRating"));
const renderLoader = () => <p></p>;

function CourseDetail() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [authorProfile, setAuthorProfile] = useState({});
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const [courceCategory, setCourceCategory] = useState([]);
  const [courseDetails, setCourseDetails] = useState();
  const [courseContent, setCourseContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      CeuCourseDetails();
    }, 200);
    // eslint-disable-next-line
  }, []);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const CeuCourseDetails = async () => {
    setOpen(!open);
    let seData = {
      url: `/api/Ceu/GetUserCeuCourseStartInfoWithoutToken?CourseId=${id}`,
      headers: headers,
    };
    let response = await GetCallApi(seData);
    setOpen(false);
    if (response.status === 200) {
      if (response.data?.success) {
        setOpen(false);
        let ceuCourseD = response.data?.payload;
        let contentList = ceuCourseD?.content?.map((item) => {
          return {
            contentId: item?.contentId,
            title: item?.lessionTitle,
            sequenceNo: item?.sequenceNo,
            videoLength: item?.videoLength,
            description: item?.lessionDescription,
            contentMaterials: item?.contentMaterials,
          };
        });
        let keyArea = ceuCourseD?.keyArea?.split(",")
          ? ceuCourseD?.keyArea?.split(",")
          : ceuCourseD?.keyArea?.split("");
        let ceuDes = {
          id: ceuCourseD?.courseId,
          title: ceuCourseD?.courseTitle,
          coach:
            "Coach" +
            " " +
            ceuCourseD?.instructor?.firstName +
            " " +
            ceuCourseD?.instructor?.lastName,
          keyArea: keyArea,
          // keyArea: ceuCourseD?.keyArea?.split(","),
          ceuReq: ceuCourseD?.totalCEU + " CEU",
          description: ceuCourseD?.description,
          // imageOrVideoUrl:
          //   ceuCourseD?.imageOrVideoUrl === "" ||
          //   ceuCourseD?.imageOrVideoUrl === null
          //     ? cardimg1
          //     : `${Config.API_HOST_URL_live}${ceuCourseD.imageOrVideoUrl}`,
          imageOrVideoUrl:
            ceuCourseD?.thumbnailCourseUrl === "" ||
              ceuCourseD?.thumbnailCourseUrl === null
              ? cardimg1
              : `${Config.API_HOST_URL_live}${ceuCourseD.thumbnailCourseUrl}`,
          shoStartButton: ceuCourseD?.shoStartButton,

          totalLession: ceuCourseD?.totalLession,
          totalQuiz: ceuCourseD?.totalQuiz,
          totalUsers: ceuCourseD?.totalUsers,
          rating: ceuCourseD?.courseRating ? ceuCourseD.courseRating : "0",
          courseProgress: ceuCourseD?.courseProgress
            ? ceuCourseD?.courseProgress
            : "0",
          completed: ceuCourseD?.courseStatus
            ? ceuCourseD?.courseStatus
            : "InProgress",
          courseMaterials: ceuCourseD?.courseMaterials
            ? ceuCourseD?.courseMaterials
            : [],
        };

        let cCat = ceuCourseD?.categories?.split(", ");
        cCat.sort((a, b) => {
          const newa = parseInt(a?.split(" ")[0]);
          const newb = parseInt(b?.split(" ")[0]);
          if (newa < newb) {
            return -1;
          }
          if (newa > newb) {
            return 1;
          }
          return 0;
        });
        setCourceCategory(cCat);
        setCourseDetails(ceuDes);
        setCourseContent(contentList);
        setAuthorProfile(ceuCourseD.instructor);
        setIsLoading(false);
      } else {
        setOpen(false);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page main_backcolor">
          <div className="container">
            <div className="">
              <div className="ceu_couce_title">{courseDetails?.title}</div>
              <div className="Couce_Card_Page_main">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cat">
                      <CardCategoryBox
                        isLoading={isLoading}
                        courceCategory={courceCategory}
                        rating={courseDetails?.rating}
                        title="Categories"
                        courseDetails={courseDetails}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row course__details_box">
                      <div className="col-lg-9">
                        <div className="course__details_box_2">
                          <CardCategoryBox
                            isLoading={isLoading}
                            courseDetails={courseDetails}
                            title="Course Details"
                            courseStart={courseDetails?.completed}
                            shoStartButton={courseDetails?.shoStartButton}
                            courseId={id}
                          />
                        </div>
                        <CardCategoryBox
                          isLoading={isLoading}
                          courseContent={courseContent}
                          title="Course Content"
                        />
                      </div>
                      <div className="col-lg-3">
                        <CourseAuther
                          item={authorProfile}
                          // date="July 2, 2022"
                          shoStartButton={courseDetails?.shoStartButton}
                          courseId={id}
                          courseStart={courseDetails?.completed}
                          // courseStart="Complete"
                          courseProgress={courseDetails?.courseProgress}
                          enroll={courseDetails?.totalUsers}
                          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
                          lesson={courseDetails?.totalLession}
                          quiz={courseDetails?.totalQuiz}
                          certi="Course Certificate"
                          value="0"
                          autherActivity="July 2, 2022"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <CourseRating
                      courseRatingData={courseRatingData}
                      avgrate="4.0"
                      avgstar="1"
                    />
                  </div> */}
                  <div className="col-md-12">
                    <CourceReviews title={courseDetails?.title} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(CourseDetail);
