import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  create_quiz: { createQuestion: false },
  Drefresh: false,
  Read: false,
};

export const userSlice = createSlice({
  name: "creates",
  initialState,
  reducers: {
    CreteDataFor(state, action) {
      state.create_data = action.payload;
    },
    CreteQuestionQuiz(state, action) {
      state.create_quiz = action.payload;
    },
    DeleteDataFor(state, action) {
      state.deleteData = action.payload;
    },
    CreatePost(state, action) {
      state.Post_data = action.payload;
    },
    DashboardRe(state, action) {
      state.Drefresh = action.payload;
    },
    DashNewPop(state, action) {
      state.Read = action.payload;
    },
  },
});

export const {
  CreteDataFor,
  DeleteDataFor,
  CreteQuestionQuiz,
  CreatePost,
  DashboardRe,
  DashNewPop,
} = userSlice.actions;

export default userSlice.reducer;
