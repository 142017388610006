import React, { useEffect, useState } from "react";
import "./index.scss";
import successimg from "../../../../images/course_completed.png";
// import start from "../../../../images/start.png";
import tagimg from "../../../../images/tagimg.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";
import Rating from "react-rating";
import star_full from "../../../../images/category_star_full.png";
import star_blank from "../../../../images/category_star_blank.png";
import Config from "../../../../Utils/Config";
import Popup from "../../../Reuseable_Components/Popup/Popup";

function SuccessCourse() {
  const [courseData, setCourseData] = useState();
  const [ayId, setAyId] = useState();
  const { id } = useParams();
  let location = useLocation();
  const LogDetail = useSelector((state) => state.login?.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (id) {
      getCourseData(id);
      academicYear();
    }
    // eslint-disable-next-line
  }, [id]);

  const getCourseData = async () => {
    let seData = {
      url: `/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status) {
      if (res?.data.success) {
        let cs = res.data.payload;
        setCourseData(cs);
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please  try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please  try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };
  const academicYear = async () => {
    let seData = {
      url: "/api/SchoolAcademicYear/GetCurrentAcademicYear",
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res.status === 200) {
      if (res.data.success) {
        let adyId = res.data.payload[0]?.academicYearId;
        setAyId(adyId);
      }
    }
  };
  return (
    <>
      <div className="success-course-section">
        <div className="container">
          <div className="main_wrapper">
            <div className="row justify-content-center">
              {courseData?.courseStatus === "Completed" ? (
                <div className="col-md-8 text-center course-section">
                  <div class="title-course">
                    Congratulations For Completing Course
                  </div>
                  <p className="pera-text">
                    We are pleased to inform you that you have successfully
                    completed your course of{" "}
                    <span style={{ color: "#1ebec8" }}>
                      {courseData?.courseTitle}
                    </span>
                    . Your dedication and hard work have paid off, and we are
                    proud of your accomplishment. You have gained valuable
                    knowledge and skills that will benefit you in your future
                    endeavors.
                  </p>
                  <div className="success-img">
                    <img
                      // src={
                      //   courseData?.imageOrVideoUrl
                      //     ? `${Config.API_HOST_URL_live}${courseData?.imageOrVideoUrl}`
                      //     : successimg
                      // }
                      src={
                        courseData?.thumbnailCourseUrl
                          ? `${Config.API_HOST_URL_live}${courseData?.thumbnailCourseUrl}`
                          : successimg
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = successimg;
                      }}
                      alt=""
                    />
                    <div className="complate-box">
                      <img src={tagimg} alt="" />
                      <span>Completed</span>
                    </div>
                    <div className="offer-block">
                      Offered By: {courseData?.instructor?.firstName +
                          " " +
                          courseData?.instructor?.lastName}
                    </div>
                  </div>
                  <div className="ceu-course-block">
                    <h6 className="text-start mb-3">
                      {courseData?.courseTitle}
                    </h6>
                    <ul>
                      <li>
                        <p>{courseData?.content?.length} Lessons</p>
                        <p>CEU: {courseData?.totalCEU}</p>
                      </li>
                      <li>
                        <p>{courseData?.courseProgress}% Complete</p>
                        <div>
                          <Rating
                            emptySymbol={
                              <img src={star_blank} alt="rating_blank" />
                            }
                            fullSymbol={
                              <img src={star_full} alt="rating-full" />
                            }
                            initialRating={
                              courseData?.courseRating
                                ? courseData?.courseRating
                                : "0"
                            }
                            readonly
                          />
                        </div>
                      </li>
                      <li>
                        <p>Key Area: {courseData?.keyArea}</p>
                      </li>
                    </ul>
                    <p>
                      Description: {courseData?.description?.slice(0, 150)}
                      {courseData?.description?.length > 150 && (
                        <span
                          className="description cr"
                          onClick={() => showReadMore(courseData?.description)}
                        >
                          ...read more
                        </span>
                      )}
                    </p>
                    <div className="btn_manage">
                      {ayId ? (
                        <Link
                          to={`/certificate/${id}/${LogDetail?.userId}/${ayId}`}
                          className="theme_btn"
                          style={{ textDecoration: "none" }}
                        >
                          View Certificate
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link
                        to={`/${location.pathname?.split("/")[1]}`}
                        className="theme_btn border_btn"
                        style={{ textDecoration: "none" }}
                      >
                        Back to Courses
                      </Link>
                      <Popup btnName="Write A Review" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-8 text-center course-section">
                  <div class="title-course">
                    You have not Completed {courseData?.courseTitle} Course
                  </div>
                  <div className=" d-flex justify-content-center">
                    <Link
                      to={`/${location.pathname?.split("/")[1]}`}
                      className="cancel_btn"
                      style={{ textDecoration: "none" }}
                    >
                      Back to Courses
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessCourse;
