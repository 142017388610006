import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import "./index.scss";
import { VscAdd } from "react-icons/vsc";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableSortLabel,
  TableCell,
  Backdrop,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import Paginations from "../Paginations/Pagination";
import { Link } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { BiSolidMinusSquare, BiSolidPlusSquare } from "react-icons/bi";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { usePapaParse } from "react-papaparse";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: "#F1F6FC",
  // },
  ".Mui-active": {
    color: "#fff !important",
    ".MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",
    fontWeight: "300 !important",
    lineHeight: "12px !important",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  backgroundColor: "#ffffff",
  // },
  // "&:nth-of-type(even)": {
  // backgroundColor: "#F1F6FC",
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.pdf_download": {
    fontSize: "9px !important",
    fontWeight: "300 !important",
    padding: "3px !important",
  },
}));

function NewListTable(prop) {
  const {
    addressLink,
    exportBtn,
    headCells,
    title,
    action,
    ListData,
    dropdown,
    addBtn,
    prdAddAr,
    AddStudents,
    actionFirst,
    as,
    showUserListInTable,
    fileName,
    exportDataList,
    innerProduct,
    mainfirstShow,
  } = prop;
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(as ? as : "EventName");
  const tableRef = useRef(null);
  const [saveFileName, setSaveFileName] = useState(fileName);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "no" || orderBy === "id") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {downloadExcel || downloadPDF ? (
            ""
          ) : actionFirst ? (
            <StyledTableCell align="center">
              <TableSortLabel>Actions</TableSortLabel>
            </StyledTableCell>
          ) : (
            ""
          )}
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
              className={`${downloadPDF && "pdf_download"}`}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {downloadExcel || downloadPDF ? (
            ""
          ) : actionFirst ? (
            ""
          ) : (
            <>
              {action ? (
                <StyledTableCell align="center">
                  <TableSortLabel>Actions</TableSortLabel>
                </StyledTableCell>
              ) : (
                ""
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = ListData?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  //   const token = useSelector((state) => state.login.LoginDetails.accessToken);
  //   const LogUserId = useSelector((state) => state.login.LoginDetails);
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //     Accept: "*/*",
  //   };
  const showReadMore = (t, des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: t,
      text: des,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };
  const [openView, setOpenView] = useState(false);
  const [rId, setRId] = useState();
  const [showUserWait, setShowUserWait] = useState(false);
  const [showUserList, setShowUserList] = useState([]);
  const handleCloseView = () => {
    setOpenView(false);
    setShowUserList([]);
    setRId();
  };
  const handleOpenView = (r) => {
    setOpenView(true);
    setRId(r?.courseId);
    if (r?.courseId === rId) {
      setOpenView(false);
    }
    // if (!prop?.showUserListWithoutAPi) {
    //   GetUserList(r);
    // }
  };
  // const GetUserList = async (d) => {
  //   setShowUserList(d);
  //   let seData = {
  //     url: d?.associationMembersId
  //       ? `/api/Association/GetAllAssociationMembersTypeAllocationListByAssociationMembersId?associationMembersId=${d?.associationMembersId}`
  //       : `/api/Association/GetAllAssociationAllocationListByAssociationId?associationId=${d?.associationId}`,
  //     headers: headers,
  //   };
  //   setShowUserWait(true);
  //   let res = await GetCallApi(seData);
  //   setShowUserWait(false);
  //   if (res?.status === 200) {
  //     if (res?.data?.success) {
  //       let usList = res.data.payload;
  //       setShowUserList(usList);
  //     } else {
  //     }
  //   } else {
  //   }
  // };

  const [openDownload, setOpenDownload] = useState(null);

  const openDownVi = Boolean(openDownload);
  const showDownBtn = (event) => {
    setOpenDownload(event?.currentTarget);
  };
  const handleCloseDown = () => {
    setOpenDownload(null);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: saveFileName ? saveFileName : fileName,
    sheet: "Product",
  });
  useEffect(() => {
    if (downloadExcel) {
      // handleDownload();
      handleFileOpen();
    }
    // eslint-disable-next-line
  }, [downloadExcel]);

  let Time = new Date();
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }
  const { jsonToCSV } = usePapaParse();
  const [downCSV, setDownCSV] = useState(false);
  const [downValCSV, setDownValCSV] = useState("");
  const handleDownload = () => {
    if (downCSV) {
      let hd = headCells.map((headCell) => {
        if (headCell.id === "Actions") {
        } else if (headCell.id === "ProdImg") {
        }
        return headCell.label;
      });
      hd = hd.filter((x) => x !== undefined);

      let bd = formattedSearchResults
        ?.slice(
          (currentPage - 1) * pageSize,
          (currentPage - 1) * pageSize + pageSize
        )
        ?.map((row, index) => {
          let r = headCells?.map((column) => {
            const value = row[column?.id];

            if (column.id === "Actions") {
            } else if (column.id === "ProdImg") {
            }
            return value;
          });
          r = r.filter((x) => x !== undefined);
          if (row?.attendeeId) {
          }
          return r;
        });
      let newArr = [];
      if (innerProduct) {
        // return false;
        let product = formattedSearchResults
          ?.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
          )
          ?.map((x, i) => {
            let ne = bd[i];
            newArr.push(ne);
            newArr.push(["id", "ProductName"]);
            x?.productDetails?.map((y, index) => {
              newArr.push([index + 1, y?.productName]);
              return 0;
            });
            return 0;
          });
        // remove warning
        let nv = product;
        product = nv;
      }

      const jsonData = {
        fields: hd,
        data: innerProduct ? newArr : bd,
      };
      const results = jsonToCSV(jsonData, {
        delimiter: downValCSV,
      });
      exportData(results, `${saveFileName}.csv`, "text/csv;charset=utf-8;");

      setDownloadExcel(false);
      // console.log("Results:", results);
    } else {
      onDownload();
      setDownloadExcel(false);
    }
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownVal = (tr, val, pdf) => {
    if (pdf) {
      setDownloadPDF(true);
    } else if (tr) {
      setDownCSV(true);
      setDownValCSV(val);
    } else {
      setDownCSV(false);
      setDownValCSV("");
    }
    setDownloadExcel(true);
  };
  const [openFileName, setOpenFileName] = useState(false);
  const handleFileOpen = () => {
    setOpenFileName(true);
  };
  const handleFileClose = () => {
    setOpenFileName(false);
    setDownloadExcel(false);
    setSaveFileName("");
  };
  const [derror, setDerror] = useState();

  const HandleSaveFile = () => {
    if (saveFileName !== "") {
      setDerror({ fName: "" });
      if (downloadPDF) {
        handleDownloadPdf();
      } else {
        handleDownload();
      }
      handleFileClose();
    } else {
      setDerror({ fName: "Please Enter File Name" });
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const [openCircle, setOpenCircle] = useState(false);

  const handleDownloadPdf = async () => {
    // return false;
    setOpenCircle(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`${saveFileName}.pdf`);
        setOpenCircle(false);

        setDownloadPDF(false);
      });
  };
  const back = {
    maxWidth: `${downloadPDF ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {mainfirstShow ? (
        <>
          <div className="d-flex justify-content-between new_listdata_control_sec">
            {dropdown ? (
              <select
                className="form-select w-25 me-auto"
                onChange={(e) => prop.Company(e.target.value)}
              >
                <option value="Company">Company</option>
                <option value="Individual">Individual</option>
              </select>
            ) : (
              <h3 className="card_title m-0">{title}</h3>
            )}
            {prop?.beforeSearchDropdown ? (
              <select
                className="form-select w-25 ms-auto"
                onChange={(e) => prop?.beforeSearchDropdownCall(e.target.value)}
              >
                {prop?.beforeSearchDropdownArray?.map((x, i) => (
                  <option value={x?.value} key={i}>
                    {x?.label}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
            {formattedSearchResults?.length > 0 ? (
              <>
                {exportDataList && (
                  <Button
                    className="detail_button  ms-auto"
                    onClick={showDownBtn}
                    // onClick={() =>  setDownloadExcel(true)}
                  >
                    {/* <Button className="btn_clm_vis f-12-600 me-3" onClick={download}> */}
                    Export as
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
            <Menu
              className={`column_visible `}
              id="long-menu-column"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={openDownload}
              open={openDownVi}
              onClose={handleCloseDown}
            >
              <MenuItem onClick={() => handleDownVal(false, "")}>
                Download Excel
              </MenuItem>
              <MenuItem onClick={() => handleDownVal(true, " ")}>
                Download TAB CSV
              </MenuItem>
              {/* <MenuItem onClick={() => handleDownVal(true, ",")}>
            Download COMMA CSV
          </MenuItem> */}
              <MenuItem onClick={() => handleDownVal(true, "|")}>
                Download PIPE CSV
              </MenuItem>
              {/* <MenuItem onClick={() => handleDownVal(true, ";")}>
            Download SEMI-COLOM CSV
          </MenuItem>
          <MenuItem onClick={() => handleDownVal(true, "~")}>
            Download TILDE CSV
          </MenuItem> */}
              <MenuItem onClick={() => handleDownVal("", "", true)}>
                Download Pdf
              </MenuItem>
            </Menu>
            {openFileName && (
              <>
                <Modal
                  open={openFileName}
                  onClose={handleFileClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="toggle_delete_data1"
                >
                  <Box sx={style} className="delete_data_box">
                    <div className="popup_delete_detail_box">
                      {/* <h6>{props?.title}</h6>  */}
                      <div className="popup_delete_detail">
                        <div className="form-group text-start">
                          <label className="form-label">File Name</label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter File Name"
                            value={saveFileName}
                            onChange={(e) => setSaveFileName(e.target.value)}
                          />
                          {derror?.fName && (
                            <span role="alert" className="error_text">
                              {derror?.fName}
                            </span>
                          )}
                        </div>
                        <button
                          type="button"
                          className="back_button me-3"
                          onClick={handleFileClose}
                        >
                          cancel
                        </button>
                        <button
                          type="button"
                          className="detail_button"
                          onClick={HandleSaveFile}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </>
            )}
            <div
              className={` search_sec ${
                prop?.beforeSearchDropdown || prop?.exportDataList
                  ? "ms-2"
                  : "ms-auto"
              } me-2`}
            >
              <div className="search_input w-100">
                <img src={search_icon} alt="" />
                <input
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Type your search here"
                />
              </div>
            </div>
            {exportBtn && (
              <>
                {formattedSearchResults?.length > 0 ? (
                  <Button onClick={onDownload} className="detail_button">
                    Export to XLS
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
            {addBtn && (
              <Link className="dashboard_btn background" to={addressLink}>
                <VscAdd className="me-2" />
                Add
              </Link>
            )}
          </div>
          {formattedSearchResults?.length > 0 ? (
            <>
              {prop?.selectAllBtn ? (
                <>
                  <Button
                    className="detail_button"
                    onClick={() =>
                      prop?.selectingAll(true, formattedSearchResults)
                    }
                  >
                    Select All
                  </Button>
                  <Button
                    className="ms-3 back_button"
                    onClick={() =>
                      prop?.selectingAll(false, formattedSearchResults)
                    }
                  >
                    Deselect All
                  </Button>

                  {prdAddAr?.length > 0 ? (
                    <>
                      <Button
                        className="ms-3 detail_button"
                        onClick={AddStudents}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <div className="new_all_list">
        <Paper className="table-box">
          <TableContainer className="" ref={tableRef} style={back}>
            {downloadPDF || downloadExcel ? (
              <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                <img
                  src={require("../../../images//Icon/20211.png")}
                  alt=""
                  height={100}
                  width={100}
                />
                <div>
                  <label
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {" "}
                    {fileName}
                  </label>

                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {" "}
                    {convertDate(
                      Time.toLocaleString("en-us", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                    )}{" "}
                    {Time.toLocaleTimeString()}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                    }}
                  >
                    Report Generated By:{" "}
                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(
                  formattedSearchResults,
                  getComparator(order, orderBy)
                )
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((row, index) => {
                    return (
                      <React.Fragment key={index + 1}>
                        <StyledTableRow
                          className={`table_body ${
                            row?.isRegister
                              ? "gr"
                              : row?.isExhiRegister
                              ? "gr"
                              : ""
                          }
                           ${downloadPDF && "pdf_download"}
                          `}
                          style={{
                            backgroundColor: row.bgcolor ? row.bgcolor : "",
                          }}
                        >
                          {downloadExcel || downloadPDF ? (
                            ""
                          ) : actionFirst ? (
                            <StyledTableCell
                              className={`column_data`}
                              key={index + 1}
                            >
                              <div className="edit_delete justify-content-center">
                                {showUserListInTable && (
                                  <>
                                    {row?.courseId ? (
                                      row?.courseId === rId ? (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="me-2"
                                          onClick={() => handleCloseView()}
                                        >
                                          <BiSolidMinusSquare
                                            fontSize={38}
                                            color="#d91f2d"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="me-2"
                                          onClick={() => handleOpenView(row)}
                                        >
                                          <BiSolidPlusSquare
                                            fontSize={38}
                                            color="#73c04d"
                                          />
                                        </div>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                                {row?.Action}
                              </div>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          {headCells?.map((column) => {
                            const value = row[column?.id];
                            if (column?.widthFix) {
                              if (downloadExcel || downloadPDF) {
                                return (
                                  <StyledTableCell
                                    className={`column_data 
                                    ${downloadPDF && "pdf_download"}`}
                                    key={column?.id}
                                  >
                                    {value ? value : ""}
                                  </StyledTableCell>
                                );
                              }
                              return (
                                <StyledTableCell
                                  className="column_data"
                                  key={column?.id}
                                >
                                  {value ? value?.slice(0, 50) : ""}
                                  {value
                                    ? value?.length > 50 && (
                                        <span
                                          className="description cr"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            showReadMore(column?.label, value)
                                          }
                                        >
                                          ...read more
                                        </span>
                                      )
                                    : ""}
                                  {/* <div
                                  style={{
                                    maxWidth: column?.widthFix + "px",
                                    lineBreak: "anywhere",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </div> */}
                                </StyledTableCell>
                              );
                            }
                            if (column?.color) {
                              return (
                                <StyledTableCell
                                  className={`column_data 
                                    ${downloadPDF && "pdf_download"}`}
                                  key={column?.id}
                                >
                                  <span className={`status_tag ${row.color}`}>
                                    {column?.format && typeof value === "number"
                                      ? column?.format(value)
                                      : value}
                                  </span>
                                </StyledTableCell>
                              );
                            }
                            return (
                              <StyledTableCell
                                className={`column_data 
                                    ${downloadPDF && "pdf_download"}`}
                                key={column?.id}
                              >
                                {column?.format && typeof value === "number"
                                  ? column?.format(value)
                                  : value}
                              </StyledTableCell>
                            );
                          })}
                          {downloadExcel || downloadPDF ? (
                            ""
                          ) : actionFirst ? (
                            ""
                          ) : (
                            <>
                              {action ? (
                                <StyledTableCell
                                  className="column_data"
                                  key={index + 1}
                                >
                                  <div className="edit_delete">
                                    {showUserListInTable && (
                                      <>
                                        {row?.courseId ? (
                                          row?.courseId === rId ? (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              className="me-2"
                                              onClick={() => handleCloseView()}
                                            >
                                              <BiSolidMinusSquare
                                                fontSize={38}
                                                color="#d91f2d"
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              className="me-2"
                                              onClick={() =>
                                                handleOpenView(row)
                                              }
                                            >
                                              <BiSolidPlusSquare
                                                fontSize={38}
                                                color="#73c04d"
                                              />
                                            </div>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                    {row?.Action}
                                  </div>
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </StyledTableRow>
                        {openView && row?.courseId === rId ? (
                          <React.Fragment>
                            <StyledTableRow className="table_body">
                              <StyledTableCell colSpan={10}>
                                <Table>
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell>Id</StyledTableCell>
                                      <StyledTableCell>
                                        Lesson Name
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Video Length
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row?.courseLessionData?.length > 0 ? (
                                      row?.courseLessionData?.map((us, i) => (
                                        <StyledTableRow
                                          className="table_body"
                                          key={i}
                                        >
                                          <StyledTableCell>
                                            {i + 1}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            {us?.lessionTitle}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            {us?.videoLength}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))
                                    ) : showUserWait ? (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={7}>
                                          <div className="er_nodata">
                                            <CircularProgress color="inherit" />
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ) : (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={7}>
                                          <div className="er_nodata">
                                            No Data Found
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow className="table_body">
                              <StyledTableCell colSpan={10}>
                                <Table>
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell>Id</StyledTableCell>
                                      <StyledTableCell>
                                        Quiz Name
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Quiz Question Length
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row?.quizData?.length > 0 ? (
                                      row?.quizData?.map((us, i) => (
                                        <StyledTableRow
                                          className="table_body"
                                          key={i}
                                        >
                                          <StyledTableCell>
                                            {i + 1}
                                          </StyledTableCell>

                                          <StyledTableCell>
                                            {us?.quizTitle}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            {us?.questionCount}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))
                                    ) : showUserWait ? (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={7}>
                                          <div className="er_nodata">
                                            <CircularProgress color="inherit" />
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ) : (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={7}>
                                          <div className="er_nodata">
                                            No Data Found
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {formattedSearchResults?.length === 0 ? (
            <div className="er_nodata">No Data Found</div>
          ) : (
            <div className="page_nation">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">show</span>

                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
                <span className="p_18_text">entries</span>
              </div>
              <Paginations
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={formattedSearchResults?.length}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}
export default React.memo(NewListTable);
