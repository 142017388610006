import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useSelector } from "react-redux";
import Quizstart from "./CourseRightStart/Quiz_start";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";
import { useSignalR } from "../../../../Utils/SignalRContext";

const CourceDetails = lazy(() => import("./CourceDetails"));
const CourseRightStart = lazy(() => import("./CourseRightStart"));
const renderLoader = () => <p></p>;

function StartCource() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [quizData, setQuizData] = useState(false);
  const [authorProfile, setAuthorProfile] = useState({});
  const [quizList, setQuizList] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [conId, setConId] = useState("");
  const [quizId, setQuizId] = useState("");
  let nav = useNavigate();
  const { connection } = useSignalR();
  useEffect(() => {
    CeuCourseDetails(true);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    CeuCourseDetails();
    // eslint-disable-next-line
  }, [quizId, conId]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setAuthorProfile((x) => ({
          ...x,
          userStatus: status ? "Online" : "Offline", 
        }));
      });
    }
  }, [connection?.state]);
  const CeuCourseDetails = async (d) => {
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    const sendData = {
      url: `/api/Ceu/GetUserCeuCourseStartInfo?CourseId=${id}&UserId=${LogUserId}`,
      headers: headers,
    };

    let response = await GetCallApi(sendData);
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        let ceuCourseD = response.data?.payload;
        if (ceuCourseD?.courseStatus !== "Completed") {
          let contentList = ceuCourseD?.content?.map((item, i) => {
            return {
              id: i + 1,
              contentId: item?.contentId,
              title: item?.lessionTitle,
              video: item?.lessionVideoUrl,
              videoLength: item?.videoLength,
              description: item?.lessionDescription,
              status: item?.status,
              sequenceNo: item?.sequenceNo,
              contentMaterials: item?.contentMaterials,
              contentType: item?.contentType,
              imageUrl: item?.imageUrl,
              lLabel: item?.linkLable,
              lUrl: item?.linkLableUrl,
            };
          });
          let ceuDes = {
            id: ceuCourseD?.courseId,
            title: ceuCourseD?.courseTitle,
            coach:
              "Coach" +
              " " +
              ceuCourseD?.instructor?.firstName +
              " " +
              ceuCourseD?.instructor?.lastName,
            ceuReq: ceuCourseD?.totalCEU + " CEU",
            description: ceuCourseD?.description,
            // imageOrVideoUrl:
            //   ceuCourseD?.imageOrVideoUrl === "" ||
            //   ceuCourseD?.imageOrVideoUrl === null
            //     ? cardimg1
            //     : `${Config.API_HOST_URL_live}${ceuCourseD?.imageOrVideoUrl}`,
            imageOrVideoUrl:
              ceuCourseD?.thumbnailCourseUrl === "" ||
                ceuCourseD?.thumbnailCourseUrl === null
                ? cardimg1
                : `${Config.API_HOST_URL_live}${ceuCourseD?.thumbnailCourseUrl}`,
            rating: ceuCourseD?.courseRating ? ceuCourseD?.courseRating : "0",
            courseProgress: ceuCourseD?.courseProgress
              ? ceuCourseD?.courseProgress
              : "0",
            courseStatus: ceuCourseD?.courseStatus,
          };
          let quiList = ceuCourseD?.quiz?.map((item, i) => {
            return {
              id: i + 1,
              quizId: item?.quizId,
              quizName: item?.quizName,
              quizDescription: item?.quizDescription,
              quizQuestion: item?.quizQuestion,
              status: item?.status,
            };
          });
          setQuizList(quiList);
          setCourseDetails(ceuDes);
          setCourseContent(contentList);
          setAuthorProfile(ceuCourseD?.instructor);
          if (d) {
            let filterId = contentList?.filter(
              (i) => i?.status !== "Completed"
            );
            setConId(filterId[0]?.contentId ? filterId[0]?.contentId : "");
            if (filterId[0] && filterId[0]) {
              startCouorse(
                filterId[0]?.contentId,
                "Lession",
                filterId[0]?.status
              );
            } else {
              let quId = quiList?.filter((q) => q?.status !== "Completed");
              setQuizId(quId[0]?.quizId ? quId[0]?.quizId : "");
              if (quId[0] && quId[0]) {
                startCouorse(quId[0]?.quizId, "Quiz", quId[0]?.status);
                setQuizData(true);
              }
            }
          }
        } else {
          nav(-1);
        }
        // }
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    //   } else {
    //     setOpen(false);
    //   }
    // });
  };

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const startCouorse = async (e, name, status) => {
    let today = new Date();

    if (status !== "Completed") {
      function convert1(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      let userCourseData = {
        // userCourseProgressId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        contentType: name,
        contentId: e,
        status: "InProgress",
        startDate: convert1(today),
        courseId: id,
        userId: LogUserId,
        createdBy: LogUserId,
      };
      // axios
      //   .post(
      //     `${Config.API_HOST_URL_live}/api/UserCourseProgress/CompleteCourseContentEntry`,
      //     userCourseData,
      //     { headers }
      //   )
      //   .then((response) => {
      //     if (response.data.success) {

      let sendData = {
        url: `/api/UserCourseProgress/CompleteCourseContentEntry`,
        body: userCourseData,
        headers: headers,
      };

      let response = await PostCallApi(sendData);
      if (response?.status === 200) {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          setOpen(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response?.data?.message
              ? response?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
    //   });
    // }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="ceu_couce_title">{courseDetails?.title}</div>
              <div className="course_start_page">
                <div className="row m-0 ">
                  <div className="col-md-4 col-xxl-4  p-0">
                    <CourceDetails
                      authorProfile={authorProfile}
                      courseList={courseContent}
                      quizList={quizList}
                      setQuizData={setQuizData}
                      setConId={setConId}
                      setQuizId={setQuizId}
                      rating={courseDetails?.rating}
                      courseStatus={courseDetails?.courseStatus}
                      courseProgress={courseDetails?.courseProgress}
                    />
                  </div>
                  <div className="col-md-8 col-xxl-8 p-0">
                    {quizData === false ? (
                      <CourseRightStart
                        conId={conId}
                        courseList={courseContent}
                        mainTitle={courseDetails?.title}
                        materialList={quizList}
                        setQuizData={setQuizData}
                        setQuizId={setQuizId}
                        quizData={quizData}
                        setConId={setConId}
                        CourseID={id}
                      />
                    ) : (
                      <Quizstart
                        quizId={quizId}
                        mainTitle={courseDetails?.title}
                        quizList={quizList}
                        CourseID={id}
                        setQuizId={setQuizId}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(StartCource);
