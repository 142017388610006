import React, { Suspense, useState, useEffect } from "react";
import "./index.scss";
import rightarrow from "../../images/right-arrow.svg";
import { Link } from "react-router-dom";
import aboutus from "../../images/about-us.jpg";
import strategy from "../../images/strategy-icon.svg";
import call from "../../images/call-icon.svg";
import service from "../../images/service-icon.svg";
import child from "../../images/catelog-img3.jpg";
import catelog1 from "../../images/catelog-img1.jpg";
import catelog2 from "../../images/catelog-img2.jpg";
import catelog from "../../images/catelog-img.jpg";
import properties from "../../images/properties-img.jpg";
// import challenging from "../../images/challenging-staff-img.jpg";
// import innovation from "../../images/innovation-img.jpg";
import { GetCallApi } from "../../Action/Action";
import Config from "../../Utils/Config";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function About() {
  const [isLoading, setIsLoading] = useState(true);
  const [aboutData, setAboutData] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);

  useEffect(() => {
    // startTimer();
    setOpenCircle(!openCircle);
    setIsLoading(true);
    setTimeout(() => {
      getAboutData();
    }, 200);
    // eslint-disable-next-line
  }, []);
  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading").innerHTML = "";
  // }, []);
  const getAboutData = async () => {
    let seData = {
      url: `/api/AboutUs/GetAboutUsListWithOutToken`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let abData = res.data.payload;
        setAboutData(abData);
        // stopTimer();
        setOpenCircle(false);
        setIsLoading(false);
      } else {
        // stopTimer();1
        setOpenCircle(false);
        setIsLoading(false);
      }
    } else {
      // stopTimer();
      setOpenCircle(false);
      setIsLoading(false);
    }
  };
  const showReadMore = (t, des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: t,
      text: des,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#86c65a", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* <div
        id="loading"
        className="h6_text p-5 text-center"
        style={{
          height: openCircle ? "100vh" : "",
          display: !openCircle ? "none" : "block",
        }}
      ></div> */}
      {/* {!openCircle ? ( */}
      <main className="aboutpage">
        <section className="aboutbanner-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-12 text-center">
                <div className="banner-content">
                  {isLoading ? (
                    <div className="skeleton-loader align-items-center">
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    <>
                      <h1>
                        ABOUT US
                        <img
                          src={rightarrow}
                          alt=""
                          className="right-arrow"
                        ></img>
                      </h1>
                      <h5>
                        {aboutData?.aboutUsDescription?.slice(0, 285)}
                        {aboutData?.aboutUsDescription?.length > 285 && (
                          <span
                            className="description cr"
                            onClick={() =>
                              showReadMore(
                                "ABOUT US",
                                aboutData?.aboutUsDescription
                              )
                            }
                          >
                            ...read more
                          </span>
                        )}
                      </h5>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Start About us Fig */}
        <section className="aboutfig-section common-padding">
          <div className="container">
            <div className="row">
              <h2 className="title-text">ABOUT US FIG</h2>
              <div className="col-lg-5">
                {isLoading ? (
                  <div className="skeleton-loader"> {box} </div>
                ) : (
                  <>
                    <img
                      src={
                        aboutData?.uploadUrl
                          ? `${Config.API_HOST_URL_live}${aboutData?.uploadUrl}`
                          : aboutus
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = aboutus;
                      }}
                      width={"100%"}
                      className="img-fluid"
                      alt=""
                    />
                  </>
                )}
              </div>
              <div className="col-lg-7">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  <>
                    <p>
                      {aboutData?.figSectionDescription?.slice(0, 285)}
                      {aboutData?.figSectionDescription?.length > 285 && (
                        <span
                          className="description cr"
                          onClick={() =>
                            showReadMore(
                              "ABOUT US FIG",
                              aboutData?.figSectionDescription
                            )
                          }
                        >
                          ...read more
                        </span>
                      )}
                    </p>
                  </>
                )}
                <ul className="about-point">
                  {isLoading ? (
                    <>
                      <div className="skeleton-loader">
                        <div className="skeleton-line"></div>
                        <div className="skeleton-line"></div>
                        <div className="skeleton-line"></div>
                        <div className="skeleton-line"></div>
                      </div>
                    </>
                  ) : (
                    aboutData?.multiTitle?.split(",")?.map((x, i) => (
                      <li key={i}>
                        <img src={rightarrow} alt="" />
                        <h5>{x}</h5>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* End About us Fig */}

        {/* Start WE ARE ALWAYES BEST */}
        <section className="weare-section common-padding">
          <div className="container">
            <div className="row">
              <h2 className="title-text">WE ARE ALWAYS BEST</h2>
              <div className="col-md-4 col-sm-6 mb-30">
                {isLoading ? (
                  <div className="skeleton-loader mb-3">
                    <div className="skeleton-round  mb-3"></div>
                    <div className="skeleton-line skeleton-title mb-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  <>
                    <div className="about-box">
                      <img
                        src={
                          aboutData?.firstImageUrl
                            ? `${Config.API_HOST_URL_live}${aboutData?.firstImageUrl}`
                            : strategy
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = strategy;
                        }}
                        width={80}
                        height={80}
                        alt=""
                      />

                      <h3>{aboutData?.firstTitle}</h3>
                      <p className="we_des">{aboutData?.firstDescription}</p>
                      {/* <p className="we_des">
                        {aboutData?.firstDescription?.slice(0, 100)}
                        {aboutData?.firstDescription?.length > 100 && (
                          <span
                            className="description cr"
                            onClick={() =>
                              showReadMore(
                                aboutData?.firstTitle,
                                aboutData?.firstDescription
                              )
                            }
                          >
                            ...read more
                          </span>
                        )}
                      </p> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-4 col-sm-6 mb-30">
                {isLoading ? (
                  <div className="skeleton-loader mb-3">
                    <div className="skeleton-round  mb-3"></div>
                    <div className="skeleton-line skeleton-title mb-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  <>
                    <div className="about-box">
                      <img
                        src={
                          aboutData?.secondImageUrl
                            ? `${Config.API_HOST_URL_live}${aboutData?.secondImageUrl}`
                            : service
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = service;
                        }}
                        width={80}
                        height={80}
                        alt=""
                      />
                      <h3>
                        {aboutData?.secondTitle}
                        {/* High-Quality Services */}
                      </h3>
                      <p className="we_des">{aboutData?.secondDescription}</p>
                      {/* <p className="we_des">
                        {aboutData?.secondDescription?.slice(0, 100)}
                        {aboutData?.secondDescription?.length > 100 && (
                          <span
                            className="description cr"
                            onClick={() =>
                              showReadMore(
                                aboutData?.secondTitle,
                                aboutData?.secondDescription
                              )
                            }
                          >
                            ...read more
                          </span>
                        )}
                      </p> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-4 col-sm-6">
                {isLoading ? (
                  <div className="skeleton-loader mb-3">
                    <div className="skeleton-round  mb-3"></div>
                    <div className="skeleton-line skeleton-title mb-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  <>
                    <div className="about-box">
                      <img
                        src={
                          aboutData?.thirdImageUrl
                            ? `${Config.API_HOST_URL_live}${aboutData?.thirdImageUrl}`
                            : call
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = call;
                        }}
                        width={80}
                        height={80}
                        alt=""
                      />
                      <h3>
                        {aboutData?.thirdTitle}
                        {/* Friendly Support */}
                      </h3>
                      <p className="we_des">{aboutData?.thirdDescription}</p>
                      {/* <p className="we_des">
                        {aboutData?.thirdDescription?.slice(0, 100)}
                        {aboutData?.thirdDescription?.length > 100 && (
                          <span
                            className="description cr"
                            onClick={() =>
                              showReadMore(
                                aboutData?.thirdTitle,
                                aboutData?.thirdDescription
                              )
                            }
                          >
                            ...read more
                          </span>
                        )}
                      </p> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End WE ARE ALWAYES BEST */}

        {/* Start OUR MISSION */}
        <section className="mission-section common-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="title-text">OUR MISSION</h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-line skeleton-title mb-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line mb-5"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  <>
                    <p className="our_mi_des">
                      {aboutData?.ourMissionDescription?.slice(0, 800)}
                      {aboutData?.ourMissionDescription?.length > 800 && (
                        <span
                          className="description cr"
                          onClick={() =>
                            showReadMore(
                              "OUR MISSION",
                              aboutData?.ourMissionDescription
                            )
                          }
                        >
                          ...read more
                        </span>
                      )}
                    </p>
                  </>
                )}
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row mission-block">
                  <div className="col-md-6 col-6 margin-bottom">
                    {isLoading ? (
                      <div className="skeleton-loader mb-3">{box}</div>
                    ) : (
                      <>
                        <img
                          src={
                            aboutData?.ourMissionFirstImageUrl
                              ? `${Config.API_HOST_URL_live}${aboutData?.ourMissionFirstImageUrl}`
                              : child
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = child;
                          }}
                          className="img-fluid"
                          alt=""
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-6 margin-bottom">
                    {isLoading ? (
                      <div className="skeleton-loader mb-3">{box}</div>
                    ) : (
                      <>
                        <img
                          src={
                            aboutData?.ourMissionSecondImageUrl
                              ? `${Config.API_HOST_URL_live}${aboutData?.ourMissionSecondImageUrl}`
                              : catelog1
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = catelog1;
                          }}
                          className="img-fluid"
                          alt=""
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-6 margin-bottom">
                    {isLoading ? (
                      <div className="skeleton-loader mb-3">{box}</div>
                    ) : (
                      <>
                        <img
                          src={
                            aboutData?.ourMissionThirdImageUrl
                              ? `${Config.API_HOST_URL_live}${aboutData?.ourMissionThirdImageUrl}`
                              : catelog2
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = catelog2;
                          }}
                          className="img-fluid"
                          alt=""
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-6">
                    {isLoading ? (
                      <div className="skeleton-loader mb-3">{box}</div>
                    ) : (
                      <>
                        <img
                          src={
                            aboutData?.ourMissionFourthImageUrl
                              ? `${Config.API_HOST_URL_live}${aboutData?.ourMissionFourthImageUrl}`
                              : catelog
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = catelog;
                          }}
                          className="img-fluid"
                          alt=""
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End OUR MISSION */}

        {/* Start CONTACT US */}
        <section className="contactus-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 contact-block">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div className="skeleton-btn mb-4"></div>
                  </div>
                ) : (
                  <>
                    <Link to={"/contactus"} className="contact-btn">
                      CONTACT US
                    </Link>
                  </>
                )}
              </div>
              <div className="col-lg-8 col-md-12">
                {isLoading ? (
                  <div className="skeleton-loader align-items-center">
                    <div className="skeleton-line skeleton-title mb-4"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line "></div>
                    <div className="skeleton-line "></div>
                  </div>
                ) : (
                  <>
                    <h5>
                      {aboutData?.contactUsTitle}
                      {/* Need a consulting services? */}
                    </h5>
                    <p className="contect_des">
                      {aboutData?.contactUsDescription}
                    </p>
                    {/* <p className="contect_des">
                      {aboutData?.contactUsDescription?.slice(0, 235)}
                      {aboutData?.contactUsDescription?.length > 235 && (
                        <span
                          className="description cr"
                          onClick={() =>
                            showReadMore(
                              aboutData?.contactUsTitle,
                              aboutData?.contactUsDescription
                            )
                          }
                        >
                          ...read more
                        </span>
                      )}
                    </p> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* End CONTACT US */}

        {/* Start CORE VALUES */}
        <section className="core-section">
          <div className="container">
            <div className="row">
              <h2 className="title-text">CORE VALUES</h2>
              {isLoading ? (
                <>
                  {[...Array(4).keys()]?.map((x, i) => (
                    <div
                      className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3"
                      key={i}
                    >
                      <div className="skeleton-loader">{box}</div>
                    </div>
                  ))}
                </>
              ) : aboutData?.coreValuelst?.length > 0 ? (
                aboutData?.coreValuelst?.map((core, i) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3"
                    key={i}
                  >
                    <div className="value-box">
                      <div className="images">
                        <img
                          src={
                            core?.coreValuesUploadUrl
                              ? `${Config.API_HOST_URL_live}${core?.coreValuesUploadUrl}`
                              : properties
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = properties;
                          }}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="value-card">
                        <h3>{core?.coreValuesTitle}</h3>
                        <p>
                          {window.innerWidth === 1024 ? (
                            <>
                              {core?.coreValuesDescription}
                              {/* {core?.coreValuesDescription?.slice(0, 45)}
                            {core?.coreValuesDescription?.length > 45 && (
                              <span
                                className="description cr"
                                onClick={() =>
                                  showReadMore(
                                    core?.coreValuesTitle,
                                    core?.coreValuesDescription
                                  )
                                }
                              >
                                ...read more
                              </span>
                            )} */}
                            </>
                          ) : (
                            <>
                              {core?.coreValuesDescription}
                              {/* {core?.coreValuesDescription?.slice(0, 90)}
                            {core?.coreValuesDescription?.length > 90 && (
                              <span
                                className="description cr"
                                onClick={() =>
                                  showReadMore(
                                    core?.coreValuesTitle,
                                    core?.coreValuesDescription
                                  )
                                }
                              >
                                ...read more
                              </span>
                            )} */}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3>
                  Keep watching, we are getting some exciting things for you.
                </h3>
              )}
            </div>
          </div>
        </section>
        {/* End CORE VALUES */}
      </main>
      {/* ) : (
        ""
      )} */}
    </Suspense>
  );
}

export default About;
