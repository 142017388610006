import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../Action/Action";
import CardGrid from "../../Card_Grid";
import Config from "../../../Utils/Config";
import cardimg1 from "../../../images/course_not_found_final_1.png";
import { TabContext, TabList, } from "@mui/lab";
import { Tab, Box } from "@mui/material";
import "./index.scss";

function CourseList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { title, type } = props;
  const [courseList, setCourseList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const [values, setValues] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    // GetAllEventsList();
    // setIsLoading(true);
    // setTimeout(() => {
    //   getCourseLists();
    // }, 2000);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    getCourseLists();
    // eslint-disable-next-line
  }, [values]);

  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading").innerHTML = "";
  // }, []);

  // React.useEffect(() => {
  //   return () => clearInterval(intervalIDRef.current); // to clean up on unmount
  // }, []);
  const getCourseLists = async () => {
    setCourseList([]);
    setOpenCircle(true);
    setIsLoading(true);
    let CourseDetails = {
      keyArea: null,
      learningObjective: null,
      trainingTopics: null,
      courseCeu: null,
      instructorId: null,
      courseTypes: type,
      LogUserId: null,
      courseLable: values === "1"
        ? "FigCEUCourses"
        : values === "2"
          ? "PartnerContent"
          : values === "3"
            ? "GovernmentContent"
            : values === "4"
              ? "FIGFaves" : "",
      schoolDistrictId: "00000000-0000-0000-0000-000000000000",
      showAllDistricts: false,
    };
    let seData = {
      url: `/api/Course/GetCoursesByFiltersWithOutToken`,
      body: CourseDetails,
      headers: headers,
    };
    let responce = await PostCallApi(seData);
    setOpenCircle(false);
    setIsLoading(false);
    // stopTimer();
    if (responce.status === 200) {
      if (responce.data.success) {
        setOpenCircle(false);
        let data = responce.data.payload?.map((item) => {
          return {
            id: item?.courseId,
            cardTitle: item?.courseTitle,
            courseCeu:
              item?.courseCeu === null || item?.courseCeu === undefined
                ? ""
                : item?.courseCeu,
            des: item?.description,
            cate: item?.categories,
            // cardimg:
            //   item?.imageOrVideoUrl === "" || item?.imageOrVideoUrl === null
            //     ? cardimg1
            //     : `${Config.API_HOST_URL_live}${item?.imageOrVideoUrl}`,
            cardimg:
              item?.thumbnailCourseUrl === "" || item?.thumbnailCourseUrl === null
                ? cardimg1
                : `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`,
            startCompleted: item?.status === null ? "" : item?.status,
            lesson:
              item?.lessions === "" || item?.lessions === ""
                ? ""
                : item?.lessions + " Lessons",
            perCompleted: item?.percentageComplete,
            rating: item?.ratings ? item.ratings : "0",
            userName:
              item?.instructor === null ||
                item?.instructor?.fistName === "" ||
                item?.instructor?.fistName === null
                ? ""
                : item?.instructor?.firstName +
                " " +
                item?.instructor?.lastName,
            stepActive:
              item?.instructor === null ||
                item?.instructor?.siteLocation === null ||
                item?.instructor?.siteLocation === ""
                ? "-"
                : item?.instructor?.siteLocation,
          };
        });
        setCourseList(data);
      } else {
        setOpenCircle(false);
        setIsLoading(false);
        setCourseList([]);
      }
    }
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#86c65a", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section className="catalog-section common-padding courses_for_catalog">
        <div className="container">
          <div className="row">
            <div className="title_with_tabs">
              <h2 className="title">{title}</h2>
              {/* <TabContext value={values}>
                <Box
                  className="tab_group mb-4"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <TabList
                    className="comment_tab_button"
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Fig Content"
                      value="1"
                      className="tab_1"
                    />
                    <Tab label="Partner Content" value="2" className="tab_2" />
                    <Tab label="Goverment Content" value="3" className="tab_3" />
                    <Tab label="Fig Faves" value="4" className="tab_4" />
                  </TabList>
                </Box>
              </TabContext> */}
              <TabContext value={values}>
                <Box className="tab_group">
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="FIG Content"
                      value="1"
                      className="tab_1"
                    />
                    <Tab
                      label="Partner Content"
                      value="2"
                      className="tab_3"
                    />
                    <Tab
                      label="Government Content"
                      value="3"
                      className="tab_3"
                    />
                    <Tab
                      label="Fig Faves"
                      value="4"
                      className="tab_5"
                    />
                  </TabList>
                </Box>
              </TabContext>
            </div>
            {/* <div id="loading" className="h6_text text-center"></div> */}
            {/* {!openCircle ? <CardGrid data={courseList} pageSize={12} /> : ""} */}
            {isLoading ? (
              <>

                <div className="row">
                  <div className="col-md-3">
                    <div className="skeleton-loader">
                      {box}
                      <div className="skeleton-line skeleton-title mb-4 mt-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-4"></div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="skeleton-loader">
                      {box}
                      <div className="skeleton-line skeleton-title mb-4 mt-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-4"></div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="skeleton-loader">
                      {box}
                      <div className="skeleton-line skeleton-title mb-4 mt-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-4"></div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="skeleton-loader">
                      {box}
                      <div className="skeleton-line skeleton-title mb-4 mt-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-4"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              !openCircle ? <CardGrid data={courseList} pageSize={12} /> : ""
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default CourseList;
