import React, { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../../../Utils/Config.jsx";
import "./index.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const renderLoader = () => <p></p>;

function ViewQuizSubmitted() {
  const { courseid, userid, ayid } = useParams();
  const [usRepo, setUsRepo] = useState({});
  const [qId, setQId] = useState();
  const [quesDeatil, setQuesDeatil] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [couseDoc, setCouseDoc] = useState();
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (courseid && userid && ayid) {
      let cUrl = `courseId=${courseid}&userId=${userid}&academicYearId=${ayid}`;
      getReportData(cUrl);
      getDocData(cUrl);
      getProfileDetail(userid);
    }
    // eslint-disable-next-line
  }, [courseid, userid, ayid]);
  const getProfileDetail = (id) => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/MyProfile?userId=${id}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setUsRepo(response.data?.payload);
        }
      });
  };
  const getReportData = (cId) => {
    setOpen(true);

    axios
      .get(
        `${Config.API_HOST_URL_live}/api/QuizQuestion/GetQuizQuestionAnswerByID?${cId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          setOpen(false);
          let dashData = response?.data?.payload;
          setQuizList(dashData);
        } else {
          setOpen(false);
        }
      });
  };
  const getDocData = (cId) => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserCourseDocument/GetUserCourseDocument?${cId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          let dashData = response?.data?.payload;
          setCouseDoc(dashData);
        }
      });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const handleQuizDetail = (q) => {
    setQId(q);
    setQuesDeatil(true);
    if (q === qId) {
      setQuesDeatil(!quesDeatil);
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <section className="certificate_page main_backcolor quiz_report_detail">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="col-md-12 m-auto">
                <div className="certificate_wrapper">
                  <div className="title text-center mb-5 d-flex w-100 justify-content-between ">
                    <h1 className="m-auto w-100">Quiz Submitted </h1>
                  </div>

                  <div className="certificate_contect">
                    <div className="table-responsive mb-5">
                      <Table className="data_table">
                        <tbody>
                          <tr className="table_pading">
                            <td className="emp_name">Employee Name</td>
                            <td className="name">
                              {usRepo?.firstName + " " + usRepo?.lastName}
                            </td>
                            <td>School DistrictName</td>
                            <td className="name">
                              {usRepo?.schoolDistrictName}
                            </td>
                          </tr>
                          <tr className="table_pading">
                            <td>Hire Date</td>
                            <td className="name">
                              {usRepo?.joiningDate
                                ? convert(usRepo?.joiningDate?.split("T")[0])
                                : ""}
                            </td>
                            <td>Email</td>
                            <td className="name">{usRepo?.email}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <h3 className="text-center mb-4">
                      Course Name: {couseDoc?.courseName}{" "}
                    </h3>
                    <div className="cou_doc">
                      <h4 className="mb-3 ms-3">Course Work</h4>
                      <div className="row m-0">
                        {couseDoc?.material?.length > 0 ? (
                          couseDoc?.material?.map((doc, i) => (
                            <div className="col-md-4" key={i}>
                              <div className="doc_box">
                                <div>
                                  <div>
                                    <span className="num me-2">{i + 1}.</span>{" "}
                                    {doc?.documentTitle}
                                  </div>
                                </div>
                                <Link
                                  to={`${Config.API_HOST_URL_live}${doc?.documentPath}`}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="err_nodf">No Data Found</div>
                        )}
                      </div>
                    </div>
                    <div className="quiz_d_main_box">
                      <h4 className="mb-3 ms-3">Quiz</h4>
                      {quizList?.length > 0 ? (
                        quizList?.map((quiz, i) => (
                          <div className="quiz_d_box" key={i}>
                            <div
                              className="quiz_name_box"
                              onClick={() => handleQuizDetail(quiz?.quizId)}
                            >
                              <div className="name">
                                {i + 1}.{" "}
                                {quiz?.quizTitle ? quiz?.quizTitle : "Quiz"}
                              </div>
                              {quiz?.quizId === qId && quesDeatil ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </div>
                            {quiz?.quizId === qId && quesDeatil ? (
                              <div className="quiestion_main_box">
                                {quiz?.quizQuestions?.length > 0 ? (
                                  quiz?.quizQuestions?.map((que, ind) => (
                                    <div className="ques_box" key={ind}>
                                      <div className="ques_title">
                                        <b>
                                          {ind + 1}. {que?.questionTitle}
                                        </b>
                                      </div>
                                      <div className="ques_ans">
                                        {que?.questionTypeId === 1 ? (
                                          <div className="d-flex">
                                            {" "}
                                            <div
                                              className={`op_box me-2 ${
                                                que?.optionAnswer === "A" ||
                                                que?.optionAnswer === "a"
                                                  ? "orig"
                                                  : ""
                                              } ${
                                                que?.userAnswer === "A"
                                                  ? "u_a"
                                                  : ""
                                              }`}
                                            >
                                              A. {que?.option1}
                                            </div>
                                            <div
                                              className={`op_box me-2 ${
                                                que?.optionAnswer === "B" ||
                                                que?.optionAnswer === "n"
                                                  ? "orig"
                                                  : ""
                                              }  ${
                                                que?.userAnswer === "B"
                                                  ? "u_a"
                                                  : ""
                                              }`}
                                            >
                                              B. {que?.option2}
                                            </div>
                                            <div
                                              className={`op_box me-2 ${
                                                que?.optionAnswer === "C" ||
                                                que?.optionAnswer === "c"
                                                  ? "orig"
                                                  : ""
                                              }  ${
                                                que?.userAnswer === "C"
                                                  ? "u_a"
                                                  : ""
                                              }`}
                                            >
                                              C. {que?.option3}
                                            </div>
                                            <div
                                              className={`op_box ${
                                                que?.optionAnswer === "D" ||
                                                que?.optionAnswer === "d"
                                                  ? "orig"
                                                  : ""
                                              }  ${
                                                que?.userAnswer === "D"
                                                  ? "u_a"
                                                  : ""
                                              }`}
                                            >
                                              D. {que?.option4}
                                            </div>
                                          </div>
                                        ) : que?.questionTypeId === 2 ? (
                                          <div className={`op_box `}>
                                            User Ans. {que?.userAnswer}
                                          </div>
                                        ) : (
                                          <div className={`op_box `}>
                                            User Ans. {que?.userAnswer}
                                          </div>
                                        )}
                                        <div className="mt-2">
                                          <b>Original Ans: </b>
                                          {que?.optionAnswer}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="err_nodf">No Data Found</div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="err_nodf">No Data Found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
export default React.memo(ViewQuizSubmitted);
