import React, { useEffect, useState } from "react";
import "./index.scss";
import SmallPop from "../../../Reuseable_Components/Smallpop";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { SettingMenuList } from "../../../../reducers/setting";

function DisplayMenu() {
  const [options, setOptions] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const MenuList = useSelector((state) => state.setting?.MenuList);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();

  const MenuName = useSelector((state) => state.setting?.MenuName);
  const showSub = (event, item) => {
    if (item?.subMenu !== undefined) {
      setAnchorEl(event?.currentTarget);
      setOptions(item?.subMenu);
    } else {
      setAnchorEl();
      setOptions();
    }
  };
  useEffect(() => {
    if (MenuList?.length === 0 || MenuList === undefined) {
      getMenuList();
    }
    // eslint-disable-next-line
  }, []);
  const getMenuList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/AccessLevel/GetMenuHierarchical?userId=${LogUserId}&menuType=SettingsMenu`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch(SettingMenuList(response.data?.payload));
        }
      });
  };
  return (
    <div className="Settings_Menu_header d-flex ">
      {MenuList?.map((item, i) => (
        <div
          className={`menu_text ${
            item.subMenu?.find((data) => data?.menuName === MenuName)
              ? `linear ${MenuName} `
              : ""
          }`}
          key={i}
          onClick={(e) => showSub(e, item)}
        >
          {item.menuName}
          {item.subMenu !== undefined ? (
            <ArrowDropDownRoundedIcon></ArrowDropDownRoundedIcon>
          ) : (
            ""
          )}
        </div>
      ))}
      <SmallPop
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        optionsMenu={options}
        className={`Menu_icon_settings`}
      />
    </div>
  );
}

export default React.memo(DisplayMenu);
