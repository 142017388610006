import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import start_cou_img from "../../../../../images/Course_start_lesson_img_new.png";

function Quiz_start(props) {
  const { mainTitle, quizList, quizId, setQuizId } = props;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const { fields } = useFieldArray({
    control,
    name: "quizQuestion",
  });
  useEffect(() => {
    let quMain = [];
    quizList?.map((item) => {
      if (item?.quizId === quizId) {
        if (item?.quizQuestion?.length > 0) {
          quMain = item?.quizQuestion?.map((item) => {
            if (item?.questionTypeId === 1) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                option1: item?.option1,
                option2: item?.option2,
                option3: item?.option3,
                option4: item?.option4,
                ans_1: "",
              };
            } else if (item?.questionTypeId === 2) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                ans_1: "",
              };
            } else if (item?.questionTypeId === 3) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                ans_1: "",
              };
            }
            return "";
          });
        }
      }
      return "";
    });
    setValue("quizQuestion", quMain);
    // eslint-disable-next-line
  }, [quizId]);
  const onSubmit = (data) => {
    let cId = quizList?.map((item, i) => {
      if (item?.quizId === quizId) {
        return i + 1;
      } else return undefined;
    });
    cId = cId?.filter(function (element) {
      return element !== undefined;
    });
    let cqvId = quizList?.map((item, i) => {
      if (i === cId[0]) {
        return item?.quizId === undefined ? "" : item?.quizId;
      } else return undefined;
    });
    cqvId = cqvId?.filter(function (element) {
      return element !== undefined;
    });
    if (cqvId[0] !== undefined) {
      setQuizId(cqvId[0]);
    } else {
      setQuizId("");
    }
  };

  return (
    <>
      <div className="CourseRightStart_page">
        <div className="course_details_right_main_box">
          <div className="start_quiz_course">
            {quizList?.map((item, i) => {
              if (item?.quizId === quizId) {
                return (
                  <>
                    <div className="course_right_heading_box">
                      <div className="head_left">
                        <span>{mainTitle}</span>
                      </div>
                      <div className="head_right">
                        Quiz {i + 1} of {quizList?.length}
                      </div>
                    </div>

                    <div className="head_text p_16_text">
                      Please complete this assessment to be awarded your CEU.
                    </div>
                    <div className="quiz_question_box">
                      {fields?.map((item, index) => {
                        return (
                          <>
                            <h6
                              className={`h6_text ${index === 1 ? "mt-3" : ""}`}
                            >
                              {item?.question}
                            </h6>
                            {item?.choise === 1 ? (
                              <>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="A"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option1}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="B"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option2}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="C"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option3}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="D"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option4}</p>
                                </div>
                                {errors.quizQuestion?.[index]?.ans_1 && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.quizQuestion?.[index]?.ans_1
                                        .message
                                    }
                                  </span>
                                )}
                              </>
                            ) : item?.choise === 2 ? (
                              <>
                                <div className="form-group col-md-12 ">
                                  <textarea
                                    type="text"
                                    rows={5}
                                    className="form-control"
                                    placeholder="Long Answer Text"
                                    {...register(`quizQuestion.${index}.ans_1`)}
                                  />
                                  {errors.quizQuestion?.[index]?.ans_1 && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.quizQuestion?.[index]?.ans_1
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </>
                            ) : item?.choise === 3 ? (
                              <>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="true"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>True</p>
                                </div>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="false"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>False</p>
                                </div>{" "}
                                {errors.quizQuestion?.[index]?.ans_1 && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.quizQuestion?.[index]?.ans_1
                                        .message
                                    }
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                      <div className="text-end">
                        <button
                          className="quiz_finish_btn p_16_text mt-2"
                          onClick={handleSubmit(onSubmit)}
                        >
                          {quizList?.length - 1 === i
                            ? "Finish Quiz"
                            : "Next Quiz"}
                        </button>
                      </div>
                    </div>
                  </>
                );
              }
              return "";
            })}
            {quizId === "" ? (
              <img src={start_cou_img} alt="" className="img-fluid" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Quiz_start);
