import React, { Suspense, useState } from "react";
import Tab from "@mui/material/Tab";
import "./index.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ViewClosedTicket from "./ViewClosedTicket";
import CreateTicket from "./CreateTicket";
import ReplyTicket from "./ReplyTicket";
const renderLoader = () => <p></p>;

function Help() {
  const [values, setValue] = React.useState("2");
  const [replyT, setReplyT] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="help_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              {/* <div className="help_t_box">
                                <div className="help_title">HELP</div>
                            </div> */}
              <div className="help_content">
                <div className="card">
                  <div className="card-body">
                    <TabContext value={values}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          value="1"
                          className="tabs_btn"
                          label="Create A Ticket"
                        />
                        <Tab
                          value="2"
                          className="tabs_btn"
                          label="View/Reply Tickets"
                        />
                        <Tab
                          value="3"
                          className="tabs_btn"
                          label="Reply Ticket"
                        />
                      </TabList>
                      <TabPanel className="tab_pannel" value="1">
                        <CreateTicket changeTab={setValue} />
                      </TabPanel>
                      <TabPanel className="tab_pannel" value="2">
                        <ViewClosedTicket
                          changeTab={setValue}
                          setReplyT={setReplyT}
                        />
                      </TabPanel>
                      <TabPanel className="tab_pannel" value="3">
                        <ReplyTicket
                          changeTab={setValue}
                          replyT={replyT}
                          setReplyT={setReplyT}
                        />
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(Help);
