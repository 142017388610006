import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { DashboardRe } from "../../../../reducers/user";
import Config from "../../../../Utils/Config";

function DeleteCEUPopUp({ handleClose }) {
  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  let dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [ceuList, setCeuList] = useState([]);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    axios
      .delete(
        `${Config.API_HOST_URL_live}/api/Ceu/DeleteCEU?CEUId=${data.CEUId}&UserId=${data.userId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          handleClose();
          if (changeDash) {
            dispatch(DashboardRe(false));
          } else {
            dispatch(DashboardRe(true));
          }
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      });
  };
  useEffect(() => {
    getUerList();
    // eslint-disable-next-line
  }, []);
  const getUerList = () => {
    let url = `${Config.API_HOST_URL_live}/api/User/UserList?userId=${LogUserId.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value:
                item?.userId === "" || item?.userId === null ? "" : item.userId,
              label:
                item?.firstName === "" ||
                item?.firstName === null ||
                item?.lastName === "" ||
                item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
            };
          });
          data.sort((a, b) => {
            const nameA = a.label?.toString()?.toLowerCase();
            const nameB = b.label?.toString()?.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setUserList(data);
        }
      })
      .catch((error) => console.log(error));
  };
  const getCEUList = (id) => {
    let url = `${Config.API_HOST_URL_live}/api/Ceu/GetCEUDataByUserId?userId=${id}&createdBy=${LogUserId.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              ceuId: item.ceuId,
              ceuTitle: item.ceuTitle,
            };
          });
          setCeuList(data);
        } else {
          setCeuList([]);
        }
      })
      .catch((error) => console.log(error));
  };

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <div className="tab_panel delete_ceu">
      <div className="tab_panel_input_mt mt-0">
        <Select
          name="users"
          options={userList}
          className="course-caretory-multi-select p_14_text user-selecting"
          classNamePrefix="select"
          placeholder="Users"
          value={userList.filter((obj) =>
            getValues("userId")?.includes(obj.value)
          )}
          {...register("userId", {
            required: {
              value: true,
              message: "This field is required",
            },
          })}
          onChange={(e) => {
            setValue("userId", e.value);
            getCEUList(e.value);
          }}
        />
        {errors.userId && (
          <span role="alert" className="error_text">
            {errors.userId.message}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt ">
        <select
          className="form-select dropdown_group"
          aria-label="Default select example"
          {...register("CEUId", {
            required: {
              value: true,
              message: "This field is required",
            },
          })}
        >
          <option value=""> Select CEU</option>
          {ceuList &&
            ceuList?.map((item, i) => (
              <option key={i} value={item.ceuId}>
                {item.ceuTitle}
              </option>
            ))}
        </select>
        {errors.CEUId && (
          <span role="alert" className="error_text">
            {errors.CEUId.message}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt delete_btn_box">
        <button
          type="button"
          className="popup_btn delete"
          onClick={handleSubmit(onSubmit)}
        >
          Delete
        </button>
        <button
          type="button"
          className="popup_btn cancel"
          onClick={handleClose}
        >
          cancel
        </button>
      </div>
    </div>
  );
}

export default React.memo(DeleteCEUPopUp);
