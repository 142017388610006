import React, { Suspense, useState } from "react";
import "./index.scss";
import { Box, Modal } from "@mui/material";
// import { Autocomplete, Box, Button, TextField, Modal } from "@mui/material";
// import CommunityFeed from "../CommunityGroups/CommunityFeed";
// import { useSelector } from "react-redux";
// import Config from "../../../Utils/Config";
// import axios from "axios";
// import backimg12 from "../../../images/group_card_back12.png";
// import pro_img from "../../../images/default_profile.jpg";
// import lockOpen_icon from "../../../images/Icon/lock-open.png";
import all_icon from "../../../images/Icon/all_icon.png";
import MemberList from "../CommunityGroups/CommunityFeed/MemberList";
import CommunityFeedNew from "../CommunityGroups/CommunityFeedNew";

const renderLoader = () => <p></p>;

function NewCommunity() {
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  // const LogUserde = useSelector((state) => state.login?.LoginDetails);
  // const [grpList, setGrpList] = useState([]);
  // const [grpId, setGrpId] = useState({
  //   value: "1",
  //   label: "All",
  //   img: all_icon,
  // });
  const grpId = {
    value: "1",
    label: "All",
    img: all_icon,
  };
  // const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };
  // useEffect(() => {
  //   getGrpList();
  //   // eslint-disable-next-line
  // }, []);
  // const getGrpList = () => {
  //   let url = "";
  //   if (LogUserde.roleId === 1) {
  //     url = `${Config.API_HOST_URL_live}/api/CommunityGroups/CommunityGroupList`;
  //   } else {
  //     url = `${Config.API_HOST_URL_live}/api/CommunityGroups/GetMyCommunityGroupData?UserId=${LogUserId}`;
  //   }
  //   axios.get(url, { headers }).then((response) => {
  //     if (response.data.success) {
  //       const cgList = response.data.payload?.map((item) => {
  //         return {
  //           value: item?.communityGroupId,
  //           label: item?.communityGroupName,
  //           img:
  //             item?.groupImageUrl === "" || item?.groupImageUrl === null
  //               ? pro_img
  //               : `${Config.API_HOST_URL_live}${item?.groupImageUrl}`,
  //           backimg:
  //             item?.groupCoverImage === "" || item?.groupCoverImage === null
  //               ? backimg12
  //               : `${Config.API_HOST_URL_live}${item?.groupCoverImage}`,
  //           organizerUserId: item?.organizerUserId,
  //         };
  //       });
  //       let main = [
  //         { value: "1", label: "All", img: all_icon },
  //         {
  //           value: "3",
  //           label: "My Connections",
  //           img: lockOpen_icon,
  //         },
  //         ...cgList,
  //       ];
  //       setGrpId(main[0]);
  //       setGrpList(main);
  //     } else {
  //       let main = [
  //         { value: "1", label: "All", img: all_icon },

  //         {
  //           value: "3",
  //           label: "My Connections",
  //           img: lockOpen_icon,
  //         },
  //       ];
  //       setGrpList(main);

  //       setGrpId(main[0]);
  //       // setOpen(false);
  //     }
  //   });
  // };
  const searchField = "";
  // const [searchField, setSearchField] = useState();
  const [viewMemberList, SetViewMemberList] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const handleClose = () => {
    SetViewMemberList(false);
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="new_community_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              {/* <div className="community_title">Community</div> */}
              <div className="community_wrapper">
                <div className="row">
                  <CommunityFeedNew
                    comGroupId={
                      grpId?.value === "1" || grpId?.value === "3"
                        ? "00000000-0000-0000-0000-000000000000"
                        : grpId?.value
                    }
                    pvtId={
                      grpId?.value === "1" ? 1 : grpId?.value === "3" ? 3 : 2
                    }
                    searchField={searchField}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      {viewMemberList && (
        <Modal
          open={viewMemberList}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_member_list"
        >
          <Box sx={style} className={`popup_wh col-xl-4 col-lg-4 col-md-12`}>
            <MemberList
              comGroupId={
                grpId?.value === "1" || grpId?.value === "3"
                  ? "00000000-0000-0000-0000-000000000000"
                  : grpId?.value
              }
            />
          </Box>
        </Modal>
      )}
    </>
  );
}
export default React.memo(NewCommunity);
