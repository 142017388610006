import React, { useState, useEffect } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CreteDataFor } from "../../../../reducers/user";

function Demonstration({ editId }) {
  const [openCircle, setOpenCircle] = useState();
  const [stateList, setStateList] = useState();

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const { register, setValue } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getState();
    // eslint-disable-next-line
  }, []);

  const getState = async () => {
    let seData = {
      url: `/api/Common/GetStatesList`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data?.payload);
      }
    }
  };
  useEffect(() => {
    if (editId) {
      getDemonData(editId);
    }
    // eslint-disable-next-line
  }, [editId]);
  const getDemonData = async (id) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/RequestDemonstration/GetRequestDemonstrationById?requestDemonstrationId=${id}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let demo = res.data.payload;
        setValue("schoolDistrictName", demo?.schoolDistrictName);
        setValue("stateId", demo?.stateId);
        setValue("noOfSchool", demo?.noOfSchool);
        setValue("noOfEmployee", demo?.noOfEmployee);
        setValue("firstName", demo?.firstName);
        setValue("lastName", demo?.lastName);
        setValue(
          "prefferedDate",
          demo?.prefferedDate ? demo?.prefferedDate.split("T")[0] : ""
        );
        setValue("bestTimeOfDay", demo?.bestTimeOfDay);
        setValue("phoneMobile", demo?.phoneMobile);
        setValue("email", demo?.email);
        setValue("questionOfComments", demo?.questionOfComments);
      }
    }
  };

  const handleClose = () => {
    dispatch(CreteDataFor(false));
  };
  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="create_hed">
        <h3>REQUEST A DEMONSTRATION</h3>
      </div>
      <form action="" className="create_school_form">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="form-label">District Name</label>
            <input
              type="text"
              className="form-control"
              disabled
              placeholder="District Name"
              {...register("schoolDistrictName")}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-label">State</label>
            <select className="form-select" disabled {...register("stateId")}>
              <option value="">Select State</option>
              {stateList?.map((st, i) => (
                <option value={st.stateId} key={i}>
                  {st?.stateCode} - {st?.stateName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label className="form-label">Number of Schools</label>
            <input
              min={0}
              type="number"
              disabled
              className="form-control"
              placeholder="Number of Schools"
              {...register("noOfSchool")}
            />
          </div>{" "}
          <div className="form-group col-md-6">
            <label className="form-label">Number of Employees</label>
            <input
              min={0}
              type="number"
              disabled
              className="form-control"
              placeholder="Number of Employees"
              {...register("noOfEmployee")}
            />
          </div>{" "}
          <div className="form-group col-md-6">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              disabled
              {...register("firstName")}
            />
          </div>{" "}
          <div className="form-group col-md-6">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              disabled
              {...register("lastName")}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-label">Preferred Date</label>
            <input
              type="date"
              disabled
              className="form-control"
              {...register("prefferedDate")}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-label">Best Time of Day</label>
            <select
              className="form-select"
              disabled
              {...register("bestTimeOfDay")}
            >
              <option value="">Select Best Time of Day</option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
              <option value="News">News</option>
            </select>
          </div>{" "}
          <div className="form-group col-md-6">
            <label className="form-label">Phone/Mobile</label>
            <input
              type="text"
              className="form-control"
              placeholder="Phone/Mobile"
              disabled
              {...register("phoneMobile")}
            />
          </div>{" "}
          <div className="form-group col-md-6">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              disabled
              {...register("email")}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="form-label">Questions or Comments</label>
            <textarea
              className="form-control"
              placeholder="Questions or Comments"
              rows="5"
              disabled
              {...register("questionOfComments")}
            ></textarea>
          </div>
          <div className="form-group col-md-12 d-flex border-0 px-3">
            <Button
              className="cancel_btn cource_reschedule_button btn_space"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Demonstration;
