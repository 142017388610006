import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import Config from "../../Utils/Config";
import Pagination from "../../Modules/Reuseable_Components/Paginations/Pagination";
// import pro_img from "../../images/fig_CROP.png";
import doc from "../../images/docs.png";
import pdf from "../../images/pdf.png";
import ppt from "../../images/ppt.png";
import sheet from "../../images/sheet.png";
// import Sch_logo from "../../images/Icon/ep_school.svg";
// import pro_img from "../../../images/default_profile.jpg";
import { Link } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import { Autocomplete, TextField } from "@mui/material";

const renderLoader = () => <p></p>;

function MainFreeSources() {
  const [searchField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [ceu_select, setCeu_select] = useState();
  const [ceu_Lear, setCeu_Lear] = useState();
  const [ceu_Trai, setCeu_Trai] = useState();
  const [ceu_Instructor, setCeu_Instructor] = useState();
  const [courseCodeList, setCourseCodeList] = useState([]);
  const [courseLearList, setCourseLearList] = useState([]);
  const [courseTraiList, setCourseTraiList] = useState([]);
  const [insList, setInsList] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [sourcesList, setSourcesList] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const headCells = ["author", "title", "schoolDistrictName"];
  const formattedSearchResults = sourcesList?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  useEffect(() => {
    setIsLoading(true);
    getCategory();
    getAuthor();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getSourcesList(ceu_select, ceu_Lear, ceu_Trai, ceu_Instructor);
    // eslint-disable-next-line
  }, [ceu_select, ceu_Lear, ceu_Trai, ceu_Instructor]);
  const getSourcesList = async (keyAr, lear, trai, inst) => {
    let sendData = {
      keyArea: keyAr?.value ? keyAr?.value : null,
      learningObjective: lear?.value ? lear?.value : null,
      trainingTopics: trai?.value ? trai?.value : null,
      authorId: inst?.value ? inst?.value : null,
      isWithOutToken: true,
    };
    let seData = {
      url: `/api/Resources/GetAllResourcesWithoutToken`,
      body: sendData,
      headers: headers,
    };
    let response = await PostCallApi(seData);
    setIsLoading(false);
    if (response?.status === 200) {
      if (response.data.success) {
        const data = response?.data.payload?.map((item) => {
          let url = item?.documentData[0]?.documentUrl?.split(".");
          url = item?.documentData?.length > 0 ? url[url?.length - 1] : "";
          let checkType =
            url === "doc" || url === "docx"
              ? doc
              : url === "pdf"
              ? pdf
              : url === "pptx"
              ? ppt
              : url === "xls" || url === "xlsx"
              ? sheet
              : `${Config.API_HOST_URL_live}${item?.documentData[0]?.documentUrl}`;
          return {
            ...item,
            Logo:
              item?.documentData?.length > 0 &&
              item?.documentData[0]?.documentUrl
                ? checkType
                : doc,
          };
        });
        setSourcesList(data);
      } else {
        setSourcesList();
      }
    }
  };
  const getCategory = async () => {
    let seData = { url: `/api/Resources/GetResourcesCategoryList` };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const listdata = res.data;
        const main = listdata.mainCategory?.map((item) => {
          return {
            value: item?.courseCategoryId,
            label: item?.courseCategoryCode + " - " + item?.courseCategoryName,
          };
        });
        const lear = listdata.learningObjective?.map((item) => {
          return {
            value: item?.courseCategoryId,
            label: item?.courseCategoryCode + " - " + item?.courseCategoryName,
          };
        });
        const trai = listdata.trainingTopic?.map((item) => {
          return {
            value: item?.courseCategoryId,
            label: item?.courseCategoryCode + " - " + item?.courseCategoryName,
          };
        });

        setCourseCodeList(main);
        setCourseLearList(lear);
        setCourseTraiList(trai);
      }
    }
  };
  const getAuthor = async () => {
    let seData = { url: `/api/Resources/GetAuthorData` };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const listdata = res.data;
        const ins = listdata?.payload
          ?.filter((x) => x?.authorId !== null)
          ?.map((item) => {
            return {
              value: item?.authorId,
              label: item?.authorName,
            };
          });
        setInsList(ins);
      }
    }
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="mainFreeSources_page main_baclcolor">
          <div className="container">
            <div className="FreeSources_page_box">
              <div className="listdata_control_sec  ">
                <div className="title">FREE Sources List</div>
              </div>
              <div className="free_sources">
                {isLoading ? (
                  <div className="row">
                    {[...Array(4)?.keys()]?.map((x, i) => (
                      <div className="col-md-3" key={i}>
                        <div className="skeleton-loader">
                          {box}
                          <div className="skeleton-line skeleton-title mb-4 mt-4"></div>
                          <div className="skeleton-line"></div>
                          <div className="skeleton-line"></div>
                          <div className="skeleton-line"></div>
                          <div className="skeleton-line mb-4"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="search_control mt-3 mb-4">
                        <Autocomplete
                          disablePortal
                          options={courseCodeList}
                          className="me-3 search"
                          sx={{ width: 200 }}
                          value={ceu_select?.label}
                          onChange={(e, newValue) => {
                            setCeu_select(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Key Area" />
                          )}
                        />
                        <Autocomplete
                          disablePortal
                          options={courseLearList}
                          className="me-3 search"
                          sx={{ width: 200 }}
                          value={ceu_Lear?.label}
                          onChange={(e, newValue) => {
                            setCeu_Lear(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Learning Objective"
                            />
                          )}
                        />
                        <Autocomplete
                          disablePortal
                          options={courseTraiList}
                          className="me-3 search"
                          sx={{ width: 200 }}
                          value={ceu_Trai?.label}
                          onChange={(e, newValue) => {
                            setCeu_Trai(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Training Topic"
                            />
                          )}
                        />
                        <Autocomplete
                          disablePortal
                          options={insList}
                          className="me-3 search"
                          sx={{ width: 200 }}
                          value={ceu_Instructor?.label}
                          onChange={(e, newValue) => {
                            setCeu_Instructor(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Author" />
                          )}
                        />
                      </div>
                      {formattedSearchResults &&
                        formattedSearchResults
                          ?.slice(
                            (currentPage - 1) * pageSize,
                            (currentPage - 1) * pageSize + pageSize
                          )
                          ?.map((reso, i) => (
                            <div
                              className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3"
                              key={i}
                            >
                              <Link
                                className="view_btn"
                                to={`/free/${reso.resourcesId}`}
                              >
                                <div className="district_box">
                                  <div className="d-flex">
                                    <div className="img_box w-25">
                                      <img
                                        // src={`${Config.API_HOST_URL_live}${reso?.schoolDistrictLogo}`}
                                        src={`${Config.API_HOST_URL_live}${reso?.thumbnailLogoUrl}`}
                                        alt=""
                                        className="img-fluid"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = doc;
                                        }}
                                        width={60}
                                        height={60}
                                      />
                                      {/* <img
                                    src={reso?.Logo}
                                    alt=""
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = doc;
                                    }}
                                  /> */}
                                    </div>
                                    <div className="details w-75 ms-3">
                                      <div className="d_boc">
                                        <div className="d_name">
                                          {reso?.title}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="details">
                                    <div className="d_boc">
                                      {/* <div className="d_name">
                                        {reso?.title}
                                      </div> */}
                                      <div className="txt">
                                        {/* Contributed By: */}
                                        {reso?.authorName}
                                      </div>
                                      <div className="txt email">
                                        {/* <img
                                          src={`${Config.API_HOST_URL_live}${reso?.schoolDistrictLogo}`}
                                          alt=""
                                          className="me-2"
                                          width={20}
                                          height={20}
                                        /> */}
                                        {/* <img
                                          src={Sch_logo}
                                          alt=""
                                          className="me-2"
                                        />{" "} */}
                                        {reso?.schoolDistrictName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                    </div>
                    {formattedSearchResults?.length === 0 ? (
                      <div className="text-center my-3 p_20_text">
                        Keep watching, we are getting some exciting things for
                        you.
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="page_nation">
                      <div className="rowsPerPageOptions">
                        <span className="p_18_text">show</span>
                        <select
                          className="pagination_select p_16_text"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                        <span className="p_18_text">entries</span>
                      </div>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={formattedSearchResults?.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MainFreeSources;
