import React, { useEffect, useState } from "react";
import "./index.scss";
import star_full from "../../../../images/category_star_full.png";
import star_blank from "../../../../images/category_star_blank.png";
import review_user from "../../../../images/review_user_img.png";
import Rating from "react-rating";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import cardimg1 from "../../../../images/fig_CROP.png";
import cardimg2 from "../../../../images/default_profile.jpg";

function CourceReviews(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [review_data, setReview_data] = useState([]);
  useEffect(() => {
    // startTimer();
    setIsLoading(true);
    setTimeout(() => {
      getReviewList();
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getReviewList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CourseReview/GetCourseReviewsByCourseIdWithoutToken?courseId=${id}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          let rData = response.data.payload?.map((item) => {
            return {
              id: item.courseId,
              // img:
              //   item?.createdBy?.profileUrl === "" ||
              //   item?.createdBy?.profileUrl === null ||
              //   item?.createdBy?.profileUrl === undefined
              //     ? review_user
              //     : `${Config.API_HOST_URL_live}${item.createdBy?.profileUrl}`,
              img:
                item?.createdBy?.thumbnailProfileUrl === "" ||
                  item?.createdBy?.thumbnailProfileUrl === null ||
                  item?.createdBy?.thumbnailProfileUrl === undefined
                  ? review_user
                  : `${Config.API_HOST_URL_live}${item.createdBy?.thumbnailProfileUrl}`,
              name:
                item?.createdBy?.firstName === "" ||
                item?.createdBy?.firstName === null ||
                item?.createdBy?.firstName === undefined
                  ? ""
                  : item?.createdBy?.firstName +
                    " " +
                    item?.createdBy?.lastName,
              rate: item?.rating ? item?.rating : 0,
              time:
                item?.createdOn === "" || item?.createdOn === null
                  ? ""
                  : convert(item?.createdOn?.split("T")[0]),
              des: item?.ratingComments ? item?.ratingComments : "",
            };
          });
          setReview_data(rData);
          setIsLoading(false);
        }
      });
  };
  function convert(str) {
    var date = new Date(str),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day + ",", date.getFullYear()].join(" ");
  }

  return (
    <div className="cource_review_page">
      <div className="couce_reviews_details_box">
        <h3 className="reviews_title">Reviews</h3>
        <div className="review_show_detail_box">
          {review_data &&
            review_data?.map((item, i) => (
              <div className="user_detail_box" key={i}>
                <div className="img_box">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-round"></div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={item?.img}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = cardimg2;
                        }}
                        alt=""
                        className="img-fluid user_r_img"
                      />
                    </>
                  )}
                </div>

                <div className="rate_text_boxes">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line mb-3"></div>
                    </div>
                  ) : (
                    <>
                      <h2 className="review_name">{item?.name}</h2>
                      <div className="rating_stars">
                        <Rating
                          emptySymbol={
                            <img
                              src={star_blank}
                              alt="rating_blank"
                              height={18}
                            />
                          }
                          fullSymbol={
                            <img
                              src={star_full}
                              alt="rating-full"
                              height={18}
                            />
                          }
                          initialRating={item.rate ? item.rate : "0"}
                          readonly
                        />
                        <h6>{item?.time}</h6>
                      </div>
                      <div className="review_des">{item?.des}</div>
                    </>
                  )}
                  <div className="review_help">
                    <h6>Was this review helpful?</h6>
                  </div>
                </div>
              </div>
            ))}
          {review_data.length > 0 ? (
            ""
          ) : (
            <label className="no_one_rate">No Ratings</label>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CourceReviews);
