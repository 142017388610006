import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
    Box,
    Paper,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    Modal,
    CircularProgress,
    IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/system";
import Header_logo_1 from '../../images/Header_logo_1.png';
import MotionType from "./MotionType";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { RiSendPlane2Line } from "react-icons/ri";


const GlobalStyle = `
  @keyframes slideUp {
    from {
      right: -600px;
       opacity: 0;
    }
    to {
      right: 0;
       opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      right: 0;
       opacity: 1;
    }
    to {
      right: -600px;
       opacity: 0;
    }
  }
`;

const ChatContainer = styled(Box)(({ open, psNameMessage }) => ({
    position: "fixed",
    width: "350px",
    height: "100%",
    // bottom: psNameMessage ? "160px" : 0,
    // bottom: open ? "100px" : "-600px",
    overflow: "auto",
    right: open === true ? "0px" : "-600px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff", // Dark background
    // backgroundColor: "#1c1c1c", // Dark background
    boxShadow: "-2px 0 5px rgba(0,0,0,0.5)",
    zIndex: 1000,
    opacity: open ? 1 : 0,
    animation: open === true ? 'slideUp 0.5s ease-out' : open === false ? 'slideDown 0.5s ease-out' : "",
    borderRadius: "12px 0 0 12px"
    // transition: "bottom 0.5s ease-in-out",
    // transition: "bottom 0.3s ease",
}));

const ChatHeader = styled(Box)({
    padding: "16px",
    // borderBottom: "1px solid #333",
    backgroundColor: "#1ebec8", // Slightly lighter dark
    textAlign: "center",
});

const Logo = styled("img")({
    borderRadius: "12px",
    width: "80px",
    height: "80px",
    objectFit: "contain",
    // padding: 5,
    marginBottom: "8px",
    border: "2px solid #ffffff",
    backgroundColor: "#ffffff",
});

const SmallLogo = styled("img")({
    borderRadius: "5px",
    width: "35px",
    height: "35px",
    objectFit: "contain",
    // padding: 5,
    border: "2px solid #ffffff",
    backgroundColor: "#ffffff",
});

const ChatMessages = styled(Box)({
    flex: 1,
    overflowY: "auto",
    padding: "16px",
    color: "#1c1c1c", // Light text
});

const ChatInput = styled(Box)({
    padding: "16px",
    borderTop: "1px solid #dadada",
    backgroundColor: "#ffffff",
    display: "flex",
});

const languageOptions = {
    English: ["English (US)", "English (UK)"],
    Español: [
        "Español (México)",
        "Español (España)",
        "Español (Colombia)",
        "Español (Argentina)",
        "Español (Caribe)",
    ],
    "中文": ["普通话 (Mandarin)", "廣東話 (Cantonese)"],
    "Tiếng Việt": ["Tiếng Việt Bắc Bộ", "Tiếng Việt Nam Bộ"],
    हिन्दी: ["हिन्दी", "தமிழ்"],
    فارسی: ["فارسی"],
    Português: ["Português (Brasil)", "Português (Portugal)"],
    Kriolu: ["Kriolu (Cabo Verde)"],
    "Kreyòl Ayisyen": ["Kreyòl Ayisyen (Haiti)"],
    "Patois Jamaican": ["Patois Jamaican"],
    Morisyen: ["Morisyen (Mauritius)"],
    Seselwa: ["Seselwa (Seychelles)"],
};

const SidebarChat = ({ onClose, isOpen, psNameMessage }) => {
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [selectedDialect, setSelectedDialect] = useState(languageOptions["English"][0]);
    const [message, setMessage] = useState("");
    const [chatHistory, setChatHistory] = useState([{ sender: "bot", message: "Hello! I’m currently trained to provide guidance and support for the FIGK-12 Platform and insights on USDA nutrition policies, GDSN Products and USDA Food Data Central product information. How can I assist you today?" }]);
    const [isThinking, setIsThinking] = useState(false);
    const [step, setStep] = useState(1);
    const chatRef = useRef(null);
    const userChatPreference = JSON.parse(sessionStorage.getItem("userChatPreference"));
    const apiKey = process.env.REACT_APP_OPENAI_KEY;


    useEffect(() => {
        if (userChatPreference?.language) {
            setStep(2)

        }
    }, [userChatPreference])

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const handleLanguageChange = (event) => {
        const language = event.target.value;
        setSelectedLanguage(language);
        setSelectedDialect(languageOptions[language][0]);
    };

    const handleDialectChange = (event) => {
        setSelectedDialect(event.target.value);
    };

    const handleSendMessage = async () => {
        if (message.trim() === "") return;

        const userMessage = { sender: "user", message };
        setChatHistory((prev) => [...prev, userMessage]);
        setMessage("");
        startTimer()
        setIsThinking(true);
        const botResponse = await getChatbotResponse(message, selectedLanguage, selectedDialect, apiKey);
        const botMessage = { sender: "bot", message: botResponse };
        setChatHistory((prev) => [...prev, botMessage]);
        setIsThinking(false);
        stopTimer()
    };

    const getChatbotResponse = async (userMessage, language, dialect, apiKey) => {
        const systemPrompt = `You are an assistant proficient in ${language}, dialect ${dialect}. You are programmed with the latest USDA, NSLP, CACFP regulations, meal planning guidance, USDA-compliant guidelines for reimbursable meals, policy, and GDSN product information. Provide responses accordingly.`;

        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-4",
                    messages: [
                        { role: "system", content: systemPrompt },
                        { role: "user", content: userMessage },
                    ],
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            );

            return response.data.choices[0]?.message?.content || "No response.";
        } catch (error) {
            console.error("Error fetching response:", error);
            return "Sorry, there was an error processing your request.";
        }
    };

    const handleCopy = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => console.log("Response copied to clipboard!"))
                .catch(err => console.error("Clipboard write failed", err));
        } else {
            // Fallback for unsupported browsers
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            console.log("Response copied to clipboard!");
        }
    };
    const intervalIDRef = React.useRef(null);

    const startTimer = React.useCallback(() => {
        var count = 0;
        intervalIDRef.current = setInterval(() => {
            document.getElementById("loading").innerHTML =
                "Analysing" + new Array((++count % 4) + 1).join(".");
        }, 500);
    }, []);

    const stopTimer = React.useCallback(() => {
        clearInterval(intervalIDRef.current);
        document.getElementById("loading").innerHTML = "";
    }, []);

    const handleSavePreference = () => {
        const userPre = {
            language: selectedLanguage,
            dialect: selectedDialect
        }
        sessionStorage.setItem("userChatPreference", JSON.stringify(userPre))
        setStep(2)
    }
    const handleStep1 = () => {
        setChatHistory([{ sender: "bot", message: "Hello! I’m currently trained to provide guidance and support for the FIGK-12 Platform and insights on USDA nutrition policies, GDSN Products and USDA Food Data Central product information. How can I assist you today?" }])
        sessionStorage.removeItem("userChatPreference");
        setStep(1)
    }
    const ref = useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return (
        <>
            <style>{GlobalStyle}</style>
            <ChatContainer className="sidebar-chat" open={isOpen} psNameMessage={psNameMessage} firstRender={firstRender}>
                {step === 1 ?
                    <>
                        <ChatHeader>
                            <Logo src={Header_logo_1} alt="FIG K12 Logo" />
                            <Typography variant="h6" style={{
                                fontWeight: "bold",
                                fontFamily: "inherit",
                                color: "#ffffff",

                            }}>
                                FIG Chat Assistant
                            </Typography>
                            <Typography variant="body2" style={{
                                fontFamily: "inherit",
                                color: "#ffffff",
                            }}>
                                This AI assistant is programmed with the latest USDA, NSLP, CACFP regulations, meal planning guidance, and USDA-compliant guidelines for reimbursable meals.
                            </Typography>
                        </ChatHeader>
                        <Box p={2}>
                            <FormControl fullWidth margin="normal">
                                <Select
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                    variant="outlined"
                                    sx={{
                                        // color: "#ffffff",
                                        fontFamily: "inherit",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#ffffff",
                                            },
                                        },
                                    }}
                                >
                                    {Object.keys(languageOptions).map((language) => (
                                        <MenuItem sx={{
                                            fontFamily: "inherit"
                                        }} key={language} value={language}>
                                            {language}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <Select
                                    value={selectedDialect}
                                    onChange={handleDialectChange}
                                    variant="outlined"
                                    sx={{
                                        // color: "#ffffff",
                                        fontFamily: "inherit",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#ffffff",
                                            },
                                        },
                                    }}
                                >
                                    {languageOptions[selectedLanguage].map((dialect) => (
                                        <MenuItem sx={{
                                            fontFamily: "inherit"
                                        }} key={dialect} value={dialect}>
                                            {dialect}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ padding: "16px" }}>
                            <Button
                                variant="contained" color="primary"
                                onClick={handleSavePreference} sx={{
                                    // mt: 1,
                                    width: "100%",
                                    background: "linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%)",

                                }}
                                disabled={isThinking}
                            >
                                Start Chat
                            </Button>
                            <Button sx={{
                                mt: 1, width: "100%",
                                color: "#1ebec8",
                                border: "1px solid #1ebec8"
                            }}
                                variant="text" color="secondary"
                                onClick={onClose}>
                                Close
                            </Button>
                        </Box>
                    </>
                    :
                    <>
                        <ChatHeader display="flex" alignItems={"center"} sx={{ textAlign: "left" }} >
                            <Box marginRight={1} onClick={handleStep1} sx={{ cursor: "pointer" }}>

                                <MdOutlineArrowBackIosNew fontSize={20} color="#ffffff" />
                            </Box>
                            <SmallLogo src={Header_logo_1} alt="FIG K12 Logo" />
                            <Box marginLeft={1}>
                                <Typography variant="body2" style={{
                                    fontFamily: "inherit",
                                    color: "#ffffff",
                                    lineHeight: 1,

                                }}>
                                    Fig Chat
                                </Typography>
                                <Typography variant="caption" style={{
                                    fontFamily: "inherit",
                                    color: "#ffffff",

                                }}>
                                    AI Assistant
                                </Typography>
                            </Box>
                            <Box marginRight={1} marginLeft={"auto"} onClick={onClose} sx={{ cursor: "pointer" }}>

                                <RxCross2 color="#ffffff" fontSize={25} />
                            </Box>
                        </ChatHeader>
                        <ChatMessages ref={chatRef}>
                            {chatHistory.map((chat, index) => (
                                <Box key={index} mb={2} display="flex" position={"relative"} justifyContent={chat.sender === "user" ? "flex-end" : "flex-start"}>
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            padding: "8px 12px",
                                            background: chat.sender === "user" ? "linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%)" : "#f9f9fc",
                                            color: chat.sender === "user" ? "#ffffff" : "#000",
                                            border: chat.sender === "user" ? "" : "1px solid gainsboro",
                                            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
                                            maxWidth: "90%",
                                            position: "relative",
                                            // fontFamily: "inherit",
                                        }}
                                    >
                                        {chat.sender === "bot" ?
                                            <MotionType text={chat.message} />
                                            :


                                            chat.message}
                                        {/* {chat.sender === "bot" && (
                                <IconButton
                                    onClick={() => handleCopy(chat.message)}
                                    size="small"
                                    sx={{
                                        position: "absolute",
                                        top: 4,
                                        right: 4,
                                        color: "#010101",
                                    }}
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            )} */}
                                    </Paper>
                                    {chat.sender === "bot" && (
                                        <IconButton
                                            onClick={() => handleCopy(chat.message)}
                                            size="small"
                                            sx={{
                                                position: "absolute",
                                                top: 4,
                                                right: 0,
                                                color: "#010101",
                                            }}
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                            {isThinking &&
                                <Box mb={2} display="flex" justifyContent={"flex-start"}>
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            padding: "8px 12px",
                                            background: "#f9f9fc",
                                            color: "#000",
                                            border: "1px solid gainsboro",
                                            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
                                            maxWidth: "80%",
                                            position: "relative",
                                            // fontFamily: "inherit",
                                        }}
                                        id="loading"
                                    >
                                    </Paper>
                                </Box>}

                        </ChatMessages>
                        <ChatInput>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Type your message..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                sx={{
                                    // padding: 1,
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset, & .MuiInputBase-input": {
                                            borderColor: "#c4c4c4",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "8px",
                                        },
                                        fontFamily: "inherit",
                                        // color: "#ffffff",
                                    },
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }
                                }}
                            />
                            <Button
                                variant="contained" color="primary"
                                onClick={handleSendMessage} sx={{
                                    // mt: 1, 
                                    ml: 1,
                                    minWidth: 0,
                                    width: "auto",
                                    background: "linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%)",

                                }}
                                disabled={isThinking}
                            >
                                <RiSendPlane2Line fontSize={20} />
                            </Button>

                            {/* <Button sx={{
                                mt: 1, width: "100%",
                                color: "#1ebec8",
                                border: "1px solid #1ebec8"
                            }}
                                variant="text" color="secondary"
                                onClick={onClose}>
                                Close
                            </Button> */}
                        </ChatInput>
                    </>
                }
                {/* <Modal open={isThinking}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            </Modal> */}
            </ChatContainer >
        </>
    );
};

export default SidebarChat;
