import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../../../../images/figevents.png";
import "./Invoice.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import ReactQuill from "react-quill";
import { GetCallApi } from "../../../../../../../Action/Action";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

function Invoice(props) {
  const { handleReset, ATRC, districtName } = props;
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [viewData, setViewData] = useState([]);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const tableRef = useRef(null);
  let Time = new Date();

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);

        handleReset();
      });
  };

  const getUpdate = async () => {
    let res = await GetCallApi({
      url: `/api/MemberManagement/GetMemberManagementStripInvoice?memberManagementId=${ATRC?.memberManagementId}`,
    });

    if (res?.status === 200 && res?.data?.success) {
      setViewData(res?.data?.payload);
    }
  };

  useEffect(() => {
    if (ATRC) {
      getUpdate();
    }
    // eslint-disable-next-line
  }, [ATRC]);
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    // maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  let newtime = new Date(viewData?.paidDate ? viewData?.paidDate : "");
  newtime?.setDate(newtime?.getDate() + 30);
  newtime = newtime.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <>
      <Button className="theme_btn mb-3" onClick={() => setDownloadpdf(true)}>
        Download
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="preview-invoice" ref={tableRef} style={back}>
        <div className="invoice-section">
          <div className="row">
            <div className="col-lg-4">
              <div className="logo">
                <img
                  src={logo}
                  style={{
                    height: "60px",
                  }}
                  alt="Confrence Planner"
                  title="Confrence Planner"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="d-flex align-items-center gap-3 justify-content-end">
                <h2>
                  {viewData?.paymentMode === "Online"
                    ? "Online/Stripe"
                    : "Invoice"}
                </h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6  mb-4">
              <h5 className="text-uppercase">{viewData?.conferenceName}</h5>
              <div className="Billto-wrap">
                <p style={{ whiteSpace: "pre-line" }}>
                  {viewData?.paymentAddress}
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="invoice-details">
                <ul>
                  <li>
                    <span className="primarylight-color">
                      {viewData?.paymentMode === "Online"
                        ? "Payment Reference"
                        : "PO Number"}
                    </span>
                    <span
                      className="invoice-text"
                      style={{
                        maxWidth: "150px",
                        wordBreak: "break-all",
                      }}
                    >
                      {viewData?.paymentMode === "Online"
                        ? viewData?.paymentReference
                        : viewData?.memberManagementInvoiceDetails
                            ?.purchaseOrderNumber}
                    </span>
                  </li>
                  <li>
                    <span className="primarylight-color">
                      {viewData?.paymentMode === "Online"
                        ? "Payment"
                        : "Invoice"}{" "}
                      Date
                    </span>
                    <span className="invoice-text">
                      {viewData?.paidDate
                        ? new Date(viewData?.paidDate).toLocaleString("en-us", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        : ""}
                    </span>
                  </li>
                  <li>
                    <span className="primarylight-color">
                      {viewData?.paymentMode === "Online"
                        ? "Status"
                        : "Due Date"}
                    </span>
                    <span className="invoice-text">
                      {viewData?.paymentMode === "Online" ? "Paid" : newtime}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6  mb-4">
              <h5 className="text-uppercase">Billed To</h5>
              <div className="Billto-wrap">
                <p>{districtName}</p>
                <p>
                  {viewData?.paymentMode === "Online"
                    ? LogDetails?.firstName + " " + LogDetails?.lastName
                    : viewData?.memberManagementInvoiceDetails?.name}
                </p>
                <p>
                  {viewData?.paymentMode === "Online"
                    ? ""
                    : viewData?.memberManagementInvoiceDetails?.addressLine}
                </p>
                <p>
                  {viewData?.paymentMode === "Online"
                    ? ""
                    : viewData?.memberManagementInvoiceDetails?.city +
                      " " +
                      viewData?.memberManagementInvoiceDetails?.state +
                      " " +
                      viewData?.memberManagementInvoiceDetails?.zipCode}
                </p>
                <p>
                  {viewData?.paymentMode === "Online"
                    ? viewData?.email
                    : viewData?.memberManagementInvoiceDetails?.email}
                </p>
              </div>
            </div>
          </div>
          <div className="table-wrap mt-5">
            <Table className="table-bordered Conference-table">
              <TableHead className="thead-primary">
                <StyledTableRow>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Item
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Qty
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Line Total
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{viewData?.conferenceName}</StyledTableCell>
                  <StyledTableCell>
                    {viewData?.conferenceQuantity}
                  </StyledTableCell>
                  <StyledTableCell>
                    ${viewData?.conferenceMembersPrice}
                  </StyledTableCell>
                </StyledTableRow>
                {viewData?.mmevtListDetails?.length > 0
                  ? viewData?.mmevtListDetails?.map((ev, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{ev?.eventName}</StyledTableCell>
                        <StyledTableCell>{ev?.quantity}</StyledTableCell>
                        <StyledTableCell>${ev?.price}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  : ""}
                {viewData?.discountValue ? (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                        textAlign: "right",
                        borderBottom: "1px solid #808080",
                      }}
                      colSpan={2}
                    >
                      Discount
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                        borderBottom: "1px solid #808080",
                      }}
                    >
                      -${viewData?.discountValue}
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  ""
                )}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      textAlign: "right",
                      borderBottom: "1px solid #808080",
                    }}
                    colSpan={2}
                  >
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      borderBottom: "1px solid #808080",
                    }}
                  >
                    <b>${viewData?.totalAmount}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </div>
          <div className="table-wrap mt-3">
            <h5>User Details</h5>
            <Table className="table-bordered Conference-table">
              <TableHead className="thead-primary">
                <StyledTableRow>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    User Name
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Email
                  </StyledTableCell>{" "}
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Phone
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Price
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {viewData?.mmGroupUserList?.length > 0
                  ? viewData?.mmGroupUserList?.map((ev, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{i + 1}</StyledTableCell>
                        <StyledTableCell>{ev?.userName}</StyledTableCell>
                        <StyledTableCell>{ev?.userEmail}</StyledTableCell>
                        <StyledTableCell>{ev?.userPhone}</StyledTableCell>
                        <StyledTableCell>
                          ${ev?.userPaymentPrice}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 text-lg-end mt-3">
              <h5 className="mt-lg-7">Becki Swan</h5>
              <p>Co-founder, Fig Events</p>
            </div>
          </div>
          {viewData?.paymentMode === "Online" ? (
            ""
          ) : (
            <div className="row term-text">
              <div className="col-md-12">
                <h5>Invoice Instructions</h5>
                <ReactQuill
                  value={
                    viewData?.memberManagementInvoiceDetails?.invoiceInstruction
                  }
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Invoice;
