import React, { useEffect, useState } from "react";
import "./index.scss";
import star_full from "../../../../../images/category_star_full.png";
import star_blank from "../../../../../images/category_star_blank.png";
import Rating from "react-rating";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cardimg2 from "../../../../../images/default_profile.jpg";

function CourceReviews(props) {
  const { id } = useParams();
  let changeDash = useSelector((state) => state.user.Drefresh);
  const [review_data, setReview_data] = useState([]);
  useEffect(() => {
    getReviewList();
    // eslint-disable-next-line
  }, [changeDash]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getReviewList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/CourseReview/GetCourseReviewsByCourseId?courseId=${id}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          let rData = response.data.payload?.map((item) => {
            return {
              id: item.courseId,
              // img:
              //   item?.createdBy?.profileUrl === "" ||
              //     item?.createdBy?.profileUrl === null ||
              //     item?.createdBy?.profileUrl === undefined
              //     ? cardimg2
              //     : `${Config.API_HOST_URL_live}${item.createdBy?.profileUrl}`,
              img:
                item?.createdBy?.thumbnailProfileUrl === "" ||
                  item?.createdBy?.thumbnailProfileUrl === null ||
                  item?.createdBy?.thumbnailProfileUrl === undefined
                  ? cardimg2
                  : `${Config.API_HOST_URL_live}${item.createdBy?.thumbnailProfileUrl}`,
              name:
                item?.createdBy?.firstName === "" ||
                  item?.createdBy?.firstName === null ||
                  item?.createdBy?.firstName === undefined
                  ? ""
                  : item?.createdBy?.firstName +
                  " " +
                  item?.createdBy?.lastName,
              rate: item?.rating ? item?.rating : 0,
              time:
                item?.createdOn === "" || item?.createdOn === null
                  ? ""
                  : convert(item?.createdOn?.split("T")[0]),
              des: item?.ratingComments ? item?.ratingComments : "",
            };
          });
          setReview_data(rData);
        }
      });
  };
  function convert(str) {
    var date = new Date(str),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day + ",", date.getFullYear()].join(" ");
  }
  return (
    <div className="cource_review_pages">
      <div className="couce_reviews_details_box">
        <h3 className="reviews_title">Reviews</h3>
        <div className="review_show_detail_box">
          {review_data &&
            review_data?.map((item, i) => (
              <div className="user_detail_box" key={i}>
                <div className="img_box">
                  <img
                    src={item?.img}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = cardimg2;
                    }}
                    alt=""
                    className="img-fluid user_r_img"
                  />
                </div>

                <div className="rate_text_boxes">
                  <div className="name_sec">
                    <h2 className="review_name">{item?.name}</h2>
                    <div className="rating_stars">
                      <Rating
                        emptySymbol={
                          <img
                            src={star_blank}
                            alt="rating_blank"
                            height={30}
                          />
                        }
                        fullSymbol={
                          <img src={star_full} alt="rating-full" height={30} />
                        }
                        initialRating={item.rate ? item.rate : "0"}
                        readonly
                      />
                      <h6>{item?.time}</h6>
                    </div>
                  </div>
                  <div className="review_des">{item?.des}</div>
                  <div className="review_help">
                    <h6>Was this review helpful?</h6>
                    {/* <div className="like_box">
                      <img src={likeup} alt="" />
                      <img src={likedown} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          {review_data.length > 0 ? (
            ""
          ) : (
            <label className="no_one_rate w-100 text-center">No Ratings</label>
          )}
        </div>
      </div>
      <div className="review_feedback">
        {/* <Popup btnName="Feedback" titleCource={title} /> */}
        {/* <button type="button">Feedback</button> */}
      </div>
    </div>
  );
}

export default React.memo(CourceReviews);
