import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";

function AppVideo() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const video = queryParams.get("video");
  const [vPlay, setVPlay] = useState(false);
  const [vLen, setVLen] = useState();
  const [plSeconds, setPlSeconds] = useState();
  const nav = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (vPlay) {
      let main = (vLen - plSeconds) * 1000;
      const timer = setTimeout(() => {
        nav(location?.pathname + location?.search + "?isComplete=true");
      }, main);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [vPlay]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactPlayer
        url={video}
        // url={video + "?playsinline=1"}
        className="react-player"
        //   playing={true}
        volume={1}
        width={`${windowDimensions.width}px`}
        height={`${windowDimensions.height}px`}
        // width="100%"
        // height="100%"
        onPlay={() => {
          setVPlay(true);
        }}
        //   playIcon={<button>Play</button>}
        //   light="https://i.stack.imgur.com/zw9Iz.png"
        onPause={() => {
          setVPlay(false);
        }}
        onEnded={() => {
          nav(location?.pathname + location?.search + "?isComplete=true");
        }}
        controls
        // responsive
        onDuration={(e) => setVLen(e)}
        onProgress={(e) => {
          setPlSeconds(e?.playedSeconds);
        }}
        playing={true}
        config={{
          vimeo: {
            playerOptions: {
              playsinline: true,
              autoplay: true, // Autooplay enabled
              muted: true,    // Mute video (necessary for autoplay in some browsers)
              loop: false,    // Disable looping (optional)
              background: false, // Disable background mode (sometimes used in fullscreen mode)
              fullscreen: false, // Prevent the video from entering fullscreen
            }
          }
        }}
      //   config={{
      //     file: {
      //       attributes: {
      //         controlsList: "nosettings",
      //       },
      //     },
      //   }}
      //   vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
      />
    </div>
  );
}

export default React.memo(AppVideo);
