import React from "react";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import privacy_bg from "../../images/privacy_bg.png";
import point1 from "../../images/point1.svg";
import point2 from "../../images/point2.svg";
import point3 from "../../images/point3.svg";
import point4 from "../../images/point4.svg";
import point5 from "../../images/point5.svg";
import point6 from "../../images/point6.svg";
import point7 from "../../images/point7.svg";
import point9 from "../../images/point9.svg";
import point10 from "../../images/point9.svg";
function PrivacyPolicy() {
  let location = useLocation();
  return (
    <section className="privacy_policy">
      <div className="privacy_policy_box">
        <div className="header_title">
          <div className="logo">
            <img src={require("../../images/Icon/20211.png")} alt="" />
          </div>
          <div className="title m-auto">Privacy Policy</div>
        </div>
        <img src={privacy_bg} className="img-fluid w-100" alt="" />
        <div className="main-content">
          <div className="description mb-auto text-center fw-500">
            Protecting your private information is our priority. This Statement
            of Privacy applies to FIG K12, and Swan Solutions, LLC and governs
            data collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to Swan Solutions, LLC
            include https://figk-12.com and FIG K-12. The FIG K-12 application
            is a professional development application. By using the FIG K-12
            application, you consent to the data practices described in this
            statement.
          </div>
          <hr className="dashborder" />
          <div className="small_title mt-4">
            1 - Collection of your Personal Information
          </div>
          <div className="description mt-2">
            In order to better provide you with products and services offered,
            FIG K-12 may collect personally identifiable information, such as
            your:
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome">
            <ul className="ul_list">
              <li className=" description">First and Last Name</li>
              <li className=" description">Mailing Address</li>
              <li className=" description">E-mail Address</li>
              <li className=" description">Phone Number</li>
              <li className=" description">Employer</li>
              <li className=" description">Job Title</li>
            </ul>
            <img src={point1} className="img-fluid" alt="" />
          </div>
          <div className="description">
            Please keep in mind that if you directly disclose personally
            identifiable information or personally sensitive data through FIG
            K-12's public message boards, this information may be collected and
            used by others.
            <div>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services. These may include: (a) registering
              for an account; (b) entering a sweepstakes or contest sponsored by
              us or one of our partners; (c) signing up for special offers from
              selected third parties; (d) sending us an email message; (e)
              submitting your credit card or other payment information when
              ordering and purchasing products and services. To wit, we will use
              your information for, but not limited to, communicating with you
              in relation to services and/or products you have requested from
              us. We also may gather additional personal or non-personal
              information in the future.
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome-reverse mt-4">
            <img src={point2} className="img-fluid" alt="" />
            <div className="content mt-4">
              <div className="text-end small_title mb-4">
                2 - Use of your Personal Information
              </div>
              <div className="text-end description">
                FIG K-12 collects and uses your personal information to operate
                and deliver the services you have requested.
              </div>
              <div className="text-end description mt-2">
                FIG K-12 may also use your personally identifiable information
                to inform you of other products or services available from FIG
                K-12 and its affiliates.
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome mt-4">
            <div className="content pe-5 mt-4">
              <div className="small_title mb-4">
                3 - Sharing Information with Third Parties
              </div>
              <div className="description">
                FIG K-12 does not sell, rent or lease its customer lists to
                third parties.
              </div>
              <div className="description mt-2">
                FIG K-12 may share data with trusted partners to help perform
                statistical analysis, send you email or postal mail, provide
                customer support, or arrange for deliveries. All such third
                parties are prohibited from using your personal information
                except to provide these services to FIG K-12, and they are
                required to maintain the confidentiality of your information.
              </div>
              <div className="description mt-2">
                FIG K-12 may disclose your personal information, without notice,
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on FIG K-12 or the site;
                (b) protect and defend the rights or property of FIG K-12;
                and/or (c) act under exigent circumstances to protect the
                personal safety of users of FIG K-12, or the public.
              </div>
            </div>
            <img src={point3} className="img-fluid" alt="" />
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome-reverse mt-4">
            <img src={point4} className="img-fluid" alt="" />
            <div className="content mt-4">
              <div className="text-end small_title mb-4">
                4 - Security of your Personal Information
              </div>
              <div className="text-end description ">
                FIG K-12 secures your personal information from unauthorized
                access, use, or disclosure. FIG K-12 uses the following methods
                for this purpose:
              </div>
              <div className="text-end description ">
                <b>SSL Protocol</b>
              </div>
              <div className="text-end description">
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use
                of encryption, such as the Secure Sockets Layer (SSL) protocol.
              </div>
              <div className="text-end description mt-2">
                We strive to take appropriate security measures to protect
                against unauthorized access to or alteration of your personal
                information. Unfortunately, no data transmission over the
                Internet or any wireless network can be guaranteed to be 100%
                secure. As a result, while we strive to protect your personal
                information, you acknowledge that: (a) there are security and
                privacy limitations inherent to the Internet which are beyond
                our control; and (b) security, integrity, and privacy of any and
                all information and data exchanged between you and us through
                this Site cannot be guaranteed.
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome mt-4">
            <div className="content mt-4">
              <div className="small_title mb-4">5 - Right to Deletion</div>
              <div className="description">
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </div>
              <ul className="ul_list">
                <li className="description">
                  Delete your personal information from our records; and{" "}
                </li>
                <li className="description">
                  Direct any service providers to delete your personal
                  information from their records.
                </li>
              </ul>
              <div className="description mt-2">
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </div>
              <ul className="ul_list">
                <li className="description">
                  Complete the transaction for which the personal information
                  was collected, fulfill theterms of a written warranty or
                  product recall conducted in accordance with federallaw,
                  provide a good or service requested by you, or reasonably
                  anticipated within thecontext of our ongoing business
                  relationship with you, or otherwise perform a contractbetween
                  you and us;
                </li>
                <li className="description">
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegalactivity; or prosecute those
                  responsible for that activity;
                </li>
                <li className="description">
                  Debug to identify and repair errors that impair existing
                  intended functionality;
                </li>
                <li className="description">
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her right of free speech, or exercise another
                  right provided for by law;
                </li>
                <li className="description">
                  Comply with the California Electronic Communications Privacy
                  Act;
                </li>
                <li className="description">
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in thepublic interest that adheres to all
                  other applicable ethics and privacy laws, when ourdeletion of
                  the information is likely to render impossible or seriously
                  impair theachievement of such research, provided we have
                  obtained your informed consent;
                </li>
                <li className="description">
                  Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us;
                </li>
                <li className="description">
                  Comply with an existing legal obligation; or
                </li>
                <li className="description">
                  Otherwise use your personal information, internally, in a
                  lawful manner that iscompatible with the context in which you
                  provided the information.
                </li>
              </ul>
            </div>
            <img src={point5} className="img-fluid" alt="" />
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome-reverse mt-4">
            <img src={point6} className="img-fluid" alt="" />
            <div className="content mt-4">
              <div className="text-end small_title mb-4">
                6 - Children Under Thirteen
              </div>
              <div className="text-end description ">
                FIG K-12 does not knowingly collect personally identifiable
                information from children under the age of thirteen. If you are
                under the age of thirteen, you must ask your parent or guardian
                for permission to use this application.
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome mt-4">
            <div className="content">
              <div className="small_title mb-4">7 - E-mail Communications</div>
              <div className="description ">
                From time to time, FIG K-12 may contact you via email for the
                purpose of providingannouncements, promotional offers, alerts,
                confirmations, surveys, and/or other generalcommunication. In
                order to improve our Services, we may receive a notification
                when you open anemail from FIG K-12 or click on a link therein.
              </div>
              <div className="description mt-2">
                If you would like to stop receiving marketing or promotional
                communications via email from FIG K-12, you may opt out of such
                communications by clicking the UNSUBSCRIBE button..
              </div>
            </div>
            <img src={point7} className="img-fluid" alt="" />
          </div>
          <div className="small_title mb-4">
            8 - External Data Storage Sites
          </div>
          <div className="description ">
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </div>{" "}
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome-reverse mt-4">
            <img src={point9} className="img-fluid" alt="" />
            <div className="content mb-4">
              <div className="text-end small_title mb-4">
                9 - Changes to this Statement
              </div>
              <div className="text-end description">
                FIG K-12 reserves the right to change this Privacy Policy from
                time to time. We will notify you about significant changes in
                the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a
                prominent notice on our application, and/or by updating any
                privacy information. Your continued use of the application
                and/or Services available after such modifications will
                constitute your: (a) acknowledgment of the modified Privacy
                Policy; and (b) agreement to abide and be bound by that Policy.
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-sm-column-custome mt-4 mb-4">
            <div className="content mt-4">
              <div className="small_title mb-4">10 - Contact Information</div>
              <div className="description">
                FIG K-12 welcomes your questions or comments regarding this
                Statement of Privacy. If you believe that FIG K-12 has not
                adhered to this Statement, please contact FIG K-12 at:
              </div>
              <div className="description mt-2">
                <span className="d-block">Swan Solutions, LLC </span>
                <span className="d-block">5189 Stewart Street</span>
                <span className="d-block">Milton, Florida 32570</span>
              </div>
              <div className="description mt-2">
                <span className="d-block">Email Address: </span>
                <span className="d-block">becki@swansolutionsllc.com </span>
              </div>{" "}
              <div className="description mt-2">
                <span className="d-block">Telephone number: </span>
                <span className="d-block">7708539289 </span>
              </div>
              <div className="description mt-3 d-flex justify-content-between">
                <span className="d-block ">Effective as of April 10, 2023</span>
              </div>
            </div>
            <img src={point10} className="img-fluid" alt="" />
          </div>
          {location.pathname === "/privacy-policy" ? (
            <div className="d-flex align-items-center justify-content-center">
              <Link
                className="theme_btn"
                to="/"
                style={{ textDecoration: "none" }}
              >
                Back to login
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
