import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.scss";

const data = [
    { name: "Page A", pv: 3000, amt: 2400 },
    { name: "Page B", pv: 6000, amt: 2210 },
    { name: "Page C", pv: 9000, amt: 2290 },
    { name: "Page D", pv: 12000, amt: 2000 },
    { name: "Page E", pv: 15000, amt: 2181 },
];

const SkeletonBarGroup = () => {
    return (
        <div className="chart-container">
            <div className="skeleton-container">
                {data.map((entry, index) => (
                    <div key={index} className="skeleton-bar-group">
                        <div className="skeleton-bars">
                            <Skeleton
                                width={50}
                                height={entry.pv / 50}
                                className="skeleton-bar skeleton-bar-pv"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default SkeletonBarGroup;