import React, { Suspense, lazy, useState } from "react";
import "./index.scss";
import back_img from "../../../../images/author_backimg.png";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import userimg1 from "../../../../images/user18.png";
import cardimg1 from "../../../../images/ceu_couce_card_img.png";
import { useSelector } from "react-redux";
// import { Backdrop, CircularProgress, Tabs } from "@mui/material";
import { Tabs, Tab, Box, Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
// import axios from "axios";
import Config from "../../../../Utils/Config";
import { useParams } from "react-router-dom";
import cardimg2 from "../../../../images/fig_CROP.png";
import PropTypes from "prop-types";
import { GetCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";

const AuthorImgProfile = lazy(() => import("./AuthorImgProfile"));
const AuthorImgDes = lazy(() => import("./AuthorImgDes"));
const CardGrid = lazy(() => import("./cardGrid"));
const CourseRating = lazy(() => import("../Course_Card_Detail/CourseRating"));
const CourceReviews = lazy(() =>
  import("../Course_Card_Detail/CourceReviews/Index")
);
const renderLoader = () => <p></p>;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function AuthorProfile(props) {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [instructorData, setInstructorData] = useState();
  const [courseRatingData, setCourseRatingData] = useState([
    { id: 1, percent: "54", star: "5" },
    { id: 2, percent: "30", star: "4" },
    { id: 3, percent: "8", star: "3" },
    { id: 4, percent: "4", star: "2" },
    { id: 5, percent: "4", star: "1" },
  ]);
  const [reCall, setReCall] = useState(false);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [ceuCardData, setCeuCardData] = useState([]);
  useEffect(() => {
    getInstructDeLists();
    // eslint-disable-next-line
  }, [reCall]);
  const getInstructDeLists = async () => {
    setOpen(true);

    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Instructor/Instructorprofile?InstructorId=${id}&followerUserId=${LogUserId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {

    const sendData = {
      url: `/api/Instructor/Instructorprofile?InstructorId=${id}&followerUserId=${LogUserId}`,
      headers: headers
    }

    let response = await GetCallApi(sendData)
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        setInstructorData(response.data.payload);
        const insData = response.data?.payload;
        const courstof = response.data?.payload?.myOfferedCourses?.map(
          (item, i) => {
            return {
              id:
                item.courseId === "" || item.courseId === null
                  ? ""
                  : item?.courseId,
              cardimg:
                item.imageOrVideoUrl === "" || item.imageOrVideoUrl === null
                  ? cardimg1
                  : `${Config.API_HOST_URL_live}${item.imageOrVideoUrl}`,
              cardTitle:
                item.courseTitle === "" || item.courseTitle === null
                  ? ""
                  : item.courseTitle,
              startCompleted: `${item?.lessions} Video Series`,
              lesson: `${item?.lessions} Lessons`,
              // des: "Deep down, what motivates you? What aspects of your life are most meaningful to you?",
              rating: item?.ratings ? item?.ratings : 0,
              userimg:
                response.data.payload?.thumbnailProfileUrl === "" ||
                  response.data.payload?.thumbnailProfileUrl === null
                  ? userimg1
                  : `${Config.API_HOST_URL_live}${response.data.payload?.thumbnailProfileUrl}`,
              userName:
                insData?.firstName === "" ||
                  insData?.firstName === null ||
                  insData?.lastName === "" ||
                  insData?.lastName === null
                  ? ""
                  : insData?.firstName + " " + insData?.lastName,
            };
          }
        );
        setCeuCardData(courstof);
        setOpen(false);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // })
    //   .catch ((error) => console.log(error));
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <section className="author_profile_page main_backcolor">
          <div className="author_profile_header_back_img">
            <img
              src={
                instructorData?.coverPhoto === "" ||
                  instructorData?.coverPhoto === null
                  ? back_img
                  : `${Config.API_HOST_URL_live}${instructorData?.coverPhoto}`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = cardimg2;
              }}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="profile_overlap mb-3">
                <AuthorImgProfile
                  authName={
                    instructorData?.firstName + " " + instructorData?.lastName
                  }
                  work={instructorData?.role}
                  lesson={ceuCardData?.length}
                  student={instructorData?.enrolledStud}
                  certi={instructorData?.followers}
                  reCall={reCall}
                  setReCall={setReCall}
                  isFollowedByMe={instructorData?.isFollowedByMe}
                  uimg={instructorData?.thumbnailProfileUrl}
                />
              </div>
              <AuthorImgDes
                authName={
                  instructorData?.firstName + " " + instructorData?.lastName
                }
                work={instructorData?.role}
                des={instructorData?.introduction}
                reCall={reCall}
                setReCall={setReCall}
              />
              <div className="courses_tabpanel">
                {/* <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                  }}
                  className="main_tab_box"
                >
                </Box> */}
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    className="comment_tab_button"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab label="Courses" {...a11yProps(0)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="tabPanel_div">
                  <div className="row">
                    <CardGrid data={ceuCardData} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} className="tabPanel_div">
                  <CourseRating
                    courseRatingData={courseRatingData}
                    courseRatingDataset={setCourseRatingData}
                    avgrate="4.0"
                    avgstar="1"
                  />
                  <CourceReviews />
                </TabPanel>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(AuthorProfile);
