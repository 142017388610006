import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { GetCallApi } from "./../../../Action/Action";

const Emj = ({ id, selected }) => {
    if (id === 1) {
        return (
            <div className="face-wrapper">
                <div
                    className="face-counter invisible"
                    data-title-none="No one rated it like this"
                    data-title-one="One person rated it like this"
                    data-title-many="other people rated it like this"
                ></div>
                <label
                    className={`face ${selected === 1 ? "" : "grayscale"} face-wtf`}
                    htmlFor="rate-1"
                    data-hint="Oh God! Why?!"
                >
                    <div className="eyes-wrapper">
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="mouth-wrapper">
                        <div className="mouth"></div>
                    </div>
                </label>
            </div>
        );
    } else if (id === 2) {
        return (
            <div className="face-wrapper">
                <div
                    className="face-counter invisible"
                    data-title-none="No one rated it like this"
                    data-title-one="One person rated it like this"
                    data-title-many="other people rated it like this"
                ></div>

                <label
                    className={`face ${selected === 2 ? "" : "grayscale"
                        } face-disappointed`}
                    htmlFor="rate-2"
                    data-hint="It sucks..."
                >
                    <div className="eyes-wrapper">
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="mouth-wrapper">
                        <div className="mouth"></div>
                    </div>
                </label>
            </div>
        );
    } else if (id === 3) {
        return (
            <div className="face-wrapper">
                <div
                    className="face-counter invisible"
                    data-title-none="No one rated it like this"
                    data-title-one="One person rated it like this"
                    data-title-many="other people rated it like this"
                ></div>
                <label
                    className={`face ${selected === 3 ? "" : "grayscale"} face-sad`}
                    htmlFor="rate-3"
                    data-hint="It's ok. I guess."
                >
                    <div className="eyes-wrapper">
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="mouth-wrapper">
                        <div className="mouth"></div>
                    </div>
                </label>
            </div>
        );
    } else if (id === 4) {
        return (
            <div className="face-wrapper">
                <div
                    className="face-counter invisible"
                    data-title-none="No one rated it like this"
                    data-title-one="One person rated it like this"
                    data-title-many="other people rated it like this"
                ></div>
                <label
                    className={`face ${selected === 4 ? "" : "grayscale"} face-happy`}
                    htmlFor="rate-4"
                    data-hint="This is great!"
                >
                    <div className="eyes-wrapper">
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="mouth-wrapper">
                        <div className="mouth"></div>
                    </div>
                </label>
            </div>
        );
    } else if (id === 5) {
        return (
            <div className="face-wrapper">
                <div
                    className="face-counter invisible"
                    data-title-none="No one rated it like this"
                    data-title-one="One person rated it like this"
                    data-title-many="other people rated it like this"
                ></div>
                <label
                    className={`face ${selected === 5 ? "" : "grayscale"} face-love`}
                    htmlFor="rate-5"
                    data-hint="OMG! I love it!"
                >
                    <div className="eyes-wrapper">
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                        <div className="eye">
                            <div className="pupil">
                                <div className="eyelid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="mouth-wrapper">
                        <div className="mouth"></div>
                    </div>
                </label>
            </div>
        );
    }
};

const EmojiRating = ({ value, onSelect }) => {
    return (
        <div className="emoji-rating">
            <div className="d-flex flex-row mt-2">
                <div className="feedback-wrapper main_wrap">
                    <div className="feedback-content">
                        <div className="feedback-faces">
                            <div className="row">
                                {[...Array(5)?.keys()]?.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`col emoji ${value === index + 1 ? "selected" : ""
                                            }`}
                                        role="button"
                                        tabIndex={0}
                                        aria-label={`Rating ${index + 1}`}
                                    >
                                        <Emj id={index + 1} selected={value} />
                                    </div>
                                ))}
                            </div>
                            <div
                                className="faces-hint text-center text-gray"
                                data-default-hint=""
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function EmployeeReview() {
    const [open, setOpen] = useState(false);

    const LogUserId = useSelector((state) => state.login.LoginDetails.userId);

    const {
        register,
        setValue,
        control,
    } = useForm();

    const { fields } = useFieldArray({
        control,
        name: "questions",
    });

    useEffect(() => {
        EmpReviewList();
        // eslint-disable-next-line
    }, []);

    const EmpReviewList = async () => {
        setOpen(true);
        let seData = {
            url: `/api/EvalutionEmployee/GetEmployeeReview?userId=${LogUserId}`,
        };
        let res = await GetCallApi(seData);
        setOpen(false);
        if (res?.status === 200) {
            if (res.data.success) {
                let allD = res.data.payload?.map((item, i) => {
                    return {
                        ...item,
                        id: i + 1,
                        evalQuestions: item?.evalQuestions?.map((x) => {
                            return { ...x, trueFalse: x?.trueFalse?.toString() };
                        }),
                    };
                });
                setValue("questions", allD ? allD : []);
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <section className="emp_review_page main_backcolor ">
                <div className="container-fluid">
                    <div className="main_wrapper">
                        <div className="chart_title_box d-flex justify-content-between">
                            <label className="chart_title">Employee Review</label>
                            <div className="d-flex">
                                <Link
                                    className="Can_cel_btn d-flex align-items-center"
                                    style={{ textDecoration: "none" }}
                                    to={-1}
                                >
                                    Back
                                </Link>
                            </div>
                        </div>
                        <form className="create_school_form">
                            <div className="row">
                                <div className="quiz_question_box">
                                    {fields?.length > 0 ? (
                                        fields?.map((gn, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div
                                                        className={`${index + 1 === fields?.length
                                                            ? ""
                                                            : "border-bottom mb-3"
                                                            }`}
                                                    >
                                                        <p>Group Name : {gn?.evalutionGroupName}</p>
                                                        {/* {gn?.evalQuestions?.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                                <h6
                                                                    className={`h6_text ${i === 1 ? "mt-3" : ""}`}
                                                                >
                                                                    {i + 1} ) {item?.evalQuestionName}
                                                                </h6>
                                                                {item?.evalQuestionTypeName === "Text" ? (
                                                                    <div className="mb-3">
                                                                        <div className="col-md-6">
                                                                            <textarea
                                                                                rows="3"
                                                                                cols="3"
                                                                                disabled
                                                                                className="form-control"
                                                                                placeholder="Enter Answer"
                                                                                {...register(
                                                                                    `questions.${index}.evalQuestions.${i}.textAnswer`,
                                                                                    {
                                                                                        required: "This field is required",
                                                                                    }
                                                                                )}
                                                                            ></textarea>
                                                                        </div>
                                                                        {errors?.questions?.[index]?.evalAnswer && (
                                                                            <span role="alert" className="error_text">
                                                                                {
                                                                                    errors?.questions?.[index]?.evalAnswer
                                                                                        ?.message
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName ===
                                                                    "True/False" ? (
                                                                    <div className="d-flex gap-5 mb-3">
                                                                        <div className="quiz_ans_select_box">
                                                                            <label className="all_quiz_label">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="login_check"
                                                                                    name="quiz_select"
                                                                                    value="true"
                                                                                    disabled
                                                                                    {...register(
                                                                                        `questions.${index}.evalQuestions.${i}.trueFalse`,
                                                                                        {
                                                                                            required:
                                                                                                "Please Select One Option",
                                                                                        }
                                                                                    )}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <p>True</p>
                                                                        </div>
                                                                        <div className="quiz_ans_select_box">
                                                                            <label className="all_quiz_label">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="login_check"
                                                                                    name="quiz_select"
                                                                                    value="false"
                                                                                    disabled
                                                                                    {...register(
                                                                                        `questions.${index}.evalQuestions.${i}.trueFalse`,
                                                                                        {
                                                                                            required:
                                                                                                "Please Select One Option",
                                                                                        }
                                                                                    )}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <p>False</p>
                                                                        </div>
                                                                        {errors.questions?.[index]?.evalAnswer && (
                                                                            <span role="alert" className="error_text">
                                                                                {
                                                                                    errors.questions?.[index]?.evalAnswer
                                                                                        .message
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName === "Rating" ? (
                                                                    <div className="mb-3">
                                                                        <div>
                                                                            <EmojiRating value={item?.rating} />
                                                                        </div>
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName === "MCQ" ? (
                                                                    <div className="row mb-3">
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"1"}
                                                                                        disabled

                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.correctAnswerForMcq`,

                                                                                            {
                                                                                                required:
                                                                                                    "Please Select One Option",
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option1}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"2"}
                                                                                        disabled

                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.correctAnswerForMcq`,

                                                                                            {
                                                                                                required:
                                                                                                    "Please Select One Option",
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option2}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"3"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.correctAnswerForMcq`,

                                                                                            {
                                                                                                required:
                                                                                                    "Please Select One Option",
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option3}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"4"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.correctAnswerForMcq`,
                                                                                            {
                                                                                                required:
                                                                                                    "Please Select One Option",
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option4}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {errors?.questions?.[index]?.evalAnswer && (
                                                                            <span role="alert" className="error_text">
                                                                                {
                                                                                    errors?.questions?.[index]?.evalAnswer
                                                                                        ?.message
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </React.Fragment>
                                                        ))}    */}

                                                        {gn?.evalQuestions?.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                                <h6
                                                                    className={`h6_text ${i === 1 ? "mt-3" : ""}`}
                                                                >
                                                                    {i + 1} ) {item?.evalQuestionName}
                                                                </h6>
                                                                {item?.evalQuestionTypeName === "Text" ? (
                                                                    <div className="mb-3">
                                                                        <div className="col-md-6">
                                                                            <textarea
                                                                                rows="3"
                                                                                cols="3"
                                                                                disabled
                                                                                className="form-control"
                                                                                placeholder="Enter Answer"
                                                                                {...register(
                                                                                    `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                )}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName ===
                                                                    "True/False" ? (
                                                                    <div className="d-flex gap-5 mb-3">
                                                                        <div className="quiz_ans_select_box">
                                                                            <label className="all_quiz_label">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="login_check"
                                                                                    name="quiz_select"
                                                                                    value="true"
                                                                                    disabled
                                                                                    {...register(
                                                                                        `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                    )}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <p>True</p>
                                                                        </div>
                                                                        <div className="quiz_ans_select_box">
                                                                            <label className="all_quiz_label">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="login_check"
                                                                                    name="quiz_select"
                                                                                    value="false"
                                                                                    disabled
                                                                                    {...register(
                                                                                        `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                    )}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <p>False</p>
                                                                        </div>
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName === "Rating" ? (
                                                                    <div className="mb-3">
                                                                        <div>
                                                                            <EmojiRating value={parseInt(item?.answerForEmployee)} />
                                                                        </div>
                                                                    </div>
                                                                ) : item?.evalQuestionTypeName === "MCQ" ? (
                                                                    <div className="row mb-3">
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"1"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option1}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"2"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option2}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"3"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option3}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="quiz_ans_select_box">
                                                                                <label className="all_quiz_label">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="login_check"
                                                                                        name="quiz_select"
                                                                                        value={"4"}
                                                                                        disabled
                                                                                        {...register(
                                                                                            `questions.${index}.evalQuestions.${i}.answerForEmployee`,
                                                                                        )}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                                <p
                                                                                    style={{
                                                                                        maxWidth: "320px",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {item?.options?.option4}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <h5 className="text-center">
                                            Your Evaluation setup not completed
                                        </h5>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EmployeeReview;
