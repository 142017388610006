import React, { useState } from "react";
import "./index.scss";
import ViewGroupsList from "./ViewGroupList";
import active_list from "../../../../../images/active_list.png";
import inactive_list from "../../../../../images/inactive_list.png";
import active_grid from "../../../../../images/active_grid.png";
import inactive_grid from "../../../../../images/inactive_grid.png";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

function ViewGroups() {
  const [grid, setGrid] = useState(true);
  const [connectionActive, setconnectionActive] = useState(1);
  const { id } = useParams();
  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="conection_view_action d-flex justify-content-end">
            {connectionActive !== 4 && (
              <div className="rightside_action w-25">
                <Button className="grid_btn" onClick={() => setGrid(true)}>
                  <img
                    src={grid === true ? active_grid : inactive_grid}
                    className="img-fluid"
                    alt="grid"
                  />
                </Button>
                <Button className="list_btn" onClick={() => setGrid(false)}>
                  <img
                    src={grid === false ? active_list : inactive_list}
                    className="img-fluid"
                    alt="grid"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        <ViewGroupsList
          id={id}
          grid={grid}
          setconnectionActive={setconnectionActive}
        />
      </div>
    </>
  );
}
export default React.memo(ViewGroups);
