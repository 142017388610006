import React, { useEffect } from "react";
import "./index.scss";
import userimg from "../../../images/user.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import pro_img from "../../../images/default_profile.jpg";

function Upload_post(props) {
  const { GoToConnection } = props;
  const [usList, setUsList] = useState();
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [usId, setUsId] = useState("");
  const [ucId, setUcId] = useState("");
  const [errShow1, setErrShow1] = useState(false);
  const [errShow2, setErrShow2] = useState(false);
  const [conList, setConList] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  useEffect(() => {
    getUserList();
    getListCon();
    // eslint-disable-next-line
  }, []);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getUserList = () => {
    setErrShow2(true);

    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Requests/MyRecommandedConnectionList?userId=${LogUserId}`,
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          const user = responce.data.payload?.map((item) => {
            return {
              userId:
                item.userId === null || item.userId === "" ? "" : item?.userId,
              img:
                item.profileUrl === null || item.profileUrl === ""
                  ? pro_img
                  : `${Config.API_HOST_URL_live}${item.profileUrl}`,
              name:
                item?.firstName === "" ||
                item?.firstName === null ||
                item?.lastName === "" ||
                item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
              status:
                item.status === null || item.status === "" ? "" : item.status,
              sId: item?.sentRequestId,
              schoolDistrictName: item?.schoolDistrictName,
              siteLocation: item?.siteLocation,
              state: item?.stateName,
            };
          });
          setUsList(user);
          setUsId("");
          setUcId("");
          setErrShow2(false);
        } else {
          setErrShow2(false);
          setUsList([]);
        }
      });
  };

  const SendReq = (req) => {
    setUsId(req);
    let userData = {
      sentRequestUserId: LogUserId,
      receiveRequestUserId: req,
      Status: "Pending",
      message: "I would like to add you as my connection.",
      createdBy: LogUserId,
    };

    axios
      .post(`${Config.API_HOST_URL_live}/api/Requests/SendRequest`, userData, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
        }
      });
  };
  const CancelReq = (req, newId) => {
    setUcId(req);

    let userData = {
      sentRequestUserId: LogUserId,
      receivedRequestUserId: req,
      Status: "Cancel",
      sentRequestId: newId,
      receivedRequestId: "00000000-0000-0000-0000-000000000000",
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Requests/CancelSentRequest`,
        userData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getUserList();
        }
      });
  };
  const getListCon = () => {
    setErrShow1(true);
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserProfile/GetMyConnectionsList?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const Mylist = response.data.payload?.map((item) => {
            return {
              userId:
                item.userId === null || item.userId === "" ? "" : item.userId,
              sentRequestUserId:
                item.sentRequestUserId === null || item.sentRequestUserId === ""
                  ? ""
                  : item.sentRequestUserId,
              name:
                item?.firstName === "" ||
                item?.firstName === null ||
                item?.lastName === "" ||
                item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
              img:
                item.profileUrl === null || item.profileUrl === ""
                  ? userimg
                  : `${Config.API_HOST_URL_live}${item.profileUrl}`,
              activeHours:
                item.activeHours !== null || item.activeHours !== ""
                  ? item.activeHours
                  : "0",
              followers:
                item.followers === null || item.followers === ""
                  ? ""
                  : item.followers,
              join:
                item?.userJoinDate === "" || item?.userJoinDate === null
                  ? ""
                  : convert(item?.userJoinDate?.split("T")[0]),
            };
          });
          setConList(Mylist);
          setErrShow1(false);
        } else {
          setErrShow1(false);
          setConList([]);
        }
      });
  };
  function convert(str) {
    var date = new Date(str),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day].join(" ");
  }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  let location = useLocation();
  const GoToCon = () => {
    GoToConnection(1);
  };
  const [searchField, setSearchField] = useState();
  const searchParam = ["name", "schoolDistrictName", "state"];
  const filterUser = usList?.filter((user) => {
    if (searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      <div className="connectionlist_card">
        <div className="card_title d-flex">
          <h6>Connections</h6>
          {location.pathname === "/edit-profile" ? (
            <Link onClick={GoToCon}>View All</Link>
          ) : (
            <Link to="/edit-profile">View All</Link>
          )}
        </div>
        <div className="card_content">
          <div className="user_list">
            {conList?.length > 0 ? (
              <>
                {conList?.map((item, i) => (
                  <div
                    className="d-flex mb-3 u_box"
                    key={i}
                    onClick={() => handleViewProfile(item?.userId)}
                  >
                    <img
                      src={item?.img}
                      className="img-fluid user_p_img"
                      alt=""
                    />
                    <div className="u_name m-auto ms-3">
                      <label className=" p_16_text ">{item?.name}</label>
                      <span className="p_14_text">View User</span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>{!errShow1 && <div>No Data Found</div>}</>
            )}
            {errShow1 && <div>Loading...</div>}
          </div>
        </div>

        <div className="card_title d-flex">
          <h6>Suggested Friends</h6>
          {/* <Link>View All</Link> */}
        </div>
        <div className="searc_box">
          <label>Search by Name, School District, State</label>
          <input
            type="search"
            className="form-control"
            placeholder=""
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
        <div className="card_content border-0 suggested_side_box">
          <div className="user_list">
            {filterUser?.length > 0 ? (
              <>
                {filterUser?.map((item, i) => (
                  <div className="d-flex user_line mb-3" key={i}>
                    <div
                      className="d-flex u_box"
                      onClick={() => handleViewProfile(item?.userId)}
                    >
                      <img
                        src={item?.img === "" ? userimg : item?.img}
                        className="img-fluid user_p_img"
                        alt=""
                      />
                      <label className="m-auto u_name ms-3">{item?.name}</label>
                    </div>
                    {item.status === "Sent" ? (
                      <Button
                        className="theme_button m-auto me-0 cancel"
                        onClick={(e) => CancelReq(item?.userId, item?.sId)}
                        disabled={item?.userId === ucId ? true : false}
                      >
                        {item?.userId === ucId ? "---" : "Cancel"}
                      </Button>
                    ) : (
                      <Button
                        className="theme_button m-auto me-0"
                        onClick={(e) => SendReq(item?.userId)}
                        disabled={item?.userId === usId ? true : false}
                      >
                        {item?.userId === usId ? "---" : "Connect"}
                      </Button>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>{!errShow2 && <div>No Data Found</div>}</>
            )}
            {errShow2 && <div>Loading...</div>}
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(Upload_post);
