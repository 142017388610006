import Axios from "../Utils/AxiosConfig";

export const GetCallApi = async (data) => {
  try {
    const response = await Axios.get(data?.url, {
      headers: data?.headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const PostCallApi = async (data) => {
  try {
    const response = await Axios.post(data?.url, data.body, {
      headers: data?.headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const DeleteCallApi = async (data) => {
  try {
    const method = data?.method?.toLowerCase(); // Get the method from data and convert to lowercase
    let response;
console.log(method);
    if (method === 'post') {
      response = await Axios.post(data?.url, data?.body, { headers: data?.headers });
    }else{
     response = await Axios.delete(data?.url, { headers: data?.headers });
    }

    // const response = await Axios.delete(data?.url, { headers: data?.headers });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
