import { createSlice } from "@reduxjs/toolkit";

const initialState = { ATRC: {} };

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    AttendeeRegistrationConference(state, action) {
      state.ATRC = action.payload;
    },
    resetCommon: () => initialState,
  },
});

export const { AttendeeRegistrationConference, resetCommon } =
  commonSlice.actions;

export default commonSlice.reducer;
