import React, { useState } from "react";
import "./index.scss";
import star_full from "../../../../../images/star_full.png";
import star_blank from "../../../../../images/star_blank.png";
import grid_lin_a from "../../../../../images//Icon/GridView_lin_a.svg";
import grid_lin from "../../../../../images//Icon/GridView_lin.svg";
import list_lin_a from "../../../../../images//Icon/ListView_lin_a.svg";
import list_lin from "../../../../../images//Icon/ListView_lin.svg";
import Rating from "react-rating";
import Pagination from "../../../../Reuseable_Components/Paginations/Pagination";
import { useNavigate } from "react-router-dom";
import cardimg1 from "../../../../../images/course_not_found_final_1.png";
// import cardimg1 from "../../../../../images/fig_CROP.png";

// pagination
let PageSize = 12;

function CardGrid(props) {
  const { data } = props;
  const [listView, setListView] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam] = useState(["cardTitle", "userName", "numericCode"]);
  const searchFieldCou = "";
  const filterCourse = data?.filter((user) => {
    if (searchFieldCou) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchFieldCou.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  let nav = useNavigate();
  const courseDe = (e) => {
    nav(`/ceu-courses/${e}`, { replace: true });
  };
  return (
    <>
      <div className="grid_list">
        <div className="grid_img" onClick={() => setListView(false)}>
          <img src={listView ? grid_lin : grid_lin_a} alt="" />
        </div>
        <div className="list_img" onClick={() => setListView(true)}>
          <img src={listView ? list_lin_a : list_lin} alt="" />
        </div>
      </div>
      {filterCourse &&
        filterCourse
          ?.slice(
            (currentPage - 1) * PageSize,
            (currentPage - 1) * PageSize + PageSize
          )
          ?.map((item, i) => (
            <div
              key={i}
              className={`auth_collection ${
                listView ? "col-12 list" : "col-xl-4 col-lg-4 col-md-6 mb-3"
              } `}
              onClick={() => courseDe(item?.id)}
            >
              <div
                className={`author_card_detail_box ${
                  listView ? "dir_row" : ""
                }`}
              >
                <div className="img_box_text">
                  <img
                    src={item?.cardimg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = cardimg1;
                    }}
                    className="img-fluid"
                    alt=""
                  />
                  <div className={`img_text `}>
                    <span>{item?.startCompleted}</span>
                  </div>
                </div>
                <div className="card_details">
                  <div className="card_title">
                    <div
                      className={`card_title_head ${
                        listView ? "" : "showoneline"
                      }`}
                    >
                      {item.cardTitle}
                    </div>
                    <div className="rating_box">
                      <Rating
                        emptySymbol={
                          <img src={star_blank} alt="rating_blank" />
                        }
                        fullSymbol={<img src={star_full} alt="rating-full" />}
                        initialRating={item.rating ? item.rating : "0"}
                        readonly
                      />
                    </div>
                  </div>
                  {item?.des ? (
                    <span className="card_lesson">{item?.des}</span>
                  ) : (
                    ""
                  )}
                  <div className="card_i_text_details">
                    <div className="img_box">
                      <img src={item?.userimg} className="img-fluid" alt="" />
                      <h6 className="detail_name">{item?.userName}</h6>
                    </div>
                    <p className="detail_text_1">{item?.lesson}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      <div className={`pagination ${props.addclass}`}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filterCourse.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default React.memo(CardGrid);
