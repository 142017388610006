import React, { Suspense, lazy } from "react";
import "./index.scss";
const Groups = lazy(() => import("../Profile/Groups"));
const renderLoader = () => <p></p>;

function CommunityGroups() {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <div className="community_groups main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="community_group_title">Groups</div>
              <div className="community_group_main_box">
                <Groups />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(CommunityGroups);
