import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./index.scss";
export default function Circuler_progress(props) {
  const idCSS = "hello";
  const gradientTransform = `rotate(90)`;
  return (
    <div className="circuller_progress">
      <svg>
        <defs>
          <linearGradient id={idCSS} gradientTransform={gradientTransform}>
            <stop offset="16.29%" stopColor="#1EBEC8" />
            <stop offset="85.56%" stopColor="#86C65A" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbar
        strokeWidth={8}
        value={props?.value}
        // text={percentage}
        styles={{
          path: { stroke: `url(#${idCSS})`, height: "100%" },
          trail: {
            stroke: "#DDDDDD",
          },
        }}
      />
    </div>
  );
}
