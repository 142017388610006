import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import { useSelector } from "react-redux";
import Config from "../../../Utils/Config";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import pro_img from "../../../images/default_profile.jpg";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../../Action/Action";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import Swal from "sweetalert2";

const renderLoader = () => <p></p>;

function FigFamily() {
  const [open, setOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [districtList, setDistrictList] = useState([]);
  const LogUserde = useSelector((state) => state.login?.LoginDetails);

  const headCells = ["DistrictName", "PhoneNumber", "Email", "State"];
  const formattedSearchResults = districtList?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  useEffect(() => {
    getSchoolDistrictList();
    // eslint-disable-next-line
  }, []);
  const getSchoolDistrictList = async () => {
    setOpen(true);
    setDistrictList([]);
    let response = await GetCallApi({
      url: `/api/SchoolDistrict/GetSchoolDistrictForFigFamily?userId=${LogUserde.userId}`,
    });

    setOpen(false);
    if (response?.status === 200 && response.data.success) {
      const data = response?.data.payload?.map((item) => {
        return {
          Logo: item?.thumbnailLogoUrl
            ? `${Config.API_HOST_URL_live}${item?.thumbnailLogoUrl}`
            : pro_img,
          districtId: item?.schoolDistrictId,
          aboutUs: item?.aboutUs,
          DistrictName: item?.districtName,
          PhoneNumber: item?.phoneNumber,
          Email: item?.email,
          Userpd: item?.noOfUsers,
          State: item?.state,
          country: item?.country,
        };
      });
      setDistrictList(data);
    }
  };
  // const showReadMore = (des) => {
  //   Swal.fire({
  //     position: "top-center",
  //     width: "50%",
  //     title: "Description",
  //     text: des,
  //     confirmButtonColor: "#fff",
  //     confirmButtonTextColor: "#000000",
  //     color: "#ffffff",
  //     background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
  //   });
  // };
  const getInitials = (districtName) => {
    if (!districtName) return "";

    const names = districtName.split(" ");
    const firstNameInitial = names[0]?.charAt(0).toUpperCase();
    const lastNameInitial = names[names.length - 1]?.charAt(0).toUpperCase();

    return `${firstNameInitial}${lastNameInitial}`;
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="fig_family_page main_baclcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="fig_family_box">
                <div className="listdata_control_sec mb-2 d-flex justify-content-between">
                  <div className="title">District List</div>
                  <div className=" search_sec w-25">
                    <div className="search_input w-100">
                      <input
                        onChange={(e) => {
                          setSearchField(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <img src={search_icon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="school_district">
                  <div className="row">
                    {formattedSearchResults &&
                      formattedSearchResults
                        ?.slice(
                          (currentPage - 1) * pageSize,
                          (currentPage - 1) * pageSize + pageSize
                        )
                        ?.map((district, i) => (
                          <div
                            className="col-xl-2 col-lg-4 col-md-6 col-12 mb-3"
                            key={i}
                          >
                            <Link
                              className="view_btn"
                              to={`/fig-family/${district.districtId}`}
                            >
                              <div className="district_box">
                                <div className="img_box">
                                  <img
                                    src={district?.Logo}
                                    alt=""
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = pro_img;
                                    }}
                                  />
                                </div>
                                <div className="details">
                                  <div className="d_boc">
                                    <div className="d_name">
                                      {district?.DistrictName}
                                    </div>
                                    <div className="txt">
                                      {district?.aboutUs?.slice(0, 85)}
                                      {district?.aboutUs?.length > 85 && "..."}
                                    </div>
                                    {/* <div className="txt">{district?.State}</div> */}
                                    {/* <div className="txt email">
                                    {district?.Email}
                                  </div> */}
                                    {/* <div className="txt">
                                    {district?.PhoneNumber}
                                  </div> */}
                                  </div>
                                  {/* <Link
                                  className="view_btn"
                                  to={`/fig-family/${district.districtId}`}
                                >
                                  <RemoveRedEyeIcon />
                                </Link> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                  </div>
                  {formattedSearchResults?.length === 0 ? (
                    <div className="text-center my-3 p_20_text">
                      Data Not Found
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="page_nation">
                    <div className="rowsPerPageOptions">
                      <span className="p_18_text">show</span>
                      <select
                        className="pagination_select p_16_text"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                      </select>
                      <span className="p_18_text">entries</span>
                    </div>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={formattedSearchResults?.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default FigFamily;
