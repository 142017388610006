import React, { Suspense, lazy, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
// import clever_log_btn_img from "../../images/clever_log_btn_img1.png";
import close_icon from "../../images/Icon/eye.svg";
import open_icon from "../../images/Icon/eye-off.svg";
import back_img from "../../images/login_in_Background.svg";
import Login_img from "../../images/login_logo.png";
import "./login.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import Config from "../../Utils/Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../reducers/login";
import { deviceDetect, mobileModel } from "react-device-detect";
import Swal from "sweetalert2";
import { TopHeaderMenuList, resetSetting } from "../../reducers/setting";
import { DashNewPop } from "../../reducers/user";
import saml from "../../images/Icon/saml.svg";
// import google_play from "../../images/Icon/googlePlay_black.png";
// import app_store from "../../images/Icon/AppStore_black.svg";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import HelpBox from "./ForgotPassword/helpBox";
import { GetCallApi, PostCallApi } from "../../Action/Action";

const ForgotPassword = lazy(() => import("./ForgotPassword"));
const renderLoader = () => <p></p>;
var CryptoJS = require("crypto-js");

let oldMenu = [
  {
    menuName: "Community Groups",
    menuUrl: "/community-groups",
    old: true,
  },
  { menuName: "Help", menuUrl: "/help", old: true },
  { menuName: "Messages", menuUrl: "/messages", old: true },
  { menuName: "Profile", menuUrl: "/edit-profile", old: true },
  {
    menuName: "View Profile",
    menuUrl: "/view-profile",
    old: true,
  },
  {
    menuName: "Email Preference",
    menuUrl: "/email-preference",
    old: true,
  },
  {
    menuName: "Logged Reset Password",
    menuUrl: "/logged-reset-password",
    old: true,
  },
  {
    menuName: "View Quiz Submitted",
    menuUrl: "/view-quiz-submitted",
    old: true,
  },
  {
    menuName: "certificate",
    menuUrl: "/certificate",
    old: true,
  },
  {
    menuName: "Complete Payment",
    menuUrl: "/complete-payment",
    old: true,
  },
];

function Login() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const [frPass, setFrPass] = useState(1);
  // const loggedIn = useSelector(
  //   (state) => state.login.LoginDetails.accessToken
  // );
  let desktop = deviceDetect();
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const onSubmit = async (data) => {
    setOpen(true);
    // navigate("/dashboard", { replace: true });
    let today = new Date();
    let logDe = {
      loginType: "login",
      loginDate: convert(today),
      latitude: "0",
      logitude: "0",
      deviceType: mobileModel === "none" ? "desktop" : "Mobile",
      deviceName: mobileModel === "none" ? desktop?.osName : mobileModel?.model,
      email: data.email,
      password: data.password,
    };
    // let url = `${Config.API_HOST_URL_live}/api/Login/Login`;
    let seData = {
      url: "/api/Login/Login",
      body: logDe,
    };
    let response = await PostCallApi(seData);
    // axios
    //   .post(`${url}`, logDe)
    //   .then(async (response) => {
    if (response?.status === 200) {
      if (response.data.success) {
        const nows = new Date();
        setOpen(false);

        let loginDeatil = {
          ...response.data?.payload,
          versionId: response.data?.versionId,
          authenticate: true,
        };
        dispatch(resetSetting());

        if (loginDeatil.roleId === 1 || loginDeatil.roleId === 2) {
          dispatch(DashNewPop(true));
        }
        let mse = {
          url: `/api/AccessLevel/GetHeaderMenu?userId=${loginDeatil?.userId}&menuType=HeaderMenu`,
          headers: {
            Authorization: `Bearer ${loginDeatil?.accessToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
        let res = await GetCallApi(mse);
        if (res?.status === 200) {
          if (res?.data.success) {
            let menuData = res.data.payload;
            menuData = [...menuData, ...oldMenu];
            if (res.data?.accessType === 3) {
              menuData = menuData?.filter((x) => x.menuUrl !== "/community");
            }
            dispatch(TopHeaderMenuList(menuData));
          } else {
            let menuData = [
              {
                menuId: 1,
                menuName: "Dashboard",
                menuUrl: "/dashboard",
                sequenceNo: 2,
              },
            ];
            menuData = [...menuData, ...oldMenu];
            dispatch(TopHeaderMenuList(menuData));
            // Swal.fire({
            //   position: "center",
            //   width: "50%",
            //   // icon: "success",
            //   title: "You have no menu assign that's why not login in site. ",
            //   // text: des,

            //   confirmButtonText: "Ok",
            //   // confirmButtonColor: "#E64942",
            // });
          }
        }
        dispatch(LoginUser(loginDeatil));
        navigate("/dashboard", { replace: true });

        if (data.remember) {
          setWithExpiry(
            "expireDate",
            loginDeatil?.email,
            loginDeatil?.password,
            nows.getDate() + 7,
            nows.getMonth() + 1
          );
        } else {
          if (res?.status === 200) {
            if (res?.data.success) {
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data?.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            }
          }
        }
        function setWithExpiry(key, e, p, expiration, mon) {
          // `item` is an object which contains the original value
          // as well as the time when it's supposed to expire
          var encrypted = CryptoJS.AES.encrypt(
            p,
            "my-secret-key@123"
          ).toString();
          const item = {
            email: e,
            password: encrypted,
            expiry: expiration > 30 ? 1 : expiration,
            month: expiration > 30 ? mon + 1 : mon,
          };
          localStorage.setItem(key, JSON.stringify(item));
        }
        setDerror({ Log: "" });
      } else {
        setDerror({ Log: response.data?.message });
        setOpen(false);
      }
    } else {
      // })
      // .catch((error) => {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
      // });
    }
  };
  const ed = JSON?.parse(localStorage?.getItem("expireDate"));
  const now = new Date();
  useEffect(() => {
    if (ed) {
      var decrypted = CryptoJS.AES.decrypt(ed?.password, "my-secret-key@123");
      let today = now.getMonth() + 1;
      if (today < ed?.month) {
        let logDe = {
          loginType: "login",
          loginDate: convert(now),
          latitude: "0",
          logitude: "0",
          deviceType: mobileModel === "none" ? "desktop" : "Mobile",
          deviceName:
            mobileModel === "none" ? desktop?.osName : mobileModel?.model,
          email: ed?.email,
          password: decrypted?.toString(CryptoJS.enc.Utf8),
        };
        let url = `${Config.API_HOST_URL_live}/api/Login/Login`;
        axios
          .post(url, logDe)
          .then(async (response) => {
            if (response.data.success) {
              let loginDeatil = {
                ...response.data?.payload,
                versionId: response.data?.versionId,
                authenticate: true,
              };
              if (loginDeatil.roleId === 1 || loginDeatil.roleId === 2) {
                dispatch(DashNewPop(true));
              }
              let mse = {
                url: `/api/AccessLevel/GetHeaderMenu?userId=${loginDeatil?.userId}&menuType=HeaderMenu`,
                headers: {
                  Authorization: `Bearer ${loginDeatil?.accessToken}`,
                  "Content-Type": "application/json",
                  Accept: "*/*",
                },
              };
              let res = await GetCallApi(mse);
              if (res?.status === 200) {
                if (res?.data.success) {
                  let menuData = res.data.payload;
                  menuData = [...menuData, ...oldMenu];
                  if (res.data?.accessType === 3) {
                    menuData = menuData?.filter(
                      (x) => x.menuUrl !== "/community"
                    );
                  }
                  dispatch(TopHeaderMenuList(menuData));
                } else {
                  let menuData = [
                    {
                      menuId: 1,
                      menuName: "Dashboard",
                      menuUrl: "/dashboard",
                      sequenceNo: 2,
                    },
                  ];
                  menuData = [...menuData, ...oldMenu];
                  dispatch(TopHeaderMenuList(menuData));

                  // Swal.fire({
                  //   position: "center",
                  //   width: "50%",
                  //   // icon: "success",
                  //   title:
                  //     "You have no menu assign that's why not login in site. ",
                  //   // text: des,

                  //   confirmButtonText: "Ok",
                  //   // confirmButtonColor: "#E64942",
                  // });
                  // localStorage.clear("expireDate");
                  // dispatch(LoginUser({ authenticate: false }));
                }
              }
              dispatch(LoginUser(loginDeatil));
              navigate("/dashboard", { replace: true });
            }
          })
          .catch((error) => {
            setOpen(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            console.log(error);
          });
      } else if (today === ed?.month) {
        if (now.getDate() > ed.expiry) {
          localStorage.clear("expireDate");
          dispatch(LoginUser({ authenticate: false }));
        } else {
          let logDe = {
            loginType: "login",
            loginDate: convert(now),
            latitude: "0",
            logitude: "0",
            deviceType: mobileModel === "none" ? "desktop" : "Mobile",
            deviceName:
              mobileModel === "none" ? desktop?.osName : mobileModel?.model,
            email: ed?.email,
            password: decrypted?.toString(CryptoJS.enc.Utf8),
          };
          let url = `${Config.API_HOST_URL_live}/api/Login/Login`;
          axios
            .post(url, logDe)
            .then(async (response) => {
              if (response.data.success) {
                let loginDeatil = {
                  ...response.data?.payload,
                  versionId: response.data?.versionId,
                  authenticate: true,
                };
                if (loginDeatil.roleId === 1 || loginDeatil.roleId === 2) {
                  dispatch(DashNewPop(true));
                }
                let mse = {
                  url: `/api/AccessLevel/GetHeaderMenu?userId=${loginDeatil?.userId}&menuType=HeaderMenu`,
                  headers: {
                    Authorization: `Bearer ${loginDeatil?.accessToken}`,
                    "Content-Type": "application/json",
                    Accept: "*/*",
                  },
                };
                let res = await GetCallApi(mse);
                if (res?.status === 200) {
                  if (res?.data.success) {
                    let menuData = res.data.payload;
                    menuData = [...menuData, ...oldMenu];
                    if (res.data?.accessType === 3) {
                      menuData = menuData?.filter(
                        (x) => x.menuUrl !== "/community"
                      );
                    }
                    dispatch(TopHeaderMenuList(menuData));
                    Swal.fire({
                      position: "top-end",
                      width: 400,
                      // icon: "success",
                      text: titleCase(response.data?.message),
                      showConfirmButton: false,
                      timer: 1500,
                      color: "#ffffff",
                      background:
                        "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                      showClass: {
                        popup: "animate__animated animate__fadeInDown",
                      },
                      hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                      },
                    });
                  } else {
                    let menuData = [
                      {
                        menuId: 1,
                        menuName: "Dashboard",
                        menuUrl: "/dashboard",
                        sequenceNo: 2,
                      },
                    ];
                    menuData = [...menuData, ...oldMenu];
                    dispatch(TopHeaderMenuList(menuData));
                    // Swal.fire({
                    //   position: "center",
                    //   width: "50%",
                    //   // icon: "success",
                    //   title:
                    //     "You have no menu assign that's why not login in site. ",
                    //   // text: des,

                    //   confirmButtonText: "Ok",
                    //   // confirmButtonColor: "#E64942",
                    // });
                    // localStorage.clear("expireDate");
                    // dispatch(LoginUser({ authenticate: false }));
                  }
                }
                dispatch(LoginUser(loginDeatil));
                navigate("/dashboard", { replace: true });
              }
            })
            .catch((error) => {
              setOpen(false);
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "red",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              console.log(error);
            });
        }
      }
    } else {
      localStorage.clear("expireDate");
      dispatch(LoginUser({ authenticate: false }));
    }
    // eslint-disable-next-line
  }, [ed]);
  // const clDe = useSelector((state) => state.login?.CleverDetails);
  // const [clbtn, setClBtn] = useState(false);

  // useEffect(() => {
  //   let cleverSc = {
  //     secretToken: "FgGr@w!213846",
  //   };
  //   axios
  //     .post(
  //       `${Config.API_HOST_URL_live}/api/Login/GetCleverCredentials`,
  //       cleverSc
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         if (res.data.success) {
  //           setClBtn(true);
  //           dispatch(CleverClientId(res.data.payload));
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));

  //   // eslint-disable-next-line
  // }, []);

  const navigateToContacts = () => {
    window.location.href =
      Config.HOST_URL_Live_SSO +
      "/oauth/authorize?client_id=" +
      Config.SSO_ClientId +
      "&redirect_uri=" +
      // Config.API_HOST_URL_live +
      // "/api/login/oauth/callback&response_type=code";
      // "http://localhost:3000" +
      Config.HOST_URL_live_clever +
      "/sso-login/code&response_type=code";
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <section className="login_1">
          <div className="row m-0">
            <div className="col-md-6 p-0">
              <img src={back_img} alt="" className="img_back" />
            </div>
            <div
              className={`col-md-6 p-0 ${frPass === 1
                  ? "d-flex flex-column justify-content-between"
                  : "m-auto"
                } `}
            >
              {frPass === 1 ? (
                <>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="form_box my-auto"
                  >
                    <div className="left_logo">
                      <img className="img-fluid" src={Login_img} alt="" />
                    </div>
                    <div className="logo_details">
                      <h3>Login</h3>
                      <div className="form-group login_input_box">
                        <label className="form-label ">Email Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Email Address"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group login_input_box position-relative">
                        <label className="form-label ">Password</label>
                        <input
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            minLength: {
                              value: 8,
                              message: "Must be 8 characters",
                            },
                            maxLength: {
                              value: 15,
                              message: "Max 15 characters",
                            },
                          })}
                        />
                        <span className="eye" onClick={togglePasswordVisiblity}>
                          <img
                            src={
                              passwordShown === true ? close_icon : open_icon
                            }
                            alt=""
                          />
                        </span>
                        {errors.password && (
                          <span role="alert" className="error_text">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between  login_re_for">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            {...register("remember")}
                          />
                          <span className="checkmark"></span>
                          Remember Me
                        </label>
                        <div className="login_link">
                          {/* <span>Lost your password?</span> */}
                          <Link onClick={() => setFrPass(2)}>
                            Lost your password?
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between login_input_box">
                        <Link
                          to="/privacy-policy"
                          style={{ textDecoration: "none" }}
                        >
                          Privacy Policy
                        </Link>
                        <Link
                          to={`${Config?.API_HOST_URL_live}/Files/login/FIG%20K12%20EULA.pdf`}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          End User License Agreement
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between sub_clever_btn">
                        <div className="log_btn_box">
                          <input
                            type="submit"
                            value="Sign In"
                            className="login_button"
                          />
                          {derror?.Log && (
                            <span role="alert" className="d-block error_text">
                              {derror.Log}
                            </span>
                          )}
                        </div>
                        <HelpBox test={() => reset()} />
                      </div>
                      <div className="d-flex align-center or_line">
                        <hr />
                        <p className="or_text ">OR</p>
                        <hr />
                      </div>
                      <div className="d-flex justify-content-between align-items-center sub_clever_btn mt-3 new_bor_top">
                        {/* {clbtn ? (
                          <div className="clever_log_btn">
                            <a
                              href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${Config.HOST_URL_live_clever}/clever-login&client_id=${clDe?.clientId}&district_id=${clDe?.districtId}`}
                              // href={`https://clever.com/oauth/instant-login?client_id=${clDe?.clientId}&district_id=${clDe?.districtId}`}
                            >
                              <img src={clever_log_btn_img} alt="" />
                            </a>
                          </div>
                        ) : (
                          ""
                        )} */}
                        <div
                          className="saml_log_btn"
                          onClick={navigateToContacts}
                        >
                          <p>
                            <img src={saml} alt="" />

                            <span>Log in with SSO</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex download_app_link ms-5 mb-5">
                    <a
                      href="https://apps.apple.com/in/app/fig-k-12/id6450881384"
                      // href="https://apps.apple.com/us/app/fig-k-12/id1665894292"
                      target="_blank"
                      className="app-btn me-3 before"
                      rel="noreferrer"
                    >
                      <AppleIcon className="icon" />
                      Available on App Store
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.figk12.app"
                      // href="https://play.google.com/store/apps/details?id=com.app.fig&hl=en_US&gl=US"
                      target="_blank"
                      className="app-btn"
                      rel="noreferrer"
                    >
                      <ShopIcon className="icon" />
                      Available on Google Play
                    </a>
                  </div>
                </>
              ) : frPass === 2 ? (
                <ForgotPassword setFrPass={setFrPass} />
              ) : (
                //  frPass === 3 ? (
                //   <ResetPassword setFrPass={setFrPass} />
                // ) :
                ""
              )}
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
export default React.memo(Login);
