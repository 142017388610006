import React, { useState } from "react";

function WriteFeedBack({ handleClose, titleCource }) {
  const [feedTxt, setFeedTxt] = useState(400);
  const [feedVal, setFeedVal] = useState("");

  const feedcountTextLength = (event) => {
    let count = event?.length;
    let countNum = 400;
    setFeedVal(event);
    if (count === 0) {
      setFeedTxt(countNum);
    } else if (count > 400) {
      document.getElementById("feed_txt").maxLength = 400;
      let k = event.slice(0, -1);
      setFeedVal(k);
    } else if (event) {
      setFeedTxt(countNum - count);
    }
  };
  const onSubmit = (data) => {
    setFeedTxt(400);
    setFeedVal("");
  };

  return (
    <div className="feed_rate">
      <h6>Your feedback on {titleCource}</h6>
      <textarea
        name="feed"
        id="feed_txt"
        rows="5"
        className="form-control"
        placeholder="provide your feedback ....."
        maxLength="400"
        value={feedVal}
        onChange={(e) => feedcountTextLength(e.target.value)}
      ></textarea>
      <p className="text-end">{feedTxt} remaining character(s)</p>
      <p className="term_con">
        *This feedback will be privately emailed to the author, if you want to
        review/rate the course please
      </p>
      <div className="text-center" onClick={handleClose}>
        <button type="button" onClick={onSubmit}>
          Send Feedback
        </button>
      </div>
    </div>
  );
}

export default React.memo(WriteFeedBack);
