import React, { Suspense } from "react";
import "./index.scss";
import rightarrow from "../../images/right-arrow.svg";
import { Link } from "react-router-dom";
import Ashley from "../../images/Ashley-morena.webp";
import becki from "../../images/becki-swan.webp";
import twitter from "../../images/meet-twitter-icon.svg";
import facebook from "../../images/meet-fb.svg";
import instagram from "../../images/meet-insta.svg";
import intersect from "../../images/intersect-icon.svg";

function MeetTeam() {
  return (
    <Suspense>
      <main className="meetpage">
        <section className="aboutbanner-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-10 text-center">
                <div className="banner-content">
                  <h1>
                    MEET OUR TEAM
                    <img
                      src={rightarrow}
                      alt="right-arrow"
                      class="right-arrow"
                    ></img>
                  </h1>
                  <h5>
                    FIG is your one stop shop for all things Professional
                    Development and Employee Wellness for your school nutrition
                    department. Connect with your team and encourage success in
                    meaningful ways relevant to them.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Start MEET OUR TEAM */}
        <section className="meetteam-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title-text">MEET OUR TEAM</h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="meetteam-block">
                  <div className="meet-img">
                    <img src={Ashley} alt="Ashley Morena" />
                  </div>
                  <div className="team-detail">
                    <img src={intersect} alt="" className="intersect-img" />
                    <h5>ASHLEY MORENA</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cras tincidunt orci ut suscipit pretium.
                    </p>
                    <div className="social-icon">
                      <Link>
                        <img src={twitter} alt="Twitter" />
                      </Link>
                      <Link>
                        <img src={facebook} alt="Facebook" />
                      </Link>
                      <Link>
                        <img src={instagram} alt="Instagram" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="meetteam-block-right">
                  <div className="team-detail-right">
                    <img src={intersect} alt="" className="intersect-img" />
                    <h5>BECKI SWAN</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cras tincidunt orci ut suscipit pretium.
                    </p>
                    <div className="social-icon">
                      <Link>
                        <img src={twitter} alt="Twitter" />
                      </Link>
                      <Link>
                        <img src={facebook} alt="Facebook" />
                      </Link>
                      <Link>
                        <img src={instagram} alt="Instagram" />
                      </Link>
                    </div>
                  </div>
                  <div className="meet-right-img">
                    <img src={becki} alt="Becki Swan" />
                  </div>
                </div>
                <div className="meetteam-block">
                  <div className="meet-img">
                    <img src={Ashley} alt="Ashley Morena" />
                  </div>
                  <div className="team-detail">
                    <img src={intersect} alt="" className="intersect-img" />
                    <h5>ASHLEY MORENA</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cras tincidunt orci ut suscipit pretium.
                    </p>
                    <div className="social-icon">
                      <Link>
                        <img src={twitter} alt="Twitter" />
                      </Link>
                      <Link>
                        <img src={facebook} alt="Facebook" />
                      </Link>
                      <Link>
                        <img src={instagram} alt="Instagram" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="meetteam-block-right">
                  <div className="team-detail-right">
                    <img src={intersect} alt="" className="intersect-img" />
                    <h5>BECKI SWAN</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cras tincidunt orci ut suscipit pretium.
                    </p>
                    <div className="social-icon">
                      <Link>
                        <img src={twitter} alt="Twitter" />
                      </Link>
                      <Link>
                        <img src={facebook} alt="Facebook" />
                      </Link>
                      <Link>
                        <img src={instagram} alt="Instagram" />
                      </Link>
                    </div>
                  </div>
                  <div className="meet-right-img">
                    <img src={becki} alt="Becki Swan" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End MEET OUR TEAM */}
      </main>
    </Suspense>
  );
}

export default MeetTeam;
