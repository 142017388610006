import React, { Suspense } from "react";
import "./index.scss";
// import ceu from "../../images/ceu-banner.png";
// import rightarrow from "../../images/right-arrow.svg";
// import meetcoaches from "../../images/meet-coaches-video.png";
import ReactPlayer from "react-player";
import { useState } from "react";
import { GetCallApi } from "../../Action/Action";
import { useEffect } from "react";
// import cardimg1 from "../../images/fig_CROP.png";
import cardimg1 from "../../images/default_profile.jpg";
import Config from "../../Utils/Config";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function Coaches() {
  const [isLoading, setIsLoading] = useState(true);
  const [insData, setInsData] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);


  useEffect(() => {
    // startTimer();
    setOpenCircle(!openCircle);
    setIsLoading(true);
    setTimeout(() => {
      getCoachData();
    }, 200);

    // eslint-disable-next-line
  }, []);
  // const intervalIDRef = React.useRef(null);

  // const startTimer = React.useCallback(() => {
  //   var count = 0;
  //   intervalIDRef.current = setInterval(() => {
  //     document.getElementById("loading").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //     document.getElementById("loading1").innerHTML =
  //       "Loading" + new Array((++count % 4) + 1).join(".");
  //   }, 500);
  // }, []);

  // const stopTimer = React.useCallback(() => {
  //   clearInterval(intervalIDRef.current);
  //   document.getElementById("loading").innerHTML = "";
  //   document.getElementById("loading1").innerHTML = "";
  // }, []);
  const getCoachData = async () => {
    let seData = {
      url: `/api/Coaches/GetCoachesDataWithOutToken`,
    };
    let res = await GetCallApi(seData);
    // stopTimer();
    if (res?.status === 200) {
      if (res.data.success) {
        let abData = res.data.payload;
        setInsData(abData);
        setOpenCircle(false);
        setIsLoading(false);
      } else {
        setOpenCircle(false);
        setIsLoading(false);
      }
    } else {
      setOpenCircle(false);
      setIsLoading(false);
    }
  };
  const showReadMore = (des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Backdrop
        sx={{ color: "#86c65a", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <main>
        {/* <section className="coaches-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>
                    IT’S NOT JUST LESSON ITS
                    <span> LIVING HEALTHY & FIT LIFE</span> LESSON
                    <img
                      src={rightarrow}
                      alt="right-arrow"
                      className="right-arrow"
                    />{" "}
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 right-banner">
                <img
                  src={ceu}
                  alt="Organic USDA"
                  className="banner-desktop-img"
                />
              </div>
              <div></div>
            </div>
          </div>
        </section> */}

        {/* Start PROFESSIONAL DEVELOPMENT COACHES */}
        <section className="professional-section">
          <div className="container">
            <div className="row">
              <h2 className="title-text"> PROFESSIONAL DEVELOPMENT COACHES</h2>
              {/* <div id="loading" className="h6_text text-center"></div> */}

              {isLoading ? (
                <div className="d-flex gap-3">
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                </div>
              ) : insData?.length > 0 ? (
                <>
                  {insData
                    ?.filter((x) => x?.courseType === "1")
                    ?.map((text, i) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-4 col-12 margin-bottom"
                        key={i}
                      >
                        <div className="catlog-box">
                          <div className="image">
                            <img
                              // src={
                              //   text?.profileURL
                              //     ? `${Config.API_HOST_URL_live}${text?.profileURL}`
                              //     : cardimg1
                              // }
                              src={
                                text?.thumbnailProfileUrl
                                  ? `${Config.API_HOST_URL_live}${text?.thumbnailProfileUrl}`
                                  : cardimg1
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = cardimg1;
                              }}
                              alt="COACHES"
                            />
                          </div>
                          <h6>{text?.coachesName}</h6>
                          <p>
                            {text?.description?.slice(0, 85)}
                            {text?.description?.length > 85 && (
                              <span
                                className="description cr"
                                onClick={() => showReadMore(text?.description)}
                              >
                                ...read more
                              </span>
                            )}
                          </p>
                          {/* <div className="right-btn">135
                      <button className="secondary-btn">
                        READ MORE <img src={rightarrow} alt="right-arrow" />{" "}
                      </button>
                    </div> */}
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <h3>
                  Keep watching, we are getting some exciting things for you.
                </h3>
              )}
            </div>
            <div className="row mt-4">
              <h2 className="title-text">FIG LIFESTYLE COACHES</h2>
              {/* <div id="loading1" className="h6_text text-center"></div> */}
              {isLoading ? (
                <div className="d-flex gap-3">
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                  <div className="skeleton-loader mb-4">{box}</div>
                </div>
              ) : insData?.length > 0 ? (
                <>
                  {insData
                    ?.filter((x) => x?.courseType === "2")
                    ?.map((text, i) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-4 col-12 margin-bottom"
                        key={i}
                      >
                        <div className="catlog-box">
                          <div className="image">
                            <img
                              // src={
                              //   text?.profileURL
                              //     ? `${Config.API_HOST_URL_live}${text?.profileURL}`
                              //     : cardimg1
                              // }
                              src={
                                text?.thumbnailProfileUrl
                                  ? `${Config.API_HOST_URL_live}${text?.thumbnailProfileUrl}`
                                  : cardimg1
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = cardimg1;
                              }}
                              alt="COACHES"
                            />
                          </div>
                          <h6>{text?.coachesName}</h6>
                          <p>
                            {" "}
                            {text?.description?.slice(0, 85)}
                            {text?.description?.length > 85 && (
                              <span
                                className="description cr"
                                onClick={() => showReadMore(text?.description)}
                              >
                                ...read more
                              </span>
                            )}
                          </p>
                          {/* <div className="right-btn">
                      <button className="secondary-btn">
                        READ MORE <img src={rightarrow} alt="right-arrow" />{" "}
                      </button>
                    </div> */}
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <h3>
                  Keep watching, we are getting some exciting things for you.
                </h3>
              )}
            </div>
          </div>
        </section>
        {/* End PROFESSIONAL DEVELOPMENT COACHES */}

        {/* Start MEET OUR COACHES */}
        <section className="meetcoasches-section common-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title-text">MEET OUR COACHES</h2>
                <p>
                  We've tapped into some of the finest instructors this industry
                  has to offer to offer your team a variety of courses. See
                  below to get to know more about the coaches you will see
                  featured in FIG K12.
                </p>

                {/* <img src={meetcoaches} alt="MeetCoaches" /> */}
              </div>
            </div>
            <ReactPlayer
              url={`https://vimeo.com/496033762`}
              className="react-player"
              volume={1}
              width="100%"
              height="620px"
              controls
              // responsive
            />
          </div>
        </section>
        {/* Start MEET OUR COACHES */}
      </main>
    </Suspense>
  );
}

export default Coaches;
