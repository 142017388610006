import React, { useEffect, useState } from "react";
import { GetCallApi } from "../../../Action/Action";
import "./index.scss";
import cardimg1 from "../../../images/course_not_found_final_1.png";
import Swal from "sweetalert2";
import Config from "../../../Utils/Config";
import { Link } from "react-router-dom";

function WebinarLive() {
  const [isLoading, setIsLoading] = useState(true);
  const [webinarData, setWebinarData] = useState([]);

  useEffect(() => {
    // GetAllEventsList();
    setIsLoading(true);
    setTimeout(() => {
      getWebinar();
    }, 200);
    // eslint-disable-next-line
  }, []);

  const getWebinar = async () => {
    var date1 = new Date().toLocaleDateString();
    let seData = {
      url: "/api/Webinar/GetMainSiteData",
      headers: {},
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ctData = res.data.payload
          ?.filter((x) => x?.webinarDate !== null)
          ?.filter((x) => new Date(x?.webinarDate) >= new Date(date1))
          ?.map((x) => {
            return {
              ...x,
              webinarDate: x.webinarDate
                ? new Date(x?.webinarDate).toLocaleDateString()
                : "",
              webinarTime: x.webinarTime
                ? new Date(x?.webinarTime).toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                : "",
              cardimg: x?.webinarImage
                ? `${Config.API_HOST_URL_live}${x?.webinarImage}`
                : cardimg1,
            };
          });
        setWebinarData(ctData);
        setIsLoading(false);
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
      setIsLoading(false);
    }
  };
  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  return (
    <section className="webinar_live common-padding">
      <div className="container">
        <div className="row">
          <h2 className="title-text">Live Webinar</h2>
          {isLoading ? (
            <>
              {[...Array(4).keys()]?.map((x, i) => (
                <div className="col-xl-3 col-lg-4 col-md-6 mb-5" key={i}>
                  <div className="d-flex gap-3">
                    <div className="skeleton-loader">
                      {box}
                      <div className="skeleton-line mt-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          )}

          {webinarData?.map((item, i) => (
            <div className="col-xl-3 col-lg-4 col-md-6 mb-5" key={i}>
              <div className={`carddetail_box `}>
                <div className="img_box_text" style={{ cursor: "default" }}>
                  <img
                    src={item?.cardimg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = cardimg1;
                    }}
                    className="img-fluid main_card_img"
                    alt=""
                  />
                </div>
                <div className="card_details">
                  <div className={`card_title tr`}>{item?.title}</div>
                  <div className="d-flex justify-content-between">
                    <span className="card_lesson">{item?.webinarDate}</span>
                    <span className="card_lesson ceu">{item?.webinarTime}</span>
                  </div>
                  <div className="mt-3 category_show p_14_text">
                    Phone: <span>{item?.phone}</span>
                  </div>{" "}
                  <div className="mt-3 category_show p_14_text">
                    Email: <span>{item?.email}</span>
                  </div>
                  <div className="mt-3">
                    <span className="description ">
                      Location: {item?.address?.slice(0, 85)}
                    </span>
                    {item?.address?.length > 85 && (
                      <span
                        className="description cr"
                        onClick={() => showReadMore(item?.des)}
                      >
                        ...read more
                      </span>
                    )}
                  </div>
                </div>
                <Link
                  className="st_btn mt-auto w-100  d-flex align-items-center justify-content-center "
                  //   href={item?.linkToJoin}
                  to={item?.linkToJoin}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Join
                </Link>
              </div>
            </div>
          ))}
          {!isLoading ? (
            <>
              {webinarData?.length === 0 ? (
                <div className="h6_text text-center">
                  Keep watching, we are getting some exciting things for you.
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default WebinarLive;
