import React, { useState } from "react";
import { Button } from "@mui/material";
import userimg from "../../../../../images/user.png";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../../../Utils/Config";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Requests(props) {
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [rqList, setRqList] = useState();
  const [mes, setMes] = useState({});
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let nav = useNavigate();
  useEffect(() => {
    getReqList();
    // eslint-disable-next-line
  }, []);
  const getReqList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Requests/GetMyConnectionRequests?myUserId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          const MyReqlist = response.data.payload?.map((item) => {
            return {
              userId: item?.userId,
              sentRequestUserId: item?.sentRequestUserId,
              name: item?.firstName + " " + item?.lastName,
              img:
                item?.profileUrl === null || item?.profileUrl === ""
                  ? userimg
                  : `${Config.API_HOST_URL_live}${item?.profileUrl}`,
              activeHours: item?.activeHours !== null ? item?.activeHours : "-",
              followers: item?.followers,
              join:
                item?.userJoinDate === "" || item?.userJoinDate === null
                  ? ""
                  : convert(item?.userJoinDate?.split("T")[0]),
              sId: item?.sentRequestId,
              rId: item?.receivedRequestId,
            };
          });
          setRqList(MyReqlist);
          setMes({ userNot: "" });
        } else {
          setRqList();
          setMes({ userNot: response.data?.message });
        }
      });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      month = date.toLocaleString("default", { month: "short" }),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day].join(" ");
  }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const reqAccept = (req, sId, rId) => {
    let userData = {
      sentRequestUserId: req,
      receivedRequestUserId: LogUserId,
      Status: "Accepted",
      sentRequestId: sId,
      receivedRequestId: rId,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Requests/AcceptRequest`,
        userData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getReqList();
        }
      });
  };
  const reqReject = (req, sId, rId) => {
    let userData = {
      sentRequestUserId: req,
      receivedRequestUserId: LogUserId,
      Status: "Rejected",

      sentRequestId: sId,
      receivedRequestId: rId,
    };
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/Requests/RejectRequest`,
        userData,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data?.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          getReqList();
        } else {
        }
      });
  };
  const handleViewProfile = (id) => {
    nav(`/view-profile/${id}`, { replace: true });
  };
  return (
    <>
      {mes?.userNot && (
        <h1 role="alert" className="req_not_found">
          {mes?.userNot}
        </h1>
      )}
      {props.grid === true ? (
        <>
          {rqList?.map((item) => (
            <div className="col-md-3 mb-3">
              <div className="grid_connection_card text-center">
                <div
                  className="connection_card_text"
                  onClick={() => handleViewProfile(item.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item?.img !== "" ? item?.img : userimg}
                    className="img-fluid mb-3 user_img_p"
                    alt="user"
                  />
                  <h6>{item?.name}</h6>
                  <p>Hire Date {item?.join}</p>
                  <p>Active {item?.activeHours} hours ago</p>
                  <p>{item?.followers} followers</p>
                </div>
                <div className="request_connection_card_btn pb-3">
                  <Button
                    fullWidth
                    className="theme_btn"
                    onClick={(e) =>
                      reqAccept(item?.sentRequestUserId, item?.sId, item?.rId)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    fullWidth
                    onClick={(e) =>
                      reqReject(item?.sentRequestUserId, item?.sId, item?.rId)
                    }
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {rqList?.map((item, i) => (
            <div className="col-md-12" key={i}>
              <div className="list_connection_card">
                <div
                  className="connection_card_text d-flex"
                  onClick={() => handleViewProfile(item.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item.img !== "" ? item.img : userimg}
                    className="img-fluid user_img_p"
                    alt="user"
                  />
                  <div className="connection_card_title">
                    <h6>{item?.name}</h6>
                    <p>Hire Date {item?.join}</p>
                  </div>
                  <p>Active {item?.activeHours} hours ago</p>
                  <p>{item?.followers} followers</p>
                </div>
                <div className="request_connection_card_btn">
                  <Button
                    className="theme_btn"
                    onClick={(e) =>
                      reqAccept(item?.sentRequestUserId, item?.sId, item?.rId)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={(e) =>
                      reqReject(item?.sentRequestUserId, item?.sId, item?.rId)
                    }
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default React.memo(Requests);
