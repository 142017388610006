import React from "react";
import "./index.scss";
import pro_img from "../../../../../../images/default_profile.jpg";
import Config from "../../../../../../Utils/Config";


export default function Profile_img(props) {

  return (
    <>
      <div className="fig_family_p_img_h">
        <img
          src={
            props?.getDistrictData?.thumbnailLogoUrl === "" ||
              props?.getDistrictData?.thumbnailLogoUrl === null
              ? pro_img
              : `${Config.API_HOST_URL_live}${props?.getDistrictData?.thumbnailLogoUrl}`
          }
          alt=""
        />
      </div>
    </>
  );
}
